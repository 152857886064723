import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import { userToolsKeys } from "../queries/useGetUserTools";
import type { Tool } from "@/types/tools";
import { agentToolsKeys } from "@/data/queries/useGetAgentTools";

const updateTool = async (props: Tool) => {
  const { data } = await restClient.post<Tool>(apiPaths.updateTool, props);
  return data;
};

export const useUpdateTool = () => {
  const queryClient = useQueryClient();

  return useMutation<Tool, AxiosError<{ message: string }>, Tool>({
    mutationFn: updateTool,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: userToolsKeys.all });
      void queryClient.invalidateQueries({ queryKey: agentToolsKeys.all });
    },
  });
};
