import { Button } from "@/components/ui/button";
import { DialogClose, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { ROUTES } from "@/constants/routes";
import { useNavigate } from "react-router-dom";
import type { Agent } from "@/types/agent";
import { SocialMediaShare } from "../../../SocialMediaShare";
import type { OnShareMutationCallback } from "../../../ShareMultiEmails";
import { ShareMultiEmails } from "../../../ShareMultiEmails";
import { useShareAgentByEmail } from "@/data/mutations/useShareAgentByEmail";

export const PublishAgentDialogSuccess = ({ agentId }: { agentId: Agent["_id"] }) => {
  const navigate = useNavigate();
  const { mutate, isPending } = useShareAgentByEmail();
  const shareLink = `${import.meta.env.VITE_BASE_FRONTEND_URL}/community/agent/${agentId}`;

  const handleShare: OnShareMutationCallback = ({ emails, onSuccessCallback }) => {
    mutate(
      { agentId, emails },
      {
        onSuccess: onSuccessCallback,
      }
    );
  };

  return (
    <>
      <DialogDescription className="flex flex-col items-center justify-center gap-4">
        <div className="size-[85px] rounded-full bg-primary-100 text-green-600">
          <Icons.Tick />
        </div>
        <h4 className="text-center text-2xl font-bold">Your agent is successfully published on Agent Space!</h4>
        <div className="text-center text-sm">
          Thank you. Now you can share your agent with friends and see it on Agent Space.
        </div>
        <SocialMediaShare shareLink={shareLink} />
        <ShareMultiEmails onShare={handleShare} isMutationPending={isPending} />
      </DialogDescription>
      <DialogFooter>
        <DialogClose asChild>
          <Button variant="tertiary" size="md">
            Done
          </Button>
        </DialogClose>
        <Button className="flex gap-1" onClick={() => navigate(ROUTES.community)} size="md">
          <Icons.Community /> View on Space
        </Button>
      </DialogFooter>
    </>
  );
};
