import type { AllAgentsStat } from "@/types/stats";
import { ALL_AGENTS_STATS_COLORS_MAP } from "../../constants/statsConstants";
import { Avatar } from "@/components/Avatar";
import { BarChartBarItem, BarChartContainer, BarChartTooltip } from "@/components/ui/bar-chart";

export const StatsAllAgentsChart = ({ data }: { data: AllAgentsStat[] | undefined }) => {
  const maxValue = Math.max(
    ...(data?.flatMap(statItem => [statItem.messages, statItem.conversations, statItem.views, statItem.likes ?? 0]) ??
      [])
  );

  return (
    <BarChartContainer itemsLength={data?.length ?? 0}>
      {({ barWidth }) => {
        return data?.map(statItem => {
          const data = [
            {
              id: "1",
              label: "Views",
              value: statItem.views,
              color: ALL_AGENTS_STATS_COLORS_MAP.views.color,
              colorTransparent: ALL_AGENTS_STATS_COLORS_MAP.views.colorTransparent,
            },
            {
              id: "2",
              label: "Messages",
              value: statItem.messages,
              color: ALL_AGENTS_STATS_COLORS_MAP.messages.color,
              colorTransparent: ALL_AGENTS_STATS_COLORS_MAP.messages.colorTransparent,
            },
            {
              id: "3",
              label: "Conversations",
              value: statItem.conversations,
              color: ALL_AGENTS_STATS_COLORS_MAP.conversations.color,
              colorTransparent: ALL_AGENTS_STATS_COLORS_MAP.conversations.colorTransparent,
            },
            {
              id: "4",
              label: "Likes",
              value: statItem.likes ?? 0,
              color: ALL_AGENTS_STATS_COLORS_MAP.likes.color,
              colorTransparent: ALL_AGENTS_STATS_COLORS_MAP.likes.colorTransparent,
            },
          ];

          return (
            <BarChartBarItem
              key={statItem.agent._id}
              maxValue={maxValue}
              data={data}
              barStyle={{
                width: barWidth,
                maxWidth: "40px",
                minWidth: "32px",
                margin: "0 2px",
              }}
              renderLabel={() => {
                return (
                  <div className="absolute left-1/2 top-full mt-4 -translate-x-1/2">
                    <Avatar src={statItem.agent.avatar} size="custom" className="h-8 w-8" />
                  </div>
                );
              }}
              renderTooltip={() => (
                <BarChartTooltip
                  renderTitle={() => (
                    <div className="mb-2 flex items-center gap-2">
                      <Avatar src={statItem.agent.avatar} />
                      <p className="my-2 text-xs font-medium text-white">{statItem.agent.name}</p>
                    </div>
                  )}
                  data={data}
                />
              )}
            />
          );
        });
      }}
    </BarChartContainer>
  );
};
