import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { UserAgent } from "@/types/user-agent";

export const userAgentsKeys = {
  all: ["userAgents"],
};

const getUserAgents = async () => {
  const { data } = await restClient.get<UserAgent[]>(apiPaths.getUserAgents);
  return data;
};

export const useGetUserAgents = ({ enabled }: { enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: userAgentsKeys.all,
    queryFn: getUserAgents,
    enabled,
  });
};
