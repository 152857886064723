import { ShareConversationButtonDialog } from "@/components/Chat/components/ShareConversationButtonDialog";
import { NewConversationButton } from "./NewConversationButton";
import type { ChatAgent } from "@/types/conversation";
import { ChatTopBarInfo } from "./ChatTopBarInfo/ChatTopBarInfo";

type ChatTopBarProps = {
  agents: ChatAgent[];
  onBackClick?: () => void;
  setIsAgentDetailsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveAgentIndex?: React.Dispatch<React.SetStateAction<number>>;
};

export const ChatTopBar = ({ agents, onBackClick, setIsAgentDetailsOpen, setActiveAgentIndex }: ChatTopBarProps) => {
  return (
    <div className="flex items-center justify-between gap-2 px-5 py-4">
      <ChatTopBarInfo
        agents={agents}
        setIsAgentDetailsOpen={setIsAgentDetailsOpen}
        onBackClick={onBackClick}
        setActiveAgentIndex={setActiveAgentIndex}
      />
      <div className="flex items-center gap-2.5">
        <ShareConversationButtonDialog />
        <NewConversationButton agents={agents} />
      </div>
    </div>
  );
};
