import { domElementIds } from "@/types/dom-element-ids";
import type { FetchNextPageOptions } from "@tanstack/react-query";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export const FetchNextPageTrigger = ({
  fetchNextPage,
}: {
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => void;
}) => {
  // fetch next page logic
  const { ref, inView } = useInView({
    root: document.getElementById(domElementIds.CHAT_CONTENT_CONTAINER),
    rootMargin: "300px 0px 0px 0px",
    threshold: 0,
    initialInView: false,
  });

  useEffect(() => {
    if (inView) {
      void fetchNextPage({ cancelRefetch: false });
    }
  }, [inView, fetchNextPage]);
  // end: fetch next page logic

  return <div className="p-px" ref={ref} />;
};
