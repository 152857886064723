import { AgentState } from "@/types/agent";
import { LoadingSpinner } from "../ui/loading-spinner";
import PlaceholderRobot from "@/assets/images/robots/robot-1.svg";
import { ChatTopBar } from "./components/ChatTopBar";
import { ChatContent } from "./components/ChatContent";
import ChatBookmarksHistoryBtn from "./components/ChatBookmarksHistoryBtn";
import { ChatInputForm } from "./components/ChatInputForm";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { cn } from "@/lib/utils";
import type { ChatAgent } from "@/types/conversation";
import { useState } from "react";
import { ChatFileDropOverlay } from "./components/ChatFileDropOverlay";

type ChatProps = {
  agents: (ChatAgent | undefined)[];
  isLoading: boolean;
  isError: boolean;
  onBackClick?: () => void;
  setIsAgentDetailsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onOpenMobileBookmarksHistoryPanel?: () => void;
  setActiveAgentIndex?: React.Dispatch<React.SetStateAction<number>>;
};

export const Chat = ({
  agents,
  isLoading,
  isError,
  onBackClick,
  setIsAgentDetailsOpen,
  onOpenMobileBookmarksHistoryPanel,
  setActiveAgentIndex,
}: ChatProps) => {
  const { isMobile } = useMediaQueriesContext();

  const [isUserDragging, setIsUserDragging] = useState(false);

  const handleDragEnter: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsUserDragging(true);
  };

  if (isLoading) {
    return (
      <div className="grid size-full place-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="p-10">
        <p>There was an error when retrieving agent data.</p>
      </div>
    );
  }

  if (agents.some(agent => !agent)) {
    return (
      <div className="flex size-full flex-col items-center justify-center gap-10">
        <img src={PlaceholderRobot} className="max-h-60" alt="a smiling cartoon-like robot" />
        <p>Create agent first to see chat preview.</p>
      </div>
    );
  }

  const isAgentLoading = agents.some(
    agent => agent?.state === AgentState.AGENT_CREATING || agent?.state === AgentState.AGENT_UPDATING
  );

  const agentsFiltered = agents.filter(agent => !!agent).map(agent => agent!);

  return (
    <div
      className={cn(
        "relative z-40 mx-auto flex size-full flex-col overflow-x-hidden",
        isAgentLoading && "pointer-events-none opacity-50"
      )}
      {...(isAgentLoading && {
        "aria-disabled": true,
        onClick: e => e.preventDefault(),
        onKeyDown: e => e.preventDefault(),
      })}
      onDragEnter={handleDragEnter}
    >
      {isUserDragging && <ChatFileDropOverlay setIsUserDragging={setIsUserDragging} />}

      <ChatTopBar
        agents={agentsFiltered}
        setIsAgentDetailsOpen={setIsAgentDetailsOpen}
        onBackClick={onBackClick}
        setActiveAgentIndex={setActiveAgentIndex}
      />

      <ChatContent agents={agentsFiltered} />

      {isMobile && (
        <div className="flex justify-end">
          <ChatBookmarksHistoryBtn onClick={onOpenMobileBookmarksHistoryPanel} />
        </div>
      )}

      <ChatInputForm agents={agentsFiltered} />
      <div className="my-2 text-center text-xxs font-medium text-neutral-500">AI can make mistakes</div>
    </div>
  );
};
