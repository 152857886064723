import { DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";

const AgentTileOptionsButton = () => {
  return (
    <DropdownMenuTrigger
      onClick={e => e.stopPropagation()}
      className="absolute right-3 top-3 z-20 scale-125 rounded-[4px] bg-white p-1 group-hover/tile:visible data-[state=open]:visible lg:invisible"
    >
      <Icons.Dots2 className="h-4 w-4 text-primary-400" />
    </DropdownMenuTrigger>
  );
};

export default AgentTileOptionsButton;
