import { agentCreationId } from "@/constants/agentCreationId";
import type { Parameter } from "./tools";
import { leadGenerationRequiredParams } from "@/constants/leadGenerationContants";
import type { Deployments } from "./user-agent";

export type Agent = {
  _id: string;
  creator: string;
  isActive: boolean;
  agentType: string;
  name: string;
  avatar: string;
  contacts: string[];
  blocked: string[];
  blockedFrom: string[];
  deleted: false;
  createdAt: string;
  updatedAt: string;
  state: AgentState;
  publishedToCommunity: boolean;
  description?: string;
  deployments?: Deployments[];
  websiteEmbeddingKey?: string;
};

export enum AgentState {
  AGENT_READY = "AGENT_READY",
  AGENT_UPDATING = "AGENT_UPDATING",
  AGENT_ERROR = "AGENT_ERROR",
  AGENT_DRAFT = "AGENT_DRAFT",
  AGENT_CREATING = "AGENT_CREATING",
  AGENT_REMOVING = "AGENT_REMOVING",
}

export type AgentConfig = {
  _id: string;
  botId: string;
  embedKnowledges: {
    web: EmbedKnowledges[];
    doc: (EmbedKnowledges | FutureEmbedKnowledges)[];
    faq: EmbedKnowledges[];
    externalHelpers: EmbedExternalKnowledge[];
  };
  inMemoryKnowledge: {
    about: string;
    instruction: string;
    onboardingList: Onboarding[];
  };
  company: string;
  temperature: number;
  llmModel: string;
  definedActions?: { greeting: { message: string }[]; predefined: Predefined[]; _id: string };
  publish: {
    isConfigured: boolean;
    isPublished: boolean;
    allowedOrigins: { domain: string }[];
    chatStyle: {
      variant: StyleSetVariant;
      color: StyleChatColor;
      image?: File | string;
    };
    customBranding: {
      isActive: boolean;
      value: string;
    };
  };
  buildinFunctions: AgentBuildInFunction[];
  loadJs: boolean;
};

export type DomainLinks = { domain: string; links: EmbedKnowledges[] };

export enum StyleSetVariant {
  Rounded = "rounded",
  Square = "square",
  Classic = "classic",
}

export enum StyleChatColor {
  Neutral = "neutral",
  Yellow = "yellow",
  Red = "red",
  Orange = "orange",
  Blue = "blue",
  Green = "green",
  Purple = "purple",
}

export type Onboarding = {
  content: string;
  description: string;
  _id: string;
  isNew?: boolean;
};

export type FullAgent = Agent & AgentConfig;

export type Predefined = {
  question: string;
  answer: string;
};

export type EmbedKnowledges = {
  _id: string;
  creator: string;
  embedIdList: string[];
  knowledgeType: "WEB" | "DOC" | "FAQ";
  source: string;
  filename: string;
  description: string;
  content: string;
  parentId: string;
  status: "success" | "failed" | "refresh";
  createdAt: string;
  updatedAt: string;
  predefined?: boolean;
  externalSource?: string;
  externalId?: string;
};

// This structure is used for new folders that come from Google Drive
// It is necessary since it can't be mapped directly to the domain
export type FutureEmbedKnowledges = {
  _id?: string;
  isNewFolder?: boolean;
  id: string;
  name: string;
  mimeType: string;
  children: { id: string; name: string; mimeType: string }[];
};

export type EmbedExternalKnowledge = {
  _id?: string;
  externalSource: string;
  externalId: string;
  id: string;
  name: string;
};

export const KnowledgeStatus = {
  SUCCESS: "success",
  FAILED: "failed",
  REFRESH: "refresh",
} as const;

export type DocumentUploadResponse = { docId: string; filename: string; path: string };

export type AgentMutationPayload = {
  newData: FullAgent & { loadJs: boolean };
  prevData: FullAgent;
};

export type AgentLeadGeneration = {
  leadGeneration?: {
    enabled: boolean;
    params: Parameter[];
    webhook: {
      enabled: boolean;
      url: string;
      header?: string[];
      method?: string;
    };
  };
};

export type AgentFormFields = FullAgent & AgentLeadGeneration;

export const agentFormDefaultValues: AgentFormFields = {
  _id: "",
  creator: "",
  isActive: false,
  agentType: "",
  name: "",
  avatar: "",
  contacts: [],
  blocked: [],
  blockedFrom: [],
  deleted: false,
  createdAt: "",
  updatedAt: "",
  state: AgentState.AGENT_READY,
  botId: "",
  embedKnowledges: {
    web: [],
    doc: [],
    faq: [],
    externalHelpers: [],
  },
  inMemoryKnowledge: {
    about: "",
    instruction: "",
    onboardingList: [],
  },
  company: "",
  temperature: 0,
  llmModel: "GPT-4o-mini",
  definedActions: {
    greeting: [],
    predefined: [],
    _id: "",
  },
  publish: {
    isConfigured: false,
    isPublished: false,
    allowedOrigins: [],
    chatStyle: {
      variant: StyleSetVariant.Rounded,
      color: StyleChatColor.Neutral,
      image: "",
    },
    customBranding: {
      isActive: false,
      value: "",
    },
  },
  buildinFunctions: [],
  loadJs: false,
  publishedToCommunity: false,
  leadGeneration: {
    enabled: false,
    params: leadGenerationRequiredParams,
    webhook: {
      enabled: false,
      url: "",
      header: [""],
      method: "POST",
    },
  },
};

export const newDraftAgent: Agent = {
  _id: agentCreationId,
  creator: "",
  isActive: false,
  agentType: "",
  name: "",
  avatar: "",
  contacts: [],
  blocked: [],
  blockedFrom: [],
  deleted: false,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  state: AgentState.AGENT_DRAFT,
  publishedToCommunity: false,
};

export const agentBuildInFunctions = {
  IMAGE_GENERATE: "IMAGE_GENERATE",
  IMAGE_GENERATE_FLUX: "IMAGE_GENERATE_FLUX",
  BROWSE_WEB: "BROWSE_WEB",
  SEARCH_ENGINE: "SEARCH_ENGINE",
  EVENT_SCHEDULER: "EVENT_SCHEDULER",
  TEXT_EXTRACTION: "TEXT_EXTRACTION",
  VIDEO_GENERATE: "VIDEO_GENERATE",
} as const;

export type AgentBuildInFunction = (typeof agentBuildInFunctions)[keyof typeof agentBuildInFunctions];

export enum ExternalAgentStatus {
  INITIALIZED = "initialized",
  ONLINE = "online",
  OFFLINE = "offline",
  ERROR = "error",
  PAUSED = "paused",
}

export type ExternalAgentBase = {
  _id: string;
  creator: string;
  botId: Agent["_id"];
  active: boolean;
  token: string;
  createdAt: string;
  updatedAt: string;
  status: ExternalAgentStatus;
};

export type DiscordAgent = ExternalAgentBase & { platform: "discord"; platformAttributes: { discordClientId: string } };

export type SlackAgent = ExternalAgentBase & {
  platform: "slack";
  platformAttributes: { temporaryId: string };
};

export type ExternalAgent = DiscordAgent | SlackAgent;

export enum ShareIntegrationType {
  DISCORD = "discord",
}
