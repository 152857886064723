import { useState } from "react";
import { Button } from "@/components/ui/button";
import type { DocumentUploadResponse, EmbedKnowledges } from "@/types/agent";
import { useFieldArray } from "react-hook-form";
import { Icons } from "@/components/ui/icons";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { restClient } from "@/data/initAxios";
import { apiPaths } from "@/data/apiPaths";
import { Label } from "@/components/ui/label";
import type { EventFor } from "@/types/types";
import { useGetUser } from "@/data/queries/useGetUser";
import UpgradeBlurBanner from "@/components/UpgradeBlurBanner/UpgradeBlurBanner";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";
import { checkIfKnowledgeLimitExceeded } from "@/utils/checkIfKnowledgeLimitExceeded";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";

import { MAX_FILE_SIZE_INPUT, MAX_FILE_SIZE_MB, isFileFormatAllowed } from "@/utils/fileUploads";
import { FileIcon } from "./FileIcon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { DocumentInfoPreview } from "./DocumentInfoPreview";

const FILE_INPUT_ID = "file_input";

export const DocumentInfoLocal = () => {
  const form = useAgentFormContext();

  const [documentInPreview, setDocumentInPreview] = useState<EmbedKnowledges | null>(null);

  const { user } = useGetUser();
  const { openDialog } = useAccountUsageDialogContext();
  const [newDocument, setNewDocument] = useState<DocumentUploadResponse>();
  const [uploadDocLoading, setUploadDocLoading] = useState(false);
  const [accordionValueOpen, setAccordionValueOpen] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { control } = form;
  const agentId = form.getValues("_id");

  const handleOpenAccountUsageDialog = () => openDialog("document");

  const userDocsLimit = user?.subscription.knowledge.docs?.limit;

  const isDocsLimitExceeded = checkIfKnowledgeLimitExceeded({
    currentUsage: user?.subscription.knowledge.docs?.currentUsage,
    limit: userDocsLimit,
  });

  const {
    fields: documentsData,
    append: appendDocument,
    remove: removeDocument,
    update: updateDocument,
  } = useFieldArray({
    control,
    name: "embedKnowledges.doc",
  });

  const documentsToDisplay = documentsData.filter((document): document is EmbedKnowledges & { id: string } => {
    return !("externalSource" in document && document.externalSource);
  });

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    if (!isFileFormatAllowed(file)) {
      return setErrorMessage("File format not allowed");
    }
    if (file.size > MAX_FILE_SIZE_INPUT) {
      return setErrorMessage(`The file must be less than ${MAX_FILE_SIZE_MB}MB`);
    }
    if (documentsData.some(document => "filename" in document && document.filename === file.name)) {
      return setErrorMessage("This document already exists");
    }
    setUploadDocLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    if (agentId) {
      formData.append("botId", agentId);
    }
    try {
      const newDocumentResponse = (await restClient.post<DocumentUploadResponse>(apiPaths.uploadDocument, formData))
        .data;
      setNewDocument(newDocumentResponse);
      form.setValue("embedKnowledges.doc", [...documentsData, newDocumentResponse] as EmbedKnowledges[], {
        shouldDirty: true,
      });
      //setErrorMessage("Please provide the description for the document and press 'Upload' button.");
    } catch (error) {
      setErrorMessage("Something went wrong while uploading the file");
      console.log(error);
    } finally {
      setAccordionValueOpen("document");
      setUploadDocLoading(false);
    }
  };

  const submitAddDocument = (e: EventFor<"form", "onSubmit">) => {
    e.preventDefault();
    if (!newDocument) {
      return;
    }
    const index = documentsData.findIndex(
      document => "filename" in document && document.filename === newDocument.filename
    );
    if (index !== -1) {
      const updatedDocument = documentsData[index];
      updateDocument(index, updatedDocument);
      setErrorMessage("");
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      appendDocument({ ...newDocument } as any);
    }
    setNewDocument(undefined);
    setAccordionValueOpen("document");
    setErrorMessage("");
  };

  const openFilePicker = () => {
    const input = document.getElementById(FILE_INPUT_ID);
    if (input) {
      input.click();
    }
  };

  return (
    <>
      <form onSubmit={submitAddDocument} className="flex w-full flex-col gap-2 xl:flex-row">
        <div className="mb-4 flex w-full flex-col gap-1.5">
          <Label htmlFor={FILE_INPUT_ID}>
            Upload{errorMessage && <span className="pl-2 text-red-500">{errorMessage}</span>}
          </Label>
          <div className="flex flex-col items-stretch gap-6 sm:flex-row sm:items-center sm:gap-2">
            <div className="relative flex w-full min-w-[170px] rounded-md border border-neutral-300 bg-white py-2 pl-2 pr-4 ring-offset-white">
              <div className="truncate text-xs font-medium leading-relaxed text-neutral-400">
                {uploadDocLoading ? "Loading..." : newDocument?.filename ? newDocument.filename : "No file chosen"}
              </div>
              <span className="absolute -bottom-5 left-0 text-[.625rem] font-medium text-neutral-400">
                (accepted: pdf, image, txt, word)
              </span>
            </div>
            <Button onClick={openFilePicker} disabled={isDocsLimitExceeded} className="h-full whitespace-nowrap">
              Choose file
            </Button>
          </div>
          <input
            type="file"
            accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.heic,.csv"
            hidden
            onChange={uploadFile}
            id={FILE_INPUT_ID}
            className="hidden"
          />
        </div>
      </form>
      <Accordion
        type="single"
        collapsible
        className="rounded-md border border-slate-200"
        value={accordionValueOpen}
        onValueChange={setAccordionValueOpen}
      >
        <AccordionItem value="document">
          <AccordionTrigger className="px-4 text-xs font-medium text-neutral-800">
            <div className="flex h-4 min-w-[16px] items-center justify-center rounded-full bg-primary-500 px-1 text-[10px] font-medium leading-none text-white">
              {documentsToDisplay.length}
            </div>
            <div className="ml-2 mr-auto">List of uploaded documents</div>
          </AccordionTrigger>
          <AccordionContent className="flex flex-col gap-2 bg-zinc-100 p-2">
            {documentsToDisplay.map((onboarding, index) => (
              <div
                key={onboarding._id}
                className="flex w-full items-center justify-between gap-2 rounded-md border border-slate-200 bg-white px-2 hover:bg-primary-50"
              >
                <div className="flex items-center gap-1">
                  <div className="min-w-5">
                    <FileIcon fileName={onboarding.filename} />
                  </div>
                  <div className="ml-2 flex flex-col gap-1 py-2">
                    {onboarding?.source ? (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <p
                            onClick={() => setDocumentInPreview(onboarding)}
                            className="break-all pr-2 text-xs font-bold text-neutral-800 hover:text-neutral-500 hover:underline"
                          >
                            {onboarding.filename}
                          </p>
                        </TooltipTrigger>
                        <TooltipContent>
                          <div className="mb-2 flex justify-between gap-5">
                            <span className="text-base font-bold">AI Summary</span>
                            <button
                              onClick={() => setDocumentInPreview(onboarding)}
                              className="text-sm font-medium text-primary-200"
                            >
                              (Click to preview)
                            </button>
                          </div>
                          <p className="line-clamp-2 text-xs font-medium">
                            {onboarding.description || "Unable to generate summary for this file."}
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    ) : (
                      <p className="break-all pr-2 text-xs font-bold text-neutral-400">(NEW) {onboarding.filename}</p>
                    )}
                  </div>
                </div>
                <Button variant="ghost" type="button" size="action-icon" onClick={() => removeDocument(index)}>
                  <Icons.Trash className="text-neutral-400" />
                </Button>
              </div>
            ))}
          </AccordionContent>

          {isDocsLimitExceeded && (
            <UpgradeBlurBanner
              currentUsageDescription={`You are using ${user?.subscription.knowledge.docs?.currentUsage} out of ${user?.subscription.knowledge.docs?.limit} documents`}
              upgradeDescription="to add more knowledge from documents"
              onUpgradeClick={handleOpenAccountUsageDialog}
            />
          )}
        </AccordionItem>
      </Accordion>
      <DocumentInfoPreview setDocumentInPreview={setDocumentInPreview} documentInPreview={documentInPreview} />
    </>
  );
};
