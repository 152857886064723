import GroupConversationAgentsName from "@/components/GroupCoversations/GroupConversationAgentsName";
import GroupConversationAvatar from "@/components/GroupCoversations/GroupConversationAvatar";
import { Accordion, AccordionContent, AccordionItem } from "@/components/ui/accordion";
import { Icons } from "@/components/ui/icons";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { useCallback } from "react";

interface BookmarkHistoryAgentTileWrapperProps {
  children: React.ReactNode;
  agents: {
    _id: string;
    avatar: string;
    name: string;
  }[];
  threadsCount: number;
  label: string;
  defaultItemIds?: string[];
}

const BookmarkHistoryAgentTileWrapper = ({
  children,
  agents,
  threadsCount,
  label,
  defaultItemIds,
}: BookmarkHistoryAgentTileWrapperProps) => {
  const threadUnit = threadsCount === 1 ? "thread" : "threads";

  const getItemValue = useCallback(
    (agentIds: string[]) => {
      return `${label}-${agentIds
        .sort()
        .map(id => id)
        .join("-")}`;
    },
    [label]
  );

  return (
    <Accordion type="single" collapsible defaultValue={getItemValue(defaultItemIds?.sort() ?? [])}>
      <AccordionItem value={getItemValue(agents.map(agent => agent._id))}>
        <AccordionPrimitive.Trigger className="flex w-full items-center gap-2 [&[data-state=open]>svg]:rotate-[-90deg]">
          <GroupConversationAvatar avatars={agents.map(agent => agent?.avatar)} />
          <div className="flex w-full flex-col overflow-hidden">
            <GroupConversationAgentsName names={agents.map(agent => agent.name)} className="!text-sm  text-white" />

            <div className="text-left text-xs text-neutral-400">
              {threadsCount} {threadUnit}
            </div>
          </div>
          <Icons.ChevronRight className="scale-125 text-white" />
        </AccordionPrimitive.Trigger>
        <AccordionContent className="mt-4 flex flex-col gap-2 pb-0">{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default BookmarkHistoryAgentTileWrapper;
