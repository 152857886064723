export const isValidUrl = (url: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[\\w\\d%'_.~+:,#\\(\\)-]*)*" + // port and path, added colon and allow more characters including ' and ()
      "(\\?[;&a-z\\d%'_.~+=-]*)?" + // query string, allow ' in query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(url);
};

export const normalizeUrl = (url: string) => url.replace(/^(https?:\/\/)?(www\.)?/, "");
