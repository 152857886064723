export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve((reader.result as string).split(",")[1]);
      } else {
        reject(new Error("FileReader result is empty"));
      }
    };
    reader.onerror = () => reject(new Error("Failed to read the blob as base64"));
    reader.readAsDataURL(blob);
  });
};
