// import { CommunityBanners } from "./components/CommunityBanners";
// import { CommunityFilters } from "./components/CommunityFilters";
import { PublishInput } from "./components/PublishInput";
import { CommunityCategories } from "./components/CommunityCategories";
import { CommunityAgents } from "./components/CommunityAgents";
import { CommunityHeader } from "./components/CommunityHeader";

export const Community = () => {
  return (
    <div className="flex w-full flex-col">
      <CommunityHeader />

      <div className="overflow-y-scroll pb-8">
        <div className="container mx-auto ">
          <div className="flex flex-col gap-7 px-2">
            <div className="flex flex-col gap-3 pt-10 text-center">
              <h1 className="text-2xl font-bold text-primary-black md:text-3xl lg:text-4xl">Welcome to Agent Space</h1>
              <p className="text-sm font-normal text-primary-black lg:text-base lg:leading-[33px]">
                Explore various agents from the community, get inspirations to kickstart your next big idea.
              </p>
            </div>

            {/* <CommunityBanners /> */}

            <PublishInput />

            <CommunityCategories />

            {/* <CommunityFilters /> */}

            <CommunityAgents />
          </div>
        </div>
      </div>
    </div>
  );
};
