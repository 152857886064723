import { Icons } from "./ui/icons";
import notFound from "@/assets/images/NotFound.svg";

export const ErrorBoundaryComponent = () => {
  return (
    <div className="flex h-dvh w-dvw flex-col items-center justify-center gap-10 p-8">
      <Icons.AgentLogo className="w-80" />
      <p className="text-xl font-medium">An unexpected internal error occured. Please try again later.</p>
      <img src={notFound} alt="Not Found" className="w-60" />
    </div>
  );
};
