import { useInfiniteQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { FullAgent } from "@/types/agent";
import type { AgentPaginationProps } from "@/types/community";

export const workspaceAgentsKeys = {
  all: ["workspaceAgents"] as const,
  params: (params: Pick<AgentPaginationProps, "limit">) => [...workspaceAgentsKeys.all, params] as const,
};

const getWorkspaceAgents = async (props: AgentPaginationProps) => {
  const { data } = await restClient.get<FullAgent[]>(apiPaths.getWorkspaceAgents(props));
  return data;
};

export const useGetWorkspaceAgents = () => {
  const limit = 20;
  return useInfiniteQuery({
    queryKey: workspaceAgentsKeys.params({ limit }),
    queryFn: ({ pageParam }) => getWorkspaceAgents({ page: pageParam, limit }),
    getNextPageParam: (lastPage, _, lastPageParam) => (lastPage.length < limit ? undefined : lastPageParam + 1),
    select: data => data.pages.flat(),
    initialPageParam: 1,
  });
};
