import type { TypeFromConstObject } from "./types";

export type Subscription = {
  agents: Usage;
  messages: Usage;
  knowledge: Knowledge;
  active: boolean;
  conversations: Usage;
  tier: SubscriptionTier;
  stripeId: string | null | undefined;
  // workspace
  status: number;
  validity: string;
};

export type CustomerSubscription = Subscription & { users?: { limit: number; currentUsage: number } };

export type Usage = {
  currentUsage: number;
  limit: number;
};

export type Knowledge = {
  urls: Usage;
  docs: Usage;
  faqs: Usage;
};

export const SubscriptionTiers = {
  FREE: 0,
  STARTUP: 1,
  PRO: 2,
  SCALE: 3,
  PREMIUM: 4,
  ENTERPRISE: 5,
} as const;

export const SubscriptionTierNames = {
  0: "Free",
  1: "Startup",
  2: "Pro",
  3: "Scale",
  4: "Premium",
  5: "Enterprise",
};

export type SubscriptionTier = TypeFromConstObject<typeof SubscriptionTiers>;

export enum SubscriptionPeriod {
  MONTHLY = "monthly",
  ANNUALLY = "annually",
}

export type UsageExceededType =
  | "url"
  | "document"
  | "faqs"
  | "message"
  | "enhancedCapability"
  | "agentCreate"
  | "advancedFeatures"
  | "advancedTools"
  | "llmModel";

export type BillingPlan = {
  tier: SubscriptionTier;
  name: BillingPlanNames;
  long_name: string;
  description: string;
  features: BillingPlanFeature[];
  features_as_text: string;
  price: {
    annually: BillingPlanPricing;
    monthly: BillingPlanPricing;
  };
};

export type BillingPlanNames = "Free" | "Startup" | "Pro" | "Scale";
export type BillingPlanPricing = { amount: number; planId: string };
export type BillingPlanFeature = { name: string; enabled: boolean; value?: number };

export type BillingPlanCoupon = {
  coupon: string;
  discount: "amount_off" | "percent_off";
  discountPrice: number;
  originalPrice: number;
};

export type BillingData = {
  stripeCustomerId: string;
  subscription: {
    planId: string;
    interval: "month" | "year";
    name: string;
    // in cents
    price: number;
    // unix timestamp
    nextBillingDate: number;
  };
  paymentMethods: PaymentMethod[];
  invoices: Invoice[];
};

export type Invoice = {
  downloadLink: string;
  invoiceId: string;
  invoiceDate: number;
  amount: number;
  status: string;
};
export type PaymentMethod = {
  id: string;
  object: "payment_method";
  allow_redisplay: string;
  billing_details: {
    address: {
      city: string;
      country: string;
      line1: string;
      line2: string | null;
      postal_code: string;
      state: string;
    };
    email: string;
    name: string;
    phone: string;
  };
  card: {
    brand: string;
    checks: {
      address_line1_check: string;
      address_postal_code_check: string;
      cvc_check: string;
    };
    country: string;
    display_brand: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    generated_from: null;
    last4: string;
    networks: {
      available: [string];
      preferred: null;
    };
    three_d_secure_usage: {
      supported: boolean;
    };
    wallet: null;
  };
  // unix timestamp
  created: 1715567193;
  customer: string;
  livemode: boolean;
  metadata: object;
  type: "card";
};
