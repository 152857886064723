import { cn } from "@/lib/utils";

export const ChatHistoryDialogFoundBadge = ({
  foundValue,
  searchValue,
  className,
}: {
  foundValue: number;
  searchValue: string;
  className?: string;
}) => {
  return (
    <div className={cn("flex items-center gap-1", className)}>
      <div className="rounded-full bg-feedback-warning px-1 py-0 text-xxs font-medium text-neutral-750">
        {foundValue}
      </div>
      <p className="line-clamp-6 text-xs font-medium text-neutral-750 custom-break-anywhere">{`'${searchValue}' found`}</p>
    </div>
  );
};
