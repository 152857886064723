import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { Agent, AgentMutationPayload } from "@/types/agent";
// eslint-disable-next-line import/no-cycle
import { updateAgentPayload } from "@/utils/updateAgentPayload";
import { agentsKeys } from "@/data/queries/useGetAgents";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";
import { singlePrivateAgentWithConfigKeys } from "../queries/useGetSinglePrivateAgentWithConfig";
import { chatHistoryWithAgentsKeys } from "../queries/useGetChatHistoryWithAgents";
import { agentsWithConfigKeys } from "../queries/useGetAgentsWithConfig";
import { workspaceAgentsKeys } from "../queries/workspace/useGetWorkspaceAgents";

export const uploadBubbleAvatar = async (image: File) => {
  const formData = new FormData();
  formData.append("avatar", image);
  try {
    const res = (await restClient.put<{ path: string }>(apiPaths.updateBubbleAvatar, formData)).data;
    return res.path;
  } catch (error) {
    console.log(error);
  }
};

const updateAgent = async (agentData: AgentMutationPayload) => {
  const payload = await updateAgentPayload(agentData);
  const { data } = await restClient.post<Agent>(apiPaths.updateAgent, payload);

  return data;
};

export const useUpdateAgent = () => {
  const queryClient = useQueryClient();
  const { openDialog } = useAccountUsageDialogContext();

  const mutation = useMutation<Agent, AxiosError<{ message: string }>, AgentMutationPayload>({
    mutationFn: updateAgent,
    onSuccess: async (agent, payload) => {
      if (payload.newData?.avatar !== payload.prevData?.avatar || payload.newData.name !== payload.prevData.name) {
        void queryClient.invalidateQueries({ queryKey: agentsKeys.all });
      }
      void queryClient.invalidateQueries({ queryKey: agentsWithConfigKeys.all });
      void queryClient.invalidateQueries({ queryKey: chatHistoryWithAgentsKeys.all });
      await queryClient.invalidateQueries({ queryKey: singlePrivateAgentWithConfigKeys.id(agent._id) });
      void queryClient.invalidateQueries({ queryKey: workspaceAgentsKeys.all });
    },
    onError: error => {
      const status = error.response?.status;
      const message = error.response?.data.message;
      if (status === 429) {
        if (message?.includes("You have exceeded the Doc")) {
          openDialog("document");
        }

        if (message?.includes("You have exceeded the Website")) {
          openDialog("url");
        }
      }
    },
  });

  return mutation;
};
