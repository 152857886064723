import { cn } from "@/lib/utils";
import type { Agent } from "@/types/agent";
import { AgentState } from "@/types/agent";

const labelsMap = {
  [AgentState.AGENT_READY]: "Online",
  [AgentState.AGENT_UPDATING]: "Updating",
  [AgentState.AGENT_ERROR]: "Error",
  [AgentState.AGENT_DRAFT]: "Draft",
  [AgentState.AGENT_CREATING]: "Creating",
  [AgentState.AGENT_REMOVING]: "Removing",
};
const AgentStatusLabel = ({ agentState, className }: { agentState: Agent["state"]; className?: string }) => {
  const text = labelsMap[agentState];

  return <p className={cn("whitespace-nowrap", className)}>{text}</p>;
};

export default AgentStatusLabel;
