import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { Tool } from "@/types/tools";
import { userToolsKeys } from "@/data/queries/useGetUserTools";
import { agentToolsKeys } from "../queries/useGetAgentTools";
import { toast } from "react-toastify";

const deleteTool = async (props: Tool["_id"]) => {
  const { data } = await restClient.delete<string>(apiPaths.deleteTool(props));
  return data;
};

export const useDeleteTool = () => {
  const queryClient = useQueryClient();

  return useMutation<string, AxiosError<{ message: string }>, Tool["_id"]>({
    mutationFn: deleteTool,
    onSuccess: () => {
      toast.success("Tool deleted successfully");
      void queryClient.invalidateQueries({ queryKey: userToolsKeys.all });
      void queryClient.invalidateQueries({ queryKey: agentToolsKeys.all });
    },
  });
};
