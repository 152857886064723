import { Table, TableBody, TableCell, TableHeader, TableRow } from "@/components/ui/table";
import { useGetAgentsOverviewStats } from "@/data/queries/stats/useGetAgentsOverviewStats";
import StatsAgentsOverviewSingleAgentRow from "./StatsAgentsOverviewSingleAgentRow";
import { useEffect, useRef, useState } from "react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { channelsLegendData, getAgentOverviewRowId } from "../../constants/statsConstants";
import AgentOverviewSkeleton from "../loaders/AgentOverviewSkeleton";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";

const headerCells = [
  {
    label: "Messages",
  },
  {
    label: "Conversations",
  },
  {
    label: "Satisfaction",
  },
  {
    label: "Channels",
  },
  {
    label: "Status",
  },
  {
    label: "Chart",
  },
];

const StatsAgentsOverview = () => {
  const { currentRange, startDate, endDate, selectedAgentId } = useStatsContext();

  const { data, isFetching } = useGetAgentsOverviewStats({ currentRange, startDate, endDate });

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [visibleContentColumnsIndexes, setVisibleContentColumnsIndexes] = useState<number[]>([0, 1]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    const updateVisibleColumns = () => {
      if (tableContainerRef.current) {
        const minWidth = 120;

        const containerWidth = tableContainerRef.current.offsetWidth;
        const allowedColumns = Math.floor(containerWidth / minWidth) - 2;

        setVisibleContentColumnsIndexes(
          allowedColumns === 0 ? [0] : Array.from({ length: allowedColumns }, (_, i) => i)
        );
      }
    };

    updateVisibleColumns();

    window.addEventListener("resize", updateVisibleColumns);
    return () => window.removeEventListener("resize", updateVisibleColumns);
  }, [isFetching]);

  const getIsColumnVisible = (index: number) => visibleContentColumnsIndexes.includes(index);

  const handleChangeVisibleColumns = (direction: "prev" | "next") => {
    if (direction === "prev") {
      if (visibleContentColumnsIndexes.includes(0)) {
        return;
      }

      setVisibleContentColumnsIndexes(prev => {
        const newIndexes = prev.map(index => index - 1);

        if (newIndexes[0] < 0) {
          return prev;
        }

        return newIndexes;
      });
    }

    if (direction === "next") {
      const columnsCount = headerCells.length;

      if (visibleContentColumnsIndexes.includes(columnsCount - 1)) {
        return;
      }

      setVisibleContentColumnsIndexes(prev => {
        const newIndexes = prev.map(index => index + 1);

        if (newIndexes[newIndexes.length - 1] >= columnsCount) {
          return prev;
        }

        return newIndexes;
      });
    }
  };

  useEffect(() => {
    if (!selectedAgentId || !data) {
      return;
    }

    const scrollToSelectedAgent = () => {
      const selectedAgentRow = document.getElementById(getAgentOverviewRowId(selectedAgentId));

      if (selectedAgentRow) {
        selectedAgentRow.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    };

    scrollToSelectedAgent();
  }, [selectedAgentId, data]);

  return (
    <div className="items-center gap-2.5 rounded-md border border-border-light bg-white px-5 py-6 shadow-md @container/main">
      {isFetching ? (
        <AgentOverviewSkeleton />
      ) : (
        <>
          <div className="flex flex-wrap gap-x-7 gap-y-2">
            <h2 className="text-2xl font-bold">Agent Overview</h2>

            <div className="flex flex-wrap items-center gap-x-7 gap-y-2">
              {channelsLegendData.map(({ color, label }) => (
                <div className="flex items-center gap-2" key={label}>
                  <div className="h-2 w-2 min-w-2 rounded-full" style={{ backgroundColor: color }} />
                  <p className="text-sm">{label}</p>
                </div>
              ))}
            </div>
          </div>

          <div
            ref={tableContainerRef}
            className="relative mt-4 max-h-[410px] w-full overflow-scroll scrollbar-custom-mt"
          >
            <Table>
              <TableHeader className="sticky top-0 z-10 bg-white">
                <TableRow className="border-b border-b-border-light">
                  <TableCell className="whitespace-nowrap text-xs font-medium text-neutral-500">Agent Name</TableCell>
                  {headerCells.map((cell, index) => (
                    <TableCell
                      key={index}
                      className={cn(
                        "relative hidden whitespace-nowrap text-center text-xs font-medium text-neutral-500 @3xl/main:table-cell @3xl/main:text-left",
                        {
                          "table-cell": getIsColumnVisible(index),
                        }
                      )}
                    >
                      {cell.label}
                    </TableCell>
                  ))}

                  <TableCell className="hidden whitespace-nowrap text-xs font-medium text-neutral-500 @3xl/main:table-cell">
                    Action
                  </TableCell>

                  <TableCell className="flex items-center gap-3 @3xl/main:hidden">
                    <Button
                      variant="outline"
                      className="rounded-full bg-neutral-50 p-1.5"
                      size="custom"
                      onClick={() => handleChangeVisibleColumns("prev")}
                    >
                      <Icons.ChevronRight className="size-4 rotate-180 text-black" />
                      <span className="sr-only">Previous column</span>
                    </Button>
                    <Button
                      variant="outline"
                      className="rounded-full bg-neutral-50 p-1.5"
                      size="custom"
                      onClick={() => handleChangeVisibleColumns("next")}
                    >
                      <Icons.ChevronRight className="size-4 text-black" />
                      <span className="sr-only">Next column</span>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHeader>

              <TableBody>
                {data?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={8} className="py-3 text-center text-base font-medium text-neutral-400">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
                {data?.map(item => (
                  <StatsAgentsOverviewSingleAgentRow
                    key={item.agent._id}
                    agent={item.agent}
                    messages={item.messages}
                    conversations={item.conversations}
                    satisfaction={item.satisfaction}
                    channels={item.channels}
                    currentColumnIndexes={visibleContentColumnsIndexes}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default StatsAgentsOverview;
