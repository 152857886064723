import { ROUTES } from "@/constants/routes";
import { unauthorizeAxiosClient } from "@/data/initAxios";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";

export const useLogOut = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return () => {
    unauthorizeAxiosClient();
    queryClient.removeQueries();
    localStorage.clear();
    navigate(ROUTES.login, { replace: true });
  };
};
