import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { CommunityCategory } from "@/types/community";

export const communityCategoriesKeys = {
  all: ["communityCategories"] as const,
};

const getCommunityCategories = async () => {
  const { data } = await restClient.get<CommunityCategory[]>(apiPaths.getCategories);
  return data;
};

export const useGetCommunityCategories = () => {
  const query = useQuery({
    queryKey: communityCategoriesKeys.all,
    queryFn: getCommunityCategories,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return query;
};
