import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { Conversation } from "@/types/conversation";
import type { User } from "@/types/user";

export const singleConversationDetailsKeys = {
  all: ["singleConversationDetailsKeys"] as const,
  id: (id: Conversation["_id"]) => [...singleConversationDetailsKeys.all, id],
};

export type ConversationWithAgentsDetails = Omit<Conversation, "bots"> & {
  bots: {
    name: string;
    avatar: string;
    _id: string;
    creator: {
      name: string;
      _id: User["_id"];
    };
    publishedToCommunity: boolean;
  }[];
};

type Props = {
  conversationId: Conversation["_id"];
  enabled?: boolean;
};

const getSingleConversationDetails = async ({ conversationId }: Props) => {
  const { data } = await restClient.get<ConversationWithAgentsDetails>(
    apiPaths.getSingleConversationDetails(conversationId)
  );
  return data;
};

export const useGetSingleConversationDetails = ({ conversationId, enabled = true }: Props) => {
  const query = useQuery({
    queryKey: singleConversationDetailsKeys.id(conversationId),
    queryFn: () => getSingleConversationDetails({ conversationId }),
    enabled,
  });

  return query;
};
