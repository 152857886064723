import { Avatar } from "@/components/Avatar";
import { Button } from "@/components/ui/button";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useRemoveAgentFromConversation } from "@/data/mutations/useRemoveAgentFromConversation";
import { LocalStorageHelpers } from "@/utils/LocalStorageHelpers";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { useNavigate } from "react-router";

const ChatTopBarAgentTile = ({
  agentId,
  name,
  avatar,
  creatorName,
  canRemove,
  currentAgentIds,
  onClick,
}: {
  agentId: string;
  name: string;
  avatar: string;
  creatorName: string;
  canRemove: boolean;
  currentAgentIds: string[];
  onClick: () => void;
}) => {
  const navigate = useNavigate();
  const { conversationId } = useChatContext();

  const { mutate: removeAgentFromConversation, isPending: isPendingRemoveAgent } = useRemoveAgentFromConversation();

  const handleRemoveAgentFromConversation = (agentId: string) => {
    const isNewConversation = !conversationId;

    if (isNewConversation) {
      navigate(getNewConversationRoute(currentAgentIds.filter(id => id !== agentId)));
    } else {
      removeAgentFromConversation(
        { conversationId: conversationId ?? "", agentId },
        {
          onSuccess: () => {
            LocalStorageHelpers.updateLocalStorageConversation({
              conversationId,
              prevAgentIds: currentAgentIds,
              updatedAgentIds: currentAgentIds.filter(id => id !== agentId),
            });
          },
        }
      );
    }
  };

  return (
    <DropdownMenuItem className="flex items-center gap-4 px-4 py-2 text-xs" onClick={onClick}>
      <Avatar name={name} src={avatar} />
      <div>
        <p className="text-sm font-semibold text-neutral-750">{name}</p>
        <span className="text-xs font-medium text-neutral-500">@{creatorName}</span>
      </div>
      {canRemove &&
        (isPendingRemoveAgent ? (
          <div className="ml-auto">
            <LoadingSpinner />
          </div>
        ) : (
          <Button
            variant="ghost"
            className="ml-auto px-0 text-primary-400"
            onClick={e => {
              e.stopPropagation();
              handleRemoveAgentFromConversation(agentId);
            }}
          >
            Remove
          </Button>
        ))}
    </DropdownMenuItem>
  );
};

export default ChatTopBarAgentTile;
