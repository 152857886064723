import { DeleteTeamMemberDialog } from "@/components/dialogs/TeamDialogs/DeleteTeamMemberDialog";
import { cn } from "@/lib/utils";
import { UserStatus, type User } from "@/types/user";

export const TeamMembers = ({ members }: { members: User[] }) => {
  return (
    <div className="flex flex-col gap-4">
      {members.map((member, index) => {
        const memberInvitationPending = member.status === UserStatus.INVITE_PENDING;
        const isAdmin = index === 0;
        const memberRole = isAdmin ? "Admin" : memberInvitationPending ? "Pending" : "Member";
        return (
          <div key={member._id} className="flex items-center justify-between gap-5">
            <div>
              <div className="text-sm font-medium">
                {member.name} {isAdmin && <span className="text-neutral-400">(You)</span>}
                {memberInvitationPending && <span className="text-neutral-500">{"<Invitiation sent>"}</span>}
              </div>
              <span className="text-xs text-neutral-400">{member.email}</span>
            </div>
            <div className="grid grid-cols-[60px_40px] items-center gap-4">
              <span
                className={cn("text-right text-xs", memberInvitationPending ? "text-neutral-400" : "text-primary-400")}
              >
                {memberRole}
              </span>
              {!isAdmin && <DeleteTeamMemberDialog userId={member._id} />}
            </div>
          </div>
        );
      })}
    </div>
  );
};
