import { useState } from "react";
import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { CustomisedToolsDialog } from "@/components/AgentForm/components/Actions/components/CustomisedToolsDialog";
import { Icons } from "@/components/ui/icons";
import { useForm } from "react-hook-form";
import type { Tool, BlankTool } from "@/types/tools";
import { blankTool } from "@/types/tools";
import { useGetUserTools } from "@/data/queries/useGetUserTools";
import { useGetAgentTools } from "@/data/queries/useGetAgentTools";
import { useGetPublicTools } from "@/data/queries/useGetPublicTools";
import { useAssignToolToAgent } from "@/data/mutations/useAssignToolToAgent";
import { useUnassignToolFromAgent } from "@/data/mutations/useUnassignToolFromAgent";
import { toast } from "react-toastify";
import { DeleteToolDialog } from "./DeleteToolDialog";
import { useGetUser } from "@/data/queries/useGetUser";
import { SubscriptionTiers } from "@/types/subscriptions";
import UpgradeBlurBanner from "@/components/UpgradeBlurBanner/UpgradeBlurBanner";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";

export const AdvancedCustomizationContent = () => {
  const agentForm = useAgentFormContext();

  const { user } = useGetUser();
  const { openDialog } = useAccountUsageDialogContext();
  const [searchToolBox, setSearchToolBox] = useState("");
  const [accordionValueOpen, setAccordionValueOpen] = useState("");
  const [isCustomisedToolsDialogOpen, setIsCustomisedToolsDialogOpen] = useState(false);
  const [isDeleteToolDialogOpen, setIsDeleteToolDialogOpen] = useState(false);
  const [deleteToolId, setDeleteToolId] = useState("");
  const { data: userTools } = useGetUserTools();
  const { data: agentTools } = useGetAgentTools({ agentId: agentForm.getValues("_id") || "" });
  const { data: publicTools } = useGetPublicTools();
  const { mutate: assignToolToAgent } = useAssignToolToAgent();
  const { mutate: unassignToolFromAgent } = useUnassignToolFromAgent();

  const handleOpenAccountUsageDialog = () => openDialog("advancedTools");

  const isAllowed = user?.subscription.tier !== SubscriptionTiers.FREE;

  const toolEditorForm = useForm<Tool>({
    defaultValues: blankTool,
  });

  const openToolEditorDialog = (tool: Tool) => {
    setIsCustomisedToolsDialogOpen(true);
    toolEditorForm.reset({ ...tool, isEditing: true });
  };

  const openToolCreatorDialog = (tool: BlankTool) => {
    setIsCustomisedToolsDialogOpen(true);
    toolEditorForm.reset(tool);
  };

  const assignTool = (tool: Tool) =>
    assignToolToAgent(
      { toolId: tool._id, agentId: agentForm.getValues("_id") },
      {
        onSuccess: () => {
          toast.success("Tool assigned successfully");
        },
      }
    );

  const unassignTool = (tool: Tool) => unassignToolFromAgent({ toolId: tool._id, agentId: agentForm.getValues("_id") });

  const filteredPublicTools = publicTools?.filter(tool =>
    tool.toolName.toLowerCase().includes(searchToolBox.toLowerCase())
  );
  const filteredUserTools = userTools?.filter(tool =>
    tool.toolName.toLowerCase().includes(searchToolBox.toLowerCase())
  );

  const removedAgentToolsFromUserTools = filteredUserTools?.filter(
    userTool => !agentTools?.find(agentTool => agentTool._id === userTool._id)
  );
  const removedAgentToolsFromPublicTools = filteredPublicTools?.filter(
    publicTool => !agentTools?.find(agentTool => agentTool._id === publicTool._id)
  );

  return (
    <>
      <div className="flex items-center justify-between">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button disabled={!isAllowed} variant="secondary" className="flex gap-1">
              <Icons.Search /> Toolbox
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" className="flex max-w-[350px] flex-col gap-2 p-3 md:w-[560px]">
            <Input
              label="Search Toolbox"
              name="searchToolBox"
              autoFocus
              onChange={e => setSearchToolBox(e.target.value)}
              value={searchToolBox}
              placeholder="Tool name"
            />
            <div className="flex h-44 flex-col gap-2 overflow-y-scroll md:h-80">
              {removedAgentToolsFromPublicTools?.map((tool, index) => (
                <div
                  key={tool._id}
                  className="flex w-full items-center justify-between gap-2 rounded-md border border-slate-200 bg-white pl-3 hover:bg-primary-50"
                >
                  <div className="flex flex-col gap-1 py-2">
                    <p className="break-all text-xs font-semibold text-neutral-800">
                      {tool.toolName} (sample tool {index + 1})
                    </p>
                  </div>
                  <div className="flex items-center">
                    <Button
                      variant="ghost"
                      type="button"
                      className="px-3 text-primary-500"
                      onClick={() => openToolCreatorDialog({ ...tool, isAssigning: true })}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              ))}

              {removedAgentToolsFromUserTools?.map(tool => (
                <div key={tool._id} className="flex w-full items-center justify-between">
                  <div className="flex w-full items-center justify-between rounded-md border border-slate-200 bg-white pl-3 hover:bg-primary-50">
                    <div className="flex flex-col gap-1 py-2">
                      <p className="break-all text-xs font-semibold text-neutral-800">{tool.toolName}</p>
                    </div>
                    <div className="flex items-center">
                      <Button
                        variant="ghost"
                        type="button"
                        className="px-3 text-primary-500"
                        onClick={() => assignTool(tool)}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                  <div className="flex gap-2 pl-2">
                    <Button variant="ghost" size="action-icon" type="button" onClick={() => openToolEditorDialog(tool)}>
                      <Icons.Edit2 className="text-neutral-400" />
                    </Button>
                    <Button
                      variant="ghost"
                      type="button"
                      size="action-icon"
                      onClick={() => {
                        setIsDeleteToolDialogOpen(true);
                        setDeleteToolId(tool._id);
                      }}
                    >
                      <Icons.Trash className="text-neutral-400" />
                    </Button>
                  </div>
                </div>
              ))}

              {agentTools?.map(tool => (
                <div key={tool._id} className="flex w-full items-center justify-between">
                  <div className="flex w-full items-center justify-between rounded-md border border-slate-200 bg-white pl-3 hover:bg-primary-50">
                    <div className="flex flex-col gap-1 py-2">
                      <p className="text-xs font-semibold text-neutral-800">{tool.toolName}</p>
                    </div>
                    <p className="px-3 text-xs text-primary-200">Added</p>
                  </div>
                  <div className="flex gap-2 pl-2">
                    <Button variant="ghost" type="button" size="action-icon" onClick={() => openToolEditorDialog(tool)}>
                      <Icons.Edit2 className="text-neutral-400" />
                    </Button>
                    <Button
                      variant="ghost"
                      type="button"
                      size="action-icon"
                      onClick={() => {
                        setIsDeleteToolDialogOpen(true);
                        setDeleteToolId(tool._id);
                      }}
                    >
                      <Icons.Trash className="text-neutral-400" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
        <Button disabled={!isAllowed} onClick={() => openToolCreatorDialog(blankTool)}>
          Add tool
        </Button>
        {/*<DropdownMenu>
          <DropdownMenuTrigger>
            <Button>Add tool</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              className="flex items-center gap-1.5 text-xs"
              onClick={() => openToolCreatorDialog(blankTool)}
            >
              <Icons.File /> Blank template
            </DropdownMenuItem>
            <DropdownMenuItem
              className="flex items-center gap-1.5 text-xs"
              onClick={() => openToolCreatorDialog(sampleTool)}
            >
              <Icons.FileCode /> Sample template
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>*/}
      </div>

      <Accordion
        type="single"
        collapsible
        className="mt-4 rounded-md border border-slate-200"
        value={accordionValueOpen}
        onValueChange={setAccordionValueOpen}
      >
        <AccordionItem value="customised">
          <AccordionTrigger
            disabled={!isAllowed && agentTools?.length === 0}
            className="px-4 text-xs font-medium text-neutral-800"
          >
            <div className="flex h-4 min-w-[16px] items-center justify-center rounded-full bg-primary-500 px-1 text-[10px] font-medium leading-none text-white">
              {agentTools?.length || 0}
            </div>
            <div className="ml-2 mr-auto">Added advanced tools</div>
          </AccordionTrigger>

          <AccordionContent className="flex flex-col gap-2 bg-zinc-100 p-2">
            {agentTools?.map(tool => (
              <div
                key={tool._id}
                className="flex w-full items-center justify-between gap-2 rounded-md border border-slate-200 bg-white px-2 hover:bg-primary-50"
              >
                <div className="flex flex-col gap-1 py-2">
                  <p className="ml-2 break-all text-xs font-semibold text-neutral-800">{tool.toolName}</p>
                </div>
                <div className="flex gap-2">
                  <Button variant="ghost" type="button" size="action-icon" onClick={() => openToolEditorDialog(tool)}>
                    <Icons.Edit2 className="text-neutral-400" />
                  </Button>
                  <Button variant="ghost" type="button" size="action-icon" onClick={() => unassignTool(tool)}>
                    <Icons.Trash className="text-neutral-400" />
                  </Button>
                </div>
              </div>
            ))}
          </AccordionContent>

          {!isAllowed && (
            <UpgradeBlurBanner
              upgradeDescription="now to access the Advanced Tool suite and customize your bot"
              onUpgradeClick={handleOpenAccountUsageDialog}
            />
          )}
        </AccordionItem>
      </Accordion>
      <CustomisedToolsDialog
        isDialogOpen={isCustomisedToolsDialogOpen}
        setShowDialog={setIsCustomisedToolsDialogOpen}
        toolEditorForm={toolEditorForm}
      />
      {deleteToolId && (
        <DeleteToolDialog
          toolId={deleteToolId}
          setShowDialog={setIsDeleteToolDialogOpen}
          isDialogOpen={isDeleteToolDialogOpen}
        />
      )}
    </>
  );
};
