/* eslint-disable @tanstack/query/exhaustive-deps */
import { useInfiniteQuery } from "@tanstack/react-query";
import type { Conversation, ConversationMessagesQueryResponse } from "@/types/conversation";
import { getConversationMessagesByIndex } from "../message";
import useIsChatWithPagination from "@/components/Chat/hooks/useIsChatWithPagination";

export const conversationMessagesKeys = {
  all: ["conversationMessages"] as const,
  id: (props: { conversationId: Conversation["_id"] }, withPagination: boolean) =>
    [...conversationMessagesKeys.all, props, withPagination] as const,
};

type Props = {
  conversationId?: Conversation["_id"] | null;
  enabled?: boolean;
};

const MESSAGES_PER_PAGE = 20;

export const useGetConversationMessages = ({ conversationId, enabled = true }: Props) => {
  const withPagination = useIsChatWithPagination();

  const query = useInfiniteQuery({
    queryKey: conversationMessagesKeys.id({ conversationId: conversationId || "" }, withPagination),
    queryFn: ({ pageParam }) => {
      if (!conversationId) {
        throw new Error("Missing conversationId");
      }

      return getConversationMessagesByIndex({
        conversationId,
        startIndex: pageParam > 1 ? pageParam : 0,
        ...(withPagination && { limit: MESSAGES_PER_PAGE }),
      });
    },

    select: data => {
      const flattedMessages = data.pages.flatMap(page => page.messages);
      const { totalMessages } = data.pages[0];

      const queryResponse: ConversationMessagesQueryResponse = {
        messages: flattedMessages,
        totalMessages,
      };

      return queryResponse;
    },
    getNextPageParam: (_, allPages) => {
      const cachedMessagesLength = allPages.flatMap(page => page.messages).length;
      const backendMessagesLength = allPages[0].totalMessages;

      return cachedMessagesLength < backendMessagesLength ? cachedMessagesLength : null;
    },

    initialPageParam: 1,
    enabled: !!conversationId && enabled,
  });

  return query;
};
