import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { Conversation, Message } from "@/types/conversation";

export const sharedConversationKeys = {
  all: ["sharedConversation"] as const,
  id: (id: Conversation["_id"]) => [...sharedConversationKeys.all, id] as const,
};

type AgentOrUser = {
  _id: string;
  name: string;
  avatar: string;
};

type SharedConversation = {
  _id: string;
  users: AgentOrUser[];
  bots: AgentOrUser[];
  createdAt: string;
  updatedAt: string;
  messages: Message[];
};

const getSharedConversation = async (id: Conversation["_id"]) => {
  const { data } = await restClient.get<SharedConversation>(apiPaths.getSharedConversation(id));
  return data;
};

export const useGetSharedConversation = ({ id }: { id: Conversation["_id"] }) => {
  const query = useQuery({
    queryKey: sharedConversationKeys.id(id),
    queryFn: () => getSharedConversation(id),
  });

  return query;
};
