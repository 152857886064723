import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import { toast } from "react-toastify";
import { useLogOut } from "@/hooks/useLogOut";

const deleteAccount = async () => {
  const { data } = await restClient.delete<{ ok: number }>(apiPaths.deleteUser);
  return data;
};

export const useDeleteAccount = () => {
  const unauthorize = useLogOut();
  const mutation = useMutation<{ ok: number }, AxiosError<{ message: string }>, undefined>({
    mutationFn: deleteAccount,
    onSuccess: () => {
      toast.success("Account deleted!");
      unauthorize();
    },
  });

  return mutation;
};
