import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { Agent } from "@/types/agent";
import type { Tool } from "@/types/tools";
import { userToolsKeys } from "@/data/queries/useGetUserTools";
import { agentToolsKeys } from "@/data/queries/useGetAgentTools";

type Props = {
  toolId: Tool["_id"];
  agentId: Agent["_id"];
};

const assignToolToAgent = async (props: Props) => {
  const { data } = await restClient.post<string>(apiPaths.assignToolToAgent(props.agentId, props.toolId), props);
  return data;
};

export const useAssignToolToAgent = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<string, AxiosError<{ message: string }>, Props>({
    mutationFn: assignToolToAgent,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: userToolsKeys.all });
      void queryClient.invalidateQueries({ queryKey: agentToolsKeys.all });
    },
  });

  return mutation;
};
