import { useForm } from "react-hook-form";
import { useState } from "react";
import { RegisterAgeVerification } from "@/pages/Register/components/RegisterAgeVerification";
import type { RegisterForm, RegisterStep } from "@/types/user-register";
import { RegisterAgeVerificationFailed } from "@/pages/Register/components/RegisterAgeVerificationFailed";
import { RegisterUserAndTerms } from "@/pages/Register/components/RegisterUserAndTerms";
import { zodResolver } from "@hookform/resolvers/zod";
import { RegisterFormValidationSchema } from "./constants/RegisterFormValidationSchema";

export const Register = () => {
  const [registerStep, setRegisterStep] = useState<RegisterStep>("ageVerificationWait");
  const [isGoogleRegister, setIsGoogleRegister] = useState(false);

  const form = useForm<RegisterForm>({
    resolver: zodResolver(RegisterFormValidationSchema({ isSso: isGoogleRegister })),
  });

  if (registerStep === "ageVerificationProcess") {
    return <RegisterAgeVerification form={form} isGoogleRegister={isGoogleRegister} />;
  }

  if (registerStep === "ageVerificationFailed") {
    return <RegisterAgeVerificationFailed />;
  }

  return (
    <RegisterUserAndTerms form={form} setRegisterStep={setRegisterStep} setIsGoogleRegister={setIsGoogleRegister} />
  );
};
