import { searchParamsData } from "@/types/search-params";
import { useSearchParams } from "react-router-dom";

const useIsChatWithPagination = () => {
  const [searchParams] = useSearchParams();

  const withPagination = !searchParams.get(searchParamsData.MESSAGE);
  return withPagination;
};

export default useIsChatWithPagination;
