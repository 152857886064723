import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { Agent } from "@/types/agent";
import type { Tool } from "@/types/tools";

export const agentLeadGenToolKeys = {
  all: ["agentLeadGenerationTool"],
  id: (id: Agent["_id"]) => [...agentLeadGenToolKeys.all, id],
};

type Props = {
  agentId: Agent["_id"];
  enabled?: boolean;
};

const getAgentLeadGenTool = async ({ agentId }: Props) => {
  const { data } = await restClient.get<Tool>(apiPaths.getAgentLeadGenerationTool(agentId));
  return data;
};

export const useGetAgentLeadGenTool = ({ agentId, enabled = true }: Props) => {
  return useQuery({
    queryKey: agentLeadGenToolKeys.id(agentId),
    queryFn: () => getAgentLeadGenTool({ agentId }),
    enabled,
  });
};
