import { Button } from "@/components/ui/button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Icons } from "@/components/ui/icons";

interface FolderAccordionFolders {
  externalId: string;
  name: string;
  children: { name: string }[];
}

type FolderAccordionProps = {
  folders: FolderAccordionFolders[];
  removeFolder: (id: string) => void;
};

export const FolderAccordion = ({ folders, removeFolder }: FolderAccordionProps) => {
  return (
    <div>
      {folders.map(folder => (
        <Accordion
          type="single"
          collapsible
          className="my-2 rounded-md border border-slate-200 bg-white"
          key={folder.externalId}
        >
          <AccordionItem value={`online-info-${folder.externalId}`} className="relative">
            <AccordionTrigger className="h-10 border-slate-200 px-4 text-xs font-medium text-neutral-800 hover:no-underline data-[state=open]:border-b">
              <div className="flex w-full items-center justify-between gap-2 pr-2">
                <div className="flex gap-2">
                  <div className="flex h-4 min-w-[16px] items-center justify-center rounded-full border border-primary-500 bg-white px-1 text-[10px] font-medium text-primary-500">
                    {folder.children.length}
                  </div>
                  <div className="break-all text-left">{folder.name}</div>
                </div>
                <div className="flex gap-2">
                  <Button
                    variant="ghost"
                    type="button"
                    size="action-icon"
                    onClick={() => removeFolder(folder.externalId)}
                  >
                    <Icons.Trash className="text-neutral-400" />
                  </Button>
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="flex flex-col bg-neutral-100 p-2">
              <div key={folder.externalId}>
                {folder.children.map(child => (
                  <div
                    key={child.name}
                    className="my-2 flex w-full cursor-pointer items-center justify-between gap-2 rounded-md border border-slate-200 bg-white px-2"
                  >
                    <div>
                      <p className="break-all text-xs font-medium text-neutral-800 hover:underline">{child.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  );
};
