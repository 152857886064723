import { useGetCommunityAgents } from "@/data/queries/useGetCommunityAgents";
//import { useGetCommunityBasicAgents } from "@/data/queries/useGetCommunityBasicAgents";
import { useGetCommunityFeaturedAgents } from "@/data/queries/useGetCommunityFeaturedAgents";
import { AgentTile } from "../../../components/agentTiles/AgentTile/AgentTile";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { PublishNewAgent } from "@/pages/Community/components/PublishNewAgent";
import { memo, useCallback, useState } from "react";
import { PublishAgentDialog } from "../../../components/dialogs/PublishAgentDialog/PublishAgentDialog";
import { useSearchParams } from "react-router-dom";
import type { CommunityAgentFilters } from "@/types/community";
import { useDebounceValue } from "usehooks-ts";
import NotFound from "@/assets/images/NotFound.svg";
import { FetchNextPageTrigger } from "@/components/Chat/components/FetchNextPageTrigger";

const _CommunityAgents = () => {
  const [searchParams] = useSearchParams();
  const [debouncedSearchQuery] = useDebounceValue(searchParams.get("q") || "", 500);
  const {
    data: featuredAgents,
    isPending: isAgentFeaturedPending,
    isSuccess: isAgentFeaturedSuccess,
  } = useGetCommunityFeaturedAgents();
  /*const {
     data: basicAgents,
     isPending: isAgentBasicPending,
    isSuccess: isAgentBasicSuccess,
  } = useGetCommunityBasicAgents();*/

  const {
    data: agents,
    isPending,
    // refetch,
    isSuccess,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetCommunityAgents({
    query: debouncedSearchQuery,
    category: searchParams.get("category") as CommunityAgentFilters["category"],
  });
  const [isPostAgentDialogOpen, setIsPostAgentDialogOpen] = useState(false);

  //if (isPending) return <LoadingSpinner className="mt-10 flex w-full items-center justify-center" />;

  const generateHeader = (title: string) => {
    return <p className="text-xl font-bold text-primary-black">{title}</p>;
  };

  const displayNoResults = () => {
    return (
      <div className="flex w-full flex-col items-center justify-center gap-2">
        <img src={NotFound} />
        <p className="text-sm text-neutral-500">Sorry, no results found</p>
      </div>
    );
  };

  const getFeaturedAgents = useCallback(() => {
    return featuredAgents?.map(agent => <AgentTile key={agent._id} agent={agent} />);
  }, [featuredAgents]);

  /*const getBasicAgents = useCallback(() => {
    return basicAgents?.map(agent => <AgentTile key={agent._id} agent={agent} />);
  }, [basicAgents]);*/

  const getCommunityAgents = useCallback(() => {
    return agents?.map(agent => <AgentTile key={agent._id} agent={agent} />);
  }, [agents]);

  return (
    <>
      {!debouncedSearchQuery.length && searchParams.size == 0 && (
        <>
          {generateHeader("Featured Agents")}
          <div className="relative grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 4xl:flex 4xl:flex-wrap">
            <PublishNewAgent onClick={() => setIsPostAgentDialogOpen(true)} text="Post Your Agents Here" />
            {isAgentFeaturedPending ? (
              <LoadingSpinner className="absolute-center" />
            ) : (
              isAgentFeaturedSuccess && getFeaturedAgents()
            )}
          </div>
          {/* {generateHeader("Basic Agents")}
          <div className="relative grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 4xl:flex 4xl:flex-wrap">
            {isAgentBasicPending ? (
              <LoadingSpinner className="absolute-cetner" />
            ) : (
              isAgentBasicSuccess && getBasicAgents()
            )}
          </div>*/}
          {generateHeader("Popular Agents")}
        </>
      )}
      <div className="relative grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 4xl:flex 4xl:flex-wrap">
        {isPending ? (
          <LoadingSpinner className="mt-16 absolute-center" />
        ) : (
          isSuccess && agents.length > 0 && <>{getCommunityAgents()}</>
        )}

        {isPostAgentDialogOpen && (
          <PublishAgentDialog isDialogOpen={isPostAgentDialogOpen} setShowDialog={setIsPostAgentDialogOpen} />
        )}
      </div>
      {isFetchingNextPage && <LoadingSpinner className="mx-auto my-5" />}

      {hasNextPage && <FetchNextPageTrigger fetchNextPage={fetchNextPage} />}
      {isSuccess && !agents?.length && displayNoResults()}
    </>
  );
};

export const CommunityAgents = memo(_CommunityAgents);
