import type { DateRangeType } from "@/types/datepicker";
import { DateRangeTypes } from "@/types/datepicker";
import { useCallback, useState } from "react";
import { StatsContext } from "./StatsContext";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { StatOrigins } from "@/types/stats";
import type { TypeFromConstObject } from "@/types/types";

export const StatsPageChannelsTabs = {
  ALL_CHANNELS: { origin: null, description: "All channels" },
  WEB: { origin: StatOrigins.WEB, description: "Web" },
  EMBEDDED: { origin: StatOrigins.EMBEDDED, description: "Embedded" },
  AGENT_SPACE: { origin: StatOrigins.AGENT_SPACE, description: "Agent Space" },
  WORKSPACE: { origin: StatOrigins.WORKSPACE, description: "Workspace" },
  DISCORD: { origin: StatOrigins.DISCORD, description: "Discord" },
  SLACK: { origin: StatOrigins.SLACK, description: "Slack" },
} as const;

export type StatsPageChannelTab = TypeFromConstObject<typeof StatsPageChannelsTabs>;

export const statsChannelsTabs = Object.values(StatsPageChannelsTabs);

type StatsProviderProps = {
  children: React.ReactNode;
};

export const StatsProvider = ({ children }: StatsProviderProps) => {
  const location = useLocation() as {
    state: { tab: StatsPageChannelTab | null } | null;
    pathname: string;
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [currentRange, setCurrentRange] = useState<DateRangeType>(DateRangeTypes.Last7Days);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const selectedAgentId = searchParams.get("agent") ?? null;
  const setSelectedAgentId = (agentId: string | null) => {
    navigate({
      pathname: location.pathname,
      ...(agentId ? { search: createSearchParams({ agent: agentId }).toString() } : {}),
    });
  };

  const [conversationsListAgentId, setConversationsListAgentId] = useState<string>("");
  const isConversationsListOpen = !!conversationsListAgentId;

  const activeChannelTab: StatsPageChannelTab = location.state?.tab ?? StatsPageChannelsTabs.ALL_CHANNELS;
  const activeChannelsOrigins =
    activeChannelTab.description === StatsPageChannelsTabs.ALL_CHANNELS.description ? [] : [activeChannelTab.origin];

  const switchChannelTab = useCallback(
    ({ newTab }: { newTab: StatsPageChannelTab }) => {
      navigate(location.pathname, { replace: true, state: { tab: newTab } });
    },
    [location.pathname, navigate]
  );

  const value = {
    currentRange,
    setCurrentRange,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedAgentId,
    setSelectedAgentId,
    conversationsListAgentId,
    setConversationsListAgentId,
    isConversationsListOpen,
    activeChannelsOrigins,
    activeChannelTab,
    switchChannelTab,
  };

  return <StatsContext.Provider value={value}>{children}</StatsContext.Provider>;
};
