import { useState } from "react";
import { Accordion, AccordionContent, AccordionItem } from "@/components/ui/accordion";
import { Controller } from "react-hook-form";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { urlRegex } from "@/constants/regexps";
import { Icons } from "@/components/ui/icons";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { Button } from "@/components/ui/button";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { get } from "lodash";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

const leadIntergationTabs = ["POST", "HUBSPOT", "WIX"] as const;
type LeadIntergationTabs = (typeof leadIntergationTabs)[number];

export const LeadGenerationWebhook = () => {
  const form = useAgentFormContext();

  const { control, watch } = form;
  const isWebhookEnabled = watch("leadGeneration.webhook.enabled");
  const method = watch("leadGeneration.webhook.method");

  const [activeLeadIntegrationTab, setActiveLeadIntegrationTab] = useState(method ?? "POST");

  const resetWebhook = () => {
    form.setValue("leadGeneration.webhook.url", "", { shouldDirty: true });
    form.clearErrors("leadGeneration.webhook.url");
    form.setValue("leadGeneration.webhook.enabled", false);
    form.setValue("leadGeneration.webhook.method", "POST");
    form.setValue("leadGeneration.webhook.header", [""], { shouldDirty: true });
    form.clearErrors("leadGeneration.webhook.header");
  };

  return (
    <div className="mt-4 rounded-md border border-neutral-300 bg-neutral-50 p-4">
      <Accordion type="single" collapsible value={isWebhookEnabled ? "enabled" : ""} className="w-full">
        <AccordionItem value="enabled">
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <Icons.Webhook className="min-w-6 text-primary-400" />
              <p className="text-xs font-medium leading-5 text-neutral-400">
                Send lead info to CRM or external API endpoint
              </p>
              <HybridTooltipPopover heading="Integration">
                <p>
                  Automatically send lead data to external services. This allows you to build an automate process of the
                  lead generation data. Such as webhook, CRM platforms, email marketing, and so on.
                </p>
              </HybridTooltipPopover>
            </div>

            <div className="flex items-center gap-2 px-1">
              <Controller
                control={control}
                name="leadGeneration.webhook.enabled"
                render={({ field: { value, onChange } }) => (
                  <Button
                    variant="ghost"
                    className="p-0 text-primary-400"
                    onClick={() => (value ? resetWebhook() : onChange(true))}
                    //disabled={value}
                  >
                    {value ? "Cancel" : "Add an endpoint"}
                  </Button>
                )}
              />
            </div>
          </div>
          <AccordionContent className="mt-4 pb-1">
            <Tabs value={activeLeadIntegrationTab} className="w-full space-y-4 pb-4">
              <TabsList className="w-full bg-neutral-100 p-1">
                {leadIntergationTabs.map(tab => (
                  <Controller
                    key={tab}
                    control={control}
                    name="leadGeneration.webhook.method"
                    render={({ field: { value: method, onChange } }) => {
                      const updateMethod = (tabValue: string) => {
                        if (!method) {
                          form.setValue("leadGeneration.webhook.method", tabValue);
                        }
                        if (tabValue !== "POST") {
                          form.setValue("leadGeneration.webhook.url", `https://${tabValue.toLowerCase()}.com/`);
                        } else {
                          form.setValue("leadGeneration.webhook.url", "");
                        }
                        setActiveLeadIntegrationTab(tabValue as LeadIntergationTabs);
                        onChange(tabValue);
                      };
                      return (
                        <TabsTrigger
                          key={tab}
                          value={tab}
                          onClick={() => updateMethod(tab)}
                          className="flex-1 text-neutral-600 data-[state=active]:text-neutral-750"
                        >
                          {tab === leadIntergationTabs[0] && tab}
                          {tab === leadIntergationTabs[1] && <Icons.HubSpot />}
                          {tab === leadIntergationTabs[2] && <Icons.Wix />}
                        </TabsTrigger>
                      );
                    }}
                  />
                ))}
              </TabsList>
            </Tabs>
            {/* Normal Post request after lead generation */}
            {activeLeadIntegrationTab === leadIntergationTabs[0] && (
              <Controller
                control={control}
                name="leadGeneration.webhook.header"
                render={({ field: { value: headers, onChange: onChangeHeaders } }) => {
                  const addHeader = () => {
                    onChangeHeaders([...(headers ?? []), ""]);
                  };

                  const updateHeader = (index: number, value: string) => {
                    const newHeaders = [...(headers ?? [])];
                    newHeaders[index] = value;
                    onChangeHeaders(newHeaders);
                  };

                  const removeHeader = (index: number) => {
                    if (headers?.length !== 0) {
                      const newHeaders = headers?.filter((_, i) => i !== index);
                      onChangeHeaders(newHeaders);
                    } else {
                      updateHeader(index, "");
                    }
                  };

                  return (
                    <form onSubmit={e => e.preventDefault()} className="mb-5 flex flex-col gap-2">
                      <div className="flex items-center justify-between">
                        <Label className="whitespace-nowrap pt-2">
                          Headers <span className="text-xs font-medium leading-5 text-neutral-400">(Optional)</span>
                        </Label>
                        <button type="button" onClick={addHeader} className="pt-2">
                          <Icons.Plus className="h-6 w-6 text-neutral-400" />
                        </button>
                      </div>
                      {headers?.map((header, index) => (
                        <div key={index} className="flex items-start gap-2">
                          <Input
                            id={`webhookHeader${index}`}
                            placeholder="(Optional) Header here. (e.g. authorization: Bearer xxxxx)"
                            containerClassName="px-1"
                            disabled={!isWebhookEnabled}
                            value={header}
                            onChange={e => {
                              updateHeader(index, e.target.value);
                            }}
                            error={get(form.formState.errors, `leadGeneration.webhook.header[${index}].message`)}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                (e.target as HTMLInputElement).blur();
                              }
                            }}
                          />
                          <button onClick={() => removeHeader(index)} className="pt-2">
                            <Icons.XCircle className="h-6 w-6 text-neutral-400" />
                          </button>
                        </div>
                      ))}
                    </form>
                  );
                }}
              />
            )}
            {/* HubSpot CRM after lead generation */}
            {activeLeadIntegrationTab === leadIntergationTabs[1] && (
              <Controller
                control={control}
                name="leadGeneration.webhook.header"
                render={({ field: { value: headers, onChange: onChangeHeaders } }) => {
                  const updateHeader = (index: number, value: string) => {
                    const newHeaders = [...(headers ?? [])];
                    newHeaders[index] = value;
                    onChangeHeaders(newHeaders);
                  };
                  return (
                    <form onSubmit={e => e.preventDefault()} className="mb-5 flex flex-col gap-2">
                      <div className="flex">
                        <Icons.LightBulb className="h-4 w-4 text-primary-400" />
                        <p className="pr-1 text-xs font-medium leading-5 text-neutral-400">
                          Learn how to obtain your HubSpot API key{" "}
                        </p>
                        <a
                          className="p-0 text-xs font-medium leading-5 text-primary-500 hover:underline"
                          target="noopener noreferrer"
                          href="https://www.agentx.so/agentx-tutorial/how-to-generate-lead-and-send-to-hubspot-crm"
                        >
                          here
                        </a>
                      </div>
                      <div className="flex items-center justify-between">
                        <Label className="whitespace-nowrap pt-2">API Key</Label>
                      </div>
                      <div className="flex items-start gap-2">
                        <Input
                          id="webhookHeaderHubSpot"
                          placeholder="HubSpot API Key (e.g. xxx-xxx-xxxx-xxxx-xxxx)"
                          containerClassName="px-1"
                          disabled={!isWebhookEnabled}
                          value={headers?.length ? headers[0] : ""}
                          onChange={e => {
                            updateHeader(0, e.target.value);
                          }}
                          error={get(form.formState.errors, `leadGeneration.webhook.header[0].message`)}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              (e.target as HTMLInputElement).blur();
                            }
                          }}
                        />
                      </div>
                    </form>
                  );
                }}
              />
            )}
            {/* Wix CRM after lead generation */}
            {activeLeadIntegrationTab === leadIntergationTabs[2] && (
              <div>
                <Controller
                  control={control}
                  name="leadGeneration.webhook.header"
                  render={({ field: { value: headers, onChange: onChangeHeaders } }) => {
                    const updateHeader = (index: number, value: string) => {
                      const newHeaders = [...(headers ?? [])];
                      newHeaders[index] = value;
                      onChangeHeaders(newHeaders);
                    };
                    return (
                      <form onSubmit={e => e.preventDefault()} className="mb-5 flex flex-col gap-2">
                        <div className="flex">
                          <Icons.LightBulb className="h-4 w-4 text-primary-400" />
                          <p className="pr-1 text-xs font-medium leading-5 text-neutral-400">
                            Learn how to obtain your Wix credentials{" "}
                          </p>
                          <a
                            className="p-0 text-xs font-medium leading-5 text-primary-500 hover:underline"
                            target="noopener noreferrer"
                            href="https://www.agentx.so/agentx-tutorial/how-to-generate-lead-and-send-to-wix-crm"
                          >
                            here
                          </a>
                        </div>
                        <div className="flex items-center justify-between">
                          <Label className="whitespace-nowrap pt-2">API Key</Label>
                        </div>
                        <div className="flex items-start gap-2">
                          <Input
                            id="webhookHeaderWix"
                            placeholder="Wix API Key (e.g. xxx.xxxxxxxxxxx.xxxx)"
                            containerClassName="px-1"
                            disabled={!isWebhookEnabled}
                            value={headers?.length ? headers[0] : ""}
                            onChange={e => {
                              updateHeader(0, e.target.value);
                            }}
                            error={get(form.formState.errors, `leadGeneration.webhook.header[0].message`)}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                (e.target as HTMLInputElement).blur();
                              }
                            }}
                          />
                        </div>
                        <div className="flex items-center justify-between">
                          <Label className="whitespace-nowrap pt-2">Account ID</Label>
                        </div>
                        <div className="flex items-start gap-2">
                          <Input
                            id="webhookHeaderAccountId"
                            placeholder="Wix Account ID (e.g. xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxx)"
                            containerClassName="px-1"
                            disabled={!isWebhookEnabled}
                            value={headers?.length && headers?.length > 1 ? headers[1] : ""}
                            onChange={e => {
                              updateHeader(1, e.target.value);
                            }}
                            error={get(form.formState.errors, `leadGeneration.webhook.header[1].message`)}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                (e.target as HTMLInputElement).blur();
                              }
                            }}
                          />
                        </div>
                        <div className="flex items-center justify-between">
                          <Label className="whitespace-nowrap pt-2">Site ID</Label>
                        </div>
                        <div className="flex items-start gap-2">
                          <Input
                            id="webhookHeaderWixSiteId"
                            placeholder="Wix Site ID (e.g. xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxx)"
                            containerClassName="px-1"
                            disabled={!isWebhookEnabled}
                            value={headers?.length && headers?.length > 2 ? headers[2] : ""}
                            onChange={e => {
                              updateHeader(2, e.target.value);
                            }}
                            error={get(form.formState.errors, `leadGeneration.webhook.header[0].message`)}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                (e.target as HTMLInputElement).blur();
                              }
                            }}
                          />
                        </div>
                      </form>
                    );
                  }}
                />
              </div>
            )}
            {activeLeadIntegrationTab === leadIntergationTabs[0] && (
              <div>
                <Label htmlFor="webhookUrl" className="whitespace-nowrap pt-2">
                  Send data to:
                </Label>
                <form onSubmit={e => e.preventDefault()} className="flex items-start gap-2">
                  <Controller
                    control={form.control}
                    name="leadGeneration.webhook.url"
                    rules={{
                      required: "Value is required",
                      pattern: {
                        value: urlRegex,
                        message: "Entered value does not match URL format",
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Input
                          id="webhookUrl"
                          placeholder="Endpoint URL here. (e.g. https://api.hubapi.com/crm/v3/objects/contacts/batch/create)"
                          containerClassName="px-1"
                          disabled={!isWebhookEnabled}
                          value={value}
                          onChange={onChange}
                          error={get(form.formState.errors, "leadGeneration.webhook.url.message")}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              (e.target as HTMLInputElement).blur();
                            }
                          }}
                        />
                      </>
                    )}
                  />
                  <button onClick={resetWebhook} className="pt-2">
                    <Icons.XCircle className="h-6 w-6 text-neutral-400" />
                  </button>
                </form>
              </div>
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
