import { Avatar } from "@/components/Avatar";
import { Button } from "@/components/ui/button";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { sleep } from "@/utils/sleep";
import { useState } from "react";
import { FILE_INPUT_AGENT_ID, MAX_IMAGE_SIZE } from "@/constants/images";
import { isImageFormatAllowed } from "@/utils/fileUploads";
import { toast } from "react-toastify";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";
import { Controller } from "react-hook-form";

export const AgentAvatar = () => {
  const { control } = useAgentFormContext();

  const [loadingGenerateAvatar, setLoadingGenerateAvatar] = useState(false);
  const [loadingUploadAvatar, setLoadingUploadAvatar] = useState(false);

  const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];
    if (!image) {
      return;
    }
    if (!isImageFormatAllowed(image)) {
      return toast.error("Image format not allowed");
    }
    if (image.size > MAX_IMAGE_SIZE) {
      return toast.error("Image size too large");
    }
    setLoadingUploadAvatar(true);
    const formData = new FormData();
    formData.append("avatar", image);
    try {
      const res = (await restClient.put<{ path: string }>(apiPaths.updateAgentAvatar, formData)).data;
      return res.path;
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingUploadAvatar(false);
    }
  };

  const openImagePicker = () => {
    const input = document.getElementById(FILE_INPUT_AGENT_ID);
    if (input) {
      input.click();
    }
  };

  const generateRandomAvatar = async () => {
    setLoadingGenerateAvatar(true);
    try {
      const { data } = await restClient.get<{ path: string }>(apiPaths.getRandomAvatar);
      await sleep(500);
      return data.path;
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingGenerateAvatar(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4 sm:flex-row md:flex-col md:justify-start lg:h-full">
      <Controller
        control={control}
        name="avatar"
        render={({ field: { value, onChange } }) => (
          <>
            <Avatar src={value} size="custom" className="size-20 sm:size-[160px]" />
            <input
              type="file"
              accept="image/*"
              hidden
              name="avatar"
              onChange={async e => {
                const path = await uploadImage(e);
                onChange(path);
              }}
              id={FILE_INPUT_AGENT_ID}
              className="hidden"
            />
            <div className="flex flex-wrap items-center justify-center gap-4 sm:flex-col">
              <Button onClick={openImagePicker} variant="secondary" loading={loadingUploadAvatar}>
                Upload Avatar
              </Button>
              <Button
                onClick={async () => {
                  const path = await generateRandomAvatar();
                  onChange(path);
                }}
                loading={loadingGenerateAvatar}
                variant="secondary"
              >
                Generate Avatar
              </Button>
            </div>
          </>
        )}
      />
    </div>
  );
};
