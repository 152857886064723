import { cn } from "@/lib/utils";
type IconProps = React.HTMLAttributes<SVGElement>;

export const Icons = {
  Pdf: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#FCFCFC"
        stroke="currentColor"
        strokeWidth="2"
        d="M4 .5h11.793L21.5 6.207V22a1.5 1.5 0 0 1-1.5 1.5H4A1.5 1.5 0 0 1 2.5 22V2A1.5 1.5 0 0 1 4 .5Z"
      ></path>
      <path strokeWidth="2" stroke="currentColor" strokeLinejoin="round" d="M15.5 1v4.5a1 1 0 0 0 1 1H21"></path>
      <path
        strokeWidth="2"
        stroke="currentColor"
        d="M13.769 15.483c1.59-.289 4.654-.507 4.182.929-.59 1.794-4.22 0-6.217-3.83-1.997-3.832-.678-4.519-.34-4.557.34-.038 2.927-.726-1.38 8.667-.73 1.48-2.51 4.162-3.807 3.042-1.62-1.4 6.758-4.417 7.562-4.251Z"
      ></path>
    </svg>
  ),
  Spreadsheet: (props: IconProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="#FCFCFC"
        d="M14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 2V8H20" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 13H10" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 17H10" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 13H16" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 17H16" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  Google: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#FFC107"
        d="M21.805 10.041H21V10h-9v4h5.651A5.998 5.998 0 0 1 6 12a6 6 0 0 1 6-6c1.53 0 2.921.577 3.98 1.52l2.83-2.83A9.954 9.954 0 0 0 12 2C6.478 2 2 6.478 2 12c0 5.523 4.478 10 10 10 5.523 0 10-4.477 10-10 0-.67-.069-1.325-.195-1.959Z"
      />
      <path
        fill="#FF3D00"
        d="m3.153 7.346 3.286 2.409A5.997 5.997 0 0 1 12 6c1.53 0 2.921.577 3.98 1.52l2.83-2.83A9.954 9.954 0 0 0 12 2a9.994 9.994 0 0 0-8.847 5.346Z"
      />
      <path
        fill="#4CAF50"
        d="M12 22c2.583 0 4.93-.988 6.704-2.596l-3.095-2.619A5.955 5.955 0 0 1 12 18a5.997 5.997 0 0 1-5.641-3.973L3.098 16.54C4.753 19.777 8.114 22 12 22Z"
      />
      <path
        fill="#1976D2"
        d="M21.805 10.041H21V10h-9v4h5.651a6.02 6.02 0 0 1-2.043 2.785h.002l3.095 2.619C18.485 19.602 22 17 22 12c0-.67-.069-1.325-.195-1.959Z"
      />
    </svg>
  ),
  GoogleFull: (props: IconProps) => (
    <svg
      width="122px"
      height="16px"
      viewBox="0 0 122 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>greyscale-short</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="greyscale-short">
          <g id="Imported-Layers-Copy-2" transform="translate(73.000000, 0.127273)" fill="#757575">
            <path
              d="M6.16,5.45866667 L6.16,7.09733333 L10.082,7.09733333 C9.96466667,8.01933333 9.65733333,8.69266667 9.18933333,9.16066667 C8.618,9.73133333 7.72533333,10.3606667 6.16,10.3606667 C3.74466667,10.3606667 1.85733333,8.41466667 1.85733333,6 C1.85733333,3.58533333 3.74466667,1.63933333 6.16,1.63933333 C7.462,1.63933333 8.41333333,2.15133333 9.116,2.81 L10.272,1.65333333 C9.29133333,0.717333333 7.98933333,7.10542736e-15 6.16,7.10542736e-15 C2.85266667,7.10542736e-15 0.072,2.69266667 0.072,6 C0.072,9.30733333 2.85266667,12 6.16,12 C7.94466667,12 9.29133333,11.4146667 10.3453333,10.3173333 C11.428,9.234 11.7646667,7.712 11.7646667,6.48266667 C11.7646667,6.10266667 11.7353333,5.75133333 11.6766667,5.45866667 L6.16,5.45866667"
              id="Fill-10"
            ></path>
            <path
              d="M16.6666667,4.12733333 C14.5253333,4.12733333 12.7793333,5.756 12.7793333,8 C12.7793333,10.2293333 14.5253333,11.8726667 16.6666667,11.8726667 C18.8086667,11.8726667 20.554,10.2293333 20.554,8 C20.554,5.756 18.8086667,4.12733333 16.6666667,4.12733333 L16.6666667,4.12733333 Z M16.6666667,10.3466667 C15.4933333,10.3466667 14.4806667,9.37866667 14.4806667,8 C14.4806667,6.60666667 15.4933333,5.65266667 16.6666667,5.65266667 C17.84,5.65266667 18.8526667,6.60666667 18.8526667,8 C18.8526667,9.37866667 17.84,10.3466667 16.6666667,10.3466667 L16.6666667,10.3466667 Z"
              id="Fill-11"
            ></path>
            <path
              d="M35.72,4.99333333 L35.6613333,4.99333333 C35.2793333,4.538 34.546,4.12733333 33.622,4.12733333 C31.686,4.12733333 30,5.814 30,8 C30,10.1706667 31.686,11.8726667 33.622,11.8726667 C34.546,11.8726667 35.2793333,11.462 35.6613333,10.9926667 L35.72,10.9926667 L35.72,11.534 C35.72,13.0153333 34.928,13.8073333 33.6513333,13.8073333 C32.61,13.8073333 31.9646667,13.0593333 31.7006667,12.4286667 L30.2193333,13.0446667 C30.6446667,14.0713333 31.774,15.3333333 33.6513333,15.3333333 C35.6466667,15.3333333 37.3333333,14.16 37.3333333,11.2993333 L37.3333333,4.32666667 L35.72,4.32666667 L35.72,4.99333333 L35.72,4.99333333 Z M33.7693333,10.3466667 C32.596,10.3466667 31.702,9.34933333 31.702,8 C31.702,6.636 32.596,5.65266667 33.7693333,5.65266667 C34.9286667,5.65266667 35.8373333,6.65066667 35.8373333,8.01466667 C35.8373333,9.364 34.9286667,10.3466667 33.7693333,10.3466667 L33.7693333,10.3466667 Z"
              id="Fill-12"
            ></path>
            <path
              d="M25.3333333,4.12733333 C23.192,4.12733333 21.446,5.756 21.446,8 C21.446,10.2293333 23.192,11.8726667 25.3333333,11.8726667 C27.4753333,11.8726667 29.2206667,10.2293333 29.2206667,8 C29.2206667,5.756 27.4753333,4.12733333 25.3333333,4.12733333 L25.3333333,4.12733333 Z M25.3333333,10.3466667 C24.16,10.3466667 23.148,9.37866667 23.148,8 C23.148,6.60666667 24.16,5.65266667 25.3333333,5.65266667 C26.5066667,5.65266667 27.5193333,6.60666667 27.5193333,8 C27.5193333,9.37866667 26.5066667,10.3466667 25.3333333,10.3466667 L25.3333333,10.3466667 Z"
              id="Fill-13"
            ></path>
            <path
              d="M38.6666667,0.16 L40.34,0.16 L40.34,11.8726667 L38.6666667,11.8726667 L38.6666667,0.16 Z"
              id="Fill-14"
            ></path>
            <path
              d="M45.51,10.3466667 C44.6446667,10.3466667 44.0286667,9.95133333 43.632,9.174 L48.81,7.032 L48.634,6.592 C48.3113333,5.72666667 47.3286667,4.12733333 45.3193333,4.12733333 C43.324,4.12733333 41.6666667,5.69733333 41.6666667,8 C41.6666667,10.1713333 43.3093333,11.8726667 45.51,11.8726667 C47.2846667,11.8726667 48.3113333,10.7873333 48.7366667,10.156 L47.4166667,9.276 C46.9766667,9.922 46.3753333,10.3466667 45.51,10.3466667 L45.51,10.3466667 Z M45.384,5.57866667 C46.0726667,5.57866667 46.656,5.92933333 46.8506667,6.43266667 L43.3533333,7.88266667 C43.3533333,6.25 44.508,5.57866667 45.384,5.57866667 L45.384,5.57866667 Z"
              id="Fill-15"
            ></path>
          </g>
          <text
            id="translated-by-copy"
            opacity="0.54"
            fontFamily="Roboto"
            fontSize="12"
            fontWeight="normal"
            fill="#000000"
          >
            <tspan x="0" y="12">
              translated by
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  ),
  Github: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" fill="none" viewBox="0 0 41 40" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.44 0C9.414 0 .5 9.167.5 20.507c0 9.065 5.711 16.738 13.635 19.454.99.204 1.353-.441 1.353-.984 0-.475-.032-2.105-.032-3.803-5.547 1.223-6.703-2.444-6.703-2.444-.891-2.377-2.212-2.988-2.212-2.988-1.815-1.256.132-1.256.132-1.256 2.014.136 3.071 2.105 3.071 2.105 1.783 3.123 4.655 2.24 5.81 1.697.165-1.324.694-2.24 1.255-2.75-4.424-.475-9.079-2.24-9.079-10.118 0-2.24.792-4.074 2.047-5.5-.198-.509-.892-2.614.198-5.432 0 0 1.684-.543 5.48 2.105a18.803 18.803 0 0 1 4.985-.68c1.684 0 3.4.239 4.985.68 3.797-2.648 5.48-2.105 5.48-2.105 1.09 2.818.397 4.923.199 5.432 1.287 1.426 2.047 3.26 2.047 5.5 0 7.877-4.655 9.609-9.112 10.118.726.645 1.353 1.867 1.353 3.803 0 2.75-.032 4.957-.032 5.636 0 .543.363 1.188 1.353.985 7.923-2.717 13.635-10.39 13.635-19.455C40.38 9.167 31.434 0 20.44 0Z"
        clipRule="evenodd"
      />
    </svg>
  ),
  HubSpot: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="25" width="60" viewBox="-10 -5 130 45" {...props}>
      <g fill="none">
        <path
          fill="#33475B"
          d="M11.48 4.976v8.475H3.694V4.976H0v20.231h3.695v-8.2h7.784v8.2h3.696V4.976zm15.522 13.692a3.0575 3.0575 0 11-6.115 0v-8.666h-3.505v8.666a6.556 6.556 0 0013.112 0v-8.666h-3.492zm25.971-7.773c0-1.777 1.175-2.34 2.462-2.34 1.038 0 2.408.79 3.305 1.748l2.295-2.706c-1.146-1.55-3.47-2.621-5.373-2.621-3.803 0-6.553 2.227-6.553 5.919 0 6.85 8.372 4.676 8.372 8.51 0 1.182-1.148 2.226-2.462 2.226-2.073 0-2.745-1.013-3.697-2.085l-2.548 2.649c1.63 2 3.64 3.016 6.047 3.016 3.613 0 6.519-2.254 6.519-5.778 0-7.604-8.373-5.241-8.373-8.538m51.8 11.08c-2.071 0-2.66-.896-2.66-2.268v-6.075h3.22v-3.078h-3.22V6.493l-3.554 1.595v12.374c0 3.165 2.184 4.76 5.178 4.76.47.008.94-.03 1.402-.112l.867-3.192c-.391.027-.84.054-1.233.054M40.344 10.103c-1.736 0-2.948.504-4.12 1.653V5.097h-3.51V17.44c0 4.62 3.34 7.786 7.094 7.786 4.164 0 7.827-3.222 7.827-7.56 0-4.284-3.371-7.56-7.291-7.56m-.022 11.587a3.9835 3.9835 0 110-7.967 3.9835 3.9835 0 010 7.967m38.142-4.211c0-4.346-3.655-7.56-7.827-7.56-3.753 0-7.094 3.165-7.094 7.786v12.35h3.51V23.39c1.17 1.147 2.384 1.653 4.118 1.653 3.92 0 7.292-3.276 7.292-7.561m-3.323-.044a3.9835 3.9835 0 11-7.967 0 3.9835 3.9835 0 017.967 0"
        />
        <path
          fill="#FF7A59"
          d="M89.806 9.752V6.225a2.716 2.716 0 001.566-2.448v-.081c0-1.5-1.216-2.716-2.716-2.716h-.081a2.716 2.716 0 00-2.716 2.716v.081a2.716 2.716 0 001.566 2.448v3.527a7.691 7.691 0 00-3.657 1.61l-9.673-7.534c.069-.249.105-.505.109-.762A3.06 3.06 0 1071.14 6.12a3.027 3.027 0 001.507-.41l9.525 7.412a7.715 7.715 0 00.118 8.69l-2.897 2.898a2.488 2.488 0 00-.724-.118 2.513 2.513 0 102.515 2.515 2.48 2.48 0 00-.118-.724l2.866-2.867a7.728 7.728 0 105.874-13.764m-1.188 11.6a3.965 3.965 0 110-7.927 3.965 3.965 0 01.004 7.925"
        />
      </g>
    </svg>
  ),
  Wix: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="25" viewBox="5 5 15 15" {...props}>
      <path
        fill="currentColor"
        d="m13.444 8.256c-.4.212-.544.562-.544 1.53 0 0 .202-.194.499-.303.218-.075.402-.195.512-.275.338-.247.385-.562.385-1.096 0 0-.553-.016-.852.144zm-2.349.229c-.32.286-.418.739-.418.739l-1.078 4.141-.889-3.395c-.087-.355-.246-.808-.495-1.107-.31-.378-.944-.401-1.015-.401-.068 0-.709.023-1.026.407-.246.303-.406.751-.493 1.108l-.889 3.395-1.066-4.147s-.092-.459-.418-.739c-.529-.465-1.314-.367-1.314-.367l2.048 7.764s.677.052 1.015-.126c.441-.224.659-.401.929-1.463.241-.94.912-3.704.974-3.905.029-.098.07-.332.241-.332.179 0 .214.229.241.332.064.195.729 2.965.976 3.905.268 1.055.481 1.227.929 1.463.338.178 1.015.126 1.015.126l2.048-7.759c-.002 0-.789-.099-1.315.361zm3.201.9s-.129.195-.42.367c-.188.104-.367.178-.562.271-.323.154-.414.332-.414.595v5.266s.522.063.854-.104c.436-.222.533-.435.541-1.404v-4.991zm5.112 2.632 2.599-3.875s-1.096-.189-1.641.309c-.35.315-.738.885-.738.885l-.952 1.386c-.053.069-.104.15-.2.15-.099 0-.161-.075-.202-.15l-.962-1.382s-.385-.568-.74-.884c-.54-.499-1.641-.31-1.641-.31l2.603 3.865-2.603 3.858s1.146.149 1.688-.35c.35-.315.688-.837.688-.837l.95-1.383c.053-.068.104-.147.2-.147.1 0 .161.075.202.147l.952 1.383s.355.51.7.837c.538.499 1.667.35 1.667.35z"
      />
    </svg>
  ),

  Discord: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="22" fill="none" viewBox="0 0 28 22" {...props}>
      <path
        fill="currentColor"
        d="M23.313 2.354a21.855 21.855 0 0 0-5.536-1.74 16.202 16.202 0 0 0-.71 1.472 20.351 20.351 0 0 0-6.135 0 15.727 15.727 0 0 0-.717-1.472c-1.944.336-3.804.93-5.54 1.744C1.17 7.652.22 12.815.695 17.905c2.324 1.735 4.576 2.79 6.79 3.479a16.883 16.883 0 0 0 1.454-2.395 14.34 14.34 0 0 1-2.29-1.114c.192-.143.38-.291.561-.445 4.416 2.066 9.213 2.066 13.576 0 .183.154.371.302.561.445a14.27 14.27 0 0 1-2.294 1.116c.42.84.905 1.643 1.454 2.395 2.216-.69 4.47-1.744 6.794-3.481.557-5.9-.952-11.016-3.989-15.551ZM9.541 14.774c-1.325 0-2.412-1.237-2.412-2.743 0-1.507 1.064-2.747 2.412-2.747 1.35 0 2.436 1.237 2.413 2.746.002 1.507-1.064 2.745-2.413 2.745Zm8.915 0c-1.325 0-2.412-1.237-2.412-2.743 0-1.507 1.064-2.747 2.412-2.747 1.35 0 2.436 1.237 2.413 2.746 0 1.507-1.064 2.745-2.413 2.745Z"
      />
    </svg>
  ),
  OpenAI: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        fill="#22B4A1"
        d="M25.488 0H6.512C2.915 0 0 2.94 0 6.565v18.87C0 29.06 2.915 32 6.512 32h18.976C29.085 32 32 29.06 32 25.435V6.565C32 2.94 29.085 0 25.488 0Z"
      />
      <path
        fill="#fff"
        d="M23.667 14.376a4.464 4.464 0 0 0-.383-3.663 4.512 4.512 0 0 0-4.858-2.165 4.46 4.46 0 0 0-3.335-1.5h-.039a4.512 4.512 0 0 0-4.293 3.124 4.461 4.461 0 0 0-2.981 2.163 4.514 4.514 0 0 0 .554 5.29 4.462 4.462 0 0 0 .383 3.662 4.512 4.512 0 0 0 4.858 2.165 4.461 4.461 0 0 0 3.336 1.499h.04a4.511 4.511 0 0 0 4.292-3.125 4.464 4.464 0 0 0 2.982-2.163 4.505 4.505 0 0 0-.555-5.287Zm-6.729 9.405h-.004a3.348 3.348 0 0 1-2.143-.777c.036-.019.071-.039.106-.06l3.564-2.059a.58.58 0 0 0 .293-.504v-5.028l1.507.87a.053.053 0 0 1 .03.041v4.162a3.36 3.36 0 0 1-3.352 3.355ZM9.73 20.702a3.348 3.348 0 0 1-.4-2.248l.106.063 3.565 2.06a.581.581 0 0 0 .585 0l4.352-2.514v1.743a.054.054 0 0 1-.021.043l-3.604 2.08a3.362 3.362 0 0 1-4.583-1.227Zm-.937-7.782c.391-.68 1.01-1.2 1.746-1.47l-.002.122v4.122a.579.579 0 0 0 .293.503l4.352 2.512-1.507.87a.054.054 0 0 1-.05.005l-3.604-2.082a3.36 3.36 0 0 1-1.228-4.581Zm12.378 2.881-4.352-2.513 1.507-.87a.054.054 0 0 1 .05-.004l3.604 2.08a3.357 3.357 0 0 1-.518 6.055v-4.246a.58.58 0 0 0-.29-.502Zm1.5-2.257a4.69 4.69 0 0 0-.106-.063L19 11.422a.582.582 0 0 0-.585 0l-4.352 2.513v-1.743c0-.017.008-.033.021-.043l3.604-2.08a3.356 3.356 0 0 1 4.983 3.475Zm-9.427 3.101-1.507-.87a.054.054 0 0 1-.03-.041v-4.162a3.355 3.355 0 0 1 5.503-2.576c-.027.015-.075.04-.106.06l-3.565 2.059a.58.58 0 0 0-.293.503v.004l-.002 5.023Zm.818-1.764L16 13.76l1.939 1.119v2.238L16 18.237l-1.938-1.119v-2.237Z"
      />
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  AgentLogo: (props: IconProps) => (
    <svg width="645" height="100" viewBox="0 0 645 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M39.9941 1.24924C40.2999 0.494141 41.0331 0 41.8478 0H58.1522C58.9669 0 59.7001 0.49414 60.0059 1.24924L99.443 98.6246C99.7092 99.282 99.2254 100 98.5161 100H81.6786C81.2689 100 80.9007 99.7501 80.7494 99.3693L50.9146 24.3012C50.5824 23.4653 49.4015 23.4588 49.0601 24.291L18.2545 99.3796C18.1006 99.7549 17.7351 100 17.3294 100H1.4839C0.774598 100 0.290772 99.2821 0.557032 98.6246L39.9941 1.24924Z"
        fill="currentColor"
      />
      <path
        d="M115 77.8261V22.1739C115 7.39131 124.808 0 144.423 0H202.5L211.154 5.07247V15H144.423C135.705 15 131.154 19.43 131.154 26V75C131.154 81.57 135.282 85 144 85H197.846C198.398 85 198.846 84.5523 198.846 84V54.0435C198.846 53.4912 198.398 53.0435 197.846 53.0435H167.154C166.602 53.0435 166.154 52.5958 166.154 52.0435V43.029C166.154 42.4767 166.602 42.029 167.154 42.029H214C214.552 42.029 215 42.4767 215 43.029V99C215 99.5523 214.552 100 214 100H144.423C124.808 100 115 92.6087 115 77.8261Z"
        fill="currentColor"
      />
      <rect x="430" width="100" height="15" fill="currentColor" />
      <rect x="470" y="100" width="70" height="20" transform="rotate(-90 470 100)" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M624 100L548.5 0H570.35L594.611 33.7625H595.389L619.65 0H642.356L607.675 48.6409L645 100H624ZM588.712 70.6731L577.262 55.6078L545 100H567.862L588.712 70.6731Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M315 0V100H329.556C330.108 100 330.556 99.5523 330.556 99V18.8406L383.45 82.7016V61.8489L332.222 0H315ZM399.444 54H399.45V100H414C414.552 100 415 99.5523 415 99V1C415 0.447715 414.552 0 414 0H400.444C399.892 0 399.444 0.447714 399.444 0.999999V54Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M315 42V57H294H288H230V42H294H315ZM230 15H294H315V0H230V15ZM230 100H315V85H294H288H230V100Z"
        fill="currentColor"
      />
    </svg>
  ),
  Info: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
      className={cn("cursor-pointer", props.className)}
    >
      <g clipPath="url(#a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8 14.665A6.667 6.667 0 1 0 8 1.332a6.667 6.667 0 0 0 0 13.333Zm0-3.998V8m0-2.668h.007"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  Trash: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path fill="#000" d="M1.875 4.25h11.25-11.25Z" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.875 4.25h11.25m-1.25 0V13c0 .625-.625 1.25-1.25 1.25h-6.25c-.625 0-1.25-.625-1.25-1.25V4.25M5 4.25V3c0-.625.625-1.25 1.25-1.25h2.5C9.375 1.75 10 2.375 10 3v1.25M6.25 7.375v3.75m2.5-3.75v3.75"
      />
    </svg>
  ),
  DialogTrash: (props: IconProps) => (
    <svg width="92" height="80" viewBox="0 0 92 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.638 80H60.5519C63.7738 80 66.885 78.8039 69.2515 76.6302C69.2858 76.5992 69.317 76.57 69.3446 76.5432C82.8722 63.7477 93.1748 45.5567 90.9344 26.4284C89.9522 18.0399 85.2859 10.1122 78.3644 5.20424C71.694 0.474036 62.998 -0.932058 55.0289 0.581778C50.1501 1.50883 45.845 3.26797 42.5239 6.99564C36.5717 13.6755 33.2273 21.3056 23.736 23.6874C19.2 24.8257 14.4938 27.3396 10.6278 30.2017C2.36412 36.3197 -0.246127 47.7365 1.08869 57.4343C2.03531 64.3132 5.01233 70.7173 9.36642 76.0727C11.3858 78.5562 14.4259 79.9994 17.638 79.9994V80Z"
        fill="url(#paint0_linear_10675_67971)"
      />
      <path
        d="M75.6634 12.6062L77.1317 12.5569C77.3644 12.549 77.5597 12.7304 77.5677 12.9623C77.5757 13.1942 77.3932 13.3878 77.1599 13.3957C77.155 13.3957 77.1501 13.3957 77.1452 13.3957H77.1311L75.6861 13.3884H75.6745C75.4571 13.3872 75.282 13.2112 75.2832 12.9952C75.2844 12.7845 75.4534 12.6135 75.6634 12.6062Z"
        fill="#E5E5E5"
      />
      <path
        d="M69.5744 13.0969C70.5639 12.9374 71.5595 12.8126 72.5576 12.7201C72.7896 12.6988 72.9954 12.868 73.0168 13.0987C73.0382 13.3294 72.868 13.5339 72.6359 13.5552C72.6341 13.5552 72.6298 13.5552 72.628 13.5558C71.6465 13.6276 70.6674 13.7311 69.6926 13.8693C69.4777 13.8997 69.2787 13.7512 69.2481 13.5376C69.2181 13.3263 69.3638 13.1309 69.5744 13.0969Z"
        fill="#E5E5E5"
      />
      <path
        d="M42.6539 31.5798L42.7127 31.4496L42.769 31.3296C42.8064 31.2511 42.8437 31.1726 42.8823 31.0965C42.9582 30.9419 43.0372 30.7915 43.1162 30.6412C43.1952 30.4902 43.2785 30.3435 43.3593 30.195L43.6109 29.7555C43.952 29.1742 44.3139 28.6075 44.6898 28.0518C45.4472 26.944 46.2714 25.8854 47.1525 24.8786C48.0342 23.8719 48.9747 22.9174 49.9648 22.019C50.9525 21.1169 52.0007 20.283 53.0888 19.5075C53.6264 19.1106 54.1928 18.7545 54.7494 18.3863L55.6078 17.8689L55.8221 17.7398L55.9293 17.6753L56.0389 17.615L56.4779 17.374L56.9169 17.1329L57.1361 17.0124C57.209 16.9722 57.2849 16.9376 57.3596 16.8992L58.256 16.4536L58.4801 16.3422L58.7085 16.24L59.1653 16.0361L59.6221 15.8322L59.8504 15.7305L60.0831 15.638C62.5519 14.6269 65.1218 13.8788 67.7345 13.3888C67.9635 13.3462 68.1839 13.496 68.2274 13.723C68.2696 13.947 68.1245 14.1637 67.901 14.2106H67.8986C65.3514 14.7499 62.863 15.5382 60.4879 16.5754L60.2644 16.6703L60.0452 16.7744L59.6061 16.9826L59.1677 17.1908L58.9485 17.2949L58.7336 17.4081L57.8752 17.8597C57.8041 17.8981 57.7313 17.9334 57.6615 17.9742L57.4521 18.0959L57.0326 18.3394L56.6132 18.5823L56.5085 18.6431L56.4062 18.7083L56.2017 18.8379L55.3837 19.3565C54.8541 19.726 54.3152 20.0809 53.8052 20.4765C52.7728 21.249 51.7815 22.0756 50.8514 22.9655C49.9189 23.8524 49.0366 24.7904 48.213 25.7759C47.3901 26.7614 46.6241 27.7931 45.9273 28.8656C45.5813 29.4037 45.2507 29.9497 44.9415 30.5055L44.7143 30.9242C44.6421 31.0649 44.5667 31.2049 44.4976 31.3461C44.4278 31.4873 44.3573 31.6279 44.2924 31.7691C44.2581 31.8397 44.2275 31.9097 44.1957 31.9797L44.1498 32.0832L44.11 32.1776L44.0989 32.2037C43.93 32.6024 43.4683 32.7899 43.0666 32.6219C42.6655 32.4539 42.477 31.9949 42.646 31.5956C42.6478 31.5914 42.6509 31.5841 42.6527 31.5804L42.6539 31.5798Z"
        fill="#E5E5E5"
      />
      <path
        d="M47.5859 15.5279L10.6824 30.1604L8.80018 30.9067C7.72864 31.3322 6.51322 30.8123 6.08584 29.7471C5.84704 29.1524 5.73438 28.5388 5.73438 27.9356C5.73438 25.9914 6.90693 24.152 8.83446 23.3886L16.8979 20.1911L18.4127 19.591L20.6795 18.6919L30.5112 14.7938L32.778 13.8948L33.8152 13.4833L38.8802 11.4752L42.355 10.0971C44.8807 9.09577 47.7445 10.3193 48.7517 12.8295C49.1797 13.8954 48.6568 15.1036 47.5853 15.5279H47.5859Z"
        fill="#7844D3"
      />
      <path
        d="M32.78 13.8947L30.5132 14.7937C30.2205 14.4139 29.8819 14.0785 29.5078 13.7924C29.4913 13.7796 29.476 13.7681 29.4594 13.7559C27.9752 12.6444 25.9595 12.306 24.1104 13.0388C22.6543 13.6159 21.5828 14.7468 21.0439 16.0921C20.8719 16.5206 20.7537 16.9704 20.6949 17.4318C20.6417 17.8457 20.6362 18.2688 20.6809 18.6918L18.4141 19.5909C18.34 19.2092 18.2953 18.8257 18.28 18.4441C18.2574 17.895 18.2935 17.349 18.3847 16.8152C18.8299 14.2124 20.5872 11.8927 23.2299 10.8439C26.3134 9.6216 29.7215 10.4799 31.8738 12.7436C31.9742 12.8495 32.0721 12.9585 32.1664 13.0699C32.3875 13.3286 32.592 13.6037 32.7787 13.8947H32.78Z"
        fill="#7844D3"
      />
      <path
        d="M20.6949 17.4311C20.6417 17.845 20.6362 18.268 20.6809 18.6911L18.4141 19.5901C18.34 19.2085 18.2953 18.825 18.28 18.4433C18.2574 17.8943 18.2935 17.3483 18.3847 16.8144L21.0439 16.0913C20.8719 16.5198 20.7537 16.9697 20.6949 17.4311Z"
        fill="#5E33A8"
      />
      <path
        d="M32.1638 13.0701C32.3848 13.3288 32.5893 13.6039 32.7761 13.8949L30.5093 14.7939C30.2166 14.4141 29.878 14.0787 29.5039 13.7926L32.1638 13.0701Z"
        fill="#5E33A8"
      />
      <path
        d="M54.7707 78.149H21.9678C19.578 78.149 17.6174 76.2663 17.5354 73.8918L16.0989 32.2987C16.0585 31.1246 17.0051 30.1506 18.1869 30.1506H58.5505C59.7322 30.1506 60.6789 31.1246 60.6384 32.2987L59.202 73.8918C59.1199 76.2663 57.16 78.149 54.7695 78.149H54.7707Z"
        fill="#7844D3"
      />
      <path
        d="M49.7849 37.7245L48.7764 70.2594C48.7495 71.122 49.3379 71.8615 50.1473 72.063C50.2704 72.0941 50.3996 72.1123 50.5319 72.116C51.5324 72.1464 52.3688 71.3649 52.3994 70.3702L53.4079 37.8353C53.4385 36.8406 52.6523 36.0098 51.6518 35.9787C51.5195 35.9745 51.3891 35.9848 51.2642 36.0079C50.917 36.0719 50.6029 36.235 50.3543 36.4669C50.0163 36.7828 49.799 37.2266 49.7836 37.7239L49.7849 37.7245Z"
        fill="#5E33A8"
      />
      <path
        d="M49.7849 37.7242L48.7764 70.2592C48.7495 71.1217 49.3379 71.8613 50.1473 72.0628C50.9678 71.9118 51.601 71.2094 51.6273 70.3469L52.6357 37.8119C52.6627 36.9494 52.0743 36.2098 51.2648 36.0083C50.9176 36.0722 50.6035 36.2353 50.3549 36.4673C50.0169 36.7832 49.7996 37.2269 49.7843 37.7242H49.7849Z"
        fill="#D8CAF2"
      />
      <path
        d="M23.3295 37.8286L24.2418 70.3666C24.2663 71.2291 24.897 71.9334 25.7168 72.0868C25.8417 72.1105 25.9716 72.1209 26.1038 72.1172C27.1043 72.0892 27.893 71.2602 27.8648 70.2662L26.9525 37.7282C26.9243 36.7335 26.0903 35.9495 25.0904 35.9775C24.9582 35.9812 24.829 35.9988 24.7053 36.0293C24.3624 36.1133 24.0587 36.2941 23.8242 36.5406C23.5052 36.8754 23.3148 37.3313 23.3289 37.8286H23.3295Z"
        fill="#5E33A8"
      />
      <path
        d="M23.3289 37.8284L24.2412 70.3664C24.2657 71.2289 24.8964 71.9332 25.7162 72.0866C26.5263 71.8881 27.1166 71.1498 27.0927 70.2873L26.1803 37.7492C26.1565 36.8867 25.5252 36.1824 24.7053 36.0291C24.3624 36.1131 24.0587 36.2938 23.8242 36.5404C23.5052 36.8751 23.3148 37.3311 23.3289 37.8284Z"
        fill="#D8CAF2"
      />
      <path
        d="M36.526 37.7717V70.3225C36.526 71.1857 37.1371 71.907 37.9521 72.0829C38.0764 72.1103 38.2056 72.1243 38.3384 72.1243C39.3389 72.1243 40.1508 71.3171 40.1508 70.3225V37.7717C40.1508 36.7771 39.3389 35.97 38.3384 35.97C38.2062 35.97 38.0764 35.984 37.9521 36.0114C37.6067 36.0856 37.2981 36.2585 37.0569 36.4983C36.7287 36.8246 36.5254 37.2744 36.5254 37.7717H36.526Z"
        fill="#5E33A8"
      />
      <path
        d="M36.526 37.7721V70.3229C36.526 71.186 37.1371 71.9073 37.9521 72.0832C38.7676 71.9073 39.3781 71.186 39.3781 70.3229V37.7721C39.3781 36.9089 38.767 36.1876 37.9521 36.0117C37.6067 36.086 37.2981 36.2589 37.0569 36.4987C36.7287 36.8249 36.5254 37.2748 36.5254 37.7721H36.526Z"
        fill="#D8CAF2"
      />
      <path
        d="M53.6026 79.9221H23.9726C22.966 79.9221 22.1504 79.1113 22.1504 78.1106H55.4248C55.4248 79.1113 54.6092 79.9221 53.6026 79.9221Z"
        fill="#5E33A8"
      />
      <path
        d="M40.4965 28.112L36.7057 25.0338C36.5373 24.8968 36.5416 24.64 36.7143 24.5091L39.3319 22.5241C39.526 22.3768 39.8088 22.4779 39.8639 22.7147L41.0371 27.7778C41.1094 28.0883 40.7451 28.3135 40.4965 28.112Z"
        fill="#FF8500"
      />
      <path
        d="M51.561 27.3085L53.5148 25.7611C53.685 25.6266 53.9367 25.6851 54.0292 25.8804L54.7425 27.3925C54.8466 27.6122 54.6868 27.8654 54.4425 27.8691L51.7753 27.9044C51.4544 27.9086 51.3105 27.5069 51.561 27.3085Z"
        fill="#FF4848"
      />
      <path
        d="M44.093 23.4227L43.9307 22.3617C43.8891 22.0884 44.1151 21.8486 44.3918 21.8711L45.1156 21.9314C45.4272 21.9575 45.6078 22.2941 45.4554 22.5656L44.8939 23.5663C44.6943 23.9224 44.1542 23.825 44.093 23.4221V23.4227Z"
        fill="#AD45FF"
      />
      <path
        d="M79.9316 53.214C86.1783 46.9874 86.1783 36.892 79.9316 30.6654C73.685 24.4387 63.5572 24.4387 57.3105 30.6654C51.0639 36.892 51.0639 46.9874 57.3105 53.214C63.5572 59.4407 73.685 59.4407 79.9316 53.214Z"
        fill="#E84150"
      />
      <path
        d="M68.6227 53.2662C70.0938 53.2662 71.2864 52.0774 71.2864 50.611C71.2864 49.1446 70.0938 47.9558 68.6227 47.9558C67.1516 47.9558 65.959 49.1446 65.959 50.611C65.959 52.0774 67.1516 53.2662 68.6227 53.2662Z"
        fill="white"
      />
      <path
        d="M68.6227 45.969C67.6915 45.969 66.9254 45.2394 66.8827 44.3121L66.3615 32.9723C66.3027 31.6872 67.3317 30.6123 68.6227 30.6123C69.9138 30.6123 70.9428 31.6863 70.884 32.9723L70.3628 44.3121C70.3201 45.2394 69.554 45.969 68.6227 45.969Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10675_67971"
          x1="45.9997"
          y1="0"
          x2="45.9997"
          y2="80"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFD6FA" />
          <stop offset="1" stopColor="#F3E5DA" />
        </linearGradient>
      </defs>
    </svg>
  ),
  Agent: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12.667 7.333H3.333C2.597 7.333 2 7.93 2 8.668v4C2 13.403 2.597 14 3.333 14h9.334c.736 0 1.333-.597 1.333-1.333v-4c0-.737-.597-1.333-1.333-1.333ZM8 4.667A1.333 1.333 0 1 0 8 2a1.333 1.333 0 0 0 0 2.667Zm0 0v2.666"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  Stats: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14.14 10.593a6.666 6.666 0 1 1-8.807-8.706"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14.667 8A6.667 6.667 0 0 0 8 1.333V8h6.667Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  Server: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M3.333 6.667h-.666a1.333 1.333 0 0 1-1.334-1.333V2.667a1.333 1.333 0 0 1 1.334-1.333h10.666a1.333 1.333 0 0 1 1.334 1.333v2.667a1.333 1.333 0 0 1-1.334 1.333h-.666M3.333 9.334h-.666a1.333 1.333 0 0 0-1.334 1.333v2.667a1.333 1.333 0 0 0 1.334 1.333h10.666a1.333 1.333 0 0 0 1.334-1.333v-2.667a1.333 1.333 0 0 0-1.334-1.333h-.666M4 4h.007M4 12h.007"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-4.667V6m0 4v.667M10.667 8H10M6 8h-.667M10 6l-.587.587M6.587 9.414 6 10m4 0-.587-.586M6.587 6.587 6 6"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  Error: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 15 15" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="#ED1C17"
          d="M7.5 11.412a.554.554 0 1 0 0-1.108.554.554 0 0 0 0 1.108Zm0-1.992a.417.417 0 0 1-.417-.416v-5a.417.417 0 1 1 .834 0v5a.417.417 0 0 1-.417.417Z"
        />
        <path
          fill="#ED1C17"
          d="M7.5 14.167A6.667 6.667 0 1 1 7.5.833a6.667 6.667 0 0 1 0 13.334Zm0-12.5a5.833 5.833 0 1 0 0 11.666 5.833 5.833 0 0 0 0-11.666Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  Dots: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        stroke="#A3A3A3"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 8.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334Z"
      />
      <path
        stroke="#A3A3A3"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 4a.667.667 0 1 0 0-1.333A.667.667 0 0 0 8 4Zm0 9.333A.667.667 0 1 0 8 12a.667.667 0 0 0 0 1.333Z"
      />
    </svg>
  ),
  Dots2: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.277 11.123a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm0 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm0-14a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      />
    </svg>
  ),
  Copy: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.333 6h-6C6.597 6 6 6.597 6 7.333v6c0 .737.597 1.334 1.333 1.334h6c.737 0 1.334-.597 1.334-1.334v-6c0-.736-.597-1.333-1.334-1.333Z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.333 10h-.666a1.333 1.333 0 0 1-1.334-1.333v-6a1.333 1.333 0 0 1 1.334-1.334h6A1.333 1.333 0 0 1 10 2.667v.666"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  EyeOff: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24" {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.367 9.88a3 3 0 1 0 4.24 4.24m-3.39-9.04c.421-.052.845-.08 1.27-.08 7 0 10 7 10 7a13.163 13.163 0 0 1-1.67 2.68"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.097 6.61A13.526 13.526 0 0 0 2.487 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61M2.487 2l20 20"
      />
    </svg>
  ),
  Eye: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.156 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.156 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      />
    </svg>
  ),
  Line: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="157" height="2" fill="none" viewBox="0 0 157 2" {...props}>
      <path stroke="#000" d="M0 1h157" />
    </svg>
  ),
  Refresh: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="none" viewBox="0 0 15 16" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M13.125 1.75V5.5h-3.75" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.875 8a5.625 5.625 0 0 1 9.375-4.188L13.125 5.5m-11.25 8.75V10.5h3.75"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.125 8a5.625 5.625 0 0 1-9.375 4.188L1.875 10.5"
      />
    </svg>
  ),
  Spacer: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1" height="16" fill="none" viewBox="0 0 1 16" {...props}>
      <path stroke="currentColor" d="M.5 0v16" />
    </svg>
  ),
  Mails: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 44" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M36.667 7.333h-22A3.667 3.667 0 0 0 11 11v16.5a3.667 3.667 0 0 0 3.667 3.667h22a3.667 3.667 0 0 0 3.666-3.667V11a3.667 3.667 0 0 0-3.666-3.667Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m40.333 12.833-13.016 6.93a3.506 3.506 0 0 1-3.3 0L11 12.833m-7.333 1.834v20.166A3.677 3.677 0 0 0 7.333 38.5H33"
      />
    </svg>
  ),
  Arrow: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 44" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M34.833 22H9.167M22 34.833 9.167 22 22 9.167"
      />
    </svg>
  ),
  User: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="87" height="87" fill="none" viewBox="0 0 87 87" {...props}>
      <path
        stroke="#A3A3A3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7.25"
        d="M68.875 76.125v-7.25a14.5 14.5 0 0 0-14.5-14.5h-21.75a14.5 14.5 0 0 0-14.5 14.5v7.25M43.5 39.875c8.008 0 14.5-6.492 14.5-14.5s-6.492-14.5-14.5-14.5S29 17.367 29 25.375s6.492 14.5 14.5 14.5Z"
      />
    </svg>
  ),
  Users: (props: IconProps) => (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M24 31.6885V28.6885C24 27.0972 23.3679 25.5711 22.2426 24.4458C21.1174 23.3206 19.5913 22.6885 18 22.6885H9C7.4087 22.6885 5.88258 23.3206 4.75736 24.4458C3.63214 25.5711 3 27.0972 3 28.6885V31.6885"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 16.6885C16.8137 16.6885 19.5 14.0022 19.5 10.6885C19.5 7.37477 16.8137 4.68848 13.5 4.68848C10.1863 4.68848 7.5 7.37477 7.5 10.6885C7.5 14.0022 10.1863 16.6885 13.5 16.6885Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 31.6878V28.6878C32.999 27.3584 32.5565 26.067 31.742 25.0163C30.9276 23.9656 29.7872 23.2152 28.5 22.8828"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 4.88281C25.2906 5.21326 26.4346 5.96386 27.2515 7.01628C28.0684 8.06869 28.5118 9.36306 28.5118 10.6953C28.5118 12.0276 28.0684 13.3219 27.2515 14.3743C26.4346 15.4268 25.2906 16.1774 24 16.5078"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  UserBlack: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2m7-10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      />
    </svg>
  ),

  Database: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 8c4.97 0 9-1.343 9-3s-4.03-3-9-3-9 1.343-9 3 4.03 3 9 3Zm9 4c0 1.66-4 3-9 3s-9-1.34-9-3"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"
      />
    </svg>
  ),
  Dollar: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 2v20m5-17H9.5a3.5 3.5 0 1 0 0 7h5a3.5 3.5 0 1 1 0 7H6"
      />
    </svg>
  ),
  UploadCloud: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242M12 12v9"
      />
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m16 16-4-4-4 4" />
    </svg>
  ),
  Bot: (props: IconProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1483_17)">
        <path
          d="M19 11H5C3.89543 11 3 11.8954 3 13V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V13C21 11.8954 20.1046 11 19 11Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12 7V11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1483_17">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  Settings: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      />
    </svg>
  ),
  Settings2: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M13.3335 4.6665H7.3335" stroke="#7844D3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.3335 11.3335H3.3335" stroke="#7844D3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.3335 13.3335C12.4381 13.3335 13.3335 12.4381 13.3335 11.3335C13.3335 10.2289 12.4381 9.3335 11.3335 9.3335C10.2289 9.3335 9.3335 10.2289 9.3335 11.3335C9.3335 12.4381 10.2289 13.3335 11.3335 13.3335Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6665 6.6665C5.77107 6.6665 6.6665 5.77107 6.6665 4.6665C6.6665 3.56193 5.77107 2.6665 4.6665 2.6665C3.56193 2.6665 2.6665 3.56193 2.6665 4.6665C2.6665 5.77107 3.56193 6.6665 4.6665 6.6665Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  baggageClaim: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M22 18H6C5.46957 18 4.96086 17.7893 4.58579 17.4142C4.21071 17.0391 4 16.5304 4 16V7C4 6.46957 3.78929 5.96086 3.41421 5.58579C3.03914 5.21071 2.53043 5 2 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 14V4C17 3.46957 16.7893 2.96086 16.4142 2.58579C16.0391 2.21071 15.5304 2 15 2H14C13.4696 2 12.9609 2.21071 12.5858 2.58579C12.2107 2.96086 12 3.46957 12 4V14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 6H9C8.44772 6 8 6.44772 8 7V13C8 13.5523 8.44772 14 9 14H20C20.5523 14 21 13.5523 21 13V7C21 6.44772 20.5523 6 20 6Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 22C19.1046 22 20 21.1046 20 20C20 18.8954 19.1046 18 18 18C16.8954 18 16 18.8954 16 20C16 21.1046 16.8954 22 18 22Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22C10.1046 22 11 21.1046 11 20C11 18.8954 10.1046 18 9 18C7.89543 18 7 18.8954 7 20C7 21.1046 7.89543 22 9 22Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),

  Facebook: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="27" fill="none" viewBox="0 0 13 27" {...props}>
      <path
        fill="currentColor"
        d="M11.88 13.783H8.31v13.074H2.905V13.783H.332V9.188h2.572V6.215c0-2.126 1.01-5.456 5.455-5.456l4.005.017v4.46H9.458c-.477 0-1.147.238-1.147 1.252v2.704h4.04l-.472 4.591Z"
      />
    </svg>
  ),
  Upload: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.5 10.427v2.667a1.333 1.333 0 0 1-1.333 1.333H3.833A1.334 1.334 0 0 1 2.5 13.094v-2.667m9.333-4.667L8.5 2.427 5.167 5.76M8.5 2.427v8"
      />
    </svg>
  ),
  Download: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50005 1.04999C7.74858 1.04999 7.95005 1.25146 7.95005 1.49999V8.41359L10.1819 6.18179C10.3576 6.00605 10.6425 6.00605 10.8182 6.18179C10.994 6.35753 10.994 6.64245 10.8182 6.81819L7.81825 9.81819C7.64251 9.99392 7.35759 9.99392 7.18185 9.81819L4.18185 6.81819C4.00611 6.64245 4.00611 6.35753 4.18185 6.18179C4.35759 6.00605 4.64251 6.00605 4.81825 6.18179L7.05005 8.41359V1.49999C7.05005 1.25146 7.25152 1.04999 7.50005 1.04999ZM2.5 10C2.77614 10 3 10.2239 3 10.5V12C3 12.5539 3.44565 13 3.99635 13H11.0012C11.5529 13 12 12.5528 12 12V10.5C12 10.2239 12.2239 10 12.5 10C12.7761 10 13 10.2239 13 10.5V12C13 13.1041 12.1062 14 11.0012 14H3.99635C2.89019 14 2 13.103 2 12V10.5C2 10.2239 2.22386 10 2.5 10Z"
      ></path>
    </svg>
  ),
  DiscordCloud: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21.75 3.11812C21.7441 2.5014 21.4953 1.91187 21.0576 1.47735C20.6199 1.04282 20.0286 0.798327 19.4119 0.796875L4.57687 0.75C3.96076 0.750122 3.36983 0.994542 2.93365 1.42967C2.49746 1.8648 2.25161 2.45514 2.25 3.07125V18.375C2.25 19.6547 3.29437 20.625 4.57687 20.625H17.25L16.6406 18.5625L21.75 23.25V3.11812ZM15.218 15.4598C15.218 15.4598 14.8092 14.9728 14.468 14.5542C15.9558 14.1352 16.5234 13.2188 16.5234 13.2188C16.1148 13.4898 15.6769 13.7139 15.218 13.8867C14.6881 14.1067 14.1364 14.27 13.5722 14.3738C12.6 14.5523 11.603 14.5485 10.6322 14.3625C10.0611 14.2564 9.50221 14.0931 8.96391 13.875C8.67914 13.7659 8.40223 13.6373 8.13516 13.4902C8.10094 13.4677 8.06719 13.4564 8.03297 13.4334C8.01 13.4222 7.99875 13.4109 7.98609 13.4109C7.78172 13.2975 7.66828 13.2183 7.66828 13.2183C7.66828 13.2183 8.21297 14.1131 9.65484 14.543C9.31406 14.9733 8.89406 15.4716 8.89406 15.4716C6.38578 15.3923 5.43234 13.762 5.43234 13.762C5.43234 10.1498 7.06687 7.21734 7.06687 7.21734C8.70141 6.00563 10.245 6.03984 10.245 6.03984L10.3584 6.17578C8.31516 6.75328 7.38469 7.64766 7.38469 7.64766C7.38469 7.64766 7.63406 7.51172 8.05406 7.33031C9.26859 6.79828 10.2338 6.66234 10.6322 6.61734C10.6958 6.60413 10.7604 6.59644 10.8253 6.59437C11.5859 6.49568 12.3556 6.48813 13.118 6.57187C14.315 6.70938 15.4748 7.07453 16.5347 7.64766C16.5347 7.64766 15.638 6.79687 13.7081 6.22031L13.867 6.03937C13.867 6.03937 15.4219 6.00516 17.0456 7.21875C17.0456 7.21875 18.6802 10.1512 18.6802 13.7634C18.6802 13.7503 17.7262 15.3806 15.218 15.4598Z"
        fill="#536BF2"
      />
      <path
        d="M9.93984 10.2188C9.29297 10.2188 8.78203 10.7738 8.78203 11.4642C8.78203 12.1547 9.30422 12.7097 9.93984 12.7097C10.5867 12.7097 11.0977 12.1552 11.0977 11.4642C11.1094 10.7723 10.5867 10.2188 9.93984 10.2188ZM14.0827 10.2188C13.4358 10.2188 12.9248 10.7738 12.9248 11.4642C12.9248 12.1547 13.447 12.7097 14.0827 12.7097C14.73 12.7097 15.2405 12.1552 15.2405 11.4642C15.2405 10.7733 14.7187 10.2188 14.0827 10.2188Z"
        fill="#536BF2"
      />
    </svg>
  ),
  Link: (props: IconProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="link">
        <path
          id="Vector"
          d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15ZM8 11H16V13H8V11Z"
          fill="#111111"
        />
      </g>
    </svg>
  ),
  Link2: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 13a5.001 5.001 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
      />
    </svg>
  ),
  ExternalLink: (props: IconProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18 13V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H11"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15 3H21V9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 14L21 3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  Send: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M1.51 21L22.5 12L1.51 3L1.5 10L16.5 12L1.5 14L1.51 21Z" fill="black" />
    </svg>
  ),
  SendWhite: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M1.51 21L22.5 12L1.51 3L1.5 10L16.5 12L1.5 14L1.51 21Z" fill="white" />
    </svg>
  ),
  Send2: (props: IconProps) => (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M35.9755 3.82245C36.151 3.0055 35.3501 2.31666 34.5688 2.61478L0.678511 15.5522C0.270632 15.7079 0.000845727 16.0988 1.98474e-06 16.5354C-0.000841757 16.972 0.267398 17.364 0.674714 17.5213L10.1951 21.1983V32.7795C10.1951 33.2681 10.5306 33.6926 11.0059 33.8056C11.4781 33.9179 11.9704 33.6932 12.1917 33.2541L16.1293 25.4405L25.7383 32.5718C26.3228 33.0056 27.163 32.7297 27.3757 32.0325C36.3474 2.6171 35.9596 3.89635 35.9755 3.82245ZM27.6098 7.52915L11.1003 19.2866L3.99688 16.5432L27.6098 7.52915ZM12.3045 21.0186L26.6951 10.7702C14.3122 23.8334 14.9589 23.1458 14.9049 23.2185C14.8247 23.3265 15.0445 22.9058 12.3045 28.343V21.0186ZM25.7944 29.9866L17.3362 23.7094L32.6297 7.57556L25.7944 29.9866Z"
        fill="currentColor"
      />
    </svg>
  ),
  Close: (props: IconProps) => (
    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <line
        x1="1.77554"
        y1="1.07666"
        x2="16.5935"
        y2="15.8946"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1.5791"
        y1="15.8944"
        x2="16.3971"
        y2="1.07643"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ),
  // Refresh: (props: IconProps) => (
  //   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  //     <path
  //       d="M13.64 2.35C12.19 0.9 10.2 0 7.99 0C3.57 0 0 3.58 0 8C0 12.42 3.57 16 7.99 16C11.72 16 14.83 13.45 15.72 10H13.64C12.82 12.33 10.6 14 7.99 14C4.68 14 1.99 11.31 1.99 8C1.99 4.69 4.68 2 7.99 2C9.65 2 11.13 2.69 12.21 3.78L8.99 7H15.99V0L13.64 2.35Z"
  //       fill="#111111"
  //     />
  //   </svg>
  // ),
  ThumbUp: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M1 22H5V10H1V22ZM23 11C23 9.9 22.1 9 21 9H14.69L15.64 4.43L15.67 4.11C15.67 3.7 15.5 3.32 15.23 3.05L14.17 2L7.59 8.59C7.22 8.95 7 9.45 7 10V20C7 21.1 7.9 22 9 22H18C18.83 22 19.54 21.5 19.84 20.78L22.86 13.73C22.95 13.5 23 13.26 23 13V11Z"
        fill="currentColor"
      />
    </svg>
  ),
  ThumbDown: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M15 2H6C5.17 2 4.46 2.5 4.16 3.22L1.14 10.27C1.05 10.5 1 10.74 1 11V13C1 14.1 1.9 15 3 15H9.31L8.36 19.57L8.33 19.89C8.33 20.3 8.5 20.68 8.77 20.95L9.83 22L16.42 15.41C16.78 15.05 17 14.55 17 14V4C17 2.9 16.1 2 15 2ZM19 2V14H23V2H19Z"
        fill="currentColor"
      />
    </svg>
  ),
  LightBulb: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M9 18H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 22H14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.09 14C15.27 13.02 15.74 12.26 16.5 11.5C16.9829 11.0555 17.3662 10.5138 17.6247 9.91058C17.8832 9.30734 18.0111 8.65621 18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 9 6.23 10.23 7.5 11.5C8.22405 12.1621 8.718 13.0379 8.91 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  XCircle: (props: IconProps) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_8293_119459)">
        <path
          d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M10 6L6 10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 6L10 10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_8293_119459">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  ),
  ArrowUp: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19V5m-7 7 7-7 7 7" />
    </svg>
  ),
  CopyChat: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
      opacity="0.6"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.169 2h-6.9c-.4 0-.8.2-1.1.5-.3.3-.5.7-.5 1.1v12.8c0 .4.2.8.5 1.1.3.3.7.5 1.1.5h9.8c.4 0 .8-.2 1.1-.5.3-.3.5-.7.5-1.1V6.5l-4.5-4.5Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3.669 7.6v12.8c0 .4.2.8.5 1.1.3.3.7.5 1.1.5h9.8m.6-20v5h5"
      />
    </svg>
  ),
  Tags: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9 5H2v7l6.29 6.29c.94.94 2.48.94 3.42 0l3.58-3.58c.94-.94.94-2.48 0-3.42L9 5ZM6 9.01V9"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m15 5 6.3 6.3a2.4 2.4 0 0 1 0 3.4L17 19"
      />
    </svg>
  ),
  Galaxy: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M14.403 4.279A7.529 7.529 0 0 0 9.697.986a1.133 1.133 0 0 0-2.15-.332A13.79 13.79 0 0 0 .994 1.86a.38.38 0 1 0 .34.68A12.99 12.99 0 0 1 7.47 1.41a1.133 1.133 0 0 0 2.073.32 6.788 6.788 0 0 1 4.217 2.95c.974 1.56 1.097 3.062.382 4.37a1.127 1.127 0 0 0-.282-.04c-.63 0-1.139.51-1.14 1.14.001.142.029.283.081.415-2.256 1.718-5.728 1.952-7.599.455-.919-.735-1.255-1.7-.923-2.648a2.87 2.87 0 0 1 1.307-1.475 2.23 2.23 0 0 0-.241 1.001 2.282 2.282 0 0 0 2.279 2.28c2.776 0 4.652-.94 5.019-2.512.309-1.326-.553-2.744-2.145-3.528-1.804-.887-4.621-.755-6.705.272a1.134 1.134 0 0 0-1.802 1.32 4.874 4.874 0 0 0-1.2 4.121c.675 4.726 5.885 5.36 8.421 5.384a1.134 1.134 0 0 0 2.178-.115 10.951 10.951 0 0 0 2.052-.404.38.38 0 1 0-.24-.72c-.62.181-1.254.305-1.896.37a1.133 1.133 0 0 0-2.091.109c-2.322-.029-7.08-.586-7.672-4.731a4.092 4.092 0 0 1 .985-3.48c.152.075.319.114.488.115a1.14 1.14 0 0 0 1.14-1.14 1.133 1.133 0 0 0-.016-.154c1.885-.928 4.414-1.057 6.023-.265 1.248.615 1.964 1.714 1.74 2.674-.2.863-1.087 1.47-2.448 1.749.29-.389.447-.86.448-1.345a2.282 2.282 0 0 0-2.279-2.28c-1.904 0-3.536 1.006-4.061 2.502-.44 1.253-.004 2.558 1.165 3.493a5.753 5.753 0 0 0 3.628 1.149 8.433 8.433 0 0 0 4.944-1.626c.17.1.363.152.56.153.63 0 1.139-.51 1.14-1.14a1.129 1.129 0 0 0-.227-.675c.88-1.557.761-3.387-.37-5.196Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  Plus: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M12 5L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 12L19 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  PlusCircle: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 8L12 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 12L16 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  Heart: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16" {...props}>
      <path
        fill="currentColor"
        d="m8.499 12.367-.067.066-.073-.066c-3.167-2.874-5.26-4.774-5.26-6.7 0-1.334 1-2.334 2.333-2.334 1.027 0 2.027.667 2.38 1.574h1.24c.354-.907 1.354-1.574 2.38-1.574 1.334 0 2.334 1 2.334 2.334 0 1.926-2.094 3.826-5.267 6.7ZM11.432 2a4.01 4.01 0 0 0-3 1.387 4.01 4.01 0 0 0-3-1.387 3.627 3.627 0 0 0-3.666 3.667c0 2.513 2.266 4.573 5.7 7.686l.966.88.967-.88c3.433-3.113 5.7-5.173 5.7-7.686A3.627 3.627 0 0 0 11.432 2Z"
      />
    </svg>
  ),
  HeartFilled: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14" {...props}>
      <path
        fill="currentColor"
        d="M9.862.867a4.01 4.01 0 0 0-3 1.386 4.01 4.01 0 0 0-3-1.386A3.627 3.627 0 0 0 .195 4.533c0 2.514 2.267 4.574 5.7 7.687l.967.88.967-.88c3.433-3.113 5.7-5.173 5.7-7.687A3.627 3.627 0 0 0 9.862.867Z"
      />
    </svg>
  ),
  SortArrows: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24" {...props}>
      <path fill="currentColor" d="M8.484 16h-4l6 6V2h-2v14Zm6-11v17h2V8h4l-6-6v3Z" />
    </svg>
  ),
  Triangle: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" viewBox="0 0 15 11" {...props}>
      <path
        fill="currentColor"
        d="M13.345.5h-12a.668.668 0 0 0-.549 1.046l6 8.667c.249.359.847.359 1.097 0l6-8.667A.667.667 0 0 0 13.345.5Z"
      />
    </svg>
  ),
  TriangleDropdown: (props: IconProps) => (
    <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.26367 6L12.2637 0H0.263672L6.26367 6Z" fill="white" />
    </svg>
  ),
  Menu: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 3H3v7h7V3Zm11 0h-7v7h7V3Zm0 11h-7v7h7v-7Zm-11 0H3v7h7v-7Z"
      />
    </svg>
  ),
  Search: (props: IconProps) => (
    <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  Share: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.333"
          d="M2.695 8.983v5.334A1.333 1.333 0 0 0 4.03 15.65h8a1.333 1.333 0 0 0 1.333-1.333V8.983m-2.666-3.999L8.03 2.317 5.363 4.984M8.03 2.317v8.667"
        />
      </g>
    </svg>
  ),
  NewConversation: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 25" {...props}>
      <g clipPath="url(#clip0_5416_81465)">
        <path
          fill="black"
          d="M14.9058 2.97225C14.9058 3.45254 14.5165 3.84184 14.0362 3.84188L4.96909 3.84212C3.21074 3.84212 1.78057 5.24717 1.74007 6.99581L1.73918 19.7585C1.73918 21.5168 3.14422 22.947 4.89287 22.9875L17.8888 22.9884C19.6471 22.9884 21.0773 21.5833 21.1178 19.8347L21.1186 10.4451C21.1187 9.96477 21.508 9.57547 21.9883 9.57547C22.4685 9.57547 22.8579 9.96482 22.8579 10.4451V19.7584C22.8579 22.5028 20.6331 24.7275 17.8888 24.7275H4.96909C2.22476 24.7275 0 22.5028 0 19.7584V7.07203C0 4.32766 2.22476 2.1029 4.96909 2.1029L14.0362 2.10266C14.5165 2.10266 14.9058 2.49196 14.9058 2.97225Z"
        />
        <path
          fill="black"
          d="M23.1903 1.89838C24.2717 2.97985 24.2717 4.73323 23.1903 5.81469L14.7663 14.2387C14.4237 14.5812 14.0126 14.8472 13.5598 15.0193L9.02742 16.7417C8.75962 16.8434 8.51446 16.8832 8.34697 16.7417C8.17949 16.6001 8.33385 16.0958 8.34697 16.0612L10.0693 11.5289C10.2414 11.0761 10.5074 10.6649 10.8499 10.3224L19.2739 1.89838C20.3554 0.816914 22.1088 0.816914 23.1903 1.89838ZM18.6239 5.15918L12.1553 11.6278C12.017 11.7662 11.9057 11.9287 11.8268 12.1072L11.795 12.1847L11.1152 13.9731L12.9039 13.2935C13.0868 13.2241 13.2549 13.1214 13.4 12.9909L13.4608 12.9333L19.9339 6.45965L18.6239 5.15918ZM20.6161 3.16899L19.9292 3.85393L21.2392 5.15445L21.8848 4.5093C22.2452 4.14883 22.2452 3.56434 21.8848 3.20386C21.5363 2.85539 20.9786 2.84377 20.6161 3.16899Z"
        />
      </g>
    </svg>
  ),
  LinkedIn: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" fill="none" viewBox="0 0 23 22" {...props}>
      <path
        fill="currentColor"
        d="M22.802 13.609v8.246H18.02v-7.693c0-1.932-.69-3.251-2.421-3.251-1.321 0-2.106.888-2.452 1.748-.126.307-.159.734-.159 1.165v8.03H8.21s.064-13.03 0-14.378h4.78v2.038c-.009.016-.022.031-.031.047h.032v-.047c.635-.978 1.768-2.375 4.308-2.375 3.146 0 5.504 2.054 5.504 6.47ZM3.207.545C1.572.545.502 1.62.502 3.03c0 1.38 1.039 2.485 2.642 2.485h.031c1.668 0 2.705-1.104 2.705-2.485C5.848 1.62 4.843.545 3.207.545Zm-2.42 21.31h4.778V7.476H.786v14.379Z"
      />
    </svg>
  ),
  TwitterX: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M14.187 10.19 22.75.238h-2.03l-7.434 8.641L7.348.238H.5l8.979 13.067L.5 23.742h2.029l7.85-9.126 6.271 9.126h6.85L14.187 10.19ZM3.26 1.765h3.116l14.345 20.519h-3.116L3.26 1.765Z"
      />
    </svg>
  ),
  // Google: (props: IconProps) => <svg></svg>,
  CheckboxIndeterminate: (props: IconProps) => (
    <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.33325 1H10.6666" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  CheckboxCheck: (props: IconProps) => (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.3334 1L5.00008 8.33333L1.66675 5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Check: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13.333 4 6 11.333 2.667 8"
      />
    </svg>
  ),
  CheckCircle: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0_8273_23319)">
        <path
          d="M8.00016 14.6668C11.6822 14.6668 14.6668 11.6822 14.6668 8.00016C14.6668 4.31816 11.6822 1.3335 8.00016 1.3335C4.31816 1.3335 1.3335 4.31816 1.3335 8.00016C1.3335 11.6822 4.31816 14.6668 8.00016 14.6668Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 7.99984L7.33333 9.33317L10 6.6665"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8273_23319">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  ),
  Circle: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  Globe: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16" {...props}>
      <path stroke="currentColor" strokeWidth="1" d="M8.667 1.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13Z" />
      <path
        stroke="currentColor"
        strokeWidth="1"
        d="M8.667 1.5C6.852 1.5 5.146 4.41 5.146 8s1.706 6.5 3.52 6.5c1.815 0 3.522-2.91 3.522-6.5S10.48 1.5 8.667 1.5Z"
      />
      <path
        strokeWidth="1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.333 3.667A7.475 7.475 0 0 0 8.667 5.02 7.474 7.474 0 0 0 13 3.667m0 8.666a7.474 7.474 0 0 0-4.333-1.354 7.474 7.474 0 0 0-4.334 1.354"
      />
      <path strokeWidth="1" stroke="currentColor" d="M8.667 1.5v13m6.5-6.5h-13" />
    </svg>
  ),
  Globe2: (props: IconProps) => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M32 12C20.9548 12 12 20.9548 12 32C12 43.0452 20.9548 52 32 52C43.0452 52 52 43.0452 52 32C52 20.9548 43.0452 12 32 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M31.9997 12C26.416 12 21.166 20.9548 21.166 32C21.166 43.0452 26.416 52 31.9997 52C37.5833 52 42.8333 43.0452 42.8333 32C42.8333 20.9548 37.5833 12 31.9997 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M18 18C21.8607 20.741 26.7208 22.3756 32 22.3756C37.2791 22.3756 42.1393 20.741 46 18M46 46C42.1393 43.259 37.2791 41.6244 32 41.6244C26.7208 41.6244 21.8607 43.259 18 46"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M32 12V52M52 32H12" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
    </svg>
  ),
  Community: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M15.07 4.279A7.529 7.529 0 0 0 10.362.986a1.133 1.133 0 0 0-2.15-.332c-2.25-.14-4.499.275-6.55 1.207a.38.38 0 1 0 .339.68 12.99 12.99 0 0 1 6.134-1.13 1.133 1.133 0 0 0 2.073.32 6.788 6.788 0 0 1 4.216 2.95c.975 1.56 1.097 3.062.383 4.37a1.127 1.127 0 0 0-.282-.04 1.14 1.14 0 0 0-1.059 1.555c-2.257 1.718-5.728 1.952-7.599.455-.92-.735-1.255-1.7-.923-2.648a2.873 2.873 0 0 1 1.307-1.475c-.157.31-.24.653-.242 1.001a2.282 2.282 0 0 0 2.28 2.28c2.776 0 4.652-.94 5.019-2.512.308-1.326-.554-2.744-2.145-3.528-1.804-.887-4.622-.755-6.706.272a1.134 1.134 0 0 0-1.8 1.32 4.874 4.874 0 0 0-1.202 4.121c.675 4.726 5.886 5.36 8.422 5.384a1.134 1.134 0 0 0 2.177-.115 10.953 10.953 0 0 0 2.053-.404.38.38 0 1 0-.24-.72c-.62.181-1.255.305-1.897.37a1.133 1.133 0 0 0-2.09.109c-2.322-.029-7.08-.586-7.673-4.731a4.092 4.092 0 0 1 .986-3.48c.152.075.319.114.488.115a1.141 1.141 0 0 0 1.14-1.14 1.132 1.132 0 0 0-.016-.154c1.884-.928 4.414-1.057 6.023-.265 1.247.615 1.964 1.714 1.74 2.674-.201.863-1.087 1.47-2.448 1.749.29-.389.447-.86.448-1.345a2.282 2.282 0 0 0-2.28-2.28c-1.904 0-3.536 1.006-4.06 2.502-.44 1.253-.004 2.558 1.164 3.493a5.753 5.753 0 0 0 3.63 1.149 8.433 8.433 0 0 0 4.943-1.626c.17.1.363.152.56.153a1.14 1.14 0 0 0 1.14-1.14 1.123 1.123 0 0 0-.228-.675c.88-1.557.762-3.387-.369-5.196Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.667 0h16v16h-16z" />
        </clipPath>
      </defs>
    </svg>
  ),
  WhatsUp: (props: IconProps) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_9040_10809)">
        <path
          d="M11.735 9.608C11.5742 9.52807 10.5293 9.04873 10.3686 8.9688C10.2078 8.88887 10.047 8.88887 9.88631 9.04873C9.72558 9.20851 9.40405 9.68793 9.24333 9.84771C9.16289 10.0075 9.00216 10.0075 8.84144 9.92756C8.27875 9.68793 7.71613 9.36829 7.23388 8.9688C6.832 8.56931 6.43012 8.08989 6.10861 7.61047C6.02823 7.45069 6.10861 7.29091 6.18898 7.21098C6.26936 7.13105 6.34974 6.97129 6.5105 6.89139C6.59087 6.81149 6.67125 6.65169 6.67125 6.57179C6.75162 6.49189 6.75162 6.33209 6.67125 6.25219C6.59087 6.17228 6.18898 5.21348 6.02824 4.81398C5.94786 4.25468 5.7871 4.25468 5.62635 4.25468C5.54597 4.25468 5.38523 4.25468 5.22447 4.25468C5.06371 4.25468 4.82259 4.41448 4.74221 4.49438C4.25995 4.97378 4.01882 5.53308 4.01882 6.17228C4.0992 6.89139 4.34033 7.61047 4.82259 8.24967C5.70673 9.52807 6.832 10.5668 8.19838 11.206C8.60027 11.3658 8.9218 11.5256 9.32369 11.6055C9.72558 11.7653 10.1274 11.7653 10.6097 11.6854C11.1723 11.6055 11.6546 11.206 11.9761 10.7266C12.1368 10.407 12.1368 10.0874 12.0565 9.76778C12.0565 9.76778 11.8957 9.68793 11.735 9.608ZM13.7444 2.33708C10.6097 -0.779027 5.54597 -0.779027 2.4113 2.33708C-0.160753 4.89388 -0.643012 8.80902 1.12527 11.9251L0 16L4.25996 14.8814C5.4656 15.5206 6.75162 15.8402 8.03766 15.8402C12.4584 15.8402 15.9949 12.3246 15.9949 7.93011C16.0753 5.85268 15.1912 3.85518 13.7444 2.33708ZM11.5742 13.5231C10.5293 14.1623 9.32369 14.5618 8.03766 14.5618C6.832 14.5618 5.70673 14.2422 4.66184 13.6829L4.4207 13.5231L1.92904 14.1623L2.57205 11.7653L2.4113 11.5256C0.482259 8.3296 1.44678 4.33458 4.58146 2.33708C7.71613 0.339575 11.735 1.37827 13.664 4.41448C15.5931 7.53062 14.7089 11.6055 11.5742 13.5231Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5742 13.5231C10.5293 14.1623 9.32369 14.5618 8.03766 14.5618C6.832 14.5618 5.70673 14.2422 4.66184 13.6829L4.4207 13.5231L1.92904 14.1623L2.57205 11.7653L2.4113 11.5256C0.482259 8.3296 1.44678 4.33458 4.58146 2.33708C7.71613 0.339575 11.735 1.37827 13.664 4.41448C15.5931 7.53062 14.7089 11.6055 11.5742 13.5231ZM10.3686 8.9688C10.5293 9.04873 11.5742 9.52807 11.735 9.608C11.8957 9.68793 12.0565 9.76778 12.0565 9.76778C12.1368 10.0874 12.1368 10.407 11.9761 10.7266C11.6546 11.206 11.1723 11.6055 10.6097 11.6854C10.1274 11.7653 9.72558 11.7653 9.32369 11.6055C8.9218 11.5256 8.60027 11.3658 8.19838 11.206C6.832 10.5668 5.70673 9.52807 4.82259 8.24967C4.34033 7.61047 4.0992 6.89139 4.01882 6.17228C4.01882 5.53308 4.25995 4.97378 4.74221 4.49438C4.82259 4.41448 5.06371 4.25468 5.22447 4.25468H5.62635C5.7871 4.25468 5.94786 4.25468 6.02824 4.81398C6.18898 5.21348 6.59087 6.17228 6.67125 6.25219C6.75162 6.33209 6.75162 6.49189 6.67125 6.57179C6.67125 6.65169 6.59087 6.81149 6.5105 6.89139C6.34974 6.97129 6.26936 7.13105 6.18898 7.21098C6.10861 7.29091 6.02823 7.45069 6.10861 7.61047C6.43012 8.08989 6.832 8.56931 7.23388 8.9688C7.71613 9.36829 8.27875 9.68793 8.84144 9.92756C9.00216 10.0075 9.16289 10.0075 9.24333 9.84771C9.40405 9.68793 9.72558 9.20851 9.88631 9.04873C10.047 8.88887 10.2078 8.88887 10.3686 8.9688Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9040_10809">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  Slack: (props: IconProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 3.5C16 2.67157 15.3284 2 14.5 2C13.6716 2 13 2.67157 13 3.5V8.5C13 9.32843 13.6716 10 14.5 10C15.3284 10 16 9.32843 16 8.5V3.5Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 8.5V10H20.5C20.7967 10 21.0867 9.91203 21.3334 9.74721C21.58 9.58238 21.7723 9.34812 21.8858 9.07403C21.9993 8.79994 22.0291 8.49834 21.9712 8.20736C21.9133 7.91639 21.7704 7.64912 21.5607 7.43934C21.3509 7.22956 21.0836 7.0867 20.7926 7.02882C20.5017 6.97094 20.2001 7.00065 19.926 7.11418C19.6519 7.22771 19.4176 7.41997 19.2528 7.66665C19.088 7.91332 19 8.20333 19 8.5Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 15.5C11 14.6716 10.3284 14 9.5 14C8.67157 14 8 14.6716 8 15.5V20.5C8 21.3284 8.67157 22 9.5 22C10.3284 22 11 21.3284 11 20.5V15.5Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 15.5V14H3.5C3.20333 14 2.91332 14.088 2.66665 14.2528C2.41997 14.4176 2.22771 14.6519 2.11418 14.926C2.00065 15.2001 1.97094 15.5017 2.02882 15.7926C2.0867 16.0836 2.22956 16.3509 2.43934 16.5607C2.64912 16.7704 2.91639 16.9133 3.20737 16.9712C3.49834 17.0291 3.79994 16.9993 4.07403 16.8858C4.34811 16.7723 4.58238 16.58 4.7472 16.3334C4.91203 16.0867 5 15.7967 5 15.5Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 13H15.5C14.6716 13 14 13.6716 14 14.5C14 15.3284 14.6716 16 15.5 16H20.5C21.3284 16 22 15.3284 22 14.5C22 13.6716 21.3284 13 20.5 13Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 19H14V20.5C14 20.7967 14.088 21.0867 14.2528 21.3334C14.4176 21.58 14.6519 21.7723 14.926 21.8858C15.2001 21.9993 15.5017 22.0291 15.7926 21.9712C16.0836 21.9133 16.3509 21.7704 16.5607 21.5607C16.7704 21.3509 16.9133 21.0836 16.9712 20.7926C17.0291 20.5017 16.9993 20.2001 16.8858 19.926C16.7723 19.6519 16.58 19.4176 16.3334 19.2528C16.0867 19.088 15.7967 19 15.5 19Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 8H3.5C2.67157 8 2 8.67157 2 9.5C2 10.3284 2.67157 11 3.5 11H8.5C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 5H10V3.5C10 3.20333 9.91203 2.91332 9.74721 2.66665C9.58238 2.41997 9.34812 2.22771 9.07403 2.11418C8.79994 2.00065 8.49834 1.97094 8.20736 2.02882C7.91639 2.0867 7.64912 2.22956 7.43934 2.43934C7.22956 2.64912 7.0867 2.91639 7.02882 3.20737C6.97094 3.49834 7.00065 3.79994 7.11418 4.07403C7.22771 4.34811 7.41997 4.58238 7.66665 4.7472C7.91332 4.91203 8.20333 5 8.5 5Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  SlackColor: (props: IconProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 3.5C16 2.67157 15.3284 2 14.5 2C13.6716 2 13 2.67157 13 3.5V8.5C13 9.32843 13.6716 10 14.5 10C15.3284 10 16 9.32843 16 8.5V3.5Z"
        stroke="#2EB67D"
        fill="#2EB67D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 8.5V10H20.5C20.7967 10 21.0867 9.91203 21.3334 9.74721C21.58 9.58238 21.7723 9.34812 21.8858 9.07403C21.9993 8.79994 22.0291 8.49834 21.9712 8.20736C21.9133 7.91639 21.7704 7.64912 21.5607 7.43934C21.3509 7.22956 21.0836 7.0867 20.7926 7.02882C20.5017 6.97094 20.2001 7.00065 19.926 7.11418C19.6519 7.22771 19.4176 7.41997 19.2528 7.66665C19.088 7.91332 19 8.20333 19 8.5Z"
        stroke="#2EB67D"
        fill="#2EB67D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 15.5C11 14.6716 10.3284 14 9.5 14C8.67157 14 8 14.6716 8 15.5V20.5C8 21.3284 8.67157 22 9.5 22C10.3284 22 11 21.3284 11 20.5V15.5Z"
        stroke="#E01E5A"
        fill="#E01E5A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 15.5V14H3.5C3.20333 14 2.91332 14.088 2.66665 14.2528C2.41997 14.4176 2.22771 14.6519 2.11418 14.926C2.00065 15.2001 1.97094 15.5017 2.02882 15.7926C2.0867 16.0836 2.22956 16.3509 2.43934 16.5607C2.64912 16.7704 2.91639 16.9133 3.20737 16.9712C3.49834 17.0291 3.79994 16.9993 4.07403 16.8858C4.34811 16.7723 4.58238 16.58 4.7472 16.3334C4.91203 16.0867 5 15.7967 5 15.5Z"
        stroke="#E01E5A"
        fill="#E01E5A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 13H15.5C14.6716 13 14 13.6716 14 14.5C14 15.3284 14.6716 16 15.5 16H20.5C21.3284 16 22 15.3284 22 14.5C22 13.6716 21.3284 13 20.5 13Z"
        stroke="#ECB22E"
        fill="#ECB22E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 19H14V20.5C14 20.7967 14.088 21.0867 14.2528 21.3334C14.4176 21.58 14.6519 21.7723 14.926 21.8858C15.2001 21.9993 15.5017 22.0291 15.7926 21.9712C16.0836 21.9133 16.3509 21.7704 16.5607 21.5607C16.7704 21.3509 16.9133 21.0836 16.9712 20.7926C17.0291 20.5017 16.9993 20.2001 16.8858 19.926C16.7723 19.6519 16.58 19.4176 16.3334 19.2528C16.0867 19.088 15.7967 19 15.5 19Z"
        stroke="#ECB22E"
        fill="#ECB22E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 8H3.5C2.67157 8 2 8.67157 2 9.5C2 10.3284 2.67157 11 3.5 11H8.5C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8Z"
        stroke="#36C5F0"
        fill="#36C5F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 5H10V3.5C10 3.20333 9.91203 2.91332 9.74721 2.66665C9.58238 2.41997 9.34812 2.22771 9.07403 2.11418C8.79994 2.00065 8.49834 1.97094 8.20736 2.02882C7.91639 2.0867 7.64912 2.22956 7.43934 2.43934C7.22956 2.64912 7.0867 2.91639 7.02882 3.20737C6.97094 3.49834 7.00065 3.79994 7.11418 4.07403C7.22771 4.34811 7.41997 4.58238 7.66665 4.7472C7.91332 4.91203 8.20333 5 8.5 5Z"
        stroke="#36C5F0"
        fill="#36C5F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),

  Text: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16" {...props}>
      <path
        fill="currentColor"
        d="M13.26 2.063h-9.5a2.252 2.252 0 0 0-2.25 2.25v6a2.253 2.253 0 0 0 2.25 2.25h.75v2a.5.5 0 0 0 .82.384l2.86-2.384h5.07a2.253 2.253 0 0 0 2.25-2.25v-6a2.252 2.252 0 0 0-2.25-2.25Zm-7.75 6.25a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm3 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm3 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
      />
    </svg>
  ),
  Tick: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="85" fill="none" viewBox="0 0 86 85" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M33.54 53.87 21.235 41.708l-4.192 4.112 16.499 16.305 35.416-35-4.161-4.113L33.54 53.87Z"
        clipRule="evenodd"
      />
    </svg>
  ),
  Message: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10Z"
      />
    </svg>
  ),
  Book: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2V3Zm20 0h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7V3Z"
      />
    </svg>
  ),
  Chat: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M14.25 4.5h-9a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5Zm-3 3h-6a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5Z"
      />
      <path
        fill="currentColor"
        d="M16.5 0H3C1.345 0 0 1.345 0 3v15a.75.75 0 0 0 1.23.576L5.521 15H16.5c1.654 0 3-1.345 3-3V3c0-1.655-1.346-3-3-3ZM18 12c0 .826-.672 1.5-1.5 1.5H5.25a.75.75 0 0 0-.48.174L1.5 16.4V3c0-.826.672-1.5 1.5-1.5h13.5c.828 0 1.5.674 1.5 1.5v9Z"
      />
      <path
        fill="currentColor"
        d="M21 6a.75.75 0 0 0 0 1.5c.828 0 1.5.674 1.5 1.5v12.689l-2.532-2.026a.756.756 0 0 0-.468-.163H9c-.828 0-1.5-.674-1.5-1.5v-.75a.75.75 0 0 0-1.5 0V18c0 1.654 1.346 3 3 3h10.236l3.544 2.837a.757.757 0 0 0 .796.09.752.752 0 0 0 .424-.677V9c0-1.654-1.346-3-3-3Z"
      />
    </svg>
  ),
  Unlike: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="m1.9.989 12.256 12.256-.942.943-2.498-2.498-2.688 2.693-5.653-5.66a4 4 0 0 1 .023-5.35L.957 1.933 1.9.988ZM13.524 3.23a4 4 0 0 1 .157 5.491l-1.09 1.09L4.868 2.09a3.99 3.99 0 0 1 3.161.988 3.999 3.999 0 0 1 5.495.152Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.027.06h16v16h-16z" />
        </clipPath>
      </defs>
    </svg>
  ),
  Edit: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 17 17" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.333"
          d="M11.834 2.013a1.885 1.885 0 1 1 2.667 2.666l-9 9-3.667 1 1-3.666 9-9Z"
        />
      </g>
    </svg>
  ),
  Edit2: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.333 2.667H2.667A1.333 1.333 0 0 0 1.334 4v9.333a1.333 1.333 0 0 0 1.333 1.333H12a1.334 1.334 0 0 0 1.334-1.333V8.666"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.334 1.667a1.414 1.414 0 1 1 2 2L8 10l-2.667.667L6 8l6.334-6.333Z"
        />
      </g>
    </svg>
  ),
  Paperclip: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m21.44 11.05-9.19 9.19a6.003 6.003 0 1 1-8.49-8.49l8.57-8.57A4.006 4.006 0 0 1 18 8.84l-8.59 8.57a2.001 2.001 0 1 1-2.83-2.83l8.49-8.48"
      />
    </svg>
  ),
  File: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M9.666 1.333H4a1.333 1.333 0 0 0-1.333 1.334v10.666A1.333 1.333 0 0 0 4 14.668h8a1.333 1.333 0 0 0 1.333-1.333V5L9.666 1.333Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M9.334 1.333v4h4"
      />
    </svg>
  ),
  FileAttachment: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21.44 11.0499L12.25 20.2399C11.1242 21.3658 9.59718 21.9982 8.005 21.9982C6.41282 21.9982 4.88584 21.3658 3.76 20.2399C2.63416 19.1141 2.00166 17.5871 2.00166 15.9949C2.00166 14.4027 2.63416 12.8758 3.76 11.7499L12.33 3.17991C13.0806 2.42802 14.0991 2.00509 15.1615 2.00415C16.2239 2.00321 17.2431 2.42435 17.995 3.17491C18.7469 3.92547 19.1698 4.94398 19.1708 6.00638C19.1717 7.06877 18.7506 8.08802 18 8.83991L9.41 17.4099C9.03472 17.7852 8.52573 17.996 7.995 17.996C7.46427 17.996 6.95528 17.7852 6.58 17.4099C6.20472 17.0346 5.99389 16.5256 5.99389 15.9949C5.99389 15.4642 6.20472 14.9552 6.58 14.5799L15.07 6.09991"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  FileCode: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M2.667 14.667H12a1.333 1.333 0 0 0 1.333-1.333V5L9.666 1.333H4a1.333 1.333 0 0 0-1.333 1.334v2.667"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M9.334 1.333v4h4M6 12l2-2-2-2M3.333 8l-2 2 2 2"
      />
    </svg>
  ),
  FileImage: (props: IconProps) => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.2864 2H6.78638C6.25594 2 5.74724 2.21071 5.37216 2.58579C4.99709 2.96086 4.78638 3.46957 4.78638 4V20C4.78638 20.5304 4.99709 21.0391 5.37216 21.4142C5.74724 21.7893 6.25594 22 6.78638 22H18.7864C19.3168 22 19.8255 21.7893 20.2006 21.4142C20.5757 21.0391 20.7864 20.5304 20.7864 20V7.5L15.2864 2Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7864 2V8H20.7864"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7864 15C11.8909 15 12.7864 14.1046 12.7864 13C12.7864 11.8954 11.8909 11 10.7864 11C9.68181 11 8.78638 11.8954 8.78638 13C8.78638 14.1046 9.68181 15 10.7864 15Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7864 17.0002L19.6964 15.9102C19.3217 15.5377 18.8147 15.3286 18.2864 15.3286C17.758 15.3286 17.2511 15.5377 16.8764 15.9102L10.7864 22.0002"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  FileText: (props: IconProps) => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.2864 2H6.78638C6.25594 2 5.74724 2.21071 5.37216 2.58579C4.99709 2.96086 4.78638 3.46957 4.78638 4V20C4.78638 20.5304 4.99709 21.0391 5.37216 21.4142C5.74724 21.7893 6.25594 22 6.78638 22H18.7864C19.3168 22 19.8255 21.7893 20.2006 21.4142C20.5757 21.0391 20.7864 20.5304 20.7864 20V7.5L15.2864 2Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7864 2V8H20.7864"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.78638 13V12H15.7864V13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7864 18H13.7864"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.7864 12V18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  FileGeneral: (props: IconProps) => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.2864 2H6.78638C6.25594 2 5.74724 2.21071 5.37216 2.58579C4.99709 2.96086 4.78638 3.46957 4.78638 4V20C4.78638 20.5304 4.99709 21.0391 5.37216 21.4142C5.74724 21.7893 6.25594 22 6.78638 22H18.7864C19.3168 22 19.8255 21.7893 20.2006 21.4142C20.5757 21.0391 20.7864 20.5304 20.7864 20V7.5L15.2864 2Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7864 2V8H20.7864"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7864 13H8.78638"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7864 17H8.78638"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.7864 9H8.78638" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  ChevronRight: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  ChevronLeft: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  ChevronsDoubleDown: (props: IconProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7 13L12 18L17 13" stroke="#A3A3A3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 6L12 11L17 6" stroke="#A3A3A3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  BookmarkOutlined: (props: IconProps) => (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15 19L8 15L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V19Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  BookmarkFilled: (props: IconProps) => (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15 19L8 15L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V19Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Locate: (props: IconProps) => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2787_53611)">
        <path
          d="M1.59766 8.15308H3.59766"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9297 8.15308H14.9297"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.26367 1.48639V3.48639"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.26367 12.8198V14.8198"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.26432 12.8197C10.8417 12.8197 12.931 10.7304 12.931 8.15306C12.931 5.57573 10.8417 3.48639 8.26432 3.48639C5.68699 3.48639 3.59766 5.57573 3.59766 8.15306C3.59766 10.7304 5.68699 12.8197 8.26432 12.8197Z"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2787_53611">
          <rect width="16" height="16" fill="white" transform="translate(0.263672 0.153076)" />
        </clipPath>
      </defs>
    </svg>
  ),
  PinOutline: (props: IconProps) => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2787_53619)">
        <path
          d="M8.26367 11.4864V14.8197"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.59766 11.4864H12.931V10.3131C12.9309 10.065 12.8615 9.8219 12.7308 9.61109C12.6001 9.40027 12.4131 9.23011 12.191 9.11972L11.0043 8.51972C10.7822 8.40934 10.5952 8.23917 10.4645 8.02836C10.3338 7.81755 10.2645 7.57445 10.2643 7.32639V4.15306H10.931C11.2846 4.15306 11.6238 4.01258 11.8738 3.76253C12.1238 3.51248 12.2643 3.17334 12.2643 2.81972C12.2643 2.4661 12.1238 2.12696 11.8738 1.87691C11.6238 1.62686 11.2846 1.48639 10.931 1.48639H5.59766C5.24403 1.48639 4.9049 1.62686 4.65485 1.87691C4.4048 2.12696 4.26432 2.4661 4.26432 2.81972C4.26432 3.17334 4.4048 3.51248 4.65485 3.76253C4.9049 4.01258 5.24403 4.15306 5.59766 4.15306H6.26432V7.32639C6.26419 7.57445 6.19486 7.81755 6.06414 8.02836C5.93341 8.23917 5.74646 8.40934 5.52432 8.51972L4.33766 9.11972C4.11551 9.23011 3.92857 9.40027 3.79784 9.61109C3.66712 9.8219 3.59779 10.065 3.59766 10.3131V11.4864Z"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2787_53619">
          <rect width="16" height="16" fill="white" transform="translate(0.263672 0.153076)" />
        </clipPath>
      </defs>
    </svg>
  ),
  PinFilled: (props: IconProps) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8 11.333V14.6663" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.33325 11.333H12.6666V10.1597C12.6665 9.91162 12.5971 9.66852 12.4664 9.4577C12.3357 9.24689 12.1487 9.07673 11.9266 8.96634L10.7399 8.36634C10.5178 8.25596 10.3308 8.08579 10.2001 7.87498C10.0694 7.66416 10.0001 7.42106 9.99992 7.17301V3.99967H10.6666C11.0202 3.99967 11.3593 3.8592 11.6094 3.60915C11.8594 3.3591 11.9999 3.01996 11.9999 2.66634C11.9999 2.31272 11.8594 1.97358 11.6094 1.72353C11.3593 1.47348 11.0202 1.33301 10.6666 1.33301H5.33325C4.97963 1.33301 4.64049 1.47348 4.39044 1.72353C4.14039 1.97358 3.99992 2.31272 3.99992 2.66634C3.99992 3.01996 4.14039 3.3591 4.39044 3.60915C4.64049 3.8592 4.97963 3.99967 5.33325 3.99967H5.99992V7.17301C5.99979 7.42106 5.93046 7.66416 5.79973 7.87498C5.669 8.08579 5.48206 8.25596 5.25992 8.36634L4.07325 8.96634C3.85111 9.07673 3.66417 9.24689 3.53344 9.4577C3.40271 9.66852 3.33338 9.91162 3.33325 10.1597V11.333Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  // Google: (props: IconProps) => <svg></svg>,
  // Google: (props: IconProps) => <svg></svg>,
  History: (props: IconProps) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2 2V5.33333H5.33333"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.03333 8.6664C2.181 10.0036 2.7738 11.2524 3.71652 12.2122C4.65924 13.172 5.89718 13.7871 7.23153 13.9588C8.56587 14.1304 9.91919 13.8487 11.0741 13.1587C12.2291 12.4688 13.1187 11.4107 13.6 10.1544C14.0813 8.89808 14.1264 7.51647 13.7281 6.23145C13.3298 4.94642 12.5112 3.83254 11.4037 3.0687C10.2962 2.30485 8.96419 1.93537 7.62148 2.01958C6.27878 2.1038 5.00334 2.63683 4 3.53307L2 5.33307"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.66602V7.99935L10.6667 9.33268"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ChatHistory: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 25 25" {...props}>
      <path
        fill="currentColor"
        d="M21.813 2.032h-19.5a2.25 2.25 0 0 0-2.25 2.25v12a2.25 2.25 0 0 0 2.25 2.25h1.124v5.25a.75.75 0 0 0 1.268.544l4.5-4.256a6.375 6.375 0 0 0 11.066-1.538h1.541a2.25 2.25 0 0 0 2.25-2.25v-12a2.25 2.25 0 0 0-2.25-2.25Zm-7.5 19.125a4.875 4.875 0 1 1 0-9.749 4.875 4.875 0 0 1 0 9.75Zm8.25-4.875a.75.75 0 0 1-.75.75h-1.174c.031-.248.047-.499.049-.75a6.375 6.375 0 1 0-12.26 2.45l-3.49 3.307v-4.257a.75.75 0 0 0-.75-.75H2.313a.75.75 0 0 1-.75-.75v-12a.75.75 0 0 1 .75-.75h19.5a.75.75 0 0 1 .75.75v12Z"
      />
      <path
        fill="currentColor"
        d="M15.063 12.158h-1.5v4.124a.75.75 0 0 0 .48.702l2.906 1.125.54-1.403-2.427-.937v-3.611Z"
      />
    </svg>
  ),
  Lock: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 11H5a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2ZM7 11V7a5 5 0 1 1 10 0v4"
      />
    </svg>
  ),
  TierUpgrade: (props: IconProps) => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_b_7828_2266)">
        <path
          d="M18.9541 12.1353C18.9665 12.1382 18.9786 12.141 18.9904 12.1437C18.9571 12.1589 18.9218 12.1753 18.8847 12.1927C18.5833 12.3341 18.1599 12.5481 17.6705 12.8386C16.6922 13.4193 15.4465 14.3081 14.3832 15.5393C12.8447 17.3209 12.0989 19.4376 11.7371 21.1042C11.6194 21.6464 11.5422 22.1418 11.4916 22.5636C11.4313 22.1592 11.347 21.686 11.2293 21.1666C10.8569 19.5242 10.1484 17.4134 8.80266 15.5546C7.65959 13.9757 6.34165 13.0809 5.30804 12.5809C4.93424 12.4 4.59761 12.2708 4.31984 12.1795C4.60595 12.1382 4.95294 12.0624 5.34013 11.9265C6.41823 11.548 7.80115 10.7056 9.04699 8.85829C10.369 6.89816 11.0065 5.00275 11.3135 3.59671C11.3995 3.20262 11.4596 2.84688 11.5016 2.53881C11.5697 2.95062 11.6685 3.44344 11.8112 3.9871C12.2217 5.55048 12.9975 7.54105 14.46 9.23339C15.6446 10.6042 16.8753 11.3498 17.8123 11.7528C18.2806 11.9542 18.6754 12.0699 18.9541 12.1353Z"
          stroke="#B294E6"
          strokeWidth="0.24386"
        />
      </g>
      <g filter="url(#filter1_b_7828_2266)">
        <path
          d="M13.7482 0C13.7482 0 13.933 4.53829 16.8252 7.88502C19.1676 10.5955 21.6849 10.8264 21.6849 10.8264C21.6849 10.8264 18.8524 11.9142 16.7485 14.3504C13.7074 17.8717 13.7908 22.7313 13.7908 22.7313C13.7908 22.7313 13.6421 18.0389 10.9768 14.3574C8.71817 11.2376 5.77295 10.8264 5.77295 10.8264C5.77295 10.8264 8.75695 11.1718 11.2188 7.52147C13.8422 3.63167 13.7482 0 13.7482 0Z"
          fill="#B294E6"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_7828_2266"
          x="1.30526"
          y="-0.926098"
          width="20.3016"
          height="27.1208"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.09737" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7828_2266" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7828_2266" result="shape" />
        </filter>
        <filter
          id="filter1_b_7828_2266"
          x="3.57821"
          y="-2.19474"
          width="20.3016"
          height="27.1208"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.09737" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7828_2266" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7828_2266" result="shape" />
        </filter>
      </defs>
    </svg>
  ),
  TierPro: (props: IconProps) => (
    <svg width="38" height="25" viewBox="0 0 38 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.87205 11.3802C8.56056 12.6179 7.68749 14.4261 7.68749 14.4261C7.68749 14.4261 10.455 14.6343 12.0533 14.0201C13.6066 13.4231 15.4885 11.4988 15.4885 11.4988C15.4885 11.4988 13.5182 11.0011 12.4426 10.3142C10.7081 9.2066 9.12593 6.21908 9.12593 6.21908C9.12593 6.21908 9.36455 9.42326 8.87205 11.3802Z"
        fill="url(#paint0_linear_10_20)"
        stroke="#7844D3"
        strokeWidth="1.22153"
        strokeLinejoin="round"
      />
      <path
        d="M24.7389 10.47C23.7756 11.3072 22.0253 12.2913 22.0253 12.2913C22.0253 12.2913 24.1764 14.0447 25.8389 14.4549C27.4545 14.8535 30.0988 14.3511 30.0988 14.3511C30.0988 14.3511 28.7671 12.8161 28.2776 11.6375C27.4881 9.73695 27.8988 6.38138 27.8988 6.38138C27.8988 6.38138 26.2621 9.14633 24.7389 10.47Z"
        fill="url(#paint1_linear_10_20)"
        stroke="#7844D3"
        strokeWidth="1.22153"
        strokeLinejoin="round"
      />
      <path
        d="M1.46118 8.95382L7.48132 20.3252C7.8403 21.0033 8.4324 21.5287 9.15805 21.7779C11.2355 22.4913 15.7686 23.9045 19 23.8743C21.9958 23.8464 26.1815 22.4838 28.1397 21.7835C28.8487 21.5299 29.4243 21.0115 29.7766 20.346L35.8077 8.95382C32.1539 14.0693 21.923 18.4539 18.9998 3.83838C14.9075 18.4539 5.60226 13.3385 1.46118 8.95382Z"
        fill="url(#paint2_linear_10_20)"
        stroke="#7844D3"
        strokeWidth="1.22153"
        strokeLinejoin="round"
      />
      <ellipse cx="1.46156" cy="8.22539" rx="1.46156" ry="1.46156" fill="#7844D3" />
      <ellipse cx="8.76987" cy="5.29993" rx="1.46156" ry="1.46156" fill="#7844D3" />
      <ellipse cx="19.0006" cy="2.37757" rx="1.46156" ry="1.46156" fill="#7844D3" />
      <ellipse cx="27.7701" cy="5.29993" rx="1.46156" ry="1.46156" fill="#7844D3" />
      <ellipse cx="36.5384" cy="8.22539" rx="1.46156" ry="1.46156" fill="#7844D3" />
      <path
        d="M18.6988 12.9269C18.7936 12.6352 19.2064 12.6352 19.3012 12.9269L19.9953 15.0634C20.0377 15.1938 20.1593 15.2822 20.2965 15.2822H22.5429C22.8497 15.2822 22.9772 15.6747 22.7291 15.855L20.9117 17.1754C20.8007 17.2561 20.7542 17.399 20.7966 17.5295L21.4908 19.6659C21.5856 19.9577 21.2517 20.2003 21.0035 20.02L19.1861 18.6996C19.0751 18.6189 18.9249 18.6189 18.8139 18.6996L16.9965 20.02C16.7483 20.2003 16.4144 19.9577 16.5092 19.6659L17.2034 17.5295C17.2458 17.399 17.1993 17.2561 17.0883 17.1754L15.2709 15.855C15.0228 15.6747 15.1503 15.2822 15.4571 15.2822H17.7035C17.8407 15.2822 17.9623 15.1938 18.0047 15.0634L18.6988 12.9269Z"
        fill="#7844D3"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10_20"
          x1="7.39777"
          y1="11.3113"
          x2="15.9428"
          y2="10.5506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDA7FF" />
          <stop offset="0.0001" stopColor="#C3AFFF" />
          <stop offset="1" stopColor="#EDD3B9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10_20"
          x1="23.5689"
          y1="9.57038"
          x2="31.0137"
          y2="13.8332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDA7FF" />
          <stop offset="0.0001" stopColor="#C3AFFF" />
          <stop offset="1" stopColor="#EDD3B9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10_20"
          x1="1.46118"
          y1="15.7716"
          x2="38.1147"
          y2="16.0267"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDA7FF" />
          <stop offset="0.0001" stopColor="#C3AFFF" />
          <stop offset="1" stopColor="#EDD3B9" />
        </linearGradient>
      </defs>
    </svg>
  ),
  TierPremium: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none" {...props}>
      <path
        d="M9.42902 16.0802C9.11754 17.3179 8.24447 19.1261 8.24447 19.1261C8.24447 19.1261 11.0119 19.3343 12.6103 18.72C14.1636 18.123 16.0454 16.1987 16.0454 16.1987C16.0454 16.1987 14.0752 15.701 12.9995 15.0141C11.2651 13.9066 9.68291 10.919 9.68291 10.919C9.68291 10.919 9.92153 14.1232 9.42902 16.0802Z"
        fill="url(#paint0_linear_9676_216462)"
        stroke="#7844D3"
        strokeWidth="1.22153"
        strokeLinejoin="round"
      />
      <path
        d="M25.2958 15.1705C24.3325 16.0077 22.5822 16.9917 22.5822 16.9917C22.5822 16.9917 24.7333 18.7452 26.3958 19.1553C28.0114 19.554 30.6557 19.0515 30.6557 19.0515C30.6557 19.0515 29.324 17.5165 28.8345 16.3379C28.045 14.4374 28.4557 11.0818 28.4557 11.0818C28.4557 11.0818 26.819 13.8468 25.2958 15.1705Z"
        fill="url(#paint1_linear_9676_216462)"
        stroke="#7844D3"
        strokeWidth="1.22153"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 12.9533L7.51088 23.3165C7.8152 24.3639 8.63449 25.181 9.6926 25.4458C12.0818 26.0438 16.4875 27.0282 19.5 27C22.3182 26.9737 26.8081 26.0089 29.2609 25.4291C30.344 25.1731 31.19 24.3459 31.5006 23.2771L34.5 12.9533C29.5 18 22.5 22 19.4996 8C16.5 22 9.5 18 4.5 12.9533Z"
        fill="url(#paint2_linear_9676_216462)"
        stroke="#7844D3"
        strokeWidth="1.22153"
        strokeLinejoin="round"
      />
      <g filter="url(#filter0_b_9676_216462)">
        <path
          d="M19.5048 18C19.5048 18 19.5513 19.1979 20.2784 20.0813C20.8672 20.7967 21.5 20.8577 21.5 20.8577C21.5 20.8577 20.788 21.1448 20.2591 21.7878C19.4946 22.7173 19.5156 24 19.5156 24C19.5156 24 19.4782 22.7614 18.8082 21.7897C18.2404 20.9662 17.5 20.8577 17.5 20.8577C17.5 20.8577 18.2501 20.9488 18.869 19.9853C19.5285 18.9586 19.5048 18 19.5048 18Z"
          fill="#7844D3"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_9676_216462"
          x="16.8407"
          y="17.3407"
          width="5.31856"
          height="7.31856"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.329639" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9676_216462" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9676_216462" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_9676_216462"
          x1="7.95475"
          y1="16.0113"
          x2="16.4998"
          y2="15.2506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDA7FF" />
          <stop offset="0.0001" stopColor="#C3AFFF" />
          <stop offset="1" stopColor="#EDD3B9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9676_216462"
          x1="24.1258"
          y1="14.2708"
          x2="31.5706"
          y2="18.5336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDA7FF" />
          <stop offset="0.0001" stopColor="#C3AFFF" />
          <stop offset="1" stopColor="#EDD3B9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9676_216462"
          x1="4.5"
          y1="19.3163"
          x2="36.5153"
          y2="19.5215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDA7FF" />
          <stop offset="0.0001" stopColor="#C3AFFF" />
          <stop offset="1" stopColor="#EDD3B9" />
        </linearGradient>
      </defs>
    </svg>
  ),
  TierScale: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none" {...props}>
      <path
        d="M3 15.1617C4.77778 22.7404 4.85507 21.72 5.96296 27.4043C6.71014 29.08 12 31 19 31C24.254 31 31.5898 29.6757 32 28C33.3913 22.3157 33 23.5 35 15.1617C32.7295 15.6021 28.971 20.8 28.971 20.8L26.7037 13.9958L23.1481 17.4936C23.1481 17.4936 21.963 9.9149 19 7C16.037 9.9149 14.8519 17.4936 14.8519 17.4936L11.2963 13.9958L9.49275 20.8C9.49275 20.8 5.69851 15.1217 3 15.1617Z"
        fill="url(#paint0_linear_9676_216942)"
        stroke="#7844D3"
        strokeWidth="1.23214"
        strokeLinejoin="round"
      />
      <path
        d="M15.8914 23.8077L19.1069 17.5103C19.1692 17.3883 19.3543 17.4439 19.3391 17.5801L18.9121 21.3905C18.9038 21.4647 18.963 21.5293 19.0377 21.5273L20.9545 21.4781C21.0479 21.4757 21.1099 21.574 21.0674 21.6573L17.8519 27.9547C17.7896 28.0767 17.6045 28.0211 17.6197 27.8849L18.0467 24.0745C18.055 24.0002 17.9958 23.9357 17.9211 23.9376L16.0043 23.9869C15.9109 23.9893 15.8489 23.8909 15.8914 23.8077Z"
        fill="#7844D3"
      />
      <g filter="url(#filter0_b_9676_216942)">
        <path
          d="M6.98719 12.1736C6.98719 12.1736 7.11407 12.7594 7.54878 13.1313C7.90084 13.4324 8.21773 13.4075 8.21773 13.4075C8.21773 13.4075 7.89204 13.6107 7.68745 13.9732C7.39172 14.4973 7.51341 15.1271 7.51341 15.1271C7.51341 15.1271 7.38747 14.5204 6.97319 14.1001C6.6221 13.7439 6.24811 13.7548 6.24811 13.7548C6.24811 13.7548 6.6254 13.7345 6.84648 13.2064C7.08206 12.6435 6.98719 12.1736 6.98719 12.1736Z"
          fill="#7844D3"
        />
      </g>
      <g filter="url(#filter1_b_9676_216942)">
        <path
          d="M30.5082 12.175C30.5082 12.175 30.4271 12.7689 30.7084 13.267C30.9362 13.6704 31.2425 13.7554 31.2425 13.7554C31.2425 13.7554 30.867 13.8349 30.5507 14.1057C30.0936 14.497 29.9925 15.1304 29.9925 15.1304C29.9925 15.1304 30.0817 14.5173 29.8361 13.9806C29.628 13.5258 29.2729 13.4081 29.2729 13.4081C29.2729 13.4081 29.6343 13.5181 30.0227 13.0974C30.4366 12.6491 30.5082 12.175 30.5082 12.175Z"
          fill="#7844D3"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_9676_216942"
          x="5.58877"
          y="11.5145"
          width="3.28828"
          height="4.27168"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.329639" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9676_216942" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9676_216942" result="shape" />
        </filter>
        <filter
          id="filter1_b_9676_216942"
          x="28.6137"
          y="11.5155"
          width="3.28828"
          height="4.27461"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.329639" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9676_216942" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9676_216942" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_9676_216942"
          x1="3"
          y1="21.2938"
          x2="37.15"
          y2="21.4787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDA7FF" />
          <stop offset="0.0001" stopColor="#C3AFFF" />
          <stop offset="1" stopColor="#EDD3B9" />
        </linearGradient>
      </defs>
    </svg>
  ),

  TierEnterprise: (props: IconProps) => (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_8934_6135)">
        <path
          d="M12 27.499V9.49902C12 9.22902 12 8.94902 12.07 8.67902C12.1312 8.40965 12.2667 8.16284 12.4612 7.96663C12.6556 7.77042 12.9012 7.63267 13.17 7.56902C13.46 7.49902 14.73 7.49902 15 7.49902H22C22.27 7.49902 22.55 7.49902 22.82 7.56902C23.0894 7.63023 23.3362 7.76575 23.5324 7.9602C23.7286 8.15464 23.8664 8.40022 23.93 8.66902C24 8.94902 24 9.22902 24 9.49902V27.499H12Z"
          fill="url(#paint0_linear_8934_6135)"
        />
        <path
          d="M6.00222 17.499V23.499C6.00222 24.599 6.90222 25.499 8.00222 25.499H10.0022V15.499H8.00222C7.73222 15.499 7.45222 15.499 7.18222 15.569C6.91222 15.639 6.66222 15.769 6.46222 15.969C6.27222 16.159 6.14222 16.409 6.07222 16.679C6.01577 16.9485 5.99227 17.2239 6.00222 17.499Z"
          stroke="#F1E9FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.822 12.5693C24.5525 12.5128 24.2771 12.4893 24.002 12.4993H22.002V25.4993H24.002C24.5324 25.4993 25.0411 25.2886 25.4162 24.9135C25.7912 24.5384 26.002 24.0297 26.002 23.4993V14.4993C26.002 14.2193 26.002 13.9493 25.932 13.6793C25.862 13.4093 25.732 13.1593 25.532 12.9593C25.342 12.7693 25.092 12.6393 24.822 12.5693Z"
          stroke="#F1E9FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.002 9.49902H18.002"
          stroke="#F1E9FF"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.002 13.499H18.002"
          stroke="#F1E9FF"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.002 17.499H18.002"
          stroke="#F1E9FF"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.002 21.499H18.002"
          stroke="#F1E9FF"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.002 25.499V7.49902C10.002 7.22902 10.002 6.94902 10.072 6.67902C10.1332 6.40965 10.2687 6.16284 10.4631 5.96663C10.6576 5.77042 10.9031 5.63267 11.172 5.56902C11.462 5.49902 12.732 5.49902 13.002 5.49902H20.002C20.272 5.49902 20.552 5.49902 20.822 5.56902C21.0913 5.63023 21.3381 5.76575 21.5343 5.9602C21.7306 6.15464 21.8683 6.40022 21.932 6.66902C22.002 6.94902 22.002 7.22902 22.002 7.49902V25.499H10.002Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.02413 2.50784C2.30858 2.06771 2.46568 1.33168 2.5423 0.85486C2.56095 0.738806 2.74175 0.712577 2.79251 0.818553C3.00121 1.25423 3.36094 1.91557 3.75984 2.25485C4.20249 2.63136 4.94956 2.82914 5.39106 2.91827C5.499 2.94006 5.5274 3.10153 5.43326 3.15873C5.03942 3.398 4.37902 3.85151 4.06629 4.36542C3.78188 4.8328 3.63294 5.60007 3.56171 6.1033C3.54494 6.22178 3.35752 6.24928 3.30758 6.14058C3.09563 5.6793 2.73439 4.98737 2.33059 4.61841C1.89751 4.22271 1.15535 3.96083 0.738992 3.83699C0.637757 3.80688 0.620855 3.65622 0.712591 3.60379C1.08086 3.39333 1.71717 2.98282 2.02413 2.50784Z"
          fill="white"
        />
        <path
          d="M5.72164 8.17223C5.91301 7.87965 5.99884 7.34372 6.02655 7.13053C6.02957 7.10728 6.0637 7.10233 6.07318 7.12376C6.16015 7.32044 6.39449 7.81034 6.66181 8.0352C6.94912 8.27686 7.47405 8.38023 7.66559 8.41108C7.68741 8.41459 7.69282 8.44572 7.67351 8.45648C7.50113 8.55255 7.02901 8.83628 6.82599 9.16586C6.63361 9.47816 6.55471 10.0397 6.52971 10.2663C6.52709 10.29 6.49184 10.2952 6.48254 10.2732C6.39379 10.0635 6.1579 9.54881 5.88581 9.3029C5.60887 9.05259 5.0977 8.90294 4.92085 8.85677C4.90034 8.85141 4.89711 8.82212 4.91589 8.81226C5.07493 8.72873 5.52387 8.47461 5.72164 8.17223Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8934_6135"
          x1="15.1206"
          y1="17.499"
          x2="25.356"
          y2="17.499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#917DE6" />
          <stop offset="0.465" stopColor="#D07ED7" />
          <stop offset="0.955" stopColor="#DAD55F" />
        </linearGradient>
        <clipPath id="clip0_8934_6135">
          <rect width="30" height="30" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  ),
  TierStartup: (props: IconProps) => (
    <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.64703 1.51576L0.583984 3.54729C0.583984 3.54729 4.64703 12.6892 6.67856 16.7522C16.7707 19.1769 19.2609 19.1769 29.0253 16.7522C31.0569 12.6892 35.1199 3.54729 35.1199 3.54729L31.0569 1.51576L29.0253 5.57881L24.5244 4.56305L26.1091 0.5H20.8992L19.8835 4.56305H15.8204L14.8047 0.5H9.72585L10.7416 4.56305L6.67856 5.57881L4.64703 1.51576Z"
        fill="url(#paint0_linear_8544_5911)"
        stroke="#7844D3"
        strokeWidth="0.958749"
        strokeLinejoin="round"
      />
      <path
        d="M6.81055 16.6872C14.2813 14.1972 21.1295 14.1975 28.6003 16.6874C19.8844 19.1777 16.149 19.1777 6.81055 16.6872Z"
        fill="url(#paint1_linear_8544_5911)"
        stroke="#7844D3"
        strokeWidth="0.791667"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8544_5911"
          x1="0.583984"
          y1="11.2625"
          x2="37.4392"
          y2="11.5484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDA7FF" />
          <stop offset="0.0001" stopColor="#C3AFFF" />
          <stop offset="1" stopColor="#EDD3B9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8544_5911"
          x1="6.81055"
          y1="17.0445"
          x2="30.0519"
          y2="17.5949"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDA7FF" />
          <stop offset="0.0001" stopColor="#C3AFFF" />
          <stop offset="1" stopColor="#EDD3B9" />
        </linearGradient>
      </defs>
    </svg>
  ),
  Favicon: (props: IconProps) => (
    <svg width="54" height="40" viewBox="0 0 54 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.1" clipPath="url(#clip0_8544_5282)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.2455 46.4695L-0.107422 -6.47168H11.6472L26.7448 11.793H27.2637L42.2455 -6.47168H53.8926L32.8225 20.0138L53.8926 46.4695H42.2455ZM23.4514 31.6459L17.5396 24.4989L-0.107422 46.4642H11.6472L23.4514 31.6459Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_8544_5282">
          <rect width="54" height="54" fill="white" transform="translate(0 -7)" />
        </clipPath>
      </defs>
    </svg>
  ),
  LineChart: (props: IconProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3 3V21H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19 9L14 14L10 10L7 13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Webhook: (props: IconProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18 16.98H12.01C10.91 16.98 10.06 17.92 9.53 18.88C9.11045 19.6672 8.43979 20.2917 7.62462 20.654C6.80945 21.0163 5.89656 21.0957 5.03107 20.8796C4.16558 20.6635 3.39715 20.1643 2.84795 19.4614C2.29874 18.7584 2.00028 17.8921 2 17C2.01 16.3 2.2 15.6 2.57 15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 17.0002L9.13 11.2202C9.66 10.2502 9.23 9.04018 8.63 8.12018C8.34681 7.66728 8.15721 7.16225 8.07236 6.63489C7.98751 6.10753 8.00915 5.56851 8.13599 5.04965C8.26283 4.53078 8.49231 4.04257 8.81088 3.61383C9.12946 3.18509 9.53068 2.82449 9.99087 2.55332C10.4511 2.28215 10.9609 2.10589 11.4903 2.03495C12.0197 1.96401 12.558 1.99982 13.0733 2.14026C13.5887 2.28071 14.0707 2.52296 14.4909 2.8527C14.9111 3.18245 15.261 3.59301 15.52 4.06018"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6L15.13 11.73C15.66 12.7 16.9 13 18 13C19.0609 13 20.0783 13.4214 20.8284 14.1716C21.5786 14.9217 22 15.9391 22 17C22 18.0609 21.5786 19.0783 20.8284 19.8284C20.0783 20.5786 19.0609 21 18 21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Exchange: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M11.3335 2.66675L13.3335 4.66675L11.3335 6.66675"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3335 9.33325L13.3335 11.3333L11.3335 13.3333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.6665 11.3334C2.6665 11.3334 2.6665 11.3334 3.99984 11.3334C8.33317 11.3334 7.54654 4.66673 10.6665 4.66675C12.6665 4.66676 12.9998 4.66675 12.9998 4.66675"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M6.33317 6.66675C5.6665 5.66675 4.99984 4.66675 3.99984 4.66675H2.6665"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M8.3335 10.3333C9.3335 11.3333 10.0002 11.3333 11.3335 11.3333C12.6668 11.3333 13.0002 11.3333 13.0002 11.3333"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  ),
  StopCircle: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M3.44775 12.5525L12.1144 3.88582" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6818 14.6668 7.99992C14.6668 4.31802 11.6821 1.33325 8.00016 1.33325C4.31826 1.33325 1.3335 4.31802 1.3335 7.99992C1.3335 11.6818 4.31826 14.6666 8.00016 14.6666Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  CreditCard: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20 5H4C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 10H22" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  AddGroup: (props: IconProps) => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.1416 11.8875C5.1416 9.76468 6.86252 8.04376 8.98535 8.04376C11.1082 8.04376 12.8291 9.76468 12.8291 11.8875C12.8291 14.0103 11.1082 15.7313 8.98535 15.7313C6.86252 15.7313 5.1416 14.0103 5.1416 11.8875Z"
        stroke="currentColor"
        strokeWidth="1.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1403 23.7548H2.98401C1.71234 23.7548 0.809477 22.5157 1.19887 21.3051C2.23326 18.0889 5.40351 15.7616 8.96348 15.7616C10.6617 15.7616 12.2362 16.2912 13.5309 17.1943"
        stroke="currentColor"
        strokeWidth="1.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8447 5.47345C14.8447 3.35062 16.5656 1.6297 18.6885 1.6297C20.8113 1.6297 22.5322 3.35062 22.5322 5.47345C22.5322 7.59628 20.8113 9.3172 18.6885 9.3172C16.5656 9.3172 14.8447 7.59628 14.8447 5.47345Z"
        stroke="currentColor"
        strokeWidth="1.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8574 11.8484C14.3145 10.3072 16.3783 9.34551 18.6668 9.34551C20.365 9.34551 21.9395 9.87511 23.2341 10.7782"
        stroke="currentColor"
        strokeWidth="1.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7202 23.7548V16.7235"
        stroke="currentColor"
        strokeWidth="1.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2358 20.2392H16.2046"
        stroke="currentColor"
        strokeWidth="1.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Team: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H6C4.93913 15 3.92172 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 21V19C21.9993 18.1137 21.7044 17.2528 21.1614 16.5523C20.6184 15.8519 19.8581 15.3516 19 15.13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  BlockRound: (props: IconProps) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_10064_81177)">
        <path
          d="M8.00065 14.6673C11.6825 14.6673 14.6673 11.6825 14.6673 8.00065C14.6673 4.31875 11.6825 1.33398 8.00065 1.33398C4.31875 1.33398 1.33398 4.31875 1.33398 8.00065C1.33398 11.6825 4.31875 14.6673 8.00065 14.6673Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M3.28711 3.28711L12.7138 12.7138" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10064_81177">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  Maximize: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M10 2H14V6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 14H2V10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.9999 2L9.33325 6.66667" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2 14.0002L6.66667 9.3335" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  Minimize: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M4 14H10V20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 10H14V4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 10L21 3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 21L10 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  ChatKnowledge: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M4 19.5C4 18.837 4.26339 18.2011 4.73223 17.7322C5.20107 17.2634 5.83696 17 6.5 17H20"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 2H20V22H6.5C5.83696 22 5.20107 21.7366 4.73223 21.2678C4.26339 20.7989 4 20.163 4 19.5V4.5C4 3.83696 4.26339 3.20107 4.73223 2.73223C5.20107 2.26339 5.83696 2 6.5 2Z"
        stroke="#7844D3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  BarChart: (props: IconProps) => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.4375 16.875H2.8125C2.29473 16.875 1.875 17.2947 1.875 17.8125V27.1875C1.875 27.7053 2.29473 28.125 2.8125 28.125H8.4375C8.95527 28.125 9.375 27.7053 9.375 27.1875V17.8125C9.375 17.2947 8.95527 16.875 8.4375 16.875Z"
        fill="currentColor"
      />
      <path
        d="M27.1875 9.375H21.5625C21.0447 9.375 20.625 9.79473 20.625 10.3125V27.1875C20.625 27.7053 21.0447 28.125 21.5625 28.125H27.1875C27.7053 28.125 28.125 27.7053 28.125 27.1875V10.3125C28.125 9.79473 27.7053 9.375 27.1875 9.375Z"
        fill="currentColor"
      />
      <path
        d="M17.8125 1.875H12.1875C11.6697 1.875 11.25 2.29473 11.25 2.8125V27.1875C11.25 27.7053 11.6697 28.125 12.1875 28.125H17.8125C18.3303 28.125 18.75 27.7053 18.75 27.1875V2.8125C18.75 2.29473 18.3303 1.875 17.8125 1.875Z"
        fill="currentColor"
      />
    </svg>
  ),
  Satisfaction: (props: IconProps) => (
    <svg width="85" height="71" viewBox="0 0 85 71" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="35.5" cy="35.1602" r="34" fill="url(#paint0_radial_10065_45254)" stroke="#FEFDFF" strokeWidth="2" />
      <mask
        id="mask0_10065_45254"
        // style="mask-type:alpha"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="71"
        height="71"
      >
        <circle cx="35.5" cy="35.1602" r="34.5" fill="url(#paint1_radial_10065_45254)" stroke="#F6F1FF" />
      </mask>
      <g mask="url(#mask0_10065_45254)">
        <path
          opacity="0.85"
          d="M-562.451 22.846C-580.991 16.3571 -600.303 18.906 -607.642 20.9915L-609.164 92.3719H70.034L69.8475 30.9999C61.719 27.7139 37.4719 33.2891 25.3006 37.2643C-10.1994 39.1326 -6.83069 20.8182 -39.4317 25.4315C-60.7947 29.4623 -81.519 33.2728 -102.076 26.8236C-122.633 20.3744 -146.91 25.0265 -170.288 33.088C-193.667 41.1496 -191.436 29.148 -216.427 26.7296C-241.418 24.3111 -241.015 40.8372 -259.556 40.4341C-278.098 40.0311 -278.901 30.2863 -293.012 24.7142C-317.036 17.4888 -329.519 30.2213 -340.312 34.5807C-360.868 38.2084 -364.356 27.5357 -382.494 21.4896C-400.633 15.4434 -415.95 25.9234 -426.027 30.7603C-436.104 35.5972 -460.018 28.1759 -467.897 24.2361C-475.776 20.2964 -494.78 14.0391 -515.637 14.0391C-536.495 14.0391 -539.276 30.9572 -562.451 22.846Z"
          fill="url(#paint2_linear_10065_45254)"
          fillOpacity="0.8"
        />
        <path
          opacity="0.85"
          d="M40.2132 39.8011C21.6731 33.3122 2.3607 35.861 -4.978 37.9466L-6.5 109.327H672.698L672.512 47.955C664.383 44.669 640.136 50.2441 627.965 54.2194C592.465 56.0877 595.833 37.7733 563.232 42.3866C541.869 46.4174 521.145 50.2279 500.588 43.7787C480.031 37.3295 455.754 41.9816 432.376 50.0431C408.997 58.1046 411.228 46.1031 386.237 43.6846C361.246 41.2662 361.649 57.7923 343.108 57.3892C324.566 56.9861 323.763 47.2413 309.652 41.6693C285.628 34.4439 273.145 47.1764 262.352 51.5358C241.796 55.1635 238.308 44.4908 220.17 38.4447C202.031 32.3985 186.714 42.8785 176.637 47.7154C166.561 52.5523 142.647 45.131 134.767 41.1912C126.888 37.2515 107.884 30.9941 87.0267 30.9941C66.1692 30.9941 63.3882 47.9123 40.2132 39.8011Z"
          fill="url(#paint3_linear_10065_45254)"
        />
      </g>
      <path
        d="M23.9104 45.6429H28.1248V31.8503H23.9104V45.6429ZM47.0896 32.9997C47.0896 31.7354 46.1414 30.701 44.9824 30.701H38.3342L39.3351 25.4483L39.3667 25.0805C39.3667 24.6092 39.1876 24.1725 38.9031 23.8621L37.7863 22.6553L30.8536 30.2297C30.4638 30.6435 30.232 31.2182 30.232 31.8503V43.3442C30.232 44.6085 31.1802 45.6429 32.3392 45.6429H41.8216C42.6961 45.6429 43.4442 45.0682 43.7602 44.2407L46.9421 36.1375C47.0369 35.8732 47.0896 35.5973 47.0896 35.2985V32.9997Z"
        fill="#7844D3"
      />
      <path
        d="M67.1287 49.997C64.8103 54.9124 61.379 59.2203 57.1068 62.5795C62.3159 50.7155 69.3509 22.9073 55.8179 6.58639C60.2402 9.74545 63.866 13.8909 66.4081 18.6944C68.9503 23.4979 70.3389 28.8274 70.464 34.2606C70.589 39.6939 69.4471 45.0816 67.1287 49.997Z"
        fill="white"
        fillOpacity="0.38"
      />
      <path
        d="M25.4932 7.47949C23.4396 8.18901 18.9867 10.223 17.603 12.6826"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1904 16.0322C14.8654 16.2549 14.1604 16.8932 13.9414 17.665"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_10065_45254"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.5 35.1602) rotate(90) scale(35)"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="#FFF9A4" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_10065_45254"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.5 35.1602) rotate(90) scale(35)"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="#FFFBC9" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_10065_45254"
          x1="-214.309"
          y1="115.607"
          x2="-212.329"
          y2="0.51347"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A38DFF" />
          <stop offset="0.465" stopColor="#E5B4EA" />
          <stop offset="0.955" stopColor="#EBDDD3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10065_45254"
          x1="389.227"
          y1="47.3735"
          x2="389.227"
          y2="132.571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A38DFF" />
          <stop offset="0.465" stopColor="#E5B4EA" />
          <stop offset="0.955" stopColor="#EBDDD3" />
        </linearGradient>
      </defs>
    </svg>
  ),
  Calendar: (props: IconProps) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_12113_44789)">
        <path
          d="M15.6264 1.64886H14.0862V0.845285C14.0862 0.549436 13.8464 0.30957 13.5505 0.30957C13.2546 0.30957 13.0148 0.549436 13.0148 0.845285V1.64886H5.51479V0.845285C5.51479 0.549436 5.27496 0.30957 4.97907 0.30957C4.68319 0.30957 4.44336 0.549436 4.44336 0.845285V1.64886H2.90318C1.68467 1.64886 0.693359 2.64016 0.693359 3.85868V15.2426C0.693359 16.4611 1.68467 17.4524 2.90318 17.4524H15.6264C16.8449 17.4524 17.8362 16.4611 17.8362 15.2426V3.85868C17.8362 2.64016 16.8449 1.64886 15.6264 1.64886ZM2.90318 2.72028H4.44336V3.256C4.44336 3.55185 4.68319 3.79171 4.97907 3.79171C5.27496 3.79171 5.51479 3.55185 5.51479 3.256V2.72028H13.0148V3.256C13.0148 3.55185 13.2546 3.79171 13.5505 3.79171C13.8464 3.79171 14.0862 3.55185 14.0862 3.256V2.72028H15.6264C16.2541 2.72028 16.7648 3.23095 16.7648 3.85868V5.131H1.76479V3.85868C1.76479 3.23095 2.27546 2.72028 2.90318 2.72028ZM15.6264 16.381H2.90318C2.27546 16.381 1.76479 15.8703 1.76479 15.2426V6.20243H16.7648V15.2426C16.7648 15.8703 16.2541 16.381 15.6264 16.381Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_12113_44789">
          <rect width="17.1429" height="17.1429" fill="white" transform="translate(0.695312 0.30957)" />
        </clipPath>
      </defs>
    </svg>
  ),
  Filter: (props: IconProps) => (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0.0683594 1H15.0684M3.06836 6H12.0684M5.06836 11H10.0684" stroke="currentColor" />
    </svg>
  ),
  LineChartDown: (props: IconProps) => (
    <svg width="170" height="149" viewBox="0 0 170 149" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_f_10700_48031)">
        <ellipse
          cx="25.5655"
          cy="14.4"
          rx="25.5655"
          ry="14.4"
          transform="matrix(0.982657 0.185432 0.19633 -0.980538 57.3867 83.5469)"
          fill="url(#paint0_linear_10700_48031)"
        />
      </g>
      <path
        d="M9.83789 49.0938L30.9829 63.9426L49.701 55.5496L71.8857 80.4065L85.0579 75.564L102.39 92.9961"
        stroke="url(#paint1_linear_10700_48031)"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_f_10700_48031"
          x="0.775913"
          y="-0.00753403"
          width="169.12"
          height="148.351"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="29.6385" result="effect1_foregroundBlur_10700_48031" />
        </filter>
        <linearGradient
          id="paint0_linear_10700_48031"
          x1="28.2874"
          y1="37.1533"
          x2="43.7575"
          y2="13.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFA6FF" />
          <stop offset="1" stopColor="#CFA6FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10700_48031"
          x1="4.22386"
          y1="47.3312"
          x2="140.135"
          y2="95.4733"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAA2D8" />
          <stop offset="0.0001" stopColor="#EAA2D8" stopOpacity="0" />
          <stop offset="1" stopColor="#F04BC7" />
        </linearGradient>
      </defs>
    </svg>
  ),
  LineChartUp: (props: IconProps) => (
    <svg width="170" height="150" viewBox="0 0 170 150" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_f_27_18)">
        <ellipse
          cx="25.5655"
          cy="14.4"
          rx="25.5655"
          ry="14.4"
          transform="matrix(0.982657 -0.185432 0.19633 0.980538 56.8476 65.6628)"
          fill="url(#paint0_linear_27_18)"
        />
      </g>
      <mask
        id="mask0_27_18"
        // style="mask-type:alpha"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="43"
        y="48"
        width="97"
        height="48"
      >
        <path
          d="M45 93.9023L66.145 79.0535L84.8631 87.4465L107.048 62.5896L120.22 67.4321L137.552 50"
          stroke="url(#paint1_linear_27_18)"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </mask>
      <g mask="url(#mask0_27_18)">
        <rect x="45" y="48" width="95" height="43" fill="url(#paint2_linear_27_18)" />
      </g>
      <defs>
        <filter
          id="filter0_f_27_18"
          x="0.236851"
          y="0.866459"
          width="169.12"
          height="148.351"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="29.6385" result="effect1_foregroundBlur_27_18" />
        </filter>
        <linearGradient
          id="paint0_linear_27_18"
          x1="28.2874"
          y1="37.1533"
          x2="43.7575"
          y2="13.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7600FF" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_27_18"
          x1="91.2759"
          y1="50"
          x2="51.205"
          y2="95.0853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D33B0" />
          <stop offset="1" stopColor="#6D33B0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_27_18"
          x1="49.6651"
          y1="88.3939"
          x2="145.511"
          y2="62.6145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7844D3" />
          <stop offset="1" stopColor="#5815A4" />
        </linearGradient>
      </defs>
    </svg>
  ),
  Infinity: (props: IconProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.178 8C23.274 8 23.274 16 18.178 16C13.083 16 11.045 8 5.43899 8C0.853994 8 0.853994 16 5.43899 16C11.045 16 13.083 8 18.179 8H18.178Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Translate: (props: IconProps) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3262 8.66016V2.66016C17.3262 2.12972 17.1155 1.62102 16.7404 1.24594C16.3653 0.87087 15.8566 0.660156 15.3262 0.660156H9.32617C8.79574 0.660156 8.28703 0.87087 7.91196 1.24594C7.53689 1.62102 7.32617 2.12972 7.32617 2.66016V8.66016C7.32617 9.19059 7.53689 9.6993 7.91196 10.0744C8.28703 10.4494 8.79574 10.6602 9.32617 10.6602H15.3262C15.8566 10.6602 16.3653 10.4494 16.7404 10.0744C17.1155 9.6993 17.3262 9.19059 17.3262 8.66016Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.32617 6.66406H3.32617C2.79574 6.66406 2.28703 6.87478 1.91196 7.24985C1.53689 7.62492 1.32617 8.13363 1.32617 8.66406V14.6641C1.32617 15.1945 1.53689 15.7032 1.91196 16.0783C2.28703 16.4533 2.79574 16.6641 3.32617 16.6641H3.32917L9.32917 16.6541C9.85909 16.6533 10.367 16.4422 10.7414 16.0672C11.1159 15.6922 11.3262 15.184 11.3262 14.6541V12.6611M6.32617 10.6641H3.32617"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.82617 12.1602L6.82617 13.1602C6.49217 13.4932 5.66017 13.9932 4.32617 14.6602"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.32617 10.6632C4.66017 11.8292 5.16017 12.6632 5.82617 13.1622C6.49217 13.6612 7.32617 14.1602 8.32617 14.6602M9.32617 8.66016L12.3262 2.66016L15.3262 8.66016M14.3262 6.66016H10.3262"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Microphone: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 2C11.2044 2 10.4413 2.31607 9.87868 2.87868C9.31607 3.44129 9 4.20435 9 5L9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12V5C15 4.20435 14.6839 3.44129 14.1213 2.87868C13.5587 2.31607 12.7956 2 12 2Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 10V12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19C10.1435 19 8.36301 18.2625 7.05025 16.9497C5.7375 15.637 5 13.8565 5 12V10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 19V22" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  VoiceMessageBars: (props: IconProps) => (
    <svg width="208" height="24" viewBox="0 0 208 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_13909_10304)">
        <path d="M4 6L4 18" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M12 4L12 20" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M20 5.5L20 18.5" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M28 7V17" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M36 5L36 19" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M44 7V17" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M52 5.5L52 18.5" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M60 3.5L60 20.5" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M68 7V17" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M76 6L76 18" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M84 4L84 20" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M92 6L92 18" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M100 3.5L100 20.5" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M108 5.5L108 18.5" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M116 4.5L116 19.5" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M124 7V17" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M132 6L132 18" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M140 3L140 21" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M148 5.5L148 18.5" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M156 4L156 20" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M164 6L164 18" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M172 7V17" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M180 5.5L180 18.5" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M188 7V17" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M196 4L196 20" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        <path d="M204 7V17" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_13909_10304">
          <rect width="208" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  PlayClip: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M7 3L21 12L7 21V3Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  PauseClip: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M10 4H6V20H10V4Z"
        fill="#333333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 4H14V20H18V4Z"
        fill="#333333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  // Google: (props: IconProps) => <svg></svg>,
  // Google: (props: IconProps) => <svg></svg>,
  // Google: (props: IconProps) => <svg></svg>,
} satisfies Record<string, React.FC<IconProps>>;
