import { Avatar } from "@/components/Avatar";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import type { FullAgent } from "@/types/agent";
import { isString } from "lodash";
import { useRef } from "react";
import { Controller, type UseFormReturn } from "react-hook-form";

type BubbleDisplayProps = {
  form: UseFormReturn<FullAgent["publish"]>;
  setImage: (src: string, name: string) => void;
};

export const BubbleDisplay = ({ form, setImage }: BubbleDisplayProps) => {
  const { control } = form;
  const inputRef = useRef<HTMLInputElement>(null);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileName = e.target.files[0].name || "";
      const reader = new FileReader();
      reader.addEventListener("load", () => setImage(reader.result?.toString() || "", fileName));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      <Controller
        control={control}
        name="chatStyle.image"
        render={({ field: { value } }) => {
          const avatarUrl = value && !isString(value) ? URL.createObjectURL(value).toString() : value;
          return (
            <>
              <div>
                <Label className="text-neutral-400">Bubble Display</Label>
                <div className="mt-2 flex w-full flex-col items-center justify-center px-6 py-4">
                  <div className="h-[80px] w-[80px] overflow-hidden rounded-l-full rounded-tr-full">
                    <Avatar src={avatarUrl} size="full" />
                  </div>
                  <input hidden ref={inputRef} type="file" accept="image/*" onChange={onSelectFile} />
                  <Button variant="link" onClick={() => inputRef.current?.click()}>
                    Upload Photo
                  </Button>
                </div>
              </div>
            </>
          );
        }}
      />
    </>
  );
};
