import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { CommunityAgent, CommunityCategory } from "@/types/community";
import type { RelationResponse } from "@/types/relation";
import { notPublishedAgentsKeys } from "@/data/queries/useGetNotPublishedAgents";
import { communityAgentsKeys } from "@/data/queries/useGetCommunityAgents";
import { userPublishedAgentsKeys } from "@/data/queries/useGetUserPublishedAgents";
import { chatHistoryWithAgentsKeys } from "../queries/useGetChatHistoryWithAgents";

type Payload =
  | {
      agentId: CommunityAgent["_id"];
      status: true;
      categories: CommunityCategory["_id"][];
      description: string;
    }
  | {
      agentId: CommunityAgent["_id"];
      status: false;
    };

const publishAgentToCommunity = async (agentData: Payload) => {
  let payload;
  if (agentData.status) {
    payload = {
      agentId: agentData.agentId,
      categories: agentData.categories,
      description: agentData.description,
      status: agentData.status,
    };
  } else {
    payload = {
      agentId: agentData.agentId,
      status: agentData.status,
    };
  }

  const { data } = await restClient.post<RelationResponse>(
    apiPaths.publishAgentToCommunity(agentData.agentId),
    payload
  );
  return data;
};

export const usePublishAgentToCommunity = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<RelationResponse, AxiosError<{ message: string }>, Payload>({
    mutationFn: publishAgentToCommunity,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: notPublishedAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: userPublishedAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: chatHistoryWithAgentsKeys.all });

      void queryClient.refetchQueries({ queryKey: communityAgentsKeys.all });
    },
  });

  return mutation;
};
