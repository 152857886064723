import { tailwindScreens } from "../constants/tailwindScreens";
import { useMediaQuery } from "usehooks-ts";

export const useTailwindMediaQuery = (screen: keyof typeof tailwindScreens) => {
  return useMediaQuery(`(min-width:${tailwindScreens[screen]})`);
};

// Ensure we're targeting 0px-[large MediaQuery]px without the last pixel of the upper bound
export const useIsMobileMediaQuery = () => !useMediaQuery(`(min-width:${tailwindScreens.lg})`);

export const useTouchMediaQuery = () => useMediaQuery("(hover:none) and (pointer:coarse)");
