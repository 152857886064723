import type { Agent } from "@/types/agent";
import type { Conversation } from "@/types/conversation";

const conversationRoot = "/conversation-details";

export const ROUTES = {
  home: "/",
  stats: "/stats",
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  updatePassword: "/update-password",
  updatePasswordEnterprise: "/update-password-enterprise",
  help: "/help",
  thirdPartyLogin: "third-party-login",
  createAgent: "/agents/create",
  editAgent: (id: Agent["_id"]) => `/agents/${id}`,
  workspace: "/workspace",
  community: "/community",
  communityProfile: (id: Agent["_id"]) => `/community/profile/${id}`,
  sharedConversation: (id: Conversation["_id"]) => `/conversation/${id}`,
  conversationRoot,
  newConversation: `${conversationRoot}/new`,
  conversation: (conversationId: Conversation["_id"]) => `${conversationRoot}/${conversationId}`,
} as const;
