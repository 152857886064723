import { useContext } from "react";
import { StatsContext } from "./StatsContext";

export const useStatsContext = () => {
  const context = useContext(StatsContext);

  if (context) {
    return context;
  }

  throw new Error("Use this hook in StatsContext scope");
};
