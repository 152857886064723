import { MessageCloud, type MessageCloudProps } from "@/components/Chat/components/MessageCloud";
import { Icons } from "@/components/ui/icons";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useDetectLanguage } from "@/data/mutations/useDetectLanguage";
import { useTranslateText } from "@/data/mutations/useTranslateText";
import { cn } from "@/lib/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useGetAllLanguages } from "@/data/queries/useGetAllLanguages";

type LanguageOption = {
  value: string;
  label: string;
};

const PREFERRED_LANGUAGE_OPTIONS: LanguageOption[] = [
  { value: "es", label: "Spanish" },
  { value: "ja", label: "Japanese" },
  { value: "it", label: "Italian" },
  { value: "de", label: "German" },
  { value: "fr", label: "French" },
  { value: "en", label: "English" },
  { value: "zh", label: "Chinese" },
];

type Props = MessageCloudProps;

export const MessageCloudTranslated = (props: Props) => {
  const { message: originalMessage } = props;

  const canTranslate = !!originalMessage;

  const isFirstRender = useRef(true);

  const { mutate: translateText } = useTranslateText();
  const { mutate: detectLanguage } = useDetectLanguage();
  const { data, isPending: isPendingAllLanguages } = useGetAllLanguages();

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [detectedLanguage, setDetectedLanguage] = useState<LanguageOption | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption | null>(null);
  const [translatedMessageText, setTranslatedMessageText] = useState<string | null>(null);

  const allLanguageOptions = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.languages.map(lang => ({
      value: lang.language,
      label: lang.name,
    }));
  }, [data]);

  const allowedLanguageOptions = useMemo(() => {
    const languagesWithoutDetectedOne = allLanguageOptions.filter(option => option.value !== detectedLanguage?.value);
    const languagesSortedByPreference = languagesWithoutDetectedOne.sort((a, b) => {
      const aIndex = PREFERRED_LANGUAGE_OPTIONS.findIndex(option => option.value === a.value);
      const bIndex = PREFERRED_LANGUAGE_OPTIONS.findIndex(option => option.value === b.value);

      return bIndex - aIndex;
    });

    return languagesSortedByPreference;
  }, [allLanguageOptions, detectedLanguage?.value]);

  const handleTranslateText = ({ text, language }: { text: string; language: LanguageOption }) => {
    translateText(
      { text, target: language.value },
      {
        onSuccess: data => {
          setTranslatedMessageText(data.translatedText);
        },
      }
    );
  };

  const handleDetectLanguage = useCallback(() => {
    detectLanguage(
      { text: originalMessage },
      {
        onSuccess: data => {
          const foundLanguage = allLanguageOptions.find(option => option.value === data.detectedLanguage);

          if (!foundLanguage) {
            return;
          }

          setDetectedLanguage(foundLanguage);
        },
      }
    );
  }, [allLanguageOptions, detectLanguage, originalMessage]);

  const handleResetToOriginalLanguage = () => {
    setSelectedLanguage(null);
    setTranslatedMessageText(null);
  };

  const handleSelectValueChange = (value: string) => {
    if (value === "original") {
      handleResetToOriginalLanguage();
      return;
    }

    const foundLanguage = allowedLanguageOptions.find(option => option.value === value);
    if (foundLanguage) {
      setSelectedLanguage(foundLanguage);
      handleTranslateText({ text: originalMessage, language: foundLanguage });
    }
  };

  useEffect(() => {
    if (!isSelectOpen || !isFirstRender.current) {
      return;
    }

    isFirstRender.current = false;
    void handleDetectLanguage();
  }, [handleDetectLanguage, isSelectOpen]);

  return (
    <div className="group/message relative">
      <MessageCloud {...props} message={translatedMessageText ?? props.message} />
      {props.vote === 0 && (
        <div className="absolute right-2 top-6">
          <Icons.ThumbDown className="w-5 text-neutral-400" />
        </div>
      )}

      <Select
        open={isSelectOpen}
        onOpenChange={open => setIsSelectOpen(open)}
        onValueChange={handleSelectValueChange}
        value={selectedLanguage?.value}
        defaultValue="original"
      >
        <SelectTrigger
          id="llmModel"
          className={cn(
            "invisible absolute bottom-3 right-6 flex w-fit scale-125 items-center gap-1 rounded-lg border-none bg-white py-0 pl-3 pr-2 shadow-md group-hover/message:visible data-[state=open]:visible",
            {
              hidden: !canTranslate,
            }
          )}
        >
          <SelectValue placeholder="Translate" className="p-6">
            <div className="flex items-center gap-1">
              <Icons.Translate />
              <span className="text-xs">{selectedLanguage?.label ?? "Translate"}</span>
            </div>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {isPendingAllLanguages ? (
            <div className="my-2 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              {!!translatedMessageText && !!detectedLanguage && (
                <SelectItem value="original">
                  <div className="flex w-full items-center justify-between gap-4 text-xs">{`Original text ${detectedLanguage ? `(${detectedLanguage.label})` : ""}`}</div>
                </SelectItem>
              )}
              {allowedLanguageOptions.map(option => {
                return (
                  <SelectItem key={option.value} value={option.value}>
                    <div className="flex w-full items-center justify-between gap-4 text-xs">{option.label}</div>
                  </SelectItem>
                );
              })}
            </>
          )}
        </SelectContent>
      </Select>

      {!!translatedMessageText && (
        <div className="mb-4 mt-2">
          <a href="http://translate.google.com/" target="_blank" rel="noopener noreferrer">
            <Icons.GoogleFull />
          </a>
        </div>
      )}
    </div>
  );
};
