import * as React from "react";

import { cn } from "@/lib/utils";

export interface DivProps extends React.HTMLAttributes<HTMLDivElement> {}

const LoginContainer = React.forwardRef<HTMLDivElement, DivProps>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn(
        "flex w-dvw flex-col rounded-t-[20px] border border-neutral-200 bg-white p-5 pb-10 shadow-register absolute-center sm:w-[559px] sm:rounded-[20px] sm:p-10 sm:px-[94px] md:py-16",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
LoginContainer.displayName = "LoginContainer";

export { LoginContainer };
