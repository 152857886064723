import type { DateRangeType } from "./datepicker";
import type { TypeFromConstObject } from "./types";
import type { Agent, StyleChatColor, StyleSetVariant } from "@/types/agent";

export type Stats = {
  summary: Summary;
  agents: AgentStats[];
};

export type BasicStats = {
  conversations: {
    total: number;
    trend: number | null;
  };
  views: {
    total: number;
    trend: number | null;
  };
  leadGeneration: {
    total: number;
    trend: number | null;
  };
  messages: {
    total: number;
    trend: number | null;
  };
};

export type TopAgentStats = {
  agent: Agent | null;
  messages: {
    total: number;
    trend: number | null;
  };
  views: {
    total: number;
    trend: number | null;
  };
  satisfaction: number | null;
};

export type AgentOverviewStats = {
  agent: Agent;
  messages: number;
  conversations: number;
  satisfaction: number | null;
  channels: {
    channel: StatOrigin;
    value: number;
  }[];
};

export type AgentStats = {
  agent: Agent;
  sharing: Sharing;
  conversations: number;
  messages: number;
  rating: Rating;
};

export type Rating = {
  positiveFeedback: number;
  negativeFeedback: number;
};

export type Sharing = {
  allowedOrigins: string[];
  chatStyle: {
    variant: StyleSetVariant;
    color: StyleChatColor;
    image?: File | string;
  };
  isPublished: boolean;
  allowedOrigin: string[];
  color?: string;
  theme: string;
};

export type Summary = {
  conversations_count: number;
  messages_count: number;
  rating: number;
};

export type StatsQueryFilters = {
  currentRange: DateRangeType;
  startDate: Date | null;
  endDate: Date | null;
  channels: number[];
};

export const StatOrigins = {
  WEB: 0,
  // API: 1,
  EMBEDDED: 2,
  AGENT_SPACE: 4,
  WORKSPACE: 5,
  //EXTERNAL_INTEGRATIONS: 6,
  DISCORD: 6,
  SLACK: 7,
  // TELEGRAM: 8,
  // OTHER: 99,
} as const;

export type StatOrigin = TypeFromConstObject<typeof StatOrigins>;

export type EngagementOverviewStat = {
  intervalStartDate: string;
  intervalEndDate: string;
  agents: number;
  messages: number;
  conversations: number;
};

export type AllAgentsStat = {
  agent: Agent;
  messages: number;
  conversations: number;
  views: number;
  likes?: number;
};

export const StatsIntervals = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
} as const;

export type StatsInterval = TypeFromConstObject<typeof StatsIntervals>;

export type SingleAgentEngagementStats = {
  agent: Agent;
  stats: {
    intervalStartDate: string;
    intervalEndDate: string;
    messages: number;
    conversations: number;
    views: number;
    likes?: number;
  }[];
};
