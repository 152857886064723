import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { AxiosError } from "axios";
import { toast } from "react-toastify";
import { externalAgentsKeys } from "@/data/queries/useGetExternalAgents";

type Response = {
  message: string;
};

type Props = {
  externalAgentId: string;
  agentId: string;
};

const deleteExternalAgent = async ({ externalAgentId }: Props) => {
  const { data } = await restClient.delete<Response>(apiPaths.deleteExternalAgent(externalAgentId));
  return data;
};

export const useDeleteExternalAgent = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Response, AxiosError<Response>, Props>({
    mutationFn: deleteExternalAgent,
    onSuccess: (_, { agentId }) => {
      toast.success("Agent successfully deleted.");
      void queryClient.invalidateQueries({ queryKey: externalAgentsKeys.id(agentId) });
    },
  });

  return mutation;
};
