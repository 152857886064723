import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { externalAgentsKeys } from "@/data/queries/useGetExternalAgents";
import type { ExternalAgent } from "@/types/agent";

type Response = {
  message: string;
  externalAgent: ExternalAgent;
};

type Props = {
  externalAgentId: string;
  agentId: string;
};

const startExternalAgent = async ({ externalAgentId }: Props) => {
  const { data } = await restClient.patch<Response, AxiosResponse<Response>, Omit<Props, "agentId">>(
    apiPaths.startExternalAgent,
    { externalAgentId }
  );
  return data;
};

export const useStartExternalAgent = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<Response, AxiosError<Response>, Props>({
    mutationFn: startExternalAgent,
    onSuccess: ({ externalAgent }, { externalAgentId, agentId }) => {
      toast.success("Agent resumed successfully.");
      queryClient.setQueryData(externalAgentsKeys.id(agentId), (oldData: ExternalAgent[]) => {
        const externalAgentIndex = oldData.findIndex(agent => agent._id === externalAgentId);
        const oldDataCopy = [...oldData];
        oldDataCopy[externalAgentIndex] = externalAgent;
        return oldDataCopy;
      });
    },
  });

  return mutation;
};
