import { Carousel, CarouselContent, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { WorkspaceBaseModelTile } from "./WorkspaceBaseModelTile";
import type { FullAgent } from "@/types/agent";

export const WorkspaceBaseModels = ({ agents, isLoading }: { agents: FullAgent[]; isLoading: boolean }) => {
  if (isLoading) {
    return <LoadingSpinner className="flex h-32 w-full items-center justify-center" />;
  }

  if (!agents?.length) {
    return null;
  }

  return (
    <div className="mb-6">
      <h4 className="mb-4 text-left font-bold">Base Models</h4>
      <Carousel
        className="h-auto max-w-none"
        opts={{
          startIndex: 0,
          align: "start",
        }}
        slideStep={3}
      >
        <CarouselContent containerClassName="relative">
          {agents?.map((agent, index) => (
            <WorkspaceBaseModelTile key={agent._id} agent={agent} isLast={index === agents.length - 1} />
          ))}
        </CarouselContent>

        <div className="absolute right-0 top-1/2 h-36 w-10 -translate-y-1/2 bg-fade-out-horizontal-light"></div>

        <CarouselPrevious className="left-1 disabled:hidden" />
        <CarouselNext className="right-1 disabled:hidden" />
      </Carousel>
    </div>
  );
};
