import { OnboardingInfo } from "./components/OnboardingInfo";
import { DocumentInfo } from "./components/DocumentInfo";
import { OnlineInfo } from "./components/OnlineInfo";
import { FAQ } from "./components/FAQ";

export const OnboardingKnowledge = () => {
  return (
    <>
      <OnboardingInfo />
      <DocumentInfo />
      <OnlineInfo />
      <FAQ />
    </>
  );
};
