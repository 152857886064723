import { toast } from "react-toastify";
import { useCopyToClipboard } from "usehooks-ts";

export const useCopyNotify = (): [string | null, (text: string) => void] => {
  const [value, copy] = useCopyToClipboard();

  const copyText = (text: string) => {
    copy(text)
      .then(() => {
        toast.success("Copied to clipboard");
      })
      .catch(() => {
        toast.error(
          "There was an error copying to clipboard. Your device might not support this feature. Please try to copy manually."
        );
      });
  };
  return [value, copyText];
};
