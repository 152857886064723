import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSendChatMessage } from "./useSendChatMessage";
import { sleep } from "@/utils/sleep";
import type { ChatAgent } from "@/types/conversation";
import { domElementIds } from "@/types/dom-element-ids";
import type { EventFor } from "@/types/types";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { useUpdateNavConversationHistory } from "@/data/queryUpdates/useUpdateNavConversationHistory";

export const useInputForm = ({ agents }: { agents: ChatAgent[] }) => {
  const { updateNavConversationHistory } = useUpdateNavConversationHistory();

  const [messageText, setMessageText] = useState("");
  const [onComposition, setOnComposition] = useState(false); // Prevent premature submittion when type Chinese/Japanese keyboard
  const { isFileUploading, files, isSendingMessage, isPreview, messageRecipients, setMessageRecipients, isGroupChat } =
    useChatContext();
  const { sendMessage } = useSendChatMessage();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textareaElement = textareaRef.current;
  const { isMobile } = useMediaQueriesContext();

  const [agentsToTag, setAgentsToTag] = useState<ChatAgent[]>([]);
  const isTagListVisible = isGroupChat && agentsToTag.length > 0;
  const [highlightedTagIndex, setHighlightedTagIndex] = useState(0);

  const handleComposition = (event: EventFor<"textarea", "onCompositionEnd">) => {
    if (event.type === "compositionstart") {
      setOnComposition(true);
    }
    if (event.type === "compositionend") {
      setOnComposition(false);
    }
  };

  const submitSendMessage = useCallback(
    async (messageText: string, audio?: { blob: Blob; duration: number }) => {
      if (isFileUploading || (files?.length === 0 && !messageText.trim().length && !audio)) {
        return;
      }
      const waitAndScroll = async () => {
        setMessageText("");

        await sleep(50);

        const scrollElement = document.getElementById(domElementIds.CHAT_CONTENT_SCROLL_TO);
        if (scrollElement) {
          scrollElement.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      };

      let messageTextContent = messageText;

      if (messageRecipients.length) {
        const recipients = messageRecipients.map(recipient => recipient.name);
        messageTextContent = `@${recipients.join(" @")} ${messageText}`;
      }

      await sendMessage({
        messageText: messageTextContent,
        onSendMessageCallback: () => {
          void waitAndScroll();

          if (!agents?.length || isPreview) {
            return;
          }

          updateNavConversationHistory({ agents });
        },
        agents,
        recipients: messageRecipients,
        audio,
      });
    },
    [agents, files, isPreview, messageRecipients, sendMessage, updateNavConversationHistory, isFileUploading]
  );

  const handleAddMessageRecipient = useCallback(
    (agent: ChatAgent) => {
      setMessageRecipients(prev => [...prev, agent]);
      setAgentsToTag([]);

      setMessageText(prev => {
        const tagTrigger = "@";
        const tagIndex = prev.lastIndexOf(tagTrigger);

        if (tagIndex === -1) {
          return prev;
        }

        const textBeforeTag = prev.slice(0, tagIndex);
        const textAfterTag = prev.slice(tagIndex + prev.length);

        return textBeforeTag + textAfterTag;
      });
    },
    [setMessageRecipients]
  );

  const handleRemoveMessageRecipient = (agentId: string) => {
    setMessageRecipients(prev => prev.filter(agent => agent._id !== agentId));
    setAgentsToTag([]);
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (isSendingMessage || !textareaElement) {
        return;
      }
      if (e.key === "ArrowDown" && isTagListVisible) {
        e.preventDefault();
        setHighlightedTagIndex(prevIndex => (prevIndex + 1) % agentsToTag.length);
      }

      if (e.key === "ArrowUp" && isTagListVisible) {
        e.preventDefault();
        setHighlightedTagIndex(prevIndex => (prevIndex - 1 + agentsToTag.length) % agentsToTag.length);
      }

      if (!onComposition && e.key === "Enter") {
        e.preventDefault();

        if (isTagListVisible) {
          if (agentsToTag[highlightedTagIndex]) {
            const selectedAgent = agentsToTag[highlightedTagIndex];
            handleAddMessageRecipient(selectedAgent);
          }

          return;
        }

        const isMetaOrShiftPressed = e.metaKey || e.shiftKey;

        if (isMetaOrShiftPressed || isMobile) {
          const cursorPosition = textareaElement.selectionStart;
          const beforeCursor = messageText.substring(0, cursorPosition);
          const afterCursor = messageText.substring(cursorPosition);
          setMessageText(beforeCursor + "\n" + afterCursor);
          setTimeout(() => {
            textareaElement.selectionStart = cursorPosition + 1;
            textareaElement.selectionEnd = cursorPosition + 1;
          }, 0);
        } else {
          void submitSendMessage(messageText);
        }
      }
    };

    if (textareaElement) {
      textareaElement.addEventListener("keydown", listener);
    }

    return () => {
      if (textareaElement) {
        textareaElement.removeEventListener("keydown", listener);
      }
    };
  }, [
    isSendingMessage,
    messageText,
    onComposition,
    submitSendMessage,
    textareaElement,
    isMobile,
    isTagListVisible,
    agentsToTag,
    highlightedTagIndex,
    handleAddMessageRecipient,
  ]);

  useEffect(() => {
    const handleSearchAgentsToTag = (tagTrigger: string) => {
      const tagIndex = messageText.lastIndexOf(tagTrigger);

      if (tagIndex === -1) {
        setAgentsToTag([]);
        return;
      }

      const searchPhrase = messageText.slice(tagIndex + tagTrigger.length).toLowerCase();
      const filteredAgents = agents.filter(
        agent =>
          agent.name.toLowerCase().includes(searchPhrase) &&
          !messageRecipients.some(recipient => recipient._id === agent._id)
      );

      setAgentsToTag(filteredAgents);
    };

    handleSearchAgentsToTag(" @");

    if (messageText.startsWith("@")) {
      handleSearchAgentsToTag("@");
    }
  }, [agents, messageText, messageRecipients]);

  return {
    messageText,
    textareaRef,
    setMessageText,
    handleComposition,
    submitSendMessage,
    handleAddMessageRecipient,
    handleRemoveMessageRecipient,
    agentsToTag,
    isTagListVisible,
    highlightedTagIndex,
  };
};
