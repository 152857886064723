import StatsDataTitle from "../common/StatsDataTitle";
import { StatsAnimatedValue } from "../common/StatsAnimatedValue/StatsAnimatedValue";
import Lottie from "react-lottie";
import { Icons } from "@/components/ui/icons";
import satisfactionWaveAnimation from "@/assets/animations/satisfaction-wave.json";

type Props = {
  satisfaction: number | null;
};

export const StatsTopAgentSatisfaction = ({ satisfaction }: Props) => {
  return (
    <div className="flex justify-between @container/satisfaction-card @xl/top-agents:flex-col @5xl/top-agents:flex-row">
      <div>
        <StatsDataTitle title="User Satisfaction" />
        {satisfaction !== null ? (
          <StatsAnimatedValue value={satisfaction} suffix="%" />
        ) : (
          <Icons.Line className="my-3 h-4 w-4" />
        )}
      </div>
      <div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: satisfactionWaveAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={70}
          width={84}
          style={{
            margin: "0 0",
          }}
        />
      </div>
    </div>
  );
};
