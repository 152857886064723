export const domElementIds = {
  CHAT_CONTENT_SCROLL_TO: "chat-content-scroll-to",
  CHAT_CONTENT_CONTAINER: "chat-content-container",
  CHAT_FILE_INPUT: "chat-file-input",
  AGENT_FORM_MOBILE_SCROLL_TO: "agent-form-mobile-scroll-to",
  STRIPE_FORM: "stripe-form",
  AGENT_FORM_ONBOARDING_INFO: "onboarding-info",
  AGENT_FORM_DOCUMENT_INFO: "document-info",
  AGENT_FORM_ONLINE_INFO: "online-info",
  AGENT_FORM_FAQ: "faq",
} as const;
