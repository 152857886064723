import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import { userKeys } from "@/data/queries/useGetUser";

const generateNewApiKey = async () => {
  const { data } = await restClient.post<string>(apiPaths.generateNewApiKey);
  return data;
};

export const useGenerateNewApiKey = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<string, AxiosError<{ message: string }>>({
    mutationFn: generateNewApiKey,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });

  return mutation;
};
