import { cn } from "@/lib/utils";

const GroupConversationAgentsName = ({
  names,
  className,
  as: Component = "h3",
}: {
  names: string[] | undefined;
  className?: string;
  as?: React.ElementType;
}) => {
  const showSuffix = names?.length && names.length > 2;

  return (
    <Component className={cn("truncate text-left text-base font-semibold text-primary-black md:text-lg", className)}>
      {names
        ?.slice(0, 2)
        ?.map(name => name)
        .join(", ")}
      {showSuffix && ` ...(${names.length})`}
    </Component>
  );
};

export default GroupConversationAgentsName;
