import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { BasicStats, StatsQueryFilters } from "@/types/stats";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";

export const basicStatsKeys = {
  all: ["basicStats"] as const,
  params: ({
    startDateParam,
    endDateParam,
    channels,
  }: {
    startDateParam: string;
    endDateParam: string;
    channels: number[];
  }) => [...basicStatsKeys.all, startDateParam, endDateParam, channels] as const,
};

const getBasicStats = async ({
  startDate,
  endDate,
  channels,
}: {
  startDate: string;
  endDate: string;
  channels: number[];
}) => {
  const { data } = await restClient.get<BasicStats>(apiPaths.basicStats, {
    params: {
      startDate,
      endDate,
      channels: channels.join(","),
      timezone: StatisticsHelpers.getTimezone(),
    },
  });
  return data;
};

export const useGetBasicStats = (props: StatsQueryFilters) => {
  const { currentRange, startDate, endDate, channels } = props;

  const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
  const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

  const query = useQuery({
    queryKey: basicStatsKeys.params({ startDateParam, endDateParam, channels }),
    queryFn: () => getBasicStats({ startDate: startDateParam, endDate: endDateParam, channels }),
    enabled: !!startDateParam && !!endDateParam,
    placeholderData: keepPreviousData,
  });

  return query;
};
