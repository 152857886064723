import { useCreateAgentInteractionStat } from "@/data/mutations/useCreateAgentInteractionStat";
import { useEffect, useRef } from "react";

export const useTrackAgentInteractionStats = ({
  agentIds,
  enabled = true,
}: {
  agentIds: string[];
  enabled?: boolean;
}) => {
  const { mutate: postAgentInteractionStat } = useCreateAgentInteractionStat();

  const alreadySentAgentIds = useRef<string[]>([]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const newAgentIds = agentIds.filter(id => !alreadySentAgentIds.current.includes(id));

    if (newAgentIds.length > 0) {
      postAgentInteractionStat({ agentIds: newAgentIds });
      alreadySentAgentIds.current = [...alreadySentAgentIds.current, ...newAgentIds];
    }
  }, [agentIds, postAgentInteractionStat, enabled]);
};
