import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "@/data/initAxios";
import { singleConversationDetailsKeys } from "../queries/useGetSingleConversationDetails";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { agentsGroupConversationsKeys } from "../queries/useGetAgentsGroupConversations";

type Props = {
  conversationId: string;
  title: string;
};

const renameConversation = async (props: Props) => {
  const { data } = await restClient.put<unknown>(
    apiPaths.updateConversationTitle({ conversationId: props.conversationId }),
    {
      title: props.title,
    }
  );
  return data;
};

export const useRenameConversation = () => {
  const queryClient = useQueryClient();

  const { conversationDetails } = useChatContext();

  const mutation = useMutation<unknown, AxiosError<{ message: string }>, Props>({
    mutationFn: renameConversation,
    onSuccess: (_, { conversationId }) => {
      void queryClient.invalidateQueries({ queryKey: singleConversationDetailsKeys.id(conversationId) });
      void queryClient.invalidateQueries({
        queryKey: agentsGroupConversationsKeys.ids(conversationDetails?.bots.map(agent => agent._id) || []),
      });
    },
  });

  return mutation;
};
