import { Dialog, DialogOverlay, DialogPortal, DialogTrigger } from "@/components/ui/dialog";
import { X } from "lucide-react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { avatarPathMap } from "@/utils/pickAvatar";

type AgentSizeupAvatarDialogProps = {
  renderButton: () => JSX.Element;
  src: string;
};

const AgentSizeupAvatarDialog = ({ renderButton, src }: AgentSizeupAvatarDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger asChild>{renderButton()}</DialogTrigger>
      <DialogPortal>
        <DialogOverlay className="gradient-orange-to-brown backdrop-blur-[14px]" />
        <DialogPrimitive.Content className="fixed left-0 top-0 z-50 flex-col gap-4 overflow-hidden  p-0 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] dark:border-slate-800 dark:bg-slate-950 sm:rounded-lg lg:rounded-2xl">
          <img
            src={avatarPathMap(src)}
            className="fixed left-1/2 top-1/2 z-50  flex aspect-square w-[600px] max-w-full -translate-x-1/2 -translate-y-1/2 p-4"
          />

          <DialogPrimitive.Close className="fixed left-3 top-12 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none  data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500 dark:ring-offset-slate-950 dark:focus:ring-slate-300 dark:data-[state=open]:bg-slate-800 dark:data-[state=open]:text-slate-400 sm:left-20">
            <X className="size-8 text-primary-black" />
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>
        </DialogPrimitive.Content>
      </DialogPortal>
    </Dialog>
  );
};

export default AgentSizeupAvatarDialog;
