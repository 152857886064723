import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { authorizeAxiosClient } from "@/data/initAxios";
import { useGetUser } from "@/data/queries/useGetUser";
import { LoadingSpinner } from "@/components/ui/loading-spinner";

export const ThirdPartyLogin = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const [token, setToken] = useState("");
  const { user } = useGetUser({ enabled: !!token });

  useEffect(() => {
    const token = new URLSearchParams(params.search).get("token");
    if (!token) {
      return navigate(ROUTES.login);
    }
    authorizeAxiosClient({ token });
    localStorage.setItem("token", token);
    setToken(token);
  }, [navigate, params.search, user]);

  return (
    <div className="flex h-full flex-col items-center justify-center text-white">
      <LoadingSpinner />
    </div>
  );
};
