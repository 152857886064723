import { Icons } from "@/components/ui/icons";

export const MessageError = () => {
  return (
    <div className="my-2 flex max-h-[54px] min-h-[54px] max-w-[87%] shrink items-center gap-[6px] whitespace-pre-wrap break-words rounded-cloud border border-solid border-white bg-cloud-light p-4">
      <div>
        <Icons.Error />
      </div>
      <div className="text-xs font-medium uppercase text-primary-black">Sorry! An unexpected error has occurred!</div>
    </div>
  );
};
