import type { StatOrigin } from "@/types/stats";
import { StatOrigins } from "@/types/stats";
import { channelsLegendData } from "../../constants/statsConstants";
import { StatsSingleAgentRowDetailsChart } from "./StatsSingleAgentRowDetailsChart";
import { Icons } from "@/components/ui/icons";
import { Button } from "@/components/ui/button";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import type { Agent } from "@/types/agent";

export const StatsSingleAgentRowDetails = ({
  channels,
  agentId,
}: {
  channels: {
    channel: StatOrigin;
    value: number;
  }[];
  agentId: Agent["_id"];
}) => {
  const { setConversationsListAgentId } = useStatsContext();

  const getChannelValue = (channel: StatOrigin) => {
    return channels.find(channelItem => channelItem.channel === channel)?.value ?? 0;
  };

  const totalValue = channels.reduce((acc, channel) => acc + channel.value, 0);

  const iconData = [
    {
      icon: <Icons.Agent />,
      channel: StatOrigins.WORKSPACE,
    },
    {
      icon: <Icons.DiscordCloud />,
      channel: StatOrigins.DISCORD,
    },
    {
      icon: <Icons.SlackColor />,
      channel: StatOrigins.SLACK,
    },
    {
      icon: <Icons.Globe />,
      channel: StatOrigins.EMBEDDED,
    },
    {
      icon: <Icons.Galaxy className="text-primary-400" />,
      channel: StatOrigins.AGENT_SPACE,
    },
  ];

  const getIcon = (channel: StatOrigin) => {
    return iconData.find(item => item.channel === channel)?.icon;
  };

  return (
    <div className="ml-11 flex flex-wrap items-center justify-center gap-12">
      {channelsLegendData.map(item => {
        return (
          <StatsSingleAgentRowDetailsChart
            key={item.channel}
            value={getChannelValue(item.channel)}
            total={totalValue}
            color={item.color}
            name={item.label}
            icon={getIcon(item.channel)}
          />
        );
      })}
      <Button variant="secondary" className="gap-2" onClick={() => setConversationsListAgentId(agentId)}>
        Conversations
        <Icons.Arrow className="size-4 rotate-180" />
      </Button>
    </div>
  );
};
