import type { AgentMutationPayload, FullAgent } from "@/types/agent";
import type { User } from "@/types/user";
// eslint-disable-next-line import/no-cycle
import { uploadBubbleAvatar } from "@/data/mutations/useUpdateAgent";
import { isString } from "lodash";

export const updateAgentPayload = async ({ newData, prevData }: AgentMutationPayload) => {
  const newFaqData = () => {
    const newFaqData = newData.embedKnowledges.faq.filter(
      newFaq => !prevData.embedKnowledges.faq.some(prevFaq => prevFaq._id === newFaq._id)
    );
    return newFaqData;
  };

  const removedFaqData = () => {
    const removedFaqData = prevData.embedKnowledges.faq.filter(
      prevFaq => !newData.embedKnowledges.faq.some(newFaq => newFaq._id === prevFaq._id)
    );
    return removedFaqData.map(faq => faq._id);
  };

  const newWebData = () => {
    const newWebData = newData.embedKnowledges.web.filter(
      newWeb => !prevData.embedKnowledges.web.some(prevWeb => prevWeb._id === newWeb._id)
    );
    return newWebData.map(web => web.source);
  };

  const removedWebData = () => {
    const removedWebData = prevData.embedKnowledges.web.filter(
      prevWeb => !newData.embedKnowledges.web.some(newWeb => newWeb._id === prevWeb._id)
    );
    return removedWebData.map(web => web._id);
  };

  const newDocData = () => {
    const newDocData = newData.embedKnowledges.doc.filter(newDoc => "docId" in newDoc || "isNewFolder" in newDoc);
    return newDocData;
  };

  const removedDocData = () => {
    const removedDocData = prevData.embedKnowledges.doc.filter(
      prevDoc => !newData.embedKnowledges.doc.some(newDoc => newDoc._id === prevDoc._id)
    );
    return removedDocData.map(doc => doc._id);
  };

  const removedExternalHelperData = () => {
    const removedExternalHelperData = prevData.embedKnowledges.externalHelpers.filter(
      prevHelper =>
        !newData.embedKnowledges.externalHelpers.some(newHelper => newHelper.externalId === prevHelper.externalId)
    );
    return removedExternalHelperData.map(helper => helper._id);
  };

  const newAllowedDomainData = () => {
    const newAllowedDomainData = newData.publish.allowedOrigins.filter(
      newAllowedDomain =>
        !prevData.publish.allowedOrigins.some(prevAllowedDomain => prevAllowedDomain === newAllowedDomain)
    );
    return newAllowedDomainData.map(domain => domain.domain);
  };
  const removedAllowedDomainData = () => {
    const removedAllowedDomainData = prevData.publish.allowedOrigins.filter(
      prevAllowedDomain =>
        !newData.publish.allowedOrigins.some(newAllowedDomain => newAllowedDomain.domain === prevAllowedDomain.domain)
    );
    return removedAllowedDomainData.map(domain => domain.domain);
  };

  const isFaqDataAdded = newData.embedKnowledges.faq.some(
    faq => !prevData.embedKnowledges.faq.some(f => f._id === faq._id)
  );
  const isFaqDataRemoved = prevData.embedKnowledges.faq.some(
    faq => !newData.embedKnowledges.faq.some(f => f._id === faq._id)
  );

  const isWebDataAdded = newData.embedKnowledges.web.some(
    web => !prevData.embedKnowledges.web.some(w => w._id === web._id)
  );
  const isWebDataRemoved = prevData.embedKnowledges.web.some(
    web => !newData.embedKnowledges.web.some(w => w._id === web._id)
  );

  const isDocDataAdded =
    newData.embedKnowledges.doc.some(doc => !prevData.embedKnowledges.doc.some(d => d._id === doc._id)) ||
    newData.embedKnowledges.doc.some(doc => "isNewFolder" in doc);

  const isDocDataRemoved = prevData.embedKnowledges.doc.some(
    doc => !newData.embedKnowledges.doc.some(d => d._id === doc._id)
  );
  const isAllowedDomainDataAdded = newData.publish?.allowedOrigins?.some(
    allowedDomain => !prevData.publish.allowedOrigins.some(d => d.domain === allowedDomain.domain)
  );
  const isAllowedDomainDataRemoved = prevData.publish?.allowedOrigins?.some(
    allowedDomain => !newData.publish.allowedOrigins.some(d => d.domain === allowedDomain.domain)
  );

  const isExternalHelperRemoved = prevData.embedKnowledges.externalHelpers.some(
    helper => !newData.embedKnowledges.externalHelpers.some(h => h.externalId === helper.externalId)
  );

  const getOnboardingList = () => {
    return newData.inMemoryKnowledge.onboardingList.map(onboardingItem => {
      if (!onboardingItem.isNew) {
        return onboardingItem;
      }

      return {
        description: onboardingItem.description,
        content: onboardingItem.content,
      };
    });
  };

  const payload = {
    id: prevData._id,
    name: newData.name,
    company: newData.company,
    about: newData.inMemoryKnowledge.about,
    avatar: newData?.avatar,
    instruction: newData.inMemoryKnowledge.instruction,
    onboardingList: getOnboardingList(),
    creativity: +newData.temperature,
    greetingList: newData.definedActions?.greeting?.map(g => g.message),
    addFaqList: isFaqDataAdded ? newFaqData() : [],
    removeFaqList: isFaqDataRemoved ? removedFaqData() : [],
    embedFaqList: newData.embedKnowledges.faq || [],
    addWebList: isWebDataAdded ? newWebData() : [],
    removeWebList: isWebDataRemoved ? removedWebData() : [],
    refreshWebList: newData.embedKnowledges.web.filter(web => web.status === "refresh"),
    addDocList: isDocDataAdded ? newDocData() : [],
    removeDocList: isDocDataRemoved ? removedDocData() : [],
    removeExternalHelperList: isExternalHelperRemoved ? removedExternalHelperData() : [],
    llmModel: prevData.llmModel !== newData.llmModel ? newData.llmModel : undefined,
    addAllowedDomainList: isAllowedDomainDataAdded ? newAllowedDomainData() : [],
    removeAllowedDomainList: isAllowedDomainDataRemoved ? removedAllowedDomainData() : [],
    // pendingFile: null,
    loadJs: newData.loadJs,
    chatStyle: {
      variant: newData.publish.chatStyle.variant,
      color: newData.publish.chatStyle.color,
      image:
        newData.publish.chatStyle.image && !isString(newData.publish.chatStyle.image)
          ? await uploadBubbleAvatar(newData.publish.chatStyle.image)
          : prevData.publish.chatStyle.image,
    },
    isPublished: newData.publish.isPublished,
    isConfigured: newData.publish.isConfigured,
    customBranding: newData.publish.customBranding,
    buildinFunctions: newData.buildinFunctions ?? [],
  };
  return payload;
};

export const createAgentPayload = async (newData: FullAgent & { loadJs: boolean }, user: User | undefined) => {
  if (!user) {
    throw new Error("User is not defined");
  }

  try {
    const getOnboardingList = () => {
      return newData.inMemoryKnowledge.onboardingList.map(onboardingItem => ({
        description: onboardingItem.description,
        content: onboardingItem.content,
      }));
    };

    const payload = {
      creator: user,
      name: newData.name,
      company: newData.company,
      about: newData.inMemoryKnowledge.about,
      avatar: newData?.avatar,
      agentType: "humanlike",
      instruction: newData.inMemoryKnowledge.instruction,
      onboardingList: getOnboardingList(),
      creativity: +newData.temperature,
      greetingList: newData.definedActions?.greeting?.map(g => g.message),
      addFaqList: newData.embedKnowledges.faq,
      embedFaqList: [],
      addWebList: newData.embedKnowledges.web.map(web => web.source),
      addDocList: newData.embedKnowledges.doc,
      llmModel: newData.llmModel,
      addAllowedDomainList: newData.publish.allowedOrigins.map(domain => domain.domain),
      // pendingFile: null,
      loadJs: newData.loadJs,
      chatStyle: {
        variant: newData.publish.chatStyle.variant,
        color: newData.publish.chatStyle.color,
        image:
          newData.publish.chatStyle.image && !isString(newData.publish.chatStyle.image)
            ? await uploadBubbleAvatar(newData.publish.chatStyle.image)
            : "",
      },
      isPublished: newData.publish.isPublished,
      isConfigured: newData.publish.isConfigured,
      customBranding: newData.publish.customBranding,
      buildinFunctions: newData.buildinFunctions ?? [],
    };

    return payload;
  } catch (error) {
    console.log("error", error);
  }
};
