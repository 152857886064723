import { useGetWorkspaceUsers } from "@/data/queries/workspace/useGetWorkspaceUsers";
import { TeamMembers } from "./TeamMembers";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import type { TeamStepChildrenProps } from "./Team";

export const TeamDashboard = ({ setActiveStep }: TeamStepChildrenProps) => {
  const { data: workspaceDetails } = useGetWorkspaceDetails();
  const { data: members, isPending } = useGetWorkspaceUsers();
  const workspaceMembersInfo = workspaceDetails?.isAdmin && workspaceDetails?.customer?.subscription.users;
  return (
    <>
      <div className="mb-5 flex items-center justify-between border-b-[1.5px] pb-5 sm:pr-12">
        <div className="flex items-center gap-x-4">
          <div className="text-lg font-bold">Team</div>
          {workspaceMembersInfo && (
            <div className="flex gap-1">
              <span className="text-sm font-medium text-primary-black">
                {(workspaceDetails.customer.subscription.users?.currentUsage ?? 0) +
                  workspaceDetails.customer.administrators.length}{" "}
                {(workspaceDetails.customer.subscription.users?.currentUsage ?? 0) +
                  workspaceDetails.customer.administrators.length ===
                1
                  ? "member"
                  : "members"}
              </span>
            </div>
          )}
        </div>
        <Button className="gap-2" onClick={() => setActiveStep("inviteTeamMember")}>
          <Icons.Plus className="size-4" />
          <span className="sr-only sm:not-sr-only">Invite member</span>
        </Button>
      </div>

      {isPending && <LoadingSpinner className="flex size-full items-center justify-center" />}
      {Array.isArray(members) && members.length > 0 && <TeamMembers members={members} />}
    </>
  );
};
