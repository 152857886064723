import { DialogClose, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { Avatar } from "@/components/Avatar";
import { PublishNewAgent } from "@/pages/Community/components/PublishNewAgent";
import { useCreateNewAgent } from "@/hooks/useCreateNewAgent";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { calculateTimeDifference } from "@/utils/calculateTimeDifference";
import { useState } from "react";
import { useGetNotPublishedAgents } from "@/data/queries/useGetNotPublishedAgents";
import type { Step } from "../types/PublishAgentDialogSteps";
import type { F1 } from "@/types/types";

type PublishAgentDialogChooseProps = {
  selectedAgentId: string | null;
  setSelectedAgentId: F1<string | null>;
  setPublishStep: F1<Step>;
};

export const PublishAgentDialogChoose = ({
  selectedAgentId,
  setSelectedAgentId,
  setPublishStep,
}: PublishAgentDialogChooseProps) => {
  const { data: agents, isPending } = useGetNotPublishedAgents();
  const { createNewAgentNavigation } = useCreateNewAgent();
  const [searchInput, setSearchInput] = useState("");

  const filteredAgents = agents?.filter(agent => agent.name.toLowerCase().includes(searchInput.toLowerCase()));
  const agentsSortedByCreatedAt = filteredAgents?.sort((a, b) => {
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <DialogDescription className="flex flex-col gap-2 pb-[68px]">
        <h4>Choose or create a bot to publish in Agentx Community!</h4>
        <div className="relative">
          <Input
            autoFocus
            inputClassName="pl-9 sm:pl-9"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
          >
            <Icons.Search className="absolute left-3 top-1/2 -translate-y-1/2" />
          </Input>
        </div>

        {isPending ? (
          <LoadingSpinner className="mt-16 flex w-full justify-center" />
        ) : (
          <div className="grid size-full grid-cols-[repeat(auto-fill,minmax(230px,1fr))] content-start gap-4 overflow-y-scroll pt-2">
            {agentsSortedByCreatedAt?.map(agent => (
              <div
                onClick={() => setSelectedAgentId(agent._id)}
                key={agent._id}
                className={`grid h-24 cursor-pointer grid-cols-[40px_1fr] items-center justify-center gap-5 rounded-lg border border-primary-50 bg-primary-50 p-3 md:h-[120px] ${
                  selectedAgentId === agent._id && "!border-primary-500 !bg-primary-100"
                }`}
              >
                <Avatar src={agent?.avatar} />
                <div className="flex flex-col gap-1 truncate">
                  <div className="text-sm font-semibold">{agent.name}</div>
                  <div className="text-xs font-medium text-neutral-500">
                    Born: {calculateTimeDifference(agent.createdAt)}
                  </div>
                </div>
              </div>
            ))}
            <PublishNewAgent
              onClick={createNewAgentNavigation}
              text="Create a New Agent"
              className="h-[120px] border-dashed border-primary-400 bg-white"
            />
          </div>
        )}
      </DialogDescription>

      <DialogFooter>
        <DialogClose asChild>
          <Button variant="tertiary" size="md">
            Cancel
          </Button>
        </DialogClose>
        <Button size="md" className="flex gap-1" onClick={() => setPublishStep("details")} disabled={!selectedAgentId}>
          <Icons.Upload />
          Publish
        </Button>
      </DialogFooter>
    </>
  );
};
