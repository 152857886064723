import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { loaderArray } from "@/utils/loaderArray";

const UpgradeBlurBanner = ({
  currentUsageDescription,
  upgradeDescription,
  onUpgradeClick,
}: {
  currentUsageDescription?: string | null;
  upgradeDescription: string;
  onUpgradeClick: () => void;
}) => {
  const itemsNum = 3;

  return (
    <div className="relative">
      {loaderArray(itemsNum).map((_, index) => (
        <div
          key={index}
          className="flex w-full items-center justify-between gap-2 rounded-md border border-slate-200 bg-white p-2 hover:bg-primary-50"
        >
          <div className="flex flex-col gap-1 py-2">
            <p className="ml-2 break-all text-xs font-semibold text-neutral-800">Example tool name</p>
          </div>
          <div className="flex gap-2">
            <Button variant="ghost" type="button" size="action-icon">
              <Icons.Edit2 className="text-neutral-400" />
            </Button>
            <Button variant="ghost" type="button" size="action-icon">
              <Icons.Trash className="text-neutral-400" />
            </Button>
          </div>
        </div>
      ))}

      <div className="text-netrual-750 absolute left-0 top-0 flex h-full w-full items-center justify-center text-base font-bold backdrop-blur-[5px]">
        <div className="p-8 text-center">
          {currentUsageDescription && <p className=" font-bold text-primary-500">{currentUsageDescription}</p>}
          <Button
            onClick={onUpgradeClick}
            variant="ghost"
            size="custom"
            className="mr-[3px] p-0 font-bold text-purple-400"
          >
            Upgrade
          </Button>{" "}
          {upgradeDescription}
        </div>
      </div>
    </div>
  );
};

export default UpgradeBlurBanner;
