import { useCallback, useEffect, useRef, useState } from "react";

export const useResizableWidth = ({
  initialWidth,
  minWidth,
  maxWidth,
}: {
  initialWidth: number;
  minWidth: number;
  maxWidth: number;
}) => {
  const windowInnerWidth = window.innerWidth;
  const windowAdjustedMinWidth = Math.min(windowInnerWidth, minWidth);
  const windowAdjustedMaxWidth = Math.min(windowInnerWidth, maxWidth);
  const windowAdjustedInitialWidth = Math.min(windowInnerWidth, initialWidth);

  const [width, setWidth] = useState<number>(windowAdjustedInitialWidth);
  const initialClientX = useRef<number>(0);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const currentClientX = e.clientX;
      const deltaClientX = initialClientX.current - currentClientX;

      setWidth(prevWidth => {
        let newWidth = prevWidth + deltaClientX;
        newWidth = Math.min(windowAdjustedMaxWidth, Math.max(windowAdjustedMinWidth, newWidth));
        initialClientX.current = currentClientX; // Update after calculation to avoid jumpiness
        return newWidth;
      });
    },
    [windowAdjustedMinWidth, windowAdjustedMaxWidth]
  );

  const handleMouseUp = useCallback(() => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    document.body.style.pointerEvents = "";
  }, [handleMouseMove]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    initialClientX.current = e.clientX;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.body.style.pointerEvents = "none";
  };

  useEffect(() => {
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.body.style.pointerEvents = "";
    };
  }, [handleMouseMove, handleMouseUp]);

  return { width, handleMouseDown };
};

export default useResizableWidth;
