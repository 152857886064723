export const tailwindScreens = {
  xs: "512px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
  "3xl": "1920px",
  "4xl": "2160px",
  "5xl": "2560px",
  "6xl": "3072px",
  "7xl": "3840px",
} as const;
