import type { ButtonProps } from "@/components/ui/button";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import type { UseAudioRecorder } from "../hooks/useAudioRecorder";

type VoiceMessageButtonProps = {
  audioRecorder: UseAudioRecorder;
  submitSendMessage: (messageText: string, audio?: { blob: Blob; duration: number }) => Promise<void>;
} & ButtonProps;

export const VoiceMessageButton = ({ audioRecorder, submitSendMessage, ...props }: VoiceMessageButtonProps) => {
  const { startRecording, isRecording, stopRecording } = audioRecorder;

  const handleStopRecording = async () => {
    const audioBlob = await stopRecording();
    if (audioBlob) {
      void submitSendMessage("", audioBlob);
    }
  };
  return isRecording ? (
    <Button
      className="min-w-10 bg-success"
      type="button"
      size="icon"
      onClick={() => !props.disabled && handleStopRecording()}
      {...props}
    >
      <Icons.ArrowUp className="size-6 min-w-6" />
      <span className="sr-only">Send your voice message</span>
    </Button>
  ) : (
    <Button
      className="min-w-10"
      type="button"
      size="icon"
      onClick={() => !props.disabled && startRecording()}
      {...props}
    >
      <Icons.Microphone className="size-6 min-w-6" />
      <span className="sr-only">Record a voice message</span>
    </Button>
  );
};
