import type { ChatAgent } from "@/types/conversation";
import { useQueryClient } from "@tanstack/react-query";
import type { ChatHistoryWithAgents } from "../queries/useGetChatHistoryWithAgents";
import { chatHistoryWithAgentsKeys } from "../queries/useGetChatHistoryWithAgents";
import { NAV_HISTORY_LIMIT } from "@/constants/navHistoryLimit";
import { useCallback } from "react";

export const useUpdateNavConversationHistory = () => {
  const queryClient = useQueryClient();

  const updateNavConversationHistory = useCallback(
    ({ agents }: { agents: ChatAgent[] }) => {
      queryClient.setQueryData<ChatHistoryWithAgents[]>(chatHistoryWithAgentsKeys.limited(NAV_HISTORY_LIMIT), prev => {
        if (!prev) {
          return prev;
        }

        const foundAgentHistoryItem = prev.find(
          item =>
            item.agents
              .map(agent => agent._id)
              .sort()
              .join(",") ===
            agents
              .map(agent => agent._id)
              .sort()
              .join(",")
        );

        if (!foundAgentHistoryItem) {
          return [
            {
              agents: agents.map(agent => ({
                avatar: agent?.avatar,
                name: agent.name,
                _id: agent._id,
                publishedToCommunity: agent.publishedToCommunity,
              })),
              conversationsCount: 1,
            },
            ...prev.slice(0, NAV_HISTORY_LIMIT - 1),
          ];
        }
      });
    },
    [queryClient]
  );

  return { updateNavConversationHistory };
};
