import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "@/data/initAxios";
import { userMessageBookmarksKeys } from "../../queries/useGetUserMessageBookmarks";

type Props = {
  messageId: string;
  agentId: string;
  isPinned: boolean;
};

const updateBookmarkPin = async ({ messageId, agentId, isPinned }: Props) => {
  const { data } = await restClient.put<unknown>(apiPaths.updateUserMessageBookmarkPin({ messageId, agentId }), {
    isPinned,
  });
  return data;
};

export const useUpdateUserMessageBookmarkPin = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, AxiosError<{ message: string }>, Props>({
    mutationFn: updateBookmarkPin,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: userMessageBookmarksKeys.all });
    },
  });

  return mutation;
};
