import { useContext } from "react";
import { BookmarkChatHistoryContext } from "./BookmarkChatHistoryContext";

export const useBookmarkChatHistoryContext = () => {
  const context = useContext(BookmarkChatHistoryContext);

  if (context) {
    return context;
  }

  throw new Error("Use this hook in BookmarkChatHistoryContext scope");
};
