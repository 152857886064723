import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogFooter, DialogClose } from "@/components/ui/dialog";
import type { FullAgent } from "@/types/agent";
import { useRef, useState } from "react";
import { Controller, type UseFormReturn } from "react-hook-form";
import {
  FixedCropper,
  ImageRestriction,
  CropperPreview,
  type FixedCropperRef,
  type CropperState,
  type CropperImage,
  type CropperTransitions,
} from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { LoadingSpinner } from "@/components/ui/loading-spinner";

const IMAGE_WIDTH = 240;
const IMAGE_HEIGHT = 240;

interface PreviewState {
  state: CropperState | null;
  image: CropperImage | null;
  transitions: CropperTransitions | null;
}

interface CropPhotoProps {
  form: UseFormReturn<FullAgent["publish"]>;
  image: {
    src: string;
    name: string;
  };
  onOpenChange: (isOpen: boolean) => void;
}

export const CropPhotoDialog = ({ form, image, onOpenChange }: CropPhotoProps) => {
  const { control } = form;
  const cropperRef = useRef<FixedCropperRef>(null);
  const [previewState, setPreviewState] = useState<PreviewState>({
    state: null,
    image: null,
    transitions: null,
  });

  const onCropUpdate = (cropper: FixedCropperRef) => {
    setPreviewState({
      state: cropper.getState(),
      image: cropper.getImage(),
      transitions: cropper.getTransitions(),
    });
  };

  const onSave = (onChange: (blob: Blob) => void) => {
    const canvas = cropperRef.current?.getCanvas({
      width: IMAGE_WIDTH,
      height: IMAGE_HEIGHT,
    });
    if (canvas) {
      canvas.toBlob(blob => {
        if (blob) {
          const file = new File([blob], image.name, { type: "image/jpeg" });
          if (file) {
            onChange(file);
          }
        }
      }, "image/jpeg");
    }
  };

  return (
    <Dialog open={!!image.src} onOpenChange={onOpenChange}>
      <Controller
        control={control}
        name="chatStyle.image"
        render={({ field: { onChange } }) => (
          <DialogContent className="h-[582px]" variant="small">
            <DialogHeader>Crop your photo</DialogHeader>
            <div className="flex flex-col items-center px-6">
              <div className="relative mb-6 h-[300px] w-[300px]">
                {image.src ? (
                  <FixedCropper
                    ref={cropperRef}
                    src={image.src}
                    stencilProps={{
                      handlers: true,
                      lines: true,
                      movable: false,
                      resizable: true,
                    }}
                    stencilSize={{
                      width: IMAGE_WIDTH,
                      height: IMAGE_HEIGHT,
                    }}
                    imageRestriction={ImageRestriction.stencil}
                    onReady={onCropUpdate}
                    onUpdate={onCropUpdate}
                    style={{
                      background: "#FFF",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                ) : (
                  <LoadingSpinner />
                )}
              </div>
              <p className="mb-2 text-sm text-gray-400">Preview</p>
              <div className="h-[80px] w-[80px] overflow-hidden rounded-l-full rounded-tr-full">
                {previewState?.image ? (
                  <CropperPreview className="preview" {...previewState} style={{ width: "100%", height: "100%" }} />
                ) : (
                  <LoadingSpinner />
                )}
              </div>
            </div>
            <DialogFooter className="px-4">
              <DialogClose asChild>
                <Button size="md" variant="tertiary">
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button size="md" onClick={() => onSave(onChange)}>
                  Save
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        )}
      />
    </Dialog>
  );
};
