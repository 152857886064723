import { Icons } from "../ui/icons";
import { AgentsHistoryItem } from "./AgentsHistoryItem";

const ChatHistoryNavBtn = ({ setIsOpen }: { setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  return (
    <AgentsHistoryItem
      onClick={() => setIsOpen(prev => !prev)}
      className="grid-cols-[16px_1fr] rounded-full bg-primary-500 px-3"
      targetWidth={120}
    >
      <Icons.ChatHistory className="text-white" />
      <div className="hidden whitespace-nowrap pl-1 text-xs text-white lg:block">Chat history</div>
    </AgentsHistoryItem>
  );
};

export default ChatHistoryNavBtn;
