import { useLocation, useNavigate } from "react-router-dom";
import type { AgentFormTab } from "../types/AgentFormTypes";
import { AgentFormTabs, agentTabs } from "../types/AgentFormTypes";
import { useCallback, useEffect } from "react";

export const useAgentFormNavigationTab = () => {
  const location = useLocation() as { state: { tab: AgentFormTab | null } | null; pathname: string; hash: string };
  const navigate = useNavigate();

  const activeTab = location.state?.tab ?? AgentFormTabs.GENERAL_INFO;
  const currentTabIndex = agentTabs.findIndex(tab => tab.name === activeTab.name);

  const isFirstTab = currentTabIndex === 0;
  const isLastTab = currentTabIndex === agentTabs.length - 1;

  const switchTab = useCallback(
    ({ newTab }: { newTab: AgentFormTab }) => {
      navigate(location.pathname, { replace: true, state: { tab: newTab } });
    },
    [location.pathname, navigate]
  );

  useEffect(() => {
    const scrollToElement = () => {
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    // Delay scrolling to ensure nested components are rendered
    const timeoutId = setTimeout(scrollToElement, 100);

    return () => clearTimeout(timeoutId);
  }, [location]);

  return { activeTab, currentTabIndex, isFirstTab, isLastTab, switchTab };
};
