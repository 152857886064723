import { useGetUserMessageBookmarks } from "@/data/queries/useGetUserMessageBookmarks";
import { useMemo, useState } from "react";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import SingleBookmark from "./SingleBookmark";
import BookmarkPinnedList from "./BookmarkPinnedList";
import { groupBookmarksByPin } from "../../../pages/Community/utils/groupBookmarksByPin";
import { filterBookmarkGroupsBySearchInput } from "../../../pages/Community/utils/filterBookmarkGroupsBySearchInput";
import BookmarkChatHistoryWrapper from "./BookmarkChatHistoryWrapper";
import BookmarkHistoryAgentTileWrapper from "./BookmarkHistoryAgentTileWrapper";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";

interface BookmarkListProps {
  onClosePanel?: () => void;
}

export const BookmarkList = ({ onClosePanel }: BookmarkListProps) => {
  const [searchInput, setSearchInput] = useState("");
  const { data: bookmarkGroups, isLoading } = useGetUserMessageBookmarks();
  const { conversationAgentIds } = useChatContext();

  const bookmarks = useMemo(() => groupBookmarksByPin(bookmarkGroups ?? []), [bookmarkGroups]);

  const pinnedFiltered = useMemo(
    () => filterBookmarkGroupsBySearchInput({ bookmarkGroups: bookmarks.pinned, input: searchInput }),
    [bookmarks.pinned, searchInput]
  );
  const unpinnedFiltered = useMemo(
    () => filterBookmarkGroupsBySearchInput({ bookmarkGroups: bookmarks.unpinned, input: searchInput }),
    [bookmarks.unpinned, searchInput]
  );

  if (isLoading) {
    return <LoadingSpinner className="flex items-center justify-center pt-12" />;
  }

  if (!bookmarkGroups?.length) {
    return <p className="p-9 text-sm text-white">You have no bookmarks yet.</p>;
  }

  return (
    <BookmarkChatHistoryWrapper
      searchInput={searchInput}
      onSearchInputChange={setSearchInput}
      inputPlaceholder="Search thread"
    >
      {!pinnedFiltered.length && !unpinnedFiltered.length && <p className="text-sm text-white">No results found.</p>}
      {!!pinnedFiltered.length && <BookmarkPinnedList bookmarkGroups={pinnedFiltered} />}
      {!!unpinnedFiltered.length && (
        <div className="flex flex-col gap-6">
          {unpinnedFiltered?.map(bookmarkGroup => {
            return (
              <BookmarkHistoryAgentTileWrapper
                key={`${bookmarkGroup.agent._id}-${conversationAgentIds.join("-")}`}
                agents={[bookmarkGroup.agent]}
                threadsCount={bookmarkGroup.messages.length}
                label="bookmarks"
                defaultItemIds={conversationAgentIds}
              >
                {bookmarkGroup.messages.map(bookmarkMessage => {
                  return (
                    <SingleBookmark
                      key={bookmarkMessage._id}
                      bookmarkMessage={bookmarkMessage}
                      agentName={bookmarkGroup.agent.name}
                      agentId={bookmarkGroup.agent._id}
                      onClosePanel={onClosePanel}
                    />
                  );
                })}
              </BookmarkHistoryAgentTileWrapper>
            );
          })}
        </div>
      )}
    </BookmarkChatHistoryWrapper>
  );
};
