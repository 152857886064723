import { useQuery } from "@tanstack/react-query";
import { restClient } from "../initAxios";
import { apiPaths } from "../apiPaths";
import type { Tool } from "@/types/tools";

export const userToolsKeys = {
  all: ["userTools"],
};

const getUserTools = async () => {
  const { data } = await restClient.get<Tool[]>(apiPaths.getUserTools);
  return data;
};

export const useGetUserTools = () => {
  const query = useQuery({
    queryKey: userToolsKeys.all,
    queryFn: getUserTools,
  });

  return query;
};
