import { cn } from "@/lib/utils";
import { ExternalAgentStatus } from "@/types/agent";

export const IntegrationStatus = ({ status }: { status: ExternalAgentStatus | undefined }) => {
  return (
    <span className="flex items-center gap-1 text-xs font-medium capitalize">
      <span
        className={cn("inline-block size-2.5 rounded-full", {
          "bg-success": status === ExternalAgentStatus.ONLINE,
          "bg-error": !status || status === ExternalAgentStatus.OFFLINE || status === ExternalAgentStatus.ERROR,
          "bg-orange-400": status === ExternalAgentStatus.INITIALIZED || status === ExternalAgentStatus.PAUSED,
        })}
      ></span>
      {status || "Offline"}
    </span>
  );
};
