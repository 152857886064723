import { Button } from "@/components/ui/button";
import { DialogHeader } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { StripePaymentDetails } from "./components/StripePaymentDetails";
import { StripePaymentForm } from "./components/StripePaymentForm";
import type { CreateSubscriptionPlanBody } from "@/data/mutations/subscriptions/useCreateSubscriptionPlan";
import { useCreateSubscriptionPlan } from "@/data/mutations/subscriptions/useCreateSubscriptionPlan";
import { domElementIds } from "@/types/dom-element-ids";
import { createPaymentMethodMutationKeys } from "@/data/mutations/subscriptions/useCreatePaymentMethod";
import { useState } from "react";
import { useGetTotalUsingCoupon } from "@/data/queries/subscriptions/useGetTotalUsingCoupon";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { useIsMutating } from "@tanstack/react-query";

export type OnSubmitStripePlan = ({
  paymentMethodId,
  customerDetails,
}: {
  paymentMethodId: CreateSubscriptionPlanBody["paymentMethodId"];
  customerDetails: CreateSubscriptionPlanBody["customerDetails"];
}) => void;

export const StripePaymentSummary = () => {
  const { setActiveStep, selectedPlan } = useSubscriptionTierDialogContext();
  const { mutate: createSubscriptionPlan, isPending: isSubscriptionPlanCreating } = useCreateSubscriptionPlan();
  const isPaymentMethodCreating = !!useIsMutating({ mutationKey: createPaymentMethodMutationKeys.all });
  const [coupon, setCoupon] = useState<string | undefined>("");

  const selectedPlanId = selectedPlan?.plan.price[selectedPlan.period].planId;
  const couponQuery = useGetTotalUsingCoupon({
    planId: selectedPlanId ?? "",
    coupon: coupon ?? "",
  });

  if (!selectedPlan) {
    return null;
  }

  const onSubmit: OnSubmitStripePlan = ({ paymentMethodId, customerDetails }) => {
    const { planId } = selectedPlan.plan.price[selectedPlan.period];
    createSubscriptionPlan(
      {
        planId,
        paymentMethodId,
        customerDetails,
        coupon: couponQuery.data?.coupon,
      },
      {
        onSuccess: () => {
          setActiveStep("stripePaymentSuccess");
        },
      }
    );
  };
  return (
    <>
      <DialogHeader className="min-h-12">
        <Button size="custom" variant="ghost" onClick={() => setActiveStep("subscriptionTiers")}>
          <Icons.Arrow className="size-6" />
          <span className="sr-only">go back to plans</span>
        </Button>
      </DialogHeader>
      <div className="overflow-auto px-8 pb-6">
        <Icons.AgentLogo className="my-2.5 h-8 w-20" />
        <div className="grid items-start gap-6 lg:grid-cols-2">
          <StripePaymentDetails couponQuery={couponQuery} setCoupon={setCoupon} />
          <div>
            <StripePaymentForm onSubmit={onSubmit} />
            <Button
              loading={isPaymentMethodCreating || isSubscriptionPlanCreating}
              className="mt-5 w-full"
              form={domElementIds.STRIPE_FORM}
              type="submit"
            >
              Confirm Subscription
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
