import type { Tool } from "@/types/tools";
import Editor from "@monaco-editor/react";
import { Controller, type UseFormReturn } from "react-hook-form";

type CodeEditorProps = {
  form: UseFormReturn<Tool>;
};

export const CodeEditor = ({ form }: CodeEditorProps) => {
  return (
    <div className="min-h-[600px] overflow-hidden rounded-md border border-neutral-300">
      <div className="w-full border-b border-neutral-300 p-3 text-sm font-medium">Code editor</div>
      <Controller
        control={form.control}
        name="toolFunction.code"
        render={({ field: { value, onChange } }) => (
          <Editor height="50vh" defaultLanguage="python" defaultValue={value} onChange={onChange} />
        )}
      />
    </div>
  );
};
