import { format } from "date-fns";

import { useGetUser } from "@/data/queries/useGetUser";
import { calculateTimeDifference } from "@/utils/calculateTimeDifference";
import { useGetConversationMessages } from "@/data/queries/useGetConversationMessages";

import type { ConversationWithUsersDetails } from "@/types/conversation";

type Props = {
  conversationItem: {
    conversation: ConversationWithUsersDetails;
    searchMatches: number;
  };
  conversationUsers: {
    _id: string;
    name: string;
    avatar: string;
  }[];
  onOpen: () => void;
};

export const ChatHistoryDialogListItem = ({ conversationItem, conversationUsers, onOpen }: Props) => {
  const { user } = useGetUser();
  const {
    data: conversationData,
    isLoading,
    isPending,
  } = useGetConversationMessages({
    conversationId: conversationItem.conversation._id,
  });

  const conversationUser = conversationData?.messages[conversationData.messages.length - 1]?.user;
  const isUserMe = user?._id === conversationUser;

  const getUserName = () => {
    if (conversationUser) {
      return conversationUsers.find(user => user._id === conversationUser)?.name ?? "";
    }
    return isUserMe ? "Me" : "No info";
  };

  return (
    <div
      className="mr-2 max-h-[4.5rem] min-h-[4.5rem] cursor-pointer rounded border border-solid border-cloud-dark px-3 pt-2 text-xs text-gray-800 hover:bg-cloud-light"
      onClick={() => onOpen()}
    >
      <div className="flex-1 truncate whitespace-nowrap rounded border border-solid border-primary-300 bg-primary-50 p-1 px-3 text-xs">
        {isPending || !conversationData?.messages
          ? "Loading..."
          : conversationData.messages[conversationData.messages.length - 1]?.text.slice(0, 150)}
      </div>
      <div className="mx-1 my-2 flex flex-1 gap-4">
        {isLoading && isPending ? (
          "Loading..."
        ) : (
          <>
            <p className="text-left text-sm font-semibold">
              {format(conversationItem.conversation.updatedAt, "dd MMM yyyy HH:mm")}
            </p>
            <div className="text-sm text-gray-500">User: {getUserName()}</div>
            <p className="ml-auto text-xs font-medium text-neutral-400">
              {calculateTimeDifference(conversationItem.conversation.updatedAt)}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
