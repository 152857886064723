import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import type { ReactNode } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Icons } from "./ui/icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";

type HybridTooltipPopoverProps = {
  triggerClassName?: string;
  heading?: string;
  children: ReactNode;
  triggerIcon?: ReactNode;
};

export const HybridTooltipPopover = ({
  heading,
  triggerClassName,
  children,
  triggerIcon,
}: HybridTooltipPopoverProps) => {
  const { isTouch } = useMediaQueriesContext();

  const Components = isTouch
    ? { Wrapper: Popover, Content: PopoverContent, Trigger: PopoverTrigger }
    : { Wrapper: Tooltip, Content: TooltipContent, Trigger: TooltipTrigger };

  return (
    <Components.Wrapper>
      <Components.Trigger className={triggerClassName || ""}>
        {triggerIcon ?? <Icons.Info className="text-primary-400" />}
      </Components.Trigger>
      <Components.Content>
        {heading ? <h4 className="font-semibold">{heading}</h4> : null}
        {children}
      </Components.Content>
    </Components.Wrapper>
  );
};
