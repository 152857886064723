import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import type { F0 } from "@/types/types";

type PublishNewAgentProps = {
  onClick: F0;
  text: string;
  className?: string;
};

export const PublishNewAgent = ({ onClick, text, className }: PublishNewAgentProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        "flex min-h-44 w-full cursor-pointer flex-col items-center justify-center gap-3 rounded-lg border border-neutral-200 bg-neutral-100 4xl:w-auto 4xl:max-w-[260px] 4xl:basis-full",
        className
      )}
    >
      <div className="flex size-10 items-center justify-center rounded-md bg-neutral-200">
        <Icons.Plus className="p-0.5" />
      </div>
      <div className="text-sm font-medium">{text}</div>
    </div>
  );
};
