import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import type { ConversationWithAgentsDetails } from "@/data/queries/useGetSingleConversationDetails";
import { singleConversationDetailsKeys } from "@/data/queries/useGetSingleConversationDetails";
import { useQueryClient } from "@tanstack/react-query";

export const useSetConversationQueryKnowledge = () => {
  const queryClient = useQueryClient();
  const { conversationId } = useChatContext();
  const setKnowledge = (
    updaterOrValue:
      | ((prevSelectedKnowledge: string[]) => ConversationWithAgentsDetails["pins"])
      | ConversationWithAgentsDetails["pins"]
  ) => {
    if (!conversationId) {
      return;
    }
    queryClient.setQueryData<ConversationWithAgentsDetails>(singleConversationDetailsKeys.id(conversationId), prev => {
      if (!prev) {
        return prev;
      }
      const pins = Array.isArray(prev.pins) ? [...prev.pins] : [];
      const updatedSelectedKnowledge = typeof updaterOrValue === "function" ? updaterOrValue(pins) : updaterOrValue;

      return {
        ...prev,
        pins: updatedSelectedKnowledge,
      };
    });
  };

  return { setKnowledge };
};
