import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { AgentTile } from "../../../components/agentTiles/AgentTile/AgentTile";
import { useGetLikedCommunityAgents } from "@/data/queries/useGetLikedCommunityAgents";
import { PublishNewAgent } from "./PublishNewAgent";
import { useState } from "react";
import { PublishAgentDialog } from "../../../components/dialogs/PublishAgentDialog/PublishAgentDialog";
import { useGetUser } from "@/data/queries/useGetUser";
import { useParams } from "react-router-dom";
import { useGetCommunityUser } from "@/data/queries/useGetCommunityUser";
import { useGetUserPublishedAgents } from "@/data/queries/useGetUserPublishedAgents";

export const CommunityProfileAgents = () => {
  const { profileId } = useParams<{ profileId: string }>();
  const { user } = useGetUser();
  const isMyProfile = user?._id === profileId;
  const { data: agents, isPending: isPendingUserAgents } = useGetUserPublishedAgents({ userId: profileId });
  const { data: likedAgents, isPending: isPendingLikedAgents } = useGetLikedCommunityAgents({ enabled: isMyProfile });
  const { data: communityUser } = useGetCommunityUser({ userId: profileId || "" });
  const [isPostAgentDialogOpen, setIsPostAgentDialogOpen] = useState(false);

  const isPending = isMyProfile ? isPendingUserAgents || isPendingLikedAgents : isPendingUserAgents;

  const MyLikedAgents = () => (
    <>
      <h4 className="py-6">My Likes</h4>
      <div className="grid gap-6 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:flex 4xl:flex-wrap">
        {likedAgents?.map(agent => <AgentTile key={agent._id} agent={agent} displayLikeOptions />)}
      </div>

      {isPostAgentDialogOpen && (
        <PublishAgentDialog isDialogOpen={isPostAgentDialogOpen} setShowDialog={setIsPostAgentDialogOpen} />
      )}
    </>
  );

  const MyAgents = () => {
    return (
      <div className="grid gap-6 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:flex 4xl:flex-wrap">
        {isMyProfile && <PublishNewAgent onClick={() => setIsPostAgentDialogOpen(true)} text="Publish a New Agent" />}
        {agents?.map(agent => <AgentTile key={agent._id} agent={agent} displayEditOptions={isMyProfile} />)}
      </div>
    );
  };

  return (
    <div className="flex w-full flex-col p-5 pt-6 sm:p-10 lg:overflow-y-scroll lg:p-12 lg:pt-6">
      {isMyProfile && <h4 className="py-6">My publication</h4>}
      {!isMyProfile && communityUser && <h4 className="py-6">{`${communityUser?.name}'s publication`}</h4>}

      {isPending ? (
        <LoadingSpinner className="my-12 flex w-full items-center justify-center" />
      ) : (
        <>
          <MyAgents />
          {isMyProfile && <MyLikedAgents />}
        </>
      )}
    </div>
  );
};
