import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { CommunityAgent, CommunityCategory } from "@/types/community";
import type { RelationResponse } from "@/types/relation";
import { notPublishedAgentsKeys } from "@/data/queries/useGetNotPublishedAgents";
import { communityAgentsKeys } from "@/data/queries/useGetCommunityAgents";
import { singleCommunityAgentKeys } from "../queries/useGetSingleCommunityAgent";
import { likedCommunityAgentsKeys } from "../queries/useGetLikedCommunityAgents";
import { userPublishedAgentsKeys } from "@/data/queries/useGetUserPublishedAgents";

type Payload = {
  agentId: CommunityAgent["_id"];
  status: true;
  categories: CommunityCategory["_id"][];
  description: string;
};

const updatePublishedCommunityAgent = async (agentData: Payload) => {
  const { data } = await restClient.put<RelationResponse>(
    apiPaths.editPublishedCommunityAgent(agentData.agentId),
    agentData
  );
  return data;
};

export const useUpdatePublishedCommunityAgent = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<RelationResponse, AxiosError<{ message: string }>, Payload>({
    mutationFn: updatePublishedCommunityAgent,
    onSuccess: (_, { agentId }) => {
      void queryClient.invalidateQueries({ queryKey: notPublishedAgentsKeys.all });
      void queryClient.refetchQueries({ queryKey: communityAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: singleCommunityAgentKeys.id(agentId) });
      void queryClient.invalidateQueries({ queryKey: communityAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: userPublishedAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: likedCommunityAgentsKeys.all });
    },
  });

  return mutation;
};
