import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { Agent } from "@/types/agent";
import { userToolsKeys } from "@/data/queries/useGetUserTools";
import { agentToolsKeys } from "@/data/queries/useGetAgentTools";
import { toast } from "react-toastify";

type Props = {
  toolId: string;
  agentId: Agent["_id"];
};

const unassignToolFromAgent = async (props: Props) => {
  const { data } = await restClient.post<string>(apiPaths.unassignToolFromAgent(props.agentId, props.toolId), props);
  return data;
};

export const useUnassignToolFromAgent = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<string, AxiosError<{ message: string }>, Props>({
    mutationFn: unassignToolFromAgent,
    onSuccess: () => {
      toast.success("Tool unassigned successfully");
      void queryClient.invalidateQueries({ queryKey: userToolsKeys.all });
      void queryClient.invalidateQueries({ queryKey: agentToolsKeys.all });
    },
  });

  return mutation;
};
