import type { ReactElement } from "react";
import type { Usage } from "@/types/subscriptions";

type AccountActivitiesProgressBarProps = {
  usage: Usage;
  children?: ReactElement;
};

export const AccountActivitiesProgressBar = ({ usage, children }: AccountActivitiesProgressBarProps) => {
  const percentage = (usage.currentUsage / Math.abs(usage.limit)) * 100;
  const strokeWidth = 3;
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="relative size-16 -rotate-90">
      <svg className="absolute" viewBox="0 0 100 100">
        <circle
          className="fill-transparent stroke-current text-gray-300"
          cx="50%"
          cy="50%"
          r={radius - strokeWidth / 2}
          strokeWidth={strokeWidth}
        />
        <circle
          className="fill-transparent stroke-current text-primary-500"
          cx="50%"
          cy="50%"
          r={radius - strokeWidth / 2}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
        />
      </svg>
      <div className="absolute flex size-full rotate-90 items-center justify-center">{children}</div>
    </div>
  );
};
