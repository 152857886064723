type WorkspaceWhatsNewArticleCardProps = {
  title: string;
  img: string;
  description: string;
  url: string;
};

export const WorkspaceWhatsNewArticleCard = ({ title, img, description, url }: WorkspaceWhatsNewArticleCardProps) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <div className="mb-1 overflow-y-hidden rounded-md border border-neutral-300 pb-4">
        <div className="bg-neutral-100 px-4 pb-2 pt-4">
          <h6 className="mb-2 text-base font-bold text-neutral-750">{title}</h6>
        </div>

        <img src={img} alt="colorful shapes" className="h-[250px] w-full object-cover" />

        <div className="mt-4 px-4">
          <p className="line-clamp-3  text-xs font-medium text-neutral-750">{description}</p>
        </div>
      </div>
    </a>
  );
};
