import { useIsMobileMediaQuery, useTouchMediaQuery } from "@/hooks/useMediaQueries";
import { MediaQueriesContext } from "@/contexts/MediaQueriesContext/MediaQueriesContext";
import type { PropsWithChildren } from "react";

export const MediaQueriesProvider = ({ children }: PropsWithChildren) => {
  const isMobile = useIsMobileMediaQuery();
  const isTouch = useTouchMediaQuery();

  return <MediaQueriesContext.Provider value={{ isMobile, isTouch }}>{children}</MediaQueriesContext.Provider>;
};
