import StatsDataTitle from "../common/StatsDataTitle";
import { StatsAnimatedValue } from "../common/StatsAnimatedValue/StatsAnimatedValue";
import StatsDataTrendChart from "../common/StatsDataTrendChart";

type Props = {
  title: string;
  value: number | undefined;
  trend: number | null | undefined;
};

export const StatsTopAgentDataWithChart = ({ title, value, trend }: Props) => {
  return (
    <div className="flex items-center justify-between px-4 @container/card">
      <div>
        <StatsDataTitle title={title} />
        <StatsAnimatedValue value={value ?? 0} />
      </div>

      {trend !== null && trend !== undefined && <StatsDataTrendChart trend={trend} width={70} />}
    </div>
  );
};
