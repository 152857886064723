import type { MessageBookmarkGroup } from "@/types/bookmarks";

export const filterBookmarkGroupsBySearchInput = ({
  bookmarkGroups,
  input,
}: {
  bookmarkGroups: MessageBookmarkGroup[];
  input: string;
}) => {
  const groupsFiltered = bookmarkGroups.reduce<MessageBookmarkGroup[]>((acc, group) => {
    const messagesFiltered = group.messages.filter(bookmarkMessage => {
      const fitsMessage =
        bookmarkMessage?.message.text.toLowerCase().includes(input.toLowerCase()) ||
        bookmarkMessage?.message.title?.toLowerCase().includes(input.toLowerCase());
      return fitsMessage;
    });

    if (messagesFiltered.length) {
      acc.push({ ...group, messages: messagesFiltered });
    }

    return acc;
  }, []);

  return groupsFiltered;
};
