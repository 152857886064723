import { Icons } from "@/components/ui/icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { ROUTES } from "@/constants/routes";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useGetConversationMessages } from "@/data/queries/useGetConversationMessages";
import type { ChatAgent } from "@/types/conversation";
import { useLocation, useNavigate } from "react-router-dom";

export const NewConversationButton = ({ agents }: { agents: ChatAgent[] }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { createNewConversation, conversationId, isPreview, conversationDetails } = useChatContext();

  const { data: conversation } = useGetConversationMessages({
    conversationId,
  });

  const isDisabled = !conversationDetails || conversation?.messages.length === 0;

  const handleCreateNewConversation = async () => {
    try {
      let newConversationId: string | undefined;

      if (isPreview) {
        newConversationId = await createNewConversation([agents[0]._id]);
      } else {
        const agentIds = agents.map(agent => agent._id);
        newConversationId = await createNewConversation(agentIds);
      }

      if (newConversationId && pathname.includes("/conversation/")) {
        const newPath = ROUTES.conversation(newConversationId);

        navigate(newPath);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Tooltip>
      <TooltipTrigger
        className="flex size-10 min-h-[40px] min-w-[40px] items-center justify-center rounded-full border border-neutral-300 disabled:cursor-not-allowed disabled:opacity-40"
        disabled={isDisabled}
        onClick={handleCreateNewConversation}
      >
        <Icons.NewConversation className="mb-0.5 scale-[1.2]" />
      </TooltipTrigger>
      <TooltipContent align="end">New conversation</TooltipContent>
    </Tooltip>
  );
};
