import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError, AxiosResponse } from "axios";
import { restClient } from "../initAxios";
import type { Conversation } from "@/types/conversation";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { v4 as uuidv4 } from "uuid";

type Props = {
  file: File;
};

export enum ChatFileUploadStatus {
  SUCCESS = "success",
  LOADING = "loading",
  ERROR = "error",
}
export type UploadChatFileResponse = {
  mimetype: string;
  filename: string;
  originalname: string;
  size: number;
  url?: string;
  status?: ChatFileUploadStatus;
};

const uploadFileToConversation = async ({ conversationId, file }: Props & { conversationId: Conversation["_id"] }) => {
  const formData = new FormData();
  formData.append("files", file);

  const { data } = await restClient.put<UploadChatFileResponse[], AxiosResponse<UploadChatFileResponse[]>>(
    apiPaths.uploadFileToConversation(conversationId),
    formData,

    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return data[0];
};

export const useUploadFileToConversation = () => {
  const { conversationId, setFiles } = useChatContext();

  return useMutation<UploadChatFileResponse, AxiosError<{ message: string }>, Props, UploadChatFileResponse>({
    mutationFn: ({ file }) => uploadFileToConversation({ conversationId: conversationId ?? "", file }),
    onMutate: ({ file }) => {
      const fileData: UploadChatFileResponse = {
        originalname: file.name,
        size: file.size,
        mimetype: file.type,
        filename: uuidv4(),
        status: ChatFileUploadStatus.LOADING,
      };

      setFiles(prevFiles => [...prevFiles, fileData]);
      return fileData;
    },
    onSuccess: (responseFile, _, mockFile) => {
      setFiles(prevFiles =>
        prevFiles.map(prevFile => {
          return prevFile.filename === mockFile.filename
            ? {
                ...responseFile,
                status: ChatFileUploadStatus.SUCCESS,
              }
            : prevFile;
        })
      );
    },
    onError: (_, __, mockFile) => {
      if (mockFile) {
        setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.filename !== mockFile.filename));
      }
    },
  });
};
