import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "@/data/initAxios";
import { userMessageBookmarksKeys } from "../../queries/useGetUserMessageBookmarks";

type Props = {
  messageId: string;
  title: string;
};

const renameBookmark = async (props: Props) => {
  const { data } = await restClient.put<unknown>(apiPaths.updateMessageBookmarkName({ messageId: props.messageId }), {
    title: props.title,
  });
  return data;
};

export const useRenameMessageBookmark = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, AxiosError<{ message: string }>, Props>({
    mutationFn: renameBookmark,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: userMessageBookmarksKeys.all });
    },
  });

  return mutation;
};
