import type { Conversation } from "@/types/conversation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import { apiPaths } from "../apiPaths";
import type { Agent } from "@/types/agent";
import { singleConversationDetailsKeys } from "../queries/useGetSingleConversationDetails";

type Props = { conversationId: Conversation["_id"]; agentIds: Agent["_id"][] };

const addAgentToConversation = async (props: Props) => {
  const { data } = await restClient.put<string>(
    apiPaths.addAgentsToConversation({ conversationId: props.conversationId }),
    {
      agents: [...props.agentIds],
    }
  );
  return data;
};

export const useAddAgentToConversation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<string, AxiosError<{ conversationId: Conversation["_id"] }>, Props>({
    mutationFn: addAgentToConversation,
    onSuccess: (_, { conversationId }) => {
      void queryClient.invalidateQueries({ queryKey: singleConversationDetailsKeys.id(conversationId) });
    },
  });

  return mutation;
};
