import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { CommunityAgent } from "@/types/community";

export const notPublishedAgentsKeys = {
  all: ["notPublishedAgents"] as const,
};

const getNotPublishedAgents = async () => {
  const { data } = await restClient.get<CommunityAgent[]>(apiPaths.getNotPublishedAgents);
  return data;
};

export const useGetNotPublishedAgents = () => {
  const query = useQuery({
    queryKey: notPublishedAgentsKeys.all,
    queryFn: getNotPublishedAgents,
  });

  return query;
};
