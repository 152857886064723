import { Icons } from "@/components/ui/icons";

export const AccountUsageBenefitList = ({ features }: { features: string[] }) => {
  return (
    <div className="flex flex-col gap-1.5">
      {features.map(feature => (
        <div key={feature} className="flex items-start justify-start gap-1">
          <Icons.Check className="pt-1 text-primary-400" />
          <p className="text-xs font-medium leading-5 text-neutral-750">{feature}</p>
        </div>
      ))}
    </div>
  );
};
