import type { FullAgent } from "@/types/agent";
import { StyleChatColor } from "@/types/agent";
import { Controller, type UseFormReturn } from "react-hook-form";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";

type BotColourProps = { form: UseFormReturn<FullAgent["publish"]> };

export const BotColour = ({ form }: BotColourProps) => {
  const { control, register } = form;

  return (
    <div>
      <Label className="text-neutral-400">Colour Theme</Label>
      <div className="mt-2 flex w-full flex-wrap items-center justify-center gap-4 rounded-md bg-neutral-50 px-6 py-4">
        <Controller
          control={control}
          {...register("chatStyle.color")}
          render={({ field: { value, onChange } }) => (
            <>
              <Checkbox
                onClick={onChange}
                value={StyleChatColor.Neutral}
                checked={value === StyleChatColor.Neutral}
                label="Neutral"
                labelClassName="text-sm font-medium text-neutral-500"
                className="size-[25px] rounded border-gray-300 bg-gray-300 bg-opacity-30 hover:border-gray-300 data-[state=checked]:bg-gray-300 data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-gray-300"
              />
              <Checkbox
                onClick={onChange}
                value={StyleChatColor.Yellow}
                checked={value === StyleChatColor.Yellow}
                label="Yellow"
                labelClassName="text-sm font-medium text-neutral-500"
                className="size-[25px] rounded border-[#F1C21F] bg-[#F1C21F] bg-opacity-30 hover:border-[#F1C21F] data-[state=checked]:bg-[#F1C21F] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#F1C21F]"
              />
              <Checkbox
                onClick={onChange}
                value={StyleChatColor.Red}
                checked={value === StyleChatColor.Red}
                label="Red"
                labelClassName="text-sm font-medium text-neutral-500"
                className="size-[25px] rounded border-[#E84150] bg-[#E84150] bg-opacity-30 hover:border-[#E84150] data-[state=checked]:bg-[#E84150] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#E84150]"
              />
              <Checkbox
                onClick={onChange}
                value={StyleChatColor.Orange}
                checked={value === StyleChatColor.Orange}
                label="Orange"
                labelClassName="text-sm font-medium text-neutral-500"
                className="size-[25px] rounded border-[#EE9B01] bg-[#EE9B01] bg-opacity-30 hover:border-[#EE9B01] data-[state=checked]:bg-[#EE9B01] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#EE9B01]"
              />
              <Checkbox
                onClick={onChange}
                value={StyleChatColor.Blue}
                checked={value === StyleChatColor.Blue}
                label="Blue"
                labelClassName="text-sm font-medium text-neutral-500"
                className="size-[25px] rounded border-[#3DA3D4] bg-[#3DA3D4] bg-opacity-30 hover:border-[#3DA3D4] data-[state=checked]:bg-[#3DA3D4] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#3DA3D4]"
              />
              <Checkbox
                onClick={onChange}
                value={StyleChatColor.Green}
                checked={value === StyleChatColor.Green}
                label="Green"
                labelClassName="text-sm font-medium text-neutral-500"
                className="size-[25px] rounded border-[#649F3B] bg-[#649F3B] bg-opacity-30 hover:border-[#649F3B] data-[state=checked]:bg-[#649F3B] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#649F3B]"
              />
              <Checkbox
                onClick={onChange}
                value={StyleChatColor.Purple}
                checked={value === StyleChatColor.Purple}
                label="Purple"
                labelClassName="text-sm font-medium text-neutral-500"
                className="size-[25px] rounded border-[#8B56AE] bg-[#8B56AE] bg-opacity-30 hover:border-[#8B56AE] data-[state=checked]:bg-[#8B56AE] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#8B56AE]"
              />
            </>
          )}
        />
      </div>
    </div>
  );
};
