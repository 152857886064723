import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import type { Conversation } from "../../types/conversation";
import { restClient } from "../initAxios";

type FormPayload = { name: string; email: string; phone: string; conversationId: Conversation["_id"] };

const sendContactForm = async (props: FormPayload) => {
  const { data } = await restClient.post<FormPayload>(apiPaths.sendContactForm, props);
  return data;
};

export const useSendContactForm = () => {
  const mutation = useMutation<FormPayload, AxiosError, FormPayload>({
    mutationFn: sendContactForm,
  });

  return mutation;
};
