import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { toast } from "react-toastify";
import { userKeys } from "@/data/queries/useGetUser";
import { customerBillingInfoKeys } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";

const cancelSubscriptionPlan = async () => {
  const { data } = await restClient.post<unknown>(apiPaths.cancelSubscriptionPlan);
  return data;
};

export const useCancelSubscriptionPlan = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError<{ message: string }>, unknown>({
    mutationFn: cancelSubscriptionPlan,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: userKeys.all });
      queryClient.removeQueries({ queryKey: customerBillingInfoKeys.all });
      toast.success("Subscription plan canceled successfully");
    },
  });
};
