import { Label } from "@/components/ui/label";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { Controller } from "react-hook-form";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { Slider } from "@/components/ui/slider";
import { cn } from "@/lib/utils";

export const CreativityLevel = () => {
  const { control } = useAgentFormContext();

  return (
    <div className="flex-1">
      <div className="flex gap-1 pb-2">
        <Label htmlFor="temperature">Creativity level</Label>
        <HybridTooltipPopover heading="Set Creativity Level:">
          <p>
            Adjust the Agent's creativity from low to high to tailor its response style. Low is more factual and direct,
            while high allows for imaginative and less conventional responses.
          </p>
        </HybridTooltipPopover>
      </div>

      <Controller
        control={control}
        name="temperature"
        render={({ field: { onChange, value } }) => (
          <>
            <Slider
              value={[value]}
              minStepsBetweenThumbs={1}
              step={0.5}
              min={0}
              max={1}
              onValueChange={e => {
                onChange(e[0]);
              }}
            />
            <div className="mt-2 flex justify-between pt-1 text-xs font-medium text-neutral-400">
              <span
                className={cn("font-medium text-neutral-400", {
                  "text-neutral-750": value === 0,
                })}
              >
                Precise
              </span>
              <span
                className={cn("font-medium text-neutral-400", {
                  "text-neutral-750": value === 0.5,
                })}
              >
                Balanced
              </span>
              <span
                className={cn("font-medium text-neutral-400", {
                  "text-neutral-750": value === 1,
                })}
              >
                Creative
              </span>
            </div>
          </>
        )}
      />
    </div>
  );
};
