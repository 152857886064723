import { useState, useEffect, useRef, useCallback } from "react";
import { toast } from "react-toastify";

export const MAX_DURATION_IN_SECONDS = 60;
const MIN_DURATION_IN_SECONDS = 1.5;

const errorHandler = (error: unknown) => {
  if (error instanceof DOMException) {
    switch (error.name) {
      case "NotAllowedError":
        toast.error("Permission denied: Please allow access to the microphone.");
        break;
      case "NotFoundError":
        toast.error("Microphone not found: Please connect a microphone.");
        break;
      case "NotReadableError":
        toast.error("Microphone is not accessible due to a hardware error.");
        break;
      case "OverconstrainedError":
        toast.error("Constraints cannot be satisfied by available devices.");
        break;
      case "SecurityError":
        toast.error("Permission denied due to security reasons.");
        break;
      case "TypeError":
        toast.error("No constraints provided or invalid constraints.");
        break;
      default:
        toast.error(`An unknown error occurred: ${error.message}`);
        break;
    }
  } else if (error instanceof Error) {
    toast.error(`Unexpected error occurred: ${error.message || "Error message not provided"}`);
  } else {
    toast.error("An unknown error occurred. Please try again later");
  }
};

type AudioData = {
  blob: Blob;
  duration: number;
} | null;

export type UseAudioRecorder = {
  isRecording: boolean;
  recordingTime: number;
  startRecording: () => Promise<void>;
  stopRecording: () => Promise<AudioData>;
  cancelRecording: () => void;
};
type UseAudioRecorderProps = {
  submitSendMessage: (messageText: string, audio?: Exclude<AudioData, null>) => Promise<void>;
};

const useAudioRecorder = ({ submitSendMessage }: UseAudioRecorderProps): UseAudioRecorder => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const recordingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const resolveStopRef = useRef<((value: AudioData) => void) | null>(null);
  const cancelRef = useRef(false);
  const startTimeRef = useRef<number | null>(null);

  const stopRecording = useCallback((): Promise<AudioData> => {
    return new Promise(resolve => {
      if (!mediaRecorderRef.current) {
        resolve(null);
        return;
      }

      resolveStopRef.current = resolve;
      mediaRecorderRef.current.stop();
    });
  }, []);

  const setupMediaRecorder = useCallback(async () => {
    try {
      if (!navigator.mediaDevices?.getUserMedia) {
        throw new Error("Media devices not supported");
      }

      // Request a new media stream
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const options = MediaRecorder.isTypeSupported("audio/webm; codecs=opus")
        ? { mimeType: "audio/webm; codecs=opus" }
        : { mimeType: "audio/mp4; codecs=mp4a.40.2" }; // Fallback option
      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = event => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstart = () => {
        setRecordingTime(0);
        setIsRecording(true);
        startTimeRef.current = performance.now();
        recordingIntervalRef.current = setInterval(() => {
          setRecordingTime(prevTime => {
            const newTime = prevTime + 1;
            if (newTime > MAX_DURATION_IN_SECONDS) {
              void stopRecording().then(audioData => {
                if (audioData) {
                  void submitSendMessage("", audioData);
                }
              });
              toast.success(
                `Message has been sent. Voice message duration exceeded ${MAX_DURATION_IN_SECONDS} seconds.`
              );
            }

            return newTime;
          });
        }, 1000);
      };

      mediaRecorder.onstop = () => {
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
        }
        if (recordingIntervalRef.current) {
          clearInterval(recordingIntervalRef.current);
          recordingIntervalRef.current = null;
        }
        setIsRecording(false);
        setRecordingTime(0);
        const recordingDurationInMs = performance.now() - (startTimeRef.current || 0);
        const recordingDurationInSeconds = recordingDurationInMs / 1000;
        if (recordingDurationInSeconds < MIN_DURATION_IN_SECONDS && !cancelRef.current) {
          toast.warning(`You can send recordings that are at least ${MIN_DURATION_IN_SECONDS}s long.`);
          cancelRef.current = true;
        }

        // Resolve the promise with the audio blob or null if canceled
        if (resolveStopRef.current) {
          let audioData: AudioData = null;
          if (!cancelRef.current) {
            audioData = {
              blob: new Blob(audioChunksRef.current, { type: "audio/webm; codecs=opus" }),
              duration: recordingDurationInMs,
            };
          }
          resolveStopRef.current(audioData);
          resolveStopRef.current = null;
        }

        audioChunksRef.current = [];
        cancelRef.current = false;
      };

      return true;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  }, [submitSendMessage, stopRecording]);

  const startRecording = useCallback(async (): Promise<void> => {
    const isSuccess = await setupMediaRecorder();
    if (!isSuccess) {
      return;
    }
    mediaRecorderRef.current?.start();
  }, [setupMediaRecorder]);

  const cancelRecording = useCallback(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
      cancelRef.current = true;
      mediaRecorderRef.current.stop();
    }
  }, []);

  useEffect(() => {
    return () => {
      if (recordingIntervalRef.current) {
        clearInterval(recordingIntervalRef.current);
      }
      cancelRecording();
    };
  }, [cancelRecording]);

  return {
    isRecording,
    recordingTime,
    startRecording,
    stopRecording,
    cancelRecording,
  };
};

export default useAudioRecorder;
