import { Avatar } from "@/components/Avatar";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { sleep } from "@/utils/sleep";

type AgentTileWrapperProps = {
  onClick: () => void;
  agent: {
    name: string;
    avatar: string;
    description?: string;
  };
  children?: React.ReactNode;
  renderBottomInfo?: () => JSX.Element;
};

const AgentTileWrapper = ({ onClick, agent, children, renderBottomInfo }: AgentTileWrapperProps) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => () => setIsHovered(false), []);

  return (
    <div
      className="group/tile relative w-full flex-1 4xl:w-auto 4xl:max-w-[250px] 4xl:basis-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={async () => {
        await sleep(100);
        setIsHovered(false);
      }}
    >
      <div
        onClick={e => {
          e.stopPropagation();
          onClick();
        }}
        className="relative flex h-[246px] w-full cursor-pointer flex-col overflow-hidden"
      >
        <div className="flex h-[180px] w-full items-center justify-center rounded-lg border border-neutral-200">
          <Avatar
            size="custom"
            name={agent.name}
            src={agent?.avatar}
            className="size-full overflow-hidden rounded-[7px]"
          />
        </div>
        <motion.div className="absolute bottom-0 flex max-h-[66px] min-h-[66px] w-full flex-col overflow-hidden rounded-lg bg-agent-summary px-4 backdrop-blur-md transition-[max-height] duration-300 ease-in-out group-hover/tile:h-auto group-hover/tile:max-h-[190px]">
          <div className="flex items-center justify-center pt-2">
            <p className="truncate text-center text-sm font-bold leading-tight text-primary-black">{agent.name}</p>
          </div>
          <div
            className={cn("line-clamp-2 w-full overflow-hidden pt-1 text-xs leading-normal text-neutral-750 ", {
              "line-clamp-[9]": isHovered,
            })}
          >
            {agent?.description}
          </div>
        </motion.div>
      </div>

      {children}

      {renderBottomInfo?.()}
    </div>
  );
};

export default AgentTileWrapper;
