import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { User } from "@/types/user";

type Props = {
  name: User["name"];
  email: User["email"];
  phone?: string;
  birth: User["birth"];
  avatar?: string;
  recaptchaToken: string | undefined;
};

const registerUser = async (props: Props) => {
  const { data } = await restClient.post<User>(apiPaths.register, { ...props, isNewFrontend: true }); // TODO: remove after moving to new frontend
  return data;
};

export const useRegisterUser = () => {
  const mutation = useMutation<User, AxiosError<{ message: string }>, Props>({
    mutationFn: registerUser,
  });

  return mutation;
};
