import type { ReactNode } from "react";
import { useState } from "react";
import { NavigationProfileContext } from "@/contexts/NavigationProfileContext/NavigationProfileContext";
import { ProfileDialog } from "@/components/Profiles/ProfileDialog";
import type { NavigationProfileSection } from "@/components/Profiles/NavigationProfileSection";

type NavProfileProviderProps = {
  children: ReactNode;
};

export type NavigationProfileStateType = {
  open: boolean;
  section: NavigationProfileSection | null;
};
export const NavigationProfileProvider = ({ children }: NavProfileProviderProps) => {
  const [navigationProfileOpen, setNavigationProfileOpen] = useState<NavigationProfileStateType>({
    open: false,
    section: null,
  });

  return (
    <NavigationProfileContext.Provider value={{ navigationProfileOpen, setNavigationProfileOpen }}>
      <ProfileDialog />
      {children}
    </NavigationProfileContext.Provider>
  );
};
