import type { AgentBuildInFunction } from "@/types/agent";
import { agentBuildInFunctions } from "@/types/agent";
import { LoadingSpinner } from "../../ui/loading-spinner";

type ActionLoaderProps = {
  action: AgentBuildInFunction;
};

export const ActionLoader = ({ action }: ActionLoaderProps) => {
  switch (action) {
    case agentBuildInFunctions.IMAGE_GENERATE || agentBuildInFunctions.IMAGE_GENERATE_FLUX:
      return (
        <div className="m-4 flex items-center space-x-2">
          <LoadingSpinner />
          <p className="text-sm font-medium leading-[170%] text-primary-400">Creating image...</p>
        </div>
      );
    case agentBuildInFunctions.VIDEO_GENERATE:
      return (
        <div className="m-4 flex items-center space-x-2">
          <LoadingSpinner />
          <p className="text-sm font-medium leading-[170%] text-primary-400">Creating video...</p>
        </div>
      );
    case agentBuildInFunctions.BROWSE_WEB:
      return (
        <>
          <LoadingSpinner />
          {agentBuildInFunctions.BROWSE_WEB}
        </>
      );
    case agentBuildInFunctions.SEARCH_ENGINE:
      return (
        <>
          <LoadingSpinner />
          {agentBuildInFunctions.SEARCH_ENGINE}
        </>
      );
    case agentBuildInFunctions.EVENT_SCHEDULER:
      return (
        <>
          <LoadingSpinner />
          {agentBuildInFunctions.EVENT_SCHEDULER}
        </>
      );
    case agentBuildInFunctions.TEXT_EXTRACTION:
      return (
        <>
          <LoadingSpinner />
          {agentBuildInFunctions.TEXT_EXTRACTION}
        </>
      );
    default:
      return <></>;
  }
};
