import * as SwitchPrimitives from "@radix-ui/react-switch";

import { cn } from "@/lib/utils";
import { forwardRef } from "react";

const SwitchWithLabel = forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => {
  const { children } = props;
  return (
    <SwitchPrimitives.Root
      className={cn(
        "peer mr-4 inline-flex h-[28px] w-[144px] shrink-0 cursor-pointer items-center rounded-full font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary-300 data-[state=unchecked]:bg-primary-300 dark:focus-visible:ring-slate-300 dark:focus-visible:ring-offset-slate-950 dark:data-[state=checked]:bg-slate-50 dark:data-[state=unchecked]:bg-slate-800",
        className
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          "pointer-events-none absolute h-full w-1/2 rounded-full border-2 border-primary-300 bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-full data-[state=unchecked]:translate-x-0 dark:bg-slate-950"
        )}
      />
      {children}
    </SwitchPrimitives.Root>
  );
});
SwitchWithLabel.displayName = "SwitchWithLabel";

export { SwitchWithLabel };
