import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { User } from "@/types/user";
import { workspaceUsersKeys } from "@/data/queries/workspace/useGetWorkspaceUsers";
import { toast } from "react-toastify";

type Props = {
  userId: User["_id"];
};
type Response = {
  message: string;
};
const deleteUserFromWorkspace = async ({ userId }: Props) => {
  const { data } = await restClient.delete<Response>(apiPaths.deleteUserFromWorkspace(userId));
  return data;
};

export const useDeleteUserFromWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<Response>, Props>({
    mutationFn: deleteUserFromWorkspace,
    onSuccess: async data => {
      await queryClient.invalidateQueries({ queryKey: workspaceUsersKeys.all });
      toast.success(data.message);
    },
  });
};
