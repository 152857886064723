import * as React from "react";
import useEmblaCarousel, { type UseEmblaCarouselType } from "embla-carousel-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Icons } from "./icons";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: "horizontal" | "vertical";
  setApi?: (api: CarouselApi) => void;
  onActiveSlideChange?: (index: number) => void;
  slideStep?: number;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
}

const Carousel = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & CarouselProps>(
  (
    {
      orientation = "horizontal",
      opts,
      setApi,
      plugins,
      className,
      onActiveSlideChange,
      children,
      slideStep,
      ...props
    },
    ref
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === "horizontal" ? "x" : "y",
      },
      plugins
    );
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);

    const onSelect = React.useCallback(
      (api: CarouselApi) => {
        if (!api) {
          return;
        }

        setCanScrollPrev(api.canScrollPrev());
        setCanScrollNext(api.canScrollNext());
        onActiveSlideChange?.(api.selectedScrollSnap());
      },
      [onActiveSlideChange]
    );

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev();

      if (slideStep) {
        const remainingSlides = slideStep - 1;
        for (let i = 0; i < remainingSlides; i++) {
          if (api?.canScrollPrev()) {
            api?.scrollPrev();
          }
        }
      }
    }, [api, slideStep]);

    const scrollNext = React.useCallback(() => {
      api?.scrollNext();

      if (slideStep) {
        const remainingSlides = slideStep - 1;

        for (let i = 0; i < remainingSlides; i++) {
          if (api?.canScrollNext()) {
            api?.scrollNext();
          }
        }
      }
    }, [api, slideStep]);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext]
    );

    React.useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on("reInit", onSelect);
      api.on("select", onSelect);

      return () => {
        api?.off("select", onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api,
          opts,
          orientation: orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn("relative h-[500px] w-full max-w-[960px] rounded-cloud bg-white", className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  }
);
Carousel.displayName = "Carousel";

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    containerClassName?: string;
  }
>(({ className, children, containerClassName, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div ref={carouselRef} className={cn("overflow-hidden", containerClassName)}>
      <div
        ref={ref}
        className={cn("flex", orientation === "horizontal" ? "-ml-4" : "-mt-4 h-fit flex-col", className)}
        {...props}
      >
        {children}
      </div>
    </div>
  );
});
CarouselContent.displayName = "CarouselContent";

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    containerClassName?: string;
  }
>(({ className, containerClassName, ...props }, ref) => {
  const { orientation } = useCarousel();

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn("min-w-0 shrink-0 grow-0", orientation === "horizontal" ? "pl-4" : "pt-4", containerClassName)}
    >
      <div className={cn("mx-auto h-fit", className)} {...props}></div>
    </div>
  );
});
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & { icon?: React.ReactNode }
>(({ className, variant = "outline", size = "icon", icon, ...props }, ref) => {
  const { orientation, scrollPrev, canScrollPrev } = useCarousel();

  return (
    <Button
      ref={ref}
      variant={variant}
      size={size}
      className={cn(
        "group absolute h-10 w-10 rounded-full border-none bg-primary-50 hover:bg-primary-100",
        orientation === "horizontal" ? "left-7 top-1/2 -translate-y-1/2" : "left-1/2 top-7 -translate-x-1/2 rotate-90",
        className
      )}
      disabled={!canScrollPrev}
      onClick={scrollPrev}
      {...props}
    >
      {icon ?? <Icons.ChevronLeft className="group-hover:text-prinary-600 h-6 w-6 text-primary-400" />}
      <span className="sr-only">Previous slide</span>
    </Button>
  );
});
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & { icon?: React.ReactNode }
>(({ className, variant = "outline", size = "icon", icon, ...props }, ref) => {
  const { orientation, scrollNext, canScrollNext } = useCarousel();

  return (
    <Button
      ref={ref}
      variant={variant}
      size={size}
      className={cn(
        "group absolute h-10 w-10 rounded-full border-none bg-primary-50 hover:bg-primary-100",
        orientation === "horizontal"
          ? "right-7 top-1/2 -translate-y-1/2"
          : "bottom-7 left-1/2 -translate-x-1/2 rotate-90",
        className
      )}
      disabled={!canScrollNext}
      onClick={scrollNext}
      {...props}
    >
      {icon ?? <Icons.ChevronRight className="group-hover:text-prinary-600 h-6 w-6 text-primary-400" />}
      <span className="sr-only">Next slide</span>
    </Button>
  );
});
CarouselNext.displayName = "CarouselNext";

const CarouselDots = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { api } = useCarousel();
    const [current, setCurrent] = React.useState(0);
    const length = api?.scrollSnapList().length ?? 1;

    React.useEffect(() => {
      if (!api) {
        return;
      }
      setCurrent(api.selectedScrollSnap());
      api.on("select", () => setCurrent(api.selectedScrollSnap()));

      return () => {
        api?.off("select", () => setCurrent(api.selectedScrollSnap()));
      };
    }, [api]);

    return (
      <div
        ref={ref}
        role="tablist"
        className={cn("absolute -bottom-10 left-1/2 flex -translate-x-1/2 justify-center gap-4", className)}
        {...props}
      >
        {Array.from({ length }).map((_, index) => (
          <button
            key={index}
            role="tab"
            aria-selected={current === index ? "true" : "false"}
            aria-label={`Slide ${index + 1}`}
            onClick={() => api?.scrollTo(index)}
            className={cn(
              "size-4 rounded-full border border-neutral-300 transition-all duration-300",
              current === index ? "bg-primary-400" : "bg-transparent"
            )}
          />
        ))}
      </div>
    );
  }
);
CarouselDots.displayName = "CarouselDots";

export { type CarouselApi, Carousel, CarouselContent, CarouselItem, CarouselPrevious, CarouselNext, CarouselDots };
