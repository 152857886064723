import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { StrippedCommunityAgent } from "@/types/community";

export const likedCommunityAgentsKeys = {
  all: ["likedCommunityAgents"] as const,
};

const getLikedCommunityAgents = async () => {
  const { data } = await restClient.get<StrippedCommunityAgent[]>(apiPaths.getLikedAgents);
  return data;
};

export const useGetLikedCommunityAgents = ({ enabled = true }: { enabled?: boolean }) => {
  const query = useQuery({
    queryKey: likedCommunityAgentsKeys.all,
    queryFn: getLikedCommunityAgents,
    enabled,
  });

  return query;
};
