import { useState } from "react";
import { Button } from "@/components/ui/button";
import type { EmbedKnowledges, FutureEmbedKnowledges } from "@/types/agent";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { restClient } from "@/data/initAxios";
import { apiPaths } from "@/data/apiPaths";
import type { EventFor } from "@/types/types";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { DocumentInfoPreview } from "./DocumentInfoPreview";
import { ContinueWithGoogleButton } from "@/components/AgentForm/components/external/ContinueWithGoogleButton";
import { Input } from "@/components/ui/input";
import { FolderAccordion } from "./FolderAccordion";
import { useFieldArray } from "react-hook-form";
import { useGetUser } from "@/data/queries/useGetUser";

const googleLoginUrl = import.meta.env.VITE_GOOGLE_LOGIN_URL as string;

export const DocumentInfoGoogleDrive = () => {
  const form = useAgentFormContext();
  const { user, refetch } = useGetUser();

  const [documentInPreview, setDocumentInPreview] = useState<EmbedKnowledges | null>(null);
  const [resourceId, setResourceId] = useState("");
  const [isResourceLoading, setIsResourceLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [accordionValueOpen, setAccordionValueOpen] = useState("");
  const [isSigninIn, setIsSigninIn] = useState(false);

  const { control } = form;
  const { fields: documentsData, remove: removeDocument } = useFieldArray({
    control,
    name: "embedKnowledges.doc",
  });

  const { fields: externalHelpersData, remove: removeHelper } = useFieldArray({
    control,
    name: "embedKnowledges.externalHelpers",
  });

  const afterClick = () => {
    setIsSigninIn(true);
  };

  const removeFolder = (externalId: string) => {
    const folderIndexInDoc = documentsData.findIndex(doc => doc.id === externalId);
    if (folderIndexInDoc !== -1) {
      removeDocument(folderIndexInDoc);
      return;
    }

    // If the folder is not in the documentsData, it means it's related to already persisted documents
    // get the index of all docs that have the same externalId
    const folderIndexInExternalHelpers = externalHelpersData.findIndex(doc => doc.externalId === externalId);
    const docsIndex = documentsData.reduce((acc, doc, index) => {
      if ("externalSource" in doc && doc.externalSource === "GOOGLE_DRIVE" && doc.parentId === externalId) {
        return [...acc, index];
      }
      return acc;
    }, [] as number[]);
    removeHelper(folderIndexInExternalHelpers);
    removeDocument(docsIndex);
  };

  const filesByFolderId = documentsData.reduce(
    (acc, doc) => {
      if ("externalSource" in doc && doc.externalSource === "GOOGLE_DRIVE") {
        acc[doc.parentId] = [...(acc[doc.parentId] ?? []), { name: doc.filename }];
      }
      return acc;
    },
    {} as Record<string, { name: string }[]>
  );

  // Properly format the data for the FolderAccordion component
  const newFoldersWithChildrenFilter = documentsData.filter(
    doc => "isNewFolder" in doc && doc.isNewFolder
  ) as FutureEmbedKnowledges[];
  const newFoldersWithChildren = newFoldersWithChildrenFilter.map(folder => ({
    externalId: folder.id,
    name: folder.name,
    children: folder.children.map(child => ({ name: child.name })),
  }));
  const knownFoldersWithChildrenFilter = externalHelpersData.filter(doc => !("isNewFolder" in doc));
  const knownFoldersWithChildren = knownFoldersWithChildrenFilter.map(helper => ({
    externalId: helper.externalId,
    name: helper.name,
    children: filesByFolderId[helper.externalId] ?? [],
  }));

  const foldersWithChildren = [...newFoldersWithChildren, ...knownFoldersWithChildren];

  const addResource = async (e: EventFor<"form", "onSubmit">) => {
    e.preventDefault();
    setErrorMessage("");

    // Check if folder already exists
    const folderExists = foldersWithChildren.some(folder => folder.externalId === resourceId);
    if (folderExists) {
      return setErrorMessage("Folder already exists");
    }

    try {
      setIsResourceLoading(true);
      const { data } = await restClient.post<FutureEmbedKnowledges>(apiPaths.readDriveId, { resourceId });

      const folderData = {
        isNewFolder: true,
        ...data,
      };

      form.setValue("embedKnowledges.doc", [...documentsData, folderData], {
        shouldDirty: true,
      });
    } catch (error) {
      console.log(error);
      return setErrorMessage("Unable to fetch the folder");
    } finally {
      setIsResourceLoading(false);
      setResourceId("");
    }
  };

  return (
    <>
      {!user?.externalIntegrations.includes("google") ? (
        <div>
          <p>To use Google Drive, please authenticate with your account below:</p>
          {!isSigninIn ? (
            <ContinueWithGoogleButton href={googleLoginUrl} afterClick={afterClick} />
          ) : (
            <Button onClick={() => refetch()}>Click here once the authentication finishes!</Button>
          )}
        </div>
      ) : (
        <>
          <form className="flex flex-col items-end gap-2 lg:flex-row" onSubmit={addResource}>
            <Input
              label="Folder ID"
              value={resourceId}
              onChange={e => setResourceId(e.target.value)}
              error={errorMessage}
            />
            <Button type="submit" className="w-full sm:w-fit" loading={isResourceLoading}>
              Add
            </Button>
          </form>
          <Accordion
            type="single"
            collapsible
            className="rounded-md border border-slate-200"
            value={accordionValueOpen}
            onValueChange={setAccordionValueOpen}
          >
            <AccordionItem value="online-info">
              <AccordionTrigger className="px-4 text-xs font-medium text-neutral-800">
                <div className="flex h-4 min-w-[16px] items-center justify-center rounded-full bg-primary-500 px-1 text-[10px] font-medium leading-none text-white">
                  {foldersWithChildren.length}
                </div>
                <div className="ml-2 mr-auto">List of added folders</div>
              </AccordionTrigger>

              <AccordionContent className="flex flex-col bg-neutral-50 p-2">
                <FolderAccordion folders={foldersWithChildren} removeFolder={removeFolder} />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
          <DocumentInfoPreview setDocumentInPreview={setDocumentInPreview} documentInPreview={documentInPreview} />
        </>
      )}
    </>
  );
};
