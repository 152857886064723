import { useState } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import type { AgentBuildInFunction } from "@/types/agent";
import { agentBuildInFunctions } from "@/types/agent";
import imageGeneration from "@/assets/images/ImageGeneration.svg";
import imageGenerationFlux from "@/assets/images/ImageGenerationFlux.svg";
import webBrowsing from "@/assets/images/WebBrowsing.svg";
import searchEngine from "@/assets/images/SearchEngine.svg";
import textExtraction from "@/assets/images/TextExtraction.svg";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { useGetUser } from "@/data/queries/useGetUser";
import { SubscriptionTiers } from "@/types/subscriptions";
import { EnhancedCapabilityCard } from "./EnhancedCapabilityCard";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { Controller } from "react-hook-form";

export const EnhancedCapability = () => {
  const { control } = useAgentFormContext();

  const [intentionAccordionValueOpen, setIntentionAccordionValueOpen] = useState("intention");
  const { user } = useGetUser();

  const isSubscriptionTierFree = user?.subscription.tier === SubscriptionTiers.FREE;

  const isCapabilitySelected = ({
    capability,
    selectedCapabilities,
  }: {
    capability: AgentBuildInFunction;
    selectedCapabilities: AgentBuildInFunction[];
  }) => {
    return selectedCapabilities?.includes(capability);
  };

  const handleToggleCapability = ({
    checked,
    capability,
    selectedCapabilities,
  }: {
    checked: boolean;
    capability: AgentBuildInFunction;
    selectedCapabilities: AgentBuildInFunction[];
  }) => {
    if (!checked) {
      return selectedCapabilities.filter(item => item !== capability);
    }

    const isAlreadyAdded = selectedCapabilities?.includes(capability);

    if (!isAlreadyAdded) {
      return [...selectedCapabilities, capability];
    }

    return selectedCapabilities;
  };

  return (
    <Accordion
      type="single"
      collapsible
      value={intentionAccordionValueOpen}
      onValueChange={setIntentionAccordionValueOpen}
      className="w-full border-b border-neutral-100"
    >
      <AccordionItem value="intention">
        <AccordionTrigger className="pt-0">
          <div className="flex gap-1 pb-2">
            <h4>Enhanced Capability</h4>
            <HybridTooltipPopover heading="Give Your Agent More Abilities">
              <p>
                Unlock additional capabilities for your Agent. Each feature opens up new possibilities for interactions
                and use cases.
              </p>
            </HybridTooltipPopover>
          </div>
        </AccordionTrigger>

        <AccordionContent className="space-y-5">
          <div className="grid grid-cols-[repeat(auto-fill,minmax(230px,1fr))] gap-4">
            <Controller
              control={control}
              name="buildinFunctions"
              render={({ field: { value, onChange } }) => (
                <>
                  <EnhancedCapabilityCard
                    name="SD Image Generator"
                    checkbox={{
                      id: "imageGeneration",
                      checked: isCapabilitySelected({
                        capability: agentBuildInFunctions.IMAGE_GENERATE,
                        selectedCapabilities: value,
                      }),
                      onCheckedChange: checked =>
                        onChange(
                          handleToggleCapability({
                            checked,
                            capability: agentBuildInFunctions.IMAGE_GENERATE,
                            selectedCapabilities: value,
                          })
                        ),
                      value: agentBuildInFunctions.IMAGE_GENERATE,
                    }}
                    isLocked={isSubscriptionTierFree}
                    isDisabled={value.includes(agentBuildInFunctions.IMAGE_GENERATE_FLUX)}
                    description="High quality image generation capability with Stable Diffusion engine. Transform text into image."
                    img={{
                      src: imageGeneration,
                      alt: "image generation",
                    }}
                  />

                  <EnhancedCapabilityCard
                    name="Flux Image Generator"
                    checkbox={{
                      id: "imageGenerationFlux",
                      checked: isCapabilitySelected({
                        capability: agentBuildInFunctions.IMAGE_GENERATE_FLUX,
                        selectedCapabilities: value,
                      }),
                      onCheckedChange: checked =>
                        onChange(
                          handleToggleCapability({
                            checked,
                            capability: agentBuildInFunctions.IMAGE_GENERATE_FLUX,
                            selectedCapabilities: value,
                          })
                        ),
                      value: agentBuildInFunctions.IMAGE_GENERATE_FLUX,
                    }}
                    isLocked={isSubscriptionTierFree}
                    isDisabled={value.includes(agentBuildInFunctions.IMAGE_GENERATE)}
                    description="High quality image generation capability with Flux engine. Transform text into image. Better but slower."
                    img={{
                      src: imageGenerationFlux,
                      alt: "image generation",
                    }}
                  />

                  <EnhancedCapabilityCard
                    name="Web Browsing"
                    checkbox={{
                      id: "webBrowsing",
                      checked: isCapabilitySelected({
                        capability: agentBuildInFunctions.BROWSE_WEB,
                        selectedCapabilities: value,
                      }),
                      onCheckedChange: checked =>
                        onChange(
                          handleToggleCapability({
                            checked,
                            capability: agentBuildInFunctions.BROWSE_WEB,
                            selectedCapabilities: value,
                          })
                        ),
                      value: agentBuildInFunctions.BROWSE_WEB,
                    }}
                    isLocked={isSubscriptionTierFree}
                    isDisabled={false}
                    description="Allow your Agent to browse certain web address upon user's request."
                    img={{
                      src: webBrowsing,
                      alt: "web browsing",
                    }}
                  />

                  <EnhancedCapabilityCard
                    name="Search Engine"
                    checkbox={{
                      id: "searchEngine",
                      checked: isCapabilitySelected({
                        capability: agentBuildInFunctions.SEARCH_ENGINE,
                        selectedCapabilities: value,
                      }),
                      onCheckedChange: checked =>
                        onChange(
                          handleToggleCapability({
                            checked,
                            capability: agentBuildInFunctions.SEARCH_ENGINE,
                            selectedCapabilities: value,
                          })
                        ),
                      value: agentBuildInFunctions.SEARCH_ENGINE,
                    }}
                    isLocked={isSubscriptionTierFree}
                    isDisabled={false}
                    description="Allow your Agent to search real-time information on the internet upon user's request."
                    img={{
                      src: searchEngine,
                      alt: "search engine",
                    }}
                  />

                  <EnhancedCapabilityCard
                    name="Text Extraction"
                    checkbox={{
                      id: "textExtraction",
                      checked: isCapabilitySelected({
                        capability: agentBuildInFunctions.TEXT_EXTRACTION,
                        selectedCapabilities: value,
                      }),
                      onCheckedChange: checked =>
                        onChange(
                          handleToggleCapability({
                            checked,
                            capability: agentBuildInFunctions.TEXT_EXTRACTION,
                            selectedCapabilities: value,
                          })
                        ),
                      value: agentBuildInFunctions.TEXT_EXTRACTION,
                    }}
                    isLocked={isSubscriptionTierFree}
                    isDisabled={false}
                    description="Enable text reading from image capability for your agent. Useful for recognizing text from images."
                    img={{
                      src: textExtraction,
                      alt: "text extraction",
                    }}
                  />
                </>
              )}
            />
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
