import { DialogHeader } from "@/components/ui/dialog";
import { SubscriptionTierPricing } from "./components/SubscriptionTierPricing";
import { SubscriptionTierBenefits } from "./components/SubscriptionTierBenefits";
import { SubscriptionTierHeader } from "./components/SubscriptionTierHeader";
import { Icons } from "@/components/ui/icons";
import { useState } from "react";
import { SubscriptionPeriod } from "@/types/subscriptions";

const BENEFITS_ID = "subscription-tier-benefits";

export const SubscriptionTiers = () => {
  const [period, setPeriod] = useState<SubscriptionPeriod>(SubscriptionPeriod.ANNUALLY);

  const handleTogglePeriod = () => {
    setPeriod(prev => (prev === SubscriptionPeriod.MONTHLY ? SubscriptionPeriod.ANNUALLY : SubscriptionPeriod.MONTHLY));
  };

  const handleScrollToBenefits = () => {
    const benefitsElement = document.getElementById(BENEFITS_ID);
    if (benefitsElement) {
      benefitsElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <DialogHeader className="min-h-12"></DialogHeader>
      <div className="overflow-auto px-8 py-6">
        <SubscriptionTierHeader period={period} onTogglePeriod={handleTogglePeriod} />
        <SubscriptionTierPricing period={period} />

        <div className="mx-auto mt-8 flex flex-col items-center justify-center">
          <button
            onClick={handleScrollToBenefits}
            className="flex flex-col items-center text-xs font-medium leading-5 text-neutral-400"
          >
            <span>Detail for the pricing plan</span>
            <Icons.ChevronsDoubleDown />
          </button>
        </div>

        <SubscriptionTierBenefits tableElementId={BENEFITS_ID} />
      </div>
    </>
  );
};
