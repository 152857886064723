import UploadFiles from "@/assets/images/UploadFiles.svg";
import { domElementIds } from "@/types/dom-element-ids";

type ChatFileDropOverlayProps = { setIsUserDragging: React.Dispatch<React.SetStateAction<boolean>> };
export const ChatFileDropOverlay = ({ setIsUserDragging }: ChatFileDropOverlayProps) => {
  const handleDragOver: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsUserDragging(false);
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsUserDragging(false);
    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      const fileInput = document.getElementById(domElementIds.CHAT_FILE_INPUT) as HTMLInputElement;
      if (fileInput) {
        fileInput.files = e.dataTransfer.files;
        const event = new Event("change", { bubbles: true });
        fileInput.dispatchEvent(event);
      }
      e.dataTransfer.clearData();
    }
  };
  return (
    <div
      style={{
        background:
          "conic-gradient(from 180deg at 50% 50%, rgba(242, 98, 181, 0.00) 0deg, rgba(95, 197, 255, 0.05) 67.5deg, rgba(255, 172, 137, 0.05) 146.25deg, rgba(129, 85, 255, 0.05) 223.12500715255737deg, rgba(120, 157, 255, 0.05) 285.0000071525574deg, rgba(159, 115, 241, 0.00) 360deg), rgba(255, 255, 255, 0.50)",
      }}
      className="absolute inset-0 z-50 flex flex-col items-center justify-center p-4 backdrop-blur-md [&>*]:pointer-events-none"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <img src={UploadFiles} alt="Pictures of different kinds of media: video, image and PDF" className="mb-6" />
      <div className="mb-2 text-xl font-bold">Upload to Chat</div>
      <p className="text-sm font-normal text-neutral-500">Drag and drop to add to the conversation</p>
    </div>
  );
};
