import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { StatsQueryFilters } from "@/types/stats";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";
import type { Agent } from "@/types/agent";
import type { Message } from "@/types/conversation";

export const agentDownvotedMessages = {
  all: ["agentDownvotedMessages"] as const,
  params: ({
    agentId,
    startDateParam,
    endDateParam,
    channels,
  }: {
    agentId: Agent["_id"];
    startDateParam: string;
    endDateParam: string;
    channels: number[];
  }) => [...agentDownvotedMessages.all, agentId, startDateParam, endDateParam, channels] as const,
};

const getAgentDownvotedMessages = async ({
  agentId,
  startDate,
  endDate,
  channels,
}: {
  agentId: Agent["_id"];
  startDate: string;
  endDate: string;
  channels: number[];
}) => {
  const { data } = await restClient.get<Message[]>(apiPaths.getAgentDownvotedMessages(agentId), {
    params: {
      startDate,
      endDate,
      channels: channels.join(","),
      timezone: StatisticsHelpers.getTimezone(),
    },
  });

  return data;
};

type Props = StatsQueryFilters & {
  agentId: Agent["_id"];
  enabled: boolean;
};
export const useGetAgentDownvotedMessages = (props: Props) => {
  const { currentRange, startDate, endDate, channels } = props;

  const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
  const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

  const query = useQuery({
    queryKey: agentDownvotedMessages.params({ agentId: props.agentId, startDateParam, endDateParam, channels }),
    queryFn: () =>
      getAgentDownvotedMessages({
        agentId: props.agentId,
        startDate: startDateParam,
        endDate: endDateParam,
        channels,
      }),
    enabled: !!startDateParam && !!endDateParam && props.enabled,
    placeholderData: keepPreviousData,
  });

  return query;
};
