import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { Conversation } from "@/types/conversation";
import { chatHistoryWithAgentsKeys } from "@/data/queries/useGetChatHistoryWithAgents";
import { agentConversationsKeys } from "@/data/queries/useGetAgentConversations";

export const useDeleteConversation = () => {
  const queryClient = useQueryClient();

  const deleteConversation = async (conversationId: Conversation["_id"]) => {
    const { data } = await restClient.delete<string>(apiPaths.deleteConversation(conversationId));
    return data;
  };

  const mutation = useMutation<Conversation["_id"], AxiosError<{ message: string }>, Conversation["_id"]>({
    mutationFn: deleteConversation,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: chatHistoryWithAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: agentConversationsKeys.all });
    },
  });

  return mutation;
};
