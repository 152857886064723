import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { User } from "@/types/user";

type Props = { email: User["email"] };

const resetPassword = async (props: Props) => {
  const { data } = await restClient.post<User>(apiPaths.resetPassword, { ...props, isNewFrontend: true }); // TODO: remove after moving to new frontend
  return data;
};

export const useResetPassword = () => {
  const mutation = useMutation<User, AxiosError<{ message: string }>, Props>({
    mutationFn: resetPassword,
  });

  return mutation;
};
