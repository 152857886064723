import type { DateRangeType } from "@/types/datepicker";
import { DateRangeTypes } from "@/types/datepicker";
import { format, subDays, startOfToday, endOfToday, startOfDay, endOfDay } from "date-fns";

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";

export class StatisticsHelpers {
  static getDatesByRangeType = (range: DateRangeType) => {
    const today = startOfToday();
    let startDate: Date;
    let endDate: Date = endOfToday();

    switch (range) {
      case DateRangeTypes.Today:
        startDate = startOfToday();
        endDate = endOfToday();
        break;
      case DateRangeTypes.Yesterday:
        startDate = startOfDay(subDays(today, 1));
        endDate = endOfDay(subDays(today, 1));
        break;
      case DateRangeTypes.Last7Days:
        startDate = startOfDay(subDays(today, 7));
        break;
      case DateRangeTypes.Last14Days:
        startDate = startOfDay(subDays(today, 14));
        break;
      case DateRangeTypes.Last30Days:
        startDate = startOfDay(subDays(today, 30));
        break;
      case DateRangeTypes.Last60Days:
        startDate = startOfDay(subDays(today, 60));
        break;
      case DateRangeTypes.Last90Days:
        startDate = startOfDay(subDays(today, 90));
        break;
      case DateRangeTypes.Custom:
      default:
        return { startDate: null, endDate: null };
    }

    return {
      startDate: format(startDate, dateFormat),
      endDate: format(endDate, dateFormat),
    };
  };

  static getStartDateParam = ({ range, startDate }: { range: DateRangeType; startDate: Date | null }) => {
    if (range === DateRangeTypes.Custom) {
      if (startDate) {
        return format(startOfDay(startDate), dateFormat);
      }
    } else {
      return this.getDatesByRangeType(range).startDate;
    }
  };

  static getEndDateParam = ({ range, endDate }: { range: DateRangeType; endDate: Date | null }) => {
    if (range === DateRangeTypes.Custom) {
      if (endDate) {
        return format(endOfDay(endDate), dateFormat);
      }
    } else {
      return this.getDatesByRangeType(range).endDate;
    }
  };

  static getTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };
}
