export const shareToTwitter = (shareLink: string) => {
  window.open(`https://twitter.com/intent/tweet?text=Check out this conversation on ${shareLink}`, "_blank");
};

export const shareToLinkedIn = (shareLink: string) => {
  window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`, "_blank");
};

export const shareToFacebook = (shareLink: string) => {
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`, "_blank");
};

export const shareToDiscord = () => {
  window.open(`https://discord.com/channels/@me`, "_blank");
};

export const shareToGithub = (shareLink: string) => {
  window.open(`https://github.com/${shareLink}`, "_blank");
};
