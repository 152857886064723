import { AgentState, type FullAgent } from "@/types/agent";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { agentsKeys } from "@/data/queries/useGetAgents";
import { agentsWithConfigKeys } from "@/data/queries/useGetAgentsWithConfig";
import { workspaceAgentsKeys } from "@/data/queries/workspace/useGetWorkspaceAgents";

type useAgentToastsProps = { agentData: FullAgent | undefined };

export const useAgentToasts = ({ agentData }: useAgentToastsProps) => {
  const prevStatusRef = useRef(agentData?.state);
  const prevIdRef = useRef(agentData?._id);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      agentData?.state === AgentState.AGENT_READY &&
      prevStatusRef.current === AgentState.AGENT_UPDATING &&
      prevIdRef.current === agentData?._id
    ) {
      toast.success("Agent updated");
      void queryClient.invalidateQueries({ queryKey: agentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: agentsWithConfigKeys.all });
      void queryClient.invalidateQueries({ queryKey: workspaceAgentsKeys.all });
    }
    if (
      agentData?.state === AgentState.AGENT_READY &&
      prevStatusRef.current === AgentState.AGENT_CREATING &&
      prevIdRef.current === agentData?._id
    ) {
      toast.success("Agent created");
      void queryClient.invalidateQueries({ queryKey: agentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: agentsWithConfigKeys.all });
      void queryClient.invalidateQueries({ queryKey: workspaceAgentsKeys.all });
    }

    if (agentData?.state) {
      prevStatusRef.current = agentData.state;
    }
    if (agentData?._id) {
      prevIdRef.current = agentData._id;
    }
  }, [agentData?._id, agentData?.state, queryClient]);
};
