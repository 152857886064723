import { ALL_AGENTS_STATS_COLORS_MAP } from "../../constants/statsConstants";
import type { AllAgentsStat } from "@/types/stats";
import { StatsAllAgentsChart } from "./StatsAllAgentsChart";

type Props = {
  data: AllAgentsStat[] | undefined;
};

export const StatsAllAgents = ({ data }: Props) => {
  return (
    <>
      <div className="my-6 flex flex-wrap items-center gap-x-8 gap-y-2">
        {Object.keys(ALL_AGENTS_STATS_COLORS_MAP).map(key => (
          <div key={key} className="flex items-center gap-2">
            <div
              className="h-4 w-4 rounded-full"
              style={{
                backgroundColor: ALL_AGENTS_STATS_COLORS_MAP[key as keyof typeof ALL_AGENTS_STATS_COLORS_MAP].color,
              }}
            />

            {ALL_AGENTS_STATS_COLORS_MAP[key as keyof typeof ALL_AGENTS_STATS_COLORS_MAP].label}
          </div>
        ))}
      </div>

      <StatsAllAgentsChart data={data} />
    </>
  );
};
