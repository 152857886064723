import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { AxiosError, AxiosResponse } from "axios";
import type { Agent, ExternalAgent } from "@/types/agent";
import { toast } from "react-toastify";
import { externalAgentsKeys } from "@/data/queries/useGetExternalAgents";

type Props = {
  token: string;
  agentId: Agent["_id"];
};

type Body = {
  token: string;
  agentId: string;
};

type Response = {
  message: string;
  externalAgent: ExternalAgent;
};

const createDiscordAgent = async ({ agentId, token }: Props) => {
  const { data } = await restClient.post<Response, AxiosResponse<Response>, Body>(apiPaths.createDiscordAgent, {
    token,
    agentId,
  });
  return data;
};

export const useCreateDiscordAgent = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<Response, AxiosError<{ message: string }>, Props>({
    mutationFn: createDiscordAgent,
    onSuccess: (_, { agentId }) => {
      toast.success("Discord agent created successfully");
      void queryClient.invalidateQueries({ queryKey: externalAgentsKeys.id(agentId) });
    },
  });

  return mutation;
};
