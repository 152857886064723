import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { CommunityUser, StrippedCommunityAgent } from "@/types/community";

export const userPublishedAgentsKeys = {
  all: ["userPublishedAgents"] as const,
  id: (props: CommunityUser["_id"]) => [...userPublishedAgentsKeys.all, props] as const,
};

type Props = {
  userId: CommunityUser["_id"];
};

const getUserPublishedAgents = async ({ userId }: Props) => {
  const { data } = await restClient.get<StrippedCommunityAgent[]>(apiPaths.getUserPublishedAgents(userId));
  return data;
};

export const useGetUserPublishedAgents = ({ userId }: Props) => {
  const query = useQuery({
    queryKey: userPublishedAgentsKeys.id(userId),
    queryFn: () => getUserPublishedAgents({ userId }),
  });

  return query;
};
