import { useFetchChatScript } from "@/hooks/useFetchChatScript";
import { Button } from "@/components/ui/button";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import type { RefObject } from "react";

type PreviewChatProps = {
  injectElementRef: RefObject<HTMLDivElement>;
  isPreviewChatOpen: boolean;
  onClose: () => void;
};

export const PreviewChat = ({ injectElementRef, isPreviewChatOpen, onClose }: PreviewChatProps) => {
  const form = useAgentFormContext();

  useFetchChatScript({ agent: form.getValues(), injectElementRef, isPreviewChatOpen });

  return (
    <div>
      <Button
        className="lg:top-6: pointer-events-auto absolute right-28 top-6 z-[2147483647] md:right-3 md:top-3"
        onClick={onClose}
      >
        Exit preview
      </Button>
    </div>
  );
};
