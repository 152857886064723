import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { ROUTES } from "@/constants/routes";
import { useNavigate } from "react-router";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import WorkspaceWhatsNew from "./components/WorkspaceWhatsNew";
import { useGetAgentsWithConfig } from "@/data/queries/useGetAgentsWithConfig";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { WorkspaceBaseModels } from "./components/WorkspaceBaseModels";
import { WorkspaceMyAgents } from "./components/WorkspaceMyAgents";
import { useGetUser } from "@/data/queries/useGetUser";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { useGetWorkspaceAgents } from "@/data/queries/workspace/useGetWorkspaceAgents";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { NavigationProfileSections } from "@/components/Profiles/NavigationProfileSection";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";

import { useGetWorkspaceBasicAgents } from "@/data/queries/workspace/useGetWorkspaceBasicAgents";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useState } from "react";
import MultiAgentsChatDialog from "@/components/dialogs/MultiAgentsChatDialog/MultiAgentsChatDialog";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { FetchNextPageTrigger } from "@/components/Chat/components/FetchNextPageTrigger";

export const Workspace = () => {
  const navigate = useNavigate();

  const [isMultiAgentsChatDialogOpen, setIsMultiAgentsChatDialogOpen] = useState(false);

  const { user } = useGetUser();
  const { setNavigationProfileOpen } = useNavigationProfileContext();
  const { data: workspaceDetails, isLoading: isWorkspaceDetailsLoading } = useGetWorkspaceDetails();
  const { data: basicAgents, isPending: isAgentBasicPending } = useGetWorkspaceBasicAgents();
  const useAgentsQuery = user?.customer ? useGetWorkspaceAgents : useGetAgentsWithConfig;
  const {
    data: agents,
    isPending: isAgentsWithConfigPending,
    isSuccess: isAgentsWithConfigSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAgentsQuery();

  const isScreenSm = !useTailwindMediaQuery("sm");
  const isDesktop = useTailwindMediaQuery("lg");

  const handleNavigationProfileOpen = () => {
    setNavigationProfileOpen({ open: true, section: NavigationProfileSections.TEAM });
  };
  const handleCreateMultiAgentsChat = ({ agentIds }: { agentIds: string[] }) => {
    navigate(getNewConversationRoute(agentIds));
  };

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel defaultSize={70} minSize={30}>
        <div className="flex h-full flex-1 flex-col text-center">
          <div className="relative mb-6 flex items-center justify-between px-6 pt-6">
            <div className="flex flex-col md:flex-row">
              <h1 className="flex flex-col text-2xl font-bold text-primary-black sm:flex-row sm:items-center">
                Workspace {isWorkspaceDetailsLoading && <LoadingSpinner className="ml-4" />}
              </h1>
              {workspaceDetails && (
                <div className="flex items-center gap-x-3 border-neutral-400 font-bold text-primary-400 sm:gap-x-4 md:ml-4 md:border-l md:pl-4 ">
                  <span
                    onClick={workspaceDetails.isAdmin ? handleNavigationProfileOpen : undefined}
                    className="cursor-pointer text-left text-xl sm:text-2xl"
                  >
                    {workspaceDetails.customer.name}
                  </span>
                  {workspaceDetails.isAdmin && (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          size="custom"
                          variant="ghost"
                          className="gap-x-1.5 text-sm font-bold text-primary-400 sm:gap-x-2 sm:text-base"
                          onClick={handleNavigationProfileOpen}
                        >
                          <Icons.Team className="size-5 sm:size-6" />
                          {(workspaceDetails.customer.subscription.users?.currentUsage ?? 0) +
                            workspaceDetails.customer.administrators.length}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>Team management</TooltipContent>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size={isScreenSm ? "icon" : "md"} className="flex items-center gap-1.5">
                  <Icons.Plus /> {!isScreenSm && "New"}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="flex flex-col gap-1 p-1.5">
                <DropdownMenuItem
                  className="flex items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
                  onClick={() => navigate(ROUTES.createAgent)}
                >
                  <Icons.Bot /> Create Agent
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="flex items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
                  onClick={() => setIsMultiAgentsChatDialogOpen(true)}
                >
                  <Icons.AddGroup /> Multi Agents Chat
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          <div className="overflow-y-scroll px-6 py-6">
            <WorkspaceBaseModels agents={basicAgents ?? []} isLoading={isAgentBasicPending} />

            <h4 className="mb-4 text-left font-bold">My Agents</h4>
            <WorkspaceMyAgents
              agents={agents ?? []}
              isLoading={isAgentsWithConfigPending}
              isSuccess={isAgentsWithConfigSuccess}
              canEdit={user?.customer ? workspaceDetails?.isAdmin ?? false : true}
            />

            {isFetchingNextPage && <LoadingSpinner className="my-5 flex items-center justify-center" />}

            {hasNextPage && <FetchNextPageTrigger fetchNextPage={fetchNextPage} />}
          </div>
        </div>

        <MultiAgentsChatDialog
          isOpen={isMultiAgentsChatDialogOpen}
          setIsOpen={setIsMultiAgentsChatDialogOpen}
          onSubmit={agentIds => handleCreateMultiAgentsChat({ agentIds })}
          headerText="Select Agents to Create Group Chat"
        />
      </ResizablePanel>

      {isDesktop && (
        <>
          <ResizableHandle withHandle />

          <ResizablePanel minSize={30}>
            <WorkspaceWhatsNew />
          </ResizablePanel>
        </>
      )}
    </ResizablePanelGroup>
  );
};
