import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { useGetUser } from "../useGetUser";
import type { WorkspaceDetails } from "@/types/workspace";

export const workspaceDetailsKeys = {
  all: ["workspaceDetails"] as const,
};
const getWorkspaceDetails = async () => {
  const { data } = await restClient.get<WorkspaceDetails>(apiPaths.getWorkspaceDetails);
  return data;
};

export const useGetWorkspaceDetails = () => {
  const { user } = useGetUser();
  return useQuery({
    queryKey: workspaceDetailsKeys.all,
    queryFn: getWorkspaceDetails,
    enabled: !!user?.customer,
  });
};
