import type React from "react";
import type { FieldError, FieldValues, Path, RegisterOptions, UseFormReturn } from "react-hook-form";
import { Controller } from "react-hook-form";
import { Textarea } from "@/components/ui/textarea";

export type FormInputProps<T extends FieldValues> = {
  formObj: UseFormReturn<T>;
  name: Path<T>;
  error?: FieldError;
  rules?: RegisterOptions;
  label?: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const FormTextarea = <T extends FieldValues>({ required = true, formObj, ...props }: FormInputProps<T>) => {
  const error = formObj.formState.errors[props.name];

  return (
    <Controller
      control={formObj.control}
      name={props.name}
      rules={{
        required,
        ...props.rules,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        validate: value => (value.length ? !!value.trim() : true),
      }}
      render={({ field: { onChange, value } }) => (
        <Textarea value={value} onChange={onChange} required={required} error={error as FieldError} {...props} />
      )}
    />
  );
};
