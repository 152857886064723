import { Skeleton } from "@/components/ui/skeleton";

const StatsTopCardSkeleton = () => {
  return (
    <div className="rounded-xl border border-neutral-50 bg-bg-light p-3 md:px-7 md:py-6">
      <div className="custom-skeleton-container flex justify-between">
        <div>
          <Skeleton height={20} width={80} />
          <Skeleton height={20} width={80} style={{ marginTop: "10px" }} />
        </div>
        <Skeleton height={45} width={45} borderRadius={12} />
      </div>

      <div className="custom-skeleton-container mt-3 flex items-center gap-3">
        <Skeleton circle height={16} width={16} />
        <Skeleton height={16} width={30} />
        <Skeleton height={16} width={60} />
      </div>
    </div>
  );
};

export default StatsTopCardSkeleton;
