import * as React from "react";
import { Slot, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { LoadingSpinner } from "./loading-spinner";

const buttonVariants = cva(
  "relative inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
  {
    variants: {
      variant: {
        primary: "bg-primary-400 text-white hover:bg-primary-600 focus-visible:ring-primary-600",
        "primary-rounded": "bg-primary-100 text-primary-400 hover:bg-primary-200 rounded-full",
        secondary:
          "border border-primary-300 bg-primary-50 text-primary-400 hover:bg-primary-100 hover:text-primary-600",
        tertiary:
          "border border-neutral-400 bg-white text-primary-black hover:bg-neutral-200 focus-visible:ring-neutral-400",
        dark: "bg-black text-white hover:bg-gray-800",
        default:
          "bg-slate-900 text-slate-50 hover:bg-slate-900/90 focus-visible:ring-primary-400 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/90",
        delete: "bg-red-500 text-red-50 hover:bg-red-500/90 dark:bg-red-50 dark:text-red-500 dark:hover:bg-red-50/90",
        outline: "border",
        ghost: "hover:bg-accent hover:text-accent-foreground text-neutral-750",
        link: "text-primary-500 underline-offset-4 hover:underline",
      },
      size: {
        sm: "text-xs px-6 py-2 leading-relaxed",
        md: "text-sm px-6 py-2 leading-relaxed",
        lg: "text-sm h-10 px-4 py-6 leading-relaxed",
        icon: "size-10",
        "action-icon": "px-1 py-2",
        custom: "",
      },
      roundedType: {
        rounded: "rounded-full",
        square: "rounded-md",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "sm",
      roundedType: "square",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, roundedType, loading = false, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    const buttonProps = {
      type: props.type ?? "button",
      disabled: loading || props.disabled,
    };

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, roundedType, className }), { "text-transparent": loading })}
        ref={ref}
        {...(!asChild && { ...buttonProps })}
        {...props}
      >
        <Slottable>{props.children}</Slottable>
        {loading && <LoadingSpinner className="absolute-center" />}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
