import { useGetChatHistoryWithAgents } from "@/data/queries/useGetChatHistoryWithAgents";
import { useState } from "react";
import { AgentConversations } from "./AgentConversations";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { DeleteChatDialog } from "../../../pages/Community/components/DeleteChatDialog";
import BookmarkChatHistoryWrapper from "./BookmarkChatHistoryWrapper";
import BookmarkHistoryAgentTileWrapper from "./BookmarkHistoryAgentTileWrapper";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";

interface ChatHistoryProps {
  onClosePanel?: () => void;
}

export const ChatHistory = ({ onClosePanel }: ChatHistoryProps) => {
  const [searchInput, setSearchInput] = useState("");
  const [isDeleteChatDialogOpen, setIsDeleteChatDialogOpen] = useState(false);
  const [conversationIdToDelete, setConversationIdToDelete] = useState<string | null>(null);
  const { data, isLoading } = useGetChatHistoryWithAgents();
  const { conversationAgentIds } = useChatContext();

  if (isLoading) {
    return <LoadingSpinner className="flex items-center justify-center pt-12" />;
  }

  const filteredAgents = data?.filter(({ agents }) =>
    agents.some(agent => agent?.name?.toLocaleLowerCase().includes(searchInput.toLowerCase()))
  );

  return (
    <BookmarkChatHistoryWrapper
      searchInput={searchInput}
      onSearchInputChange={setSearchInput}
      inputPlaceholder="Search Agents"
    >
      <div className="flex flex-col gap-6">
        {filteredAgents?.map(({ conversationsCount, agents }) => (
          <BookmarkHistoryAgentTileWrapper
            key={`${agents.map(agent => agent._id).join("-")}-${conversationAgentIds.join("-")}`}
            agents={agents}
            threadsCount={conversationsCount}
            label="history"
            defaultItemIds={conversationAgentIds}
          >
            <AgentConversations
              agentIds={agents.map(agent => agent._id)}
              setIsDeleteChatDialogOpen={setIsDeleteChatDialogOpen}
              setConversationIdToDelete={setConversationIdToDelete}
              onClosePanel={onClosePanel}
            />
          </BookmarkHistoryAgentTileWrapper>
        ))}
      </div>
      <DeleteChatDialog
        isDialogOpen={isDeleteChatDialogOpen}
        setShowDialog={setIsDeleteChatDialogOpen}
        conversationIdToDelete={conversationIdToDelete}
        setConversationIdToDelete={setConversationIdToDelete}
      />
    </BookmarkChatHistoryWrapper>
  );
};
