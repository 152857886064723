import type { AgentPaginationProps, CommunityAgentFilters, StrippedCommunityAgent } from "./../../types/community";
import { useInfiniteQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";

export const communityAgentsKeys = {
  all: ["communityAgents"] as const,
  params: (params: CommunityAgentFilters & Pick<AgentPaginationProps, "limit">) =>
    [...communityAgentsKeys.all, params] as const,
};

type Props = CommunityAgentFilters & AgentPaginationProps;

const getCommunityAgents = async (props: Props) => {
  const { data } = await restClient.get<StrippedCommunityAgent[]>(apiPaths.getCommunityAgents(props));
  return data;
};

// export const useGetCommunityAgents = (props: Props) => {
//   const query = useQuery({
//     queryKey: communityAgentsKeys.params({ ...props }),
//     queryFn: () => getCommunityAgents({ ...props }),
//   });

//   return query;
// };
export const useGetCommunityAgents = (props: CommunityAgentFilters) => {
  const limit = 30;
  return useInfiniteQuery({
    queryKey: communityAgentsKeys.params({ ...props, limit }),
    queryFn: ({ pageParam }) => getCommunityAgents({ ...props, page: pageParam, limit }),
    getNextPageParam: (lastPage, _, lastPageParam) => (lastPage.length < limit ? undefined : lastPageParam + 1),
    select: data => data.pages.flat(),
    initialPageParam: 1,
  });
};
