import { useParams } from "react-router-dom";
import { CommunityHeader } from "./components/CommunityHeader";
import { CommunityProfileAgents } from "./components/CommunityProfileAgents";
import { CommunityUserProfile } from "./components/CommunityUserProfile";
import { useGetUser } from "@/data/queries/useGetUser";

export const CommunityProfile = () => {
  const { profileId } = useParams<{ profileId: string }>();
  const { user } = useGetUser();

  const isMyProfile = user?._id === profileId;

  if (!isMyProfile) {
    return (
      <div className="relative flex w-full flex-col">
        <div className="flex h-full flex-col overflow-y-auto lg:flex-row lg:overflow-y-visible">
          <CommunityUserProfile />
          <CommunityProfileAgents />
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col">
      <CommunityHeader />
      <div className="flex h-full flex-col overflow-y-scroll lg:flex-row lg:overflow-y-hidden">
        <CommunityUserProfile />

        <CommunityProfileAgents />
      </div>
    </div>
  );
};
