import { useState } from "react";
import { TeamDashboard } from "./TeamDashboard";
import { InviteTeamMember } from "./InviteTeamMember";

export type TeamStepChildrenProps = {
  setActiveStep: React.Dispatch<React.SetStateAction<TeamSteps>>;
};
const teamSteps = ["teamDashboard", "inviteTeamMember"] as const;
type TeamSteps = (typeof teamSteps)[number];
const teamStepsMap: {
  [K in TeamSteps]: React.ComponentType<TeamStepChildrenProps>;
} = {
  teamDashboard: TeamDashboard,
  inviteTeamMember: InviteTeamMember,
};

export const Team = () => {
  const [activeStep, setActiveStep] = useState<TeamSteps>("teamDashboard");
  const ActiveStepComponent = teamStepsMap[activeStep];
  return <ActiveStepComponent setActiveStep={setActiveStep} />;
};
