import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { CommunityUser } from "@/types/community";

export const profileFollowingsKeys = {
  all: ["profileFollowings"] as const,
  id: (id: CommunityUser["_id"]) => [...profileFollowingsKeys.all, id],
};

type Props = {
  profileId: CommunityUser["_id"];
};

const getProfileFollowings = async ({ profileId }: Props) => {
  const { data } = await restClient.get<CommunityUser[]>(apiPaths.getProfileFollowings(profileId));
  return data;
};

export const useGetProfileFollowings = ({ profileId }: Props) => {
  const query = useQuery({
    queryKey: profileFollowingsKeys.id(profileId),
    queryFn: () => getProfileFollowings({ profileId }),
  });

  return query;
};
