import { createContext } from "react";
import type { Agent } from "../../types/agent";
import type { ChatAgent, Conversation } from "@/types/conversation";
import type { ConversationWithAgentsDetails } from "@/data/queries/useGetSingleConversationDetails";
import type { UploadChatFileResponse } from "@/data/mutations/useUploadFileToConversation";

type ChatProps = {
  agentId: Agent["_id"] | undefined;
  conversationId: Conversation["_id"] | null;
  setConversationId: React.Dispatch<React.SetStateAction<string | null>>;
  isSendingMessage: boolean;
  setIsSendingMessage: React.Dispatch<React.SetStateAction<boolean>>;
  isAppLoading: boolean;
  setIsAppLoading: React.Dispatch<React.SetStateAction<boolean>>;
  createNewConversation: (agentIds: Agent["_id"][]) => Promise<string | undefined>;
  isMessageError: boolean;
  setIsMessageError: React.Dispatch<React.SetStateAction<boolean>>;
  isPreview: boolean;
  messageRecipients: ChatAgent[];
  setMessageRecipients: React.Dispatch<React.SetStateAction<ChatAgent[]>>;
  isGroupChat: boolean;
  conversationAgentIds: string[];
  conversationDetails: ConversationWithAgentsDetails | undefined;
  files: UploadChatFileResponse[];
  setFiles: React.Dispatch<React.SetStateAction<UploadChatFileResponse[]>>;
  isFileUploading: boolean;
};

export const ChatContext = createContext<ChatProps | null>(null);
