import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useGetAppConfig } from "@/data/queries/useGetAppConfig";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { useGetUser } from "@/data/queries/useGetUser";
import { Icons } from "@/components/ui/icons";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";
import { Button } from "@/components/ui/button";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";
import { Controller } from "react-hook-form";

export const LLMModel = () => {
  const { control } = useAgentFormContext();

  const { data } = useGetAppConfig();
  const { user } = useGetUser();
  const { openDialog } = useAccountUsageDialogContext();
  const llmList = data?.llmList || [];

  const handleOpenAccountUsageDialog = () => openDialog("llmModel");

  const isFreeTier = user?.subscription.tier === 0;

  return (
    <div className="flex flex-1 flex-col gap-1.5">
      <div className="flex items-center gap-1">
        <Label htmlFor="llmModel">Choose LLM Engine</Label>
        <HybridTooltipPopover heading="Choose Your Assistant's Brain:">
          <p>
            Select from a variety of Large Language Models (LLMs) to power your Chat Agent. Each engine has its unique
            capabilities and styles, enabling a tailored conversational experience.
          </p>
        </HybridTooltipPopover>
        {isFreeTier && (
          <Button
            onClick={handleOpenAccountUsageDialog}
            variant="ghost"
            className="ml-4 p-0 font-medium text-primary-400"
          >
            UPGRADE
          </Button>
        )}
      </div>

      <Controller
        control={control}
        name="llmModel"
        render={({ field: { onChange, value } }) => (
          <Select onValueChange={onChange} value={value}>
            <SelectTrigger id="llmModel" className="w-full">
              <SelectValue placeholder="Pick a engine for your bot"></SelectValue>
            </SelectTrigger>
            <SelectContent>
              {llmList.map(model => {
                const isLocked =
                  model.minTier !== undefined &&
                  user?.subscription.tier !== undefined &&
                  model.minTier > user.subscription.tier;
                const isLegacy = model.legacy;
                return (
                  <SelectItem
                    key={model.model}
                    value={model.model}
                    disabled={isLocked}
                    className="justify-start data-[disabled]:opacity-100"
                    itemTextProps={{
                      asChild: true,
                    }}
                  >
                    <div className="flex w-full items-center justify-between gap-4 text-xs">
                      <div className="flex items-center justify-start gap-2">
                        <img src={model.logo} className="w-6 rounded" />
                        {model.display}
                        {isLegacy && (
                          <span className="rounded-md bg-yellow-50 px-2 py-1 text-xs text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                            LEGACY
                          </span>
                        )}
                      </div>
                      {isLocked && (
                        <span className=" flex items-center gap-1 text-primary-400">
                          <Icons.TierUpgrade className=" w-4" />
                          UPGRADE
                        </span>
                      )}
                    </div>
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        )}
      />
    </div>
  );
};
