import { Elements } from "@stripe/react-stripe-js";
import type { StripeElementsOptions } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import type { PropsWithChildren } from "react";
const stripeOptions: StripeElementsOptions = {
  appearance: {
    variables: {
      borderRadius: "0.375rem",
      fontLineHeight: "170%",
      fontFamily: "Inter, sans-serif",
      colorDanger: "#F91C28",
    },
    rules: {
      ".Label": {
        fontSize: "0.875rem",
        color: "#333333",
        fontWeight: "500",
        marginBottom: "0.375rem",
        lineHeight: "170%",
      },
      ".Input": {
        border: "1px solid #D4D4D4",
        fontSize: "1rem",
        lineHeight: "1.7",
        padding: "8px 12px",
      },
      ".Input:focus": {
        border: "1px solid #B294E6",
        boxShadow: "0 0 0 2px #fff, 0 0 0 3px #7844d3, 0 0 #0000",
        outline: "2px solid transparent",
        outlineOffset: "2px",
      },
      ".Input--invalid": {
        border: "1px solid #F91C28",
        color: "#404040",
        boxShadow: "none",
      },
      ".Input--invalid:focus": {
        color: "#404040",
        border: "1px solid #F91C28",
      },
      ".Error": {
        color: "#F91C28",
        fontSize: "0.75rem",
        lineHeight: "1rem",
      },
    },
  },
};
const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY as string;
const stripePromise = loadStripe(stripePublicKey);

export const StripeElementsProvider = ({ children }: PropsWithChildren) => {
  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      {children}
    </Elements>
  );
};
