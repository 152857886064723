import { useContext } from "react";
import { AccountUsageContext } from "./AccountUsageDialogContext";

export const useAccountUsageDialogContext = () => {
  const context = useContext(AccountUsageContext);

  if (context) {
    return context;
  }

  throw new Error("useAccountUsageDialogContext must be used within a NavigationProfileProvider");
};
