import { ShareMultiEmails, type OnShareMutationCallback } from "@/components/ShareMultiEmails";
import { Button } from "@/components/ui/button";
import { useInviteUserToWorkspace } from "@/data/mutations/workspace/useInviteUsersToWorkspace";
import type { TeamStepChildrenProps } from "./Team";
import { Icons } from "@/components/ui/icons";

export const InviteTeamMember = ({ setActiveStep }: TeamStepChildrenProps) => {
  const { mutate: inviteUsersToWorkspace, isPending: isInvitationPending } = useInviteUserToWorkspace();

  const handleMemberInvitation: OnShareMutationCallback = ({ emails, onSuccessCallback }) => {
    inviteUsersToWorkspace({ emails }, { onSuccess: onSuccessCallback });
  };
  return (
    <>
      <div className="mb-5 flex items-center gap-x-4 border-b-[1.5px] pb-5">
        <Button className="h-9" size="custom" variant="ghost" onClick={() => setActiveStep("teamDashboard")}>
          <Icons.ChevronRight className="size-6 rotate-180" />
          <span className="sr-only">Back to Team dashboard</span>
        </Button>
        <span className="text-lg font-bold">Invite members</span>
      </div>
      <p className="mb-4 text-sm text-neutral-400">
        This workspace is private, only select members and roles can use this workspace.
      </p>
      <ShareMultiEmails label="Emails" onShare={handleMemberInvitation} isMutationPending={isInvitationPending} />
    </>
  );
};
