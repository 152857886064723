import { Sheet, SheetContent } from "@/components/ui/sheet";
import type { EmbedKnowledges } from "@/types/agent";
import { allowedFileFormats } from "@/utils/fileUploads";
import useResizableWidth from "../hooks/useResizableWidth";
import { Icons } from "@/components/ui/icons";
import DocViewer, { MSDocRenderer } from "react-doc-viewer";

type DocumentInfoPreviewProps = {
  documentInPreview: EmbedKnowledges | null;
  setDocumentInPreview: React.Dispatch<React.SetStateAction<EmbedKnowledges | null>>;
  docPreviewPage?: number | null;
  setDocPreviewPage?: React.Dispatch<React.SetStateAction<number | null>>;
};
export const DocumentInfoPreview = ({
  setDocumentInPreview,
  documentInPreview,
  docPreviewPage,
  setDocPreviewPage,
}: DocumentInfoPreviewProps) => {
  const { width, handleMouseDown } = useResizableWidth({ initialWidth: 500, minWidth: 350, maxWidth: 1200 });

  const handleTogglePreviewOpen = (open: boolean) => {
    if (!open) {
      setDocumentInPreview(null);
    }
    if (!open && setDocPreviewPage) {
      setDocPreviewPage(null);
    }
  };
  const fileFormat = documentInPreview?.filename.slice(documentInPreview?.filename.lastIndexOf("."));
  const isImage = allowedFileFormats.some(el => el.mimeType.startsWith("image") && el.extension === fileFormat);

  return (
    <Sheet modal={false} onOpenChange={handleTogglePreviewOpen} open={!!documentInPreview}>
      <SheetContent
        closeButtonIcon={<Icons.Close className="mt-1 size-4" />}
        style={{
          width: `${width}px`,
        }}
        side="right"
        className="h-full bg-white p-0 sm:max-w-none"
        onInteractOutside={e => e.preventDefault()}
        hideOverlay
      >
        <div className="relative size-full p-6 pl-8">
          <div className="ml-8">
            <div className="mb-2 text-base font-bold">{documentInPreview?.filename}</div>
            <p className="text-xs font-medium">{documentInPreview?.description}</p>
          </div>

          <div className="mt-10 size-full">
            {documentInPreview?.source && fileFormat === ".pdf" ? (
              <iframe
                src={`${documentInPreview.source}${docPreviewPage && "#page=".concat((docPreviewPage + 1).toString())}`}
                width="100%"
                height="85%"
              />
            ) : (fileFormat === ".xls" || fileFormat === ".xlsx") && documentInPreview?.source ? (
              <DocViewer
                pluginRenderers={[MSDocRenderer]}
                className="h-5/6 w-full"
                documents={[{ uri: documentInPreview.source || "", fileType: fileFormat.substring(1) }]}
              />
            ) : isImage && documentInPreview?.source ? (
              <img src={documentInPreview.source} alt="preview" />
            ) : (
              <p className="text-sm text-neutral-500">
                A preview is available only for image, PDF, XLS and XLSX file types.
              </p>
            )}
          </div>
          <div
            className="absolute left-1.5 top-1/2 -translate-y-1/2 cursor-ew-resize p-1.5"
            onPointerDown={handleMouseDown}
          >
            <div className="h-20 w-1.5 rounded-full bg-neutral-600"></div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
