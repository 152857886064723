import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogFooter, DialogClose } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useCreateDiscordAgent } from "@/data/mutations/external-agents/useCreateDiscordAgent";
import { useState } from "react";

type DiscordDialogProps = {
  isDialogOpen: boolean;
  setShowDialog: (value: boolean) => void;
};

export const DiscordDialog = ({ isDialogOpen, setShowDialog }: DiscordDialogProps) => {
  const [token, setToken] = useState<string>("");
  const { getValues: getAgentFormValues } = useAgentFormContext();
  const agentId = getAgentFormValues("_id");
  const { mutate: createDiscordAgent, isPending: isDiscordAgentCreating } = useCreateDiscordAgent();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (token.length < 10) {
      return;
    }
    createDiscordAgent(
      { token, agentId },
      {
        onSuccess: () => setShowDialog(false),
      }
    );
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <DialogContent variant="custom" className="min-h-min w-full max-w-[650px] pb-[68px]">
        <DialogHeader>Discord bot settings</DialogHeader>
        <div className="px-5 sm:px-8">
          <div className="mb-0.5 text-sm font-bold">Connect to Discord and chat with this bot in Discord App.</div>
          <div className="text-sm font-medium text-primary-400">
            <a
              href="https://www.agentx.so/post/how-to-effortlessly-add-ai-agents-to-discord-integrate-gpt-4-gemini-1-5-pro-and-claude-3"
              target="_blank"
              className="text-sm font-medium text-primary-400"
              rel="agentx noreferrer"
            >
              How to get Discord Bot Token?
            </a>
          </div>
          <form id="discordForm" className="my-6" onSubmit={handleSubmit}>
            <Input
              label="Discord bot token"
              placeholder="Paste your token here"
              showRevealPasswordButton
              value={token}
              onChange={e => setToken(e.target.value)}
            />
          </form>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button loading={isDiscordAgentCreating} disabled={token.length < 10} type="submit" form="discordForm">
            Configure
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
