import {
  getSinglePrivateAgentWithConfig,
  singlePrivateAgentWithConfigKeys,
} from "@/data/queries/useGetSinglePrivateAgentWithConfig";
import { userKeys } from "@/data/queries/useGetUser";
import type { Agent } from "@/types/agent";
import { AgentState } from "@/types/agent";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

export const useRefetchAgentOnUpdate = ({
  agentId,
  agentState,
}: {
  agentId: string | undefined;
  agentState: Agent["state"] | undefined;
}) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined = undefined;

    if (!agentId) {
      return;
    }

    if (agentState === AgentState.AGENT_UPDATING || agentState === AgentState.AGENT_CREATING) {
      const refetchAgent = async () => {
        const data = await queryClient.fetchQuery({
          queryKey: singlePrivateAgentWithConfigKeys.refetch(agentId),
          queryFn: () => getSinglePrivateAgentWithConfig(agentId),
          staleTime: 0,
        });

        if (data?.state === AgentState.AGENT_READY) {
          queryClient.setQueryData(singlePrivateAgentWithConfigKeys.id(agentId), data);
          void queryClient.invalidateQueries({ queryKey: userKeys.all });
        }
      };

      intervalId = setInterval(() => {
        void refetchAgent();
      }, 1500);
    }

    return () => {
      intervalId && clearInterval(intervalId);
      void queryClient.cancelQueries({ queryKey: singlePrivateAgentWithConfigKeys.refetch(agentId) });
    };
  }, [agentState, queryClient, agentId]);
};
