import { useContext } from "react";
import { MediaQueriesContext } from "@/contexts/MediaQueriesContext/MediaQueriesContext";

export const useMediaQueriesContext = () => {
  const context = useContext(MediaQueriesContext);

  if (context) {
    return context;
  }

  throw new Error("useMediaQueriesContext is either outside of its provider or wasn't provided a valid value.");
};
