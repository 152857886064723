import { useContext } from "react";
import { ChatContext } from "./ChatContext";

export const useChatContext = () => {
  const context = useContext(ChatContext);

  if (context) {
    return context;
  }

  throw new Error("Use this hook in ChatContext scope");
};
