import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import { userKeys } from "@/data/queries/useGetUser";

const updateAvatarUser = async (props: FormData) => {
  return (await restClient.put<{ path: string }>(apiPaths.updateUserAvatar, props)).data;
};

export const useUpdateAvatarUser = () => {
  const queryClient = useQueryClient();

  return useMutation<{ path: string }, AxiosError<{ message: string }>, FormData>({
    mutationFn: updateAvatarUser,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
};
