import type { InfiniteData } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../../initAxios";
import { toast } from "react-toastify";
import { userMessageBookmarksKeys } from "../../queries/useGetUserMessageBookmarks";
import { conversationMessagesKeys } from "@/data/queries/useGetConversationMessages";
import type { ConversationMessagesResponse } from "@/types/conversation";
import useIsChatWithPagination from "@/components/Chat/hooks/useIsChatWithPagination";

type Props = {
  messageId: string;
  agentId: string;
  conversationId: string;
};

const deleteUserMessageBookmark = async (props: Props) => {
  const { data } = await restClient.delete<string>(apiPaths.deleteUserMessageBookmark(props));
  return data;
};

export const useDeleteUserMessageBookmark = () => {
  const queryClient = useQueryClient();
  const withPagination = useIsChatWithPagination();

  return useMutation<string, AxiosError<{ message: string }>, Props>({
    mutationFn: deleteUserMessageBookmark,
    onSuccess: () => {
      toast.success("Bookmark deleted successfully");
      void queryClient.invalidateQueries({ queryKey: userMessageBookmarksKeys.all });
    },
    onMutate: async ({ conversationId, messageId }) => {
      await queryClient.cancelQueries({
        queryKey: conversationMessagesKeys.id({ conversationId }, withPagination),
      });

      queryClient.setQueryData<InfiniteData<ConversationMessagesResponse>>(
        conversationMessagesKeys.id({ conversationId }, withPagination),
        prev => {
          if (!prev) {
            return undefined;
          }

          return {
            pageParams: [...prev.pageParams],
            pages: prev.pages.map(page => {
              return {
                ...page,
                messages: page.messages.map(message => {
                  if (message._id === messageId) {
                    return { ...message, isBookmark: false };
                  }
                  return message;
                }),
              };
            }),
          };
        }
      );
    },
  });
};
