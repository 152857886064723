import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { Button } from "@/components/ui/button";
import { Avatar } from "@/components/Avatar";
import type { CommunityUser } from "@/types/community";
import { useLikeUser } from "@/data/mutations/useLikeUser";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ROUTES } from "@/constants/routes";
import type { F0 } from "@/types/types";

type FollowersDialogProfileProps = {
  isLoading: boolean;
  profiles: CommunityUser[] | undefined;
  closeDialog: F0;
};

export const FollowersDialogProfile = ({ isLoading, profiles, closeDialog }: FollowersDialogProfileProps) => {
  const { mutate: followOrUnfollowProfile, isPending } = useLikeUser();
  const [clickedProfileId, setClickedProfileId] = useState<CommunityUser["_id"] | null>(null);
  const navigate = useNavigate();

  const handleFollowOrUnfollow = (profileId: CommunityUser["_id"], status: boolean) => {
    followOrUnfollowProfile({ userId: profileId, status });
    setClickedProfileId(profileId);
  };

  const navigateToProfile = (profileId: CommunityUser["_id"]) => {
    navigate(ROUTES.communityProfile(profileId));
    closeDialog();
  };

  if (isLoading) {
    return <LoadingSpinner className="flex flex-1 items-center justify-center" />;
  }

  return (
    <div className="flex flex-col gap-3">
      {profiles?.map(profile => (
        <div key={profile._id} className="flex w-full items-center justify-between">
          <div className="flex cursor-pointer items-center gap-2" onClick={() => navigateToProfile(profile._id)}>
            <Avatar name={profile.name} src={profile?.avatar} size="s" />
            <p className="text-xs hover:underline">@{profile.name}</p>
          </div>
          <Button
            loading={isPending && profile._id === clickedProfileId}
            onClick={() => handleFollowOrUnfollow(profile._id, !profile.followedByUser)}
          >
            {profile.followedByUser ? "Unfollow" : "Follow"}
          </Button>
        </div>
      ))}
    </div>
  );
};
