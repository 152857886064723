import { useGetExternalAgents } from "@/data/queries/useGetExternalAgents";
import { WebsiteEmbeddingTile } from "./WebsiteEmbedding/WebsiteEmbeddingTile";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { DiscordTile } from "./Discord/DiscordTile";
import { SlackTile } from "./Slack/SlackTile";

export const Integrations = () => {
  const agentForm = useAgentFormContext();
  const { data: externalAgents } = useGetExternalAgents({ agentId: agentForm.getValues("_id") });
  return (
    <div className="mb-10">
      <h4 className="mb-6">Other Deployments</h4>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(330px,1fr))] gap-6 3xl:max-w-fit 3xl:grid-cols-2">
        <WebsiteEmbeddingTile />
        <DiscordTile externalAgents={externalAgents} />
        <SlackTile externalAgents={externalAgents} />
      </div>
    </div>
  );
};
