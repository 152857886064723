import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { CommunityUser } from "@/types/community";

export const communityUserKeys = {
  all: ["communityUser"],
  id: (id: CommunityUser["_id"]) => [...communityUserKeys.all, id],
};

type Props = {
  userId: CommunityUser["_id"];
};

const getCommunityUser = async ({ userId }: Props) => {
  const { data } = await restClient.get<CommunityUser>(apiPaths.getCommunityUser(userId));
  return data;
};

export const useGetCommunityUser = ({ userId }: Props) => {
  return useQuery({
    queryKey: communityUserKeys.id(userId),
    queryFn: () => getCommunityUser({ userId }),
    enabled: !!userId,
  });
};
