import type { Reference } from "@/types/trace";

export type TitleAndSource = {
  title?: string;
  source?: string;
  content?: string;
  filename?: string;
  page?: number;
};

export const getReferenceTitleSource = (references: Reference[]) => {
  const allFilenamesAndSources: string[] = [];
  const titleAndSource: TitleAndSource[] = [];

  (references || []).map(ref => {
    const { source, title, filename, page_content: pageContent, page } = ref;
    if (filename) {
      // it's a document reference
      if (!allFilenamesAndSources.includes(filename)) {
        titleAndSource.push({ filename, title, source, page });
        allFilenamesAndSources.push(filename);
      }
    } else {
      if (source) {
        // it's a website reference
        if (!allFilenamesAndSources.includes(source)) {
          const trimmedSource = source.replace("https://", "").replace("http://", "").replace("www.", "");
          titleAndSource.push({ source: trimmedSource, title, page });
          allFilenamesAndSources.push(source);
        }
      } else {
        // it's faq
        titleAndSource.push({ source: "FAQ", content: pageContent.substring(0, 100) + "..." });
        allFilenamesAndSources.push(source);
        // TODO, how to display FAQ reference?
      }
    }
  });
  return titleAndSource;
};
