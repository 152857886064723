import { Avatar } from "@/components/Avatar";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import type { LegacyRef } from "react";
import { useCopyNotify } from "@/hooks/useCopyNotify";
import { Icons } from "@/components/ui/icons";
import { References } from "@/components/Chat/components/References";
import { GeneratedImage } from "@/components/ui/GeneratedImage";
import { ActionLoader } from "@/components/Chat/components/ActionLoader";
import type { Trace } from "@/types/trace";

export type Props = {
  message: string;
  isBotMessage: boolean;
  trace?: Trace | null;
  agent?: {
    _id: string;
    name: string;
    avatar: string;
  };
};

export const SharedMessageCloud = ({ message, trace, isBotMessage, agent }: Props) => {
  const [, copy] = useCopyNotify();

  if (!message.length && !trace?.genImage) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-1 pt-3">
        <div>
          {isBotMessage ? (
            <div className="flex items-center gap-3">
              <Avatar name={agent?.name} src={agent?.avatar} />
              <div className="text-base font-bold text-primary-black">{agent?.name}:</div>
            </div>
          ) : (
            <div className="flex items-center gap-3">
              <Avatar />
              <div className="text-base font-bold text-primary-black">Me:</div>
            </div>
          )}
        </div>

        <div className="whitespace-pre-wrap break-words pl-[54px] text-base font-medium text-primary-black">
          <Markdown
            remarkPlugins={[remarkGfm]}
            children={message}
            components={{
              a: ({ node, ...props }) => (
                <a
                  {...props}
                  className="text-sky-900 underline decoration-sky-900 hover:text-sky-600"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              ol: ({ node, ...props }) => <ol {...props} className="ml-5 list-inside list-decimal whitespace-normal" />,
              ul: ({ node, ...props }) => <ul {...props} className="ml-5 list-inside list-decimal whitespace-normal" />,
              li: ({ node, ...props }) => <li {...props} className="my-2 list-item" />,
              h2: ({ node, ...props }) => <h2 {...props} className="text-lg" />,
              table: ({ node, ...props }) => <table {...props} className="min-w-full divide-y divide-gray-200" />,
              thead: ({ node, ...props }) => <thead {...props} className="bg-gray-50" />,
              tbody: ({ node, ...props }) => <tbody {...props} className="divide-y divide-gray-200 bg-white" />,
              tr: ({ node, ...props }) => <tr {...props} />,
              th: ({ node, ...props }) => (
                <th
                  {...props}
                  scope="col"
                  className="border border-gray-200 px-4 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500"
                />
              ),
              td: ({ node, ...props }) => <td {...props} className="whitespace-nowrap border px-4 py-2" />,
              code(props) {
                const { children, className, node, style, ...rest } = props;
                const match = /language-(\w+)/.exec(className || "");
                const ref = props.ref as LegacyRef<SyntaxHighlighter> | undefined;
                return match ? (
                  <div className="rounded-md border border-neutral-300 bg-[#F5F2F0] tracking-wide">
                    <div className="mb-[-7px] flex h-[24px] w-full items-center justify-between rounded-t-md border-b border-neutral-300 bg-[#e7e0db] px-2 text-sm text-neutral-500">
                      <div>{match[1]}</div>
                      <div
                        className="flex cursor-pointer items-center gap-1"
                        onClick={() => copy(String(children).replace(/\n$/, ""))}
                      >
                        copy
                        <Icons.CopyChat className="w-4" />
                      </div>
                    </div>
                    <SyntaxHighlighter
                      {...rest}
                      PreTag="div"
                      ref={ref}
                      children={String(children).replace(/\n$/, "")}
                      language={match[1]}
                    />
                  </div>
                ) : (
                  <code {...rest} className={className + " bg-[#F5F2F0]"}>
                    {children}
                  </code>
                );
              },
              p: ({ node, ...props }) => <p {...props} className="text-base font-medium text-primary-black" />,
              img: ({ node, ...props }) => (
                <img {...props} className="mb-4 w-full max-w-[450px] rounded-md object-contain" />
              ),
            }}
          />
          {trace?.genImage?.status && <GeneratedImage genImage={trace.genImage} />}
          {isBotMessage && trace?.actionType && <ActionLoader action={trace.actionType} />}
          {trace?.reference && <References references={trace.reference} />}
        </div>
      </div>
    </div>
  );
};
