import { useQuery } from "@tanstack/react-query";
import { restClient } from "../initAxios";
import { apiPaths } from "../apiPaths";
import type { Agent } from "@/types/agent";
import type { Conversation } from "@/types/conversation";

export const agentPreviewConversationsKeys = {
  all: ["agentPreviewConversations"] as const,
  id: (agentId: Agent["_id"]) => [...agentPreviewConversationsKeys.all, agentId] as const,
};

type Props = {
  agentId: Agent["_id"];
  enabled?: boolean;
};

type AgentPreviewConversation = {
  _id: Conversation["_id"];
  createdAt: Conversation["createdAt"];
  lastActivity: Conversation["lastActivity"];
  title: string;
};

export const getAgentPreviewConversations = async ({ agentId }: Props) => {
  const { data } = await restClient.get<AgentPreviewConversation[]>(apiPaths.getAgentPreviewConversations(agentId));
  return data;
};

export const useGetAgentPreviewConversations = ({ agentId, enabled = true }: Props) => {
  return useQuery({
    queryKey: agentPreviewConversationsKeys.id(agentId),
    queryFn: () => getAgentPreviewConversations({ agentId }),
    enabled,
  });
};
