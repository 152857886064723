import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

export const StatsAnimatedValueColumn = ({ digit }: { digit: string }) => {
  const [position, setPosition] = useState(0);
  const columnContainerRef = useRef<HTMLDivElement>(null);

  const setColumnToNumber = (num: string) => {
    if (columnContainerRef.current === null) {
      return;
    }
    setPosition(columnContainerRef.current.clientHeight * -0.77);

    const timeout = setTimeout(() => {
      if (columnContainerRef.current === null) {
        return;
      }
      setPosition(columnContainerRef.current?.clientHeight * parseInt(num));
    }, 400);

    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    setColumnToNumber(digit);
  }, [digit]);

  const numbersArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <div ref={columnContainerRef} className="relative">
      <motion.div animate={{ y: position }} transition={{ duration: 1 }} className="absolute bottom-0 h-[1000%]">
        {numbersArray.reverse().map(num => (
          <div key={num} className="flex h-[10%] w-full justify-center overflow-hidden">
            <span>{num}</span>
          </div>
        ))}
      </motion.div>
      <span className="invisible">0</span>
    </div>
  );
};
