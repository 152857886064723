import type { SingleAgentEngagementStats } from "@/types/stats";
import { SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP } from "../../constants/statsConstants";
import { StatsSingleAgentEngagementChart } from "./StatsSingleAgentEngagementChart";

type Props = {
  data: SingleAgentEngagementStats | undefined;
};

export const StatsSingleAgentEngagement = ({ data }: Props) => {
  return (
    <>
      <div className="my-6 flex flex-wrap items-center gap-x-8 gap-y-2">
        {Object.keys(SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP).map(key => (
          <div key={key} className="flex items-center gap-2">
            <div
              className="h-4 w-4 rounded-full"
              style={{
                backgroundColor:
                  SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP[key as keyof typeof SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP]
                    .color,
              }}
            />

            {
              SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP[key as keyof typeof SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP]
                .label
            }
          </div>
        ))}
      </div>

      <StatsSingleAgentEngagementChart data={data} />
    </>
  );
};
