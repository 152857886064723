import { useState } from "react";
import { motion } from "framer-motion";
import type { Message } from "@/types/conversation";
import { useSendContactForm } from "@/data/mutations/useSendContactForm";
import { Icons } from "@/components/ui/icons";
import type { EventFor } from "@/types/types";

type ContactFormProps = {
  conversationId: Message["conversationId"];
};

export const ContactForm = ({ conversationId }: ContactFormProps) => {
  const [isFormOpen, setIsFormOpen] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const { mutate, isSuccess, isPending } = useSendContactForm();

  if (!isFormOpen) {
    return null;
  }

  const submitForm = (e: EventFor<"form", "onSubmit">) => {
    e.preventDefault();
    mutate(
      { name, email, phone, conversationId },
      {
        onSuccess: () => {
          setName("");
          setEmail("");
          setPhone("");
        },
      }
    );
  };

  if (isSuccess) {
    return (
      <div className="mb-2 mt-1 flex w-[87%] flex-col gap-2 rounded-[20px] border border-solid border-white bg-cloud-light p-3 text-sm text-primary-black shadow-lg">
        Contact inquiry has been sent!
      </div>
    );
  }

  return (
    <motion.form
      onSubmit={submitForm}
      initial={{ height: 0 }}
      animate={{ height: "auto" }}
      transition={{ duration: 0.5 }}
      className="mb-2 mt-1 flex w-[87%] flex-col gap-2 overflow-hidden rounded-[20px] border border-solid border-white bg-cloud-light p-4 text-sm text-primary-black shadow-lg"
    >
      <div className="flex items-center justify-between">
        <div className="font-medium text-primary-black">Let us know how to contact you</div>
        <Icons.Close onClick={() => setIsFormOpen(false)} className="w-3 cursor-pointer text-primary-black" />
      </div>

      <div className="flex flex-1 flex-col gap-1">
        <label htmlFor="name" className="text-primary-black">
          Name
        </label>
        <input
          className="h-8 flex-1 rounded-[6px] border-none bg-white p-2"
          id="name"
          name="name"
          required
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </div>
      <div className="flex flex-1 flex-col gap-1">
        <label htmlFor="email" className="text-primary-black">
          Email
        </label>
        <input
          className="h-8 flex-1 rounded-[6px] border-none bg-white p-2"
          id="email"
          name="email"
          type="email"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className="flex flex-1 flex-col gap-1">
        <label className="text-primary-black" htmlFor="phone">
          Phone <span className="font-light">(optional)</span>
        </label>
        <input
          className="h-8 flex-1 rounded-[6px] border-none bg-white p-2"
          id="phone"
          name="phone"
          type="tel"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
      </div>

      <button
        type="submit"
        className="ml-auto mt-1 flex cursor-pointer items-center justify-center rounded bg-black p-2 font-bold text-white"
        disabled={!email.length}
      >
        {isPending ? <span className="text-white">Loading...</span> : <Icons.SendWhite className="h-[20px]" />}
      </button>
    </motion.form>
  );
};
