import { createContext } from "react";
import type { UsageExceededType } from "@/types/subscriptions";
import type { F1 } from "@/types/types";

type AccountUsageDialogContextProps = {
  isOpen: boolean;
  openDialog: F1<UsageExceededType>;
};

export const AccountUsageContext = createContext<AccountUsageDialogContextProps | null>(null);
