import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import type { AxiosError } from "axios";
import type { User } from "@/types/user";
import { authorizeAxiosClient, restClient } from "@/data/initAxios";
import { userKeys } from "@/data/queries/useGetUser";

type Props = {
  email: User["email"];
  password: string;
  recaptchaToken?: string | undefined;
};
type Response = { user: User; token: string };
const loginUser = async (props: Props) => {
  const { data } = await restClient.post<Response>(apiPaths.login, props);
  return data;
};

export const useLoginUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Response, AxiosError<{ message: string }>, Props>({
    mutationFn: loginUser,
    onSuccess: ({ user, token }) => {
      localStorage.setItem("token", token);
      authorizeAxiosClient({ token });
      queryClient.setQueryData(userKeys.all, user);
    },
  });

  return mutation;
};
