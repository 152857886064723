import { Avatar } from "@/components/Avatar";
import { Icons } from "@/components/ui/icons";
import { ROUTES } from "@/constants/routes";
import type { User } from "@/types/user";
import { useNavigate } from "react-router";

type AgentTileCreatorInfoProps = {
  agent: {
    creatorUser?: {
      _id: User["_id"];
      name: User["name"];
      avatar: User["avatar"];
      email: User["email"];
    };
    likedByUser?: boolean;
    likes?: number;
  };
  onLikeAgentClick: () => void;
  hideLikesCount?: boolean;
};

const AgentTileCreatorInfo = ({ agent, hideLikesCount, onLikeAgentClick }: AgentTileCreatorInfoProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between gap-2 pt-2">
      {!!agent.creatorUser && (
        <>
          <Avatar
            name={agent.creatorUser.name}
            src={agent.creatorUser?.avatar}
            size="custom"
            className="size-5 cursor-pointer"
            onClick={() => navigate(ROUTES.communityProfile(agent.creatorUser?._id || ""))}
          />
          <div className="flex flex-col">
            <div
              className="cursor-pointer break-all text-xs"
              onClick={() => navigate(ROUTES.communityProfile(agent.creatorUser?._id || ""))}
            >
              @{agent.creatorUser.name}
            </div>
          </div>
        </>
      )}

      {hideLikesCount ? (
        <div className="ml-auto" />
      ) : (
        <div className="ml-auto flex items-center justify-center gap-1 text-neutral-400">
          <div onClick={onLikeAgentClick} className="cursor-pointer">
            {agent.likedByUser ? (
              <Icons.HeartFilled className="text-primary-500" />
            ) : (
              <Icons.Heart className="text-neutral-400" />
            )}
          </div>
          <p className="text-xs font-medium leading-5 text-neutral-400">{agent.likes}</p>
        </div>
      )}
    </div>
  );
};

export default AgentTileCreatorInfo;
