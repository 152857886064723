import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  targetWidth?: number | string;
};

export const AgentsHistoryItem = ({ children, onClick, className, targetWidth = "auto" }: Props) => {
  const { isMobile } = useMediaQueriesContext();

  const hoverAnimation = {
    initial: { width: 40 },
    transition: { duration: 0.4 },
    whileHover: { width: targetWidth },
  };

  return (
    <div className="relative duration-500 animate-in fade-in zoom-in">
      <motion.div
        {...(!isMobile && { ...hoverAnimation })}
        className={cn("flex h-10 min-w-10 cursor-pointer items-center gap-2 overflow-hidden lg:grid", className)}
        onClick={onClick}
      >
        {children}
      </motion.div>
    </div>
  );
};
