import { useRef } from "react";
import { createPortal } from "react-dom";
import { PreviewChat } from "./PreviewChat";

interface PreviewChatDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PreviewChatDialog = ({ isOpen, onClose }: PreviewChatDialogProps) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {createPortal(
        <div
          ref={overlayRef}
          className="fixed left-0 top-0 z-[2147483647] h-full w-full"
          style={{
            background: "rgba(0, 0, 0, 0.3)",
            pointerEvents: isOpen ? "auto" : "none",
            opacity: isOpen ? 1 : 0,
            visibility: isOpen ? "visible" : "hidden",
            transition: isOpen
              ? "opacity .2s ease-in, visibility 0s ease-in 0s"
              : "opacity .2s ease-in, visibility 0s ease-in .2s",
          }}
        >
          <div id="chatBubbleRoot"></div>
          <PreviewChat isPreviewChatOpen={isOpen} onClose={onClose} injectElementRef={overlayRef} />
        </div>,
        document.body
      )}
    </>
  );
};
