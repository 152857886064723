import { cn } from "@/lib/utils";
import { memo, useEffect, useRef, useState } from "react";

type VoiceMessageBarsProps = {
  className?: string;
};

const initialBars = Array.from({ length: 75 }, (_, i) => -1 - i);
const barHeight = (bar: number) => (bar < 0 || bar % 2 === 0 ? 90 : 100);

const _VoiceMessageBars = ({ className }: VoiceMessageBarsProps) => {
  const [bars, setBars] = useState<number[]>(initialBars);
  const intervalCounter = useRef(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const updateBars = () => {
      setBars(prevBars => {
        if (prevBars.length > 75) {
          return [...prevBars.slice(1), intervalCounter.current];
        }
        return [...prevBars, intervalCounter.current];
      });
    };
    if (intervalCounter.current === 0) {
      intervalRef.current = setInterval(() => {
        requestAnimationFrame(updateBars);
        intervalCounter.current = intervalCounter.current + 1;
      }, 150);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      setBars(initialBars);
    };
  }, []);
  return (
    <div
      className={cn(
        "flex h-6 w-full items-center justify-end overflow-x-scroll duration-500 animate-in fade-in",
        className
      )}
    >
      {bars.map(bar => (
        <div
          key={`voice-bar-${bar}`}
          style={{ maxHeight: `${barHeight(bar)}%` }}
          className={cn("mr-1 h-full min-w-1 animate-bar rounded-md ", bar < 0 ? "bg-neutral-400" : "bg-blue-500")}
        ></div>
      ))}
    </div>
  );
};

export const VoiceMessageBars = memo(_VoiceMessageBars);
