import type { FullAgent } from "@/types/agent";
import { type RefObject, useEffect } from "react";
import { useGetUser } from "@/data/queries/useGetUser";
import { useParams } from "react-router-dom";

type useFetchChatScriptProps = {
  agent: FullAgent | undefined;
  injectElementRef: RefObject<HTMLDivElement>;
  isPreviewChatOpen: boolean;
};

export const useFetchChatScript = ({ agent, injectElementRef, isPreviewChatOpen }: useFetchChatScriptProps) => {
  const { user } = useGetUser();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    if (!agent || !agent._id) {
      return;
    }
    const script = document.createElement("script");
    window.agx = `${agent._id}${user?.agxKey}`;
    const getChatBubbleAgentDataFormat = () => {
      const greeting = agent.definedActions?.greeting.map(greeting => greeting.message);
      const predefinedFaq = agent.embedKnowledges?.faq
        .filter(faq => faq.predefined)
        .map(faq => ({ question: faq.description, answer: faq.content }));
      const definedActions = { ...agent.definedActions, greeting, predefined: predefinedFaq };
      const allowedOrigins = agent.publish?.allowedOrigins.map(origin => origin.domain);
      const publish = { ...agent.publish, allowedOrigins };
      return { ...agent, publish, definedActions };
    };
    script.src = (import.meta.env.VITE_CHAT_BUBBLE_URL as string) || "";
    script.async = true;
    window.postMessage({ agent: getChatBubbleAgentDataFormat() }, "*");
    const scriptElement = document.getElementById("agentx-chat");
    if (scriptElement) {
      return;
    }
    script.id = "agentx-chat";
    if (injectElementRef.current) {
      injectElementRef.current.appendChild(script);
    }
  }, [agent, id, user?.agxKey, injectElementRef, isPreviewChatOpen]);
};
