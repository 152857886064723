import { useQuery } from "@tanstack/react-query";
import { restClient } from "../initAxios";
import { apiPaths } from "../apiPaths";
import type { Agent } from "@/types/agent";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";
import type { DateRangeType } from "@/types/datepicker";
import type { ConversationWithUsersDetails } from "@/types/conversation";

export const allUsersConversationsWithAgent = {
  all: ["allUsersConversationsWithAgent"] as const,
  params: ({
    agentId,
    startDateParam,
    endDateParam,
    minMessages,
    maxMessages,
    searchValue,
    onlyWithDownvotes,
  }: {
    agentId: Agent["_id"];
    startDateParam: string;
    endDateParam: string;
    minMessages: number | null;
    maxMessages: number | null;
    searchValue: string;
    onlyWithDownvotes: boolean;
  }) =>
    [
      ...allUsersConversationsWithAgent.all,
      agentId,
      startDateParam,
      endDateParam,
      minMessages,
      maxMessages,
      searchValue,
      onlyWithDownvotes,
    ] as const,
};

type Props = {
  agentId: Agent["_id"];
  enabled?: boolean;
  currentRange: DateRangeType;
  startDate: Date | null;
  endDate: Date | null;
  minMessages: number | null;
  maxMessages: number | null;
  searchValue: string;
  onlyWithDownvotes: boolean;
};

const getAllUsersConversationsWithAgent = async ({
  agentId,
  startDate,
  endDate,
  minMessages,
  maxMessages,
  searchValue,
  onlyWithDownvotes,
}: {
  agentId: string;
  startDate: string;
  endDate: string;
  minMessages: number | null;
  maxMessages: number | null;
  searchValue: string;
  onlyWithDownvotes: boolean;
}) => {
  const { data } = await restClient.get<{
    conversations: {
      conversation: ConversationWithUsersDetails;
      searchMatches: number;
    }[];
    totalSearchMatches: number;
  }>(apiPaths.getAllUsersConversationsWithAgent(agentId), {
    params: {
      startDate,
      endDate,
      minMessages,
      maxMessages,
      search: searchValue,
      onlyWithDownvotes,
    },
  });
  return data;
};

export const useGetAllUsersConversationsWithAgent = ({
  agentId,
  enabled = false,
  startDate,
  endDate,
  currentRange,
  minMessages,
  maxMessages,
  searchValue,
  onlyWithDownvotes,
}: Props) => {
  const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
  const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

  return useQuery({
    queryKey: allUsersConversationsWithAgent.params({
      agentId,
      startDateParam,
      endDateParam,
      minMessages,
      maxMessages,
      searchValue,
      onlyWithDownvotes,
    }),
    queryFn: () =>
      getAllUsersConversationsWithAgent({
        agentId,
        startDate: startDateParam,
        endDate: endDateParam,
        minMessages,
        maxMessages,
        searchValue,
        onlyWithDownvotes,
      }),
    enabled,
  });
};
