import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { Agent } from "@/types/agent";
import { agentsKeys } from "@/data/queries/useGetAgents";
import { agentsWithConfigKeys } from "../queries/useGetAgentsWithConfig";
import { workspaceAgentsKeys } from "../queries/workspace/useGetWorkspaceAgents";
import { userAgentsKeys } from "../queries/useGetUserAgents";

export const useDuplicateAgent = () => {
  const queryClient = useQueryClient();

  const duplicateAgent = async (agentId: Agent["_id"]) => {
    const { data } = await restClient.post<Agent>(apiPaths.duplicateAgent, { agentId });
    return data;
  };

  const mutation = useMutation<Agent, AxiosError<{ message: string }>, Agent["_id"]>({
    mutationFn: duplicateAgent,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: agentsKeys.all });
      await queryClient.invalidateQueries({ queryKey: agentsWithConfigKeys.all });
      void queryClient.invalidateQueries({ queryKey: workspaceAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: userAgentsKeys.all });
    },
  });

  return mutation;
};
