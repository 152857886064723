import type { TypeFromConstObject } from "@/types/types";

export const MessagesNumRanges = {
  All: "All",
  AtLeast5: "At least 5 messages",
  AtLeast10: "At least 10 messages",
  Custom: "Custom",
} as const;

export type MessagesNumRange = TypeFromConstObject<typeof MessagesNumRanges>;

export const messagesNumRanges = Object.values(MessagesNumRanges);

export const MESSAGES_SLIDER_VALUES = [0, 5, 10, 30, "Max"];
