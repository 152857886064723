import type { ReactDatePickerProps } from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type WithRange = boolean | undefined;
type WithMultiple = boolean | undefined;
type Props = ReactDatePickerProps<WithRange, WithMultiple>;

const Datepicker = (props: Props) => {
  return (
    <ReactDatePicker
      calendarClassName="border-none"
      dayClassName={() => "text-black opacity-90"}
      calendarStartDay={1}
      formatWeekDay={nameOfDay => nameOfDay.slice(0, 1)}
      {...props}
    />
  );
};

export default Datepicker;
