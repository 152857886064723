import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { User } from "@/types/user";
import { userKeys } from "@/data/queries/useGetUser";

type Props = {
  name: User["name"];
  email?: User["email"];
};

const updateUser = async (props: Props) => {
  const { data } = await restClient.put<User>(apiPaths.updateName, props);
  return data;
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation<User, AxiosError<{ message: string }>, Props>({
    mutationFn: updateUser,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
};
