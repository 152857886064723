import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import type { CommunityUser } from "@/types/community";
import { useGetProfileFollowers } from "@/data/queries/useGetProfileFollowers";
import { useGetProfileFollowings } from "@/data/queries/useGetProfileFollowings";
import { FollowersDialogProfile } from "./FollowersDialogProfile";
import type { F1 } from "@/types/types";

export type DialogState = "followers" | "followings" | null;

type FollowersDialogProps = {
  profileId: CommunityUser["_id"];
  openedDialogTab: DialogState;
  setOpenedDialogTab: F1<DialogState>;
};

export const FollowersDialog = ({ profileId, openedDialogTab, setOpenedDialogTab }: FollowersDialogProps) => {
  const { data: followers, isLoading: isFollowersLoading } = useGetProfileFollowers({ profileId });
  const { data: followings, isLoading: isFollowingsLoading } = useGetProfileFollowings({ profileId });

  const onDialogOpenChange = (isOpen: boolean) => {
    setOpenedDialogTab(isOpen ? "followers" : null);
  };

  return (
    <Dialog open={!!openedDialogTab} onOpenChange={onDialogOpenChange}>
      <DialogContent className="" variant="medium">
        <DialogHeader className="flex gap-6">
          <div
            className={`cursor-pointer ${openedDialogTab === "followers" ? "font-bold" : "text-neutral-500"}`}
            onClick={() => setOpenedDialogTab("followers")}
          >
            Followers
          </div>
          <div
            className={`cursor-pointer ${openedDialogTab === "followings" ? "font-bold" : "text-neutral-500"}`}
            onClick={() => setOpenedDialogTab("followings")}
          >
            Followings
          </div>
        </DialogHeader>
        <DialogDescription className="flex h-full flex-1 flex-col gap-2 overflow-y-scroll">
          {openedDialogTab === "followers" && (
            <FollowersDialogProfile
              isLoading={isFollowersLoading}
              profiles={followers}
              closeDialog={() => setOpenedDialogTab(null)}
            />
          )}
          {openedDialogTab === "followings" && (
            <FollowersDialogProfile
              isLoading={isFollowingsLoading}
              profiles={followings}
              closeDialog={() => setOpenedDialogTab(null)}
            />
          )}
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="md" variant="tertiary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
