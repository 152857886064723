import type { Parameter } from "@/types/tools";

export const leadGenerationRequiredParams: Parameter[] = [
  {
    _id: "1",
    parameterName: "name",
    parameterDescription: "user's name, default empty",
    required: true,
    type: "string",
  },
  {
    _id: "2",
    parameterName: "email",
    parameterDescription: "user's email, default empty",
    required: true,
    type: "string",
  },
];

export const LEAD_GEN_PARAMS_SUGGESTIONS = [
  {
    value: "address",
    label: "Address",
  },
  {
    value: "phone_number",
    label: "Phone number",
  },
  {
    value: "zipcode",
    label: "Zipcode",
  },
  {
    value: "country",
    label: "Country",
  },
  {
    value: "city",
    label: "City",
  },
  {
    value: "company",
    label: "Company",
  },
  {
    value: "occupation",
    label: "Occupation",
  },
];
