import { cn } from "@/lib/utils";

const StatsDataTitle = ({ title, className }: { title: string; className?: string }) => {
  return (
    <h5
      className={cn(
        "whitespace-nowrap text-sm font-medium leading-6 text-neutral-400 @xs/card:text-base @md/card:text-xl",
        className
      )}
    >
      {title}
    </h5>
  );
};

export default StatsDataTitle;
