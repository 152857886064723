import type { Conversation } from "@/types/conversation";
import type { Agent } from "../types/agent";
import { apiPaths } from "./apiPaths";
import { restClient } from "./initAxios";

export const createConversation = async (agentId: Agent["_id"], { isPreview }: { isPreview?: boolean } = {}) => {
  const { data } = await restClient.post<Conversation>(apiPaths.createConversation(agentId, { isPreview }));
  return data;
};

export const createGroupConversation = async (agentIds: Agent["_id"][]) => {
  const { data } = await restClient.post<Conversation>(apiPaths.createGroupConversation, { agents: agentIds });
  return data;
};
