import type { Conversation } from "@/types/conversation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import { apiPaths } from "../apiPaths";
import type { Agent } from "@/types/agent";
import type { ConversationWithAgentsDetails } from "../queries/useGetSingleConversationDetails";
import { singleConversationDetailsKeys } from "../queries/useGetSingleConversationDetails";

type Props = { conversationId: Conversation["_id"]; agentId: Agent["_id"] };

const removeAgentFromConversation = async (props: Props) => {
  const { data } = await restClient.delete<string>(
    apiPaths.removeAgentFromConversation({ conversationId: props.conversationId, agentId: props.agentId })
  );
  return data;
};

export const useRemoveAgentFromConversation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<string, AxiosError<{ conversationId: Conversation["_id"] }>, Props>({
    mutationFn: removeAgentFromConversation,
    onSuccess: (_, { conversationId, agentId }) => {
      queryClient.setQueryData<ConversationWithAgentsDetails>(
        singleConversationDetailsKeys.id(conversationId),
        prevData => {
          if (!prevData) {
            return prevData;
          }

          const newData = {
            ...prevData,
            bots: prevData.bots.filter(bot => bot._id !== agentId),
          };

          return newData;
        }
      );
    },
  });

  return mutation;
};
