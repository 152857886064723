import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import type { ConversationWithSearchMatches } from "@/pages/Stats/components/AgentChatHistoryDialog/AgentChatHistoryDialog";
import { Icons } from "../ui/icons";
import { ChatHistoryConversation } from "./components/ChatHistoryConversation";

type Props = {
  header?: string;
  isOpen: boolean;
  onClose: () => void;
  onBackButtonPress: () => void;
  showSearchMatches: boolean;
  debouncedSearchValue: string;
  currentConversation: ConversationWithSearchMatches;
};

export const ChatHistoryConversationDialog = ({
  currentConversation,
  isOpen,
  onBackButtonPress,
  onClose,
  showSearchMatches,
  debouncedSearchValue,
}: Props) => {
  const isScreenXl = useTailwindMediaQuery("xl");

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        onBackButtonPress();
        onClose();
      }}
    >
      <DialogContent variant={isScreenXl ? "xl" : "large"}>
        <DialogHeader className="h-14 px-12 py-4 font-bold text-primary-black">Selected conversation</DialogHeader>
        <div className="h-full w-full px-12 py-6 pb-20">
          <div className="flex !w-[1144px] justify-between pb-10">
            <Button size="md" variant="tertiary" onClick={() => onBackButtonPress()}>
              <Icons.Arrow className="mr-3 mt-[-1px] size-4" />
              Back
            </Button>
          </div>
          <ChatHistoryConversation
            key={currentConversation.conversation._id}
            conversationItem={currentConversation}
            currentSearchValue={debouncedSearchValue}
            showSearchMatches={showSearchMatches}
            conversationUsers={currentConversation.conversation.users}
            renderSubtitle={() => (
              <p className="text-sm text-neutral-500">
                (Agents: {currentConversation.conversation.bots.map(bot => bot.name).join(", ")})
              </p>
            )}
          />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="md" variant="secondary" onClick={() => onBackButtonPress()}>
              Back
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
