import { Checkbox } from "@/components/ui/checkbox";
import { Icons } from "@/components/ui/icons";
import { Label } from "@/components/ui/label";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";
import type { AgentBuildInFunction } from "@/types/agent";

type EnhancedCapabilityCardProps = {
  name: string;
  checkbox: {
    id: string;
    checked: boolean;
    onCheckedChange: (checked: boolean) => void;
    value: AgentBuildInFunction;
  };
  isLocked: boolean;
  isDisabled: boolean;
  description: string;
  img: {
    src: string;
    alt: string;
  };
};

export const EnhancedCapabilityCard = ({
  name,
  checkbox,
  isLocked,
  isDisabled,
  description,
  img,
}: EnhancedCapabilityCardProps) => {
  const { openDialog } = useAccountUsageDialogContext();

  const openAccountUsageDialog = () => openDialog("enhancedCapability");

  return (
    <div
      className={`group flex flex-1 flex-col justify-between overflow-hidden rounded-md border border-neutral-50 bg-neutral-50 px-4 py-3 transition duration-200 hover:bg-primary-50 sm:py-4 ${isDisabled && "pointer-events-none opacity-35"}`}
    >
      <div className="mb-2 flex justify-between">
        <Label htmlFor={checkbox.id} className="text-base font-bold">
          {name}
        </Label>
        {isLocked ? (
          <Icons.Lock className="h-6 w-6 text-primary-500" />
        ) : (
          <Checkbox
            id={checkbox.id}
            size="sm"
            checked={checkbox.checked}
            onCheckedChange={checkbox.onCheckedChange}
            value={checkbox.value}
            className="ml-1 mt-[5px]"
          />
        )}
      </div>
      <div className="mt-4 flex h-[106px] flex-col">
        <div className="hidden group-hover:flex group-hover:h-full group-hover:flex-col group-hover:justify-between">
          <p className="text-xs font-medium leading-5 text-zinc-800">{description}</p>
          {isLocked && (
            <div
              className="ml-auto mt-auto cursor-pointer pt-2 text-xs font-bold text-violet-600"
              onClick={openAccountUsageDialog}
            >
              UPGRADE
            </div>
          )}
        </div>
        <img src={img.src} alt={img.alt} className="h-[104px] object-contain group-hover:hidden" />
      </div>
    </div>
  );
};
