import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { StrippedCommunityAgent } from "@/types/community";

export const communityBasicAgentsKeys = {
  all: ["basicAgents"] as const,
};
const getCommunityBasicAgents = async () => {
  const { data } = await restClient.get<StrippedCommunityAgent[]>(apiPaths.getCommunityBasicAgents);
  return data;
};

export const useGetCommunityBasicAgents = ({ enabled }: { enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: communityBasicAgentsKeys.all,
    queryFn: getCommunityBasicAgents,
    enabled,
  });
};
