import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import type { EmbedKnowledges } from "@/types/agent";
import { isValidUrl, normalizeUrl } from "@/utils/isValidUrl";
import { useState } from "react";
import type { EventFor, F1 } from "@/types/types";
import { APP_LINKS } from "@/constants/appLinks";

type OnlineInfoWWWProps = { appendWeb: F1<EmbedKnowledges>; webData: EmbedKnowledges[]; disabled: boolean };

export const OnlineInfoWWW = ({ appendWeb, webData, disabled }: OnlineInfoWWWProps) => {
  const [newWeb, setNewWeb] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const addWeb = (e: EventFor<"form", "onSubmit">) => {
    e.preventDefault();
    const urlWithHttps = newWeb.includes("https://") || newWeb.includes("http://") ? newWeb : `https://${newWeb}`;
    const isValid = isValidUrl(urlWithHttps);
    if (!isValid) {
      return setErrorMessage("Invalid URL");
    }
    if (webData.some(web => normalizeUrl(web.source) === normalizeUrl(urlWithHttps))) {
      return setErrorMessage("This link already exists");
    }
    const newWebObject = { source: urlWithHttps } as EmbedKnowledges;
    appendWeb(newWebObject);
    setNewWeb("");
    setErrorMessage("");
  };

  return (
    <form onSubmit={addWeb} className="flex flex-col items-end gap-2 lg:flex-row">
      <Input
        label="Address"
        value={newWeb}
        name="address"
        onChange={e => setNewWeb(e.target.value)}
        error={errorMessage}
        placeholder={APP_LINKS.landing}
        disabled={disabled}
      />
      <Button type="submit" disabled={!newWeb.trim().length || disabled} className="w-full sm:w-fit">
        Add link
      </Button>
    </form>
  );
};
