import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { BillingData } from "@/types/subscriptions";
import { useGetUser } from "../useGetUser";

export const customerBillingInfoKeys = {
  all: ["customerBillingInfo"] as const,
};

const getCustomerBillingInfo = async () => {
  const { data } = await restClient.get<BillingData>(apiPaths.getCustomerBillingInfo);
  return data;
};

export const useGetCustomerBillingInfo = () => {
  const { user } = useGetUser();
  const query = useQuery({
    queryKey: customerBillingInfoKeys.all,
    queryFn: getCustomerBillingInfo,
    // if stripeId exists
    enabled: !!user?.subscription.stripeId,
  });

  return query;
};
