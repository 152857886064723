import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import type { BillingDetails, Stripe, StripeCardNumberElement } from "@stripe/stripe-js";
import { useGetUser } from "@/data/queries/useGetUser";
import { useStripe } from "@stripe/react-stripe-js";

export const createPaymentMethodMutationKeys = {
  all: ["createPaymentMethod"],
} as const;

type MutationProps = {
  cardNumberElement: StripeCardNumberElement;
  userBillingDetails: BillingDetails;
};

type CreatePaymentMethodProps = MutationProps & {
  stripe: Stripe | null;
  email: string | undefined;
};

type PaymentMethodReturn = {
  paymentMethodId: string;
  customerDetails: {
    name: string;
    address: BillingDetails["address"];
  };
};

const createPaymentMethod = async ({
  email,
  stripe,
  cardNumberElement,
  userBillingDetails,
}: CreatePaymentMethodProps) => {
  if (!stripe) {
    throw new Error("Stripe is not initialized");
  }

  if (!email) {
    throw new Error("There was an error when retreiving the user email. Please try again.");
  }

  const { paymentMethod } = await stripe.createPaymentMethod({
    type: "card",
    card: cardNumberElement,
    billing_details: {
      address: {
        line1: userBillingDetails.address.line1,
        line2: userBillingDetails.address.line2 ?? undefined,
        city: userBillingDetails.address.city,
        state: userBillingDetails.address.state,
        postal_code: userBillingDetails.address.postal_code,
        country: userBillingDetails.address.country,
      },
      name: userBillingDetails.name,
      email,
    },
  });

  const paymentMethodId = paymentMethod?.id;

  if (!paymentMethodId) {
    throw new Error("There was an error when creating the payment method. Please try again.");
  }

  return {
    paymentMethodId,
    customerDetails: {
      name: userBillingDetails.name,
      address: userBillingDetails.address,
    },
  };
};

export const useCreatePaymentMethod = () => {
  const { user } = useGetUser();
  const stripe = useStripe();
  return useMutation<PaymentMethodReturn, AxiosError<{ message: string }>, MutationProps>({
    mutationFn: variables => createPaymentMethod({ ...variables, stripe, email: user?.email }),
    mutationKey: createPaymentMethodMutationKeys.all,
  });
};
