import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { workspaceUsersKeys } from "@/data/queries/workspace/useGetWorkspaceUsers";

type Payload = {
  emails: string[];
};

type Response = {
  message: string;
};
const inviteUsersToWorkspace = async (payload: Payload) => {
  const { data } = await restClient.post<Response>(apiPaths.inviteUsersToWorkspace, payload);
  return data;
};

export const useInviteUserToWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<{ message: string }>, Payload>({
    mutationFn: inviteUsersToWorkspace,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: workspaceUsersKeys.all });
    },
  });
};
