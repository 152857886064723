import { Icons } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";

interface BookmarkChatHistoryWrapperProps {
  searchInput: string;
  onSearchInputChange: (searchInput: string) => void;
  children: React.ReactNode;
  inputPlaceholder?: string;
}

const BookmarkChatHistoryWrapper = ({
  searchInput,
  onSearchInputChange,
  children,
  inputPlaceholder,
}: BookmarkChatHistoryWrapperProps) => {
  return (
    <div className="mb-8 flex max-h-full flex-col overflow-y-scroll p-9 pr-8 pt-2">
      <div className="relative my-4">
        <Input
          value={searchInput}
          onChange={e => onSearchInputChange(e.target.value)}
          placeholder={inputPlaceholder}
          inputClassName="bg-[#1F0E3D] pl-9 sm:pl-9 text-white placeholder:text-slate-400"
        />
        <Icons.Search className="absolute left-3 top-1/2 -translate-y-1/2 text-primary-500" />
      </div>

      <div className="mb-14 max-h-fit grow pb-20">{children}</div>
    </div>
  );
};

export default BookmarkChatHistoryWrapper;
