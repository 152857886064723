import { Skeleton } from "@/components/ui/skeleton";

const AgentOverviewSkeleton = () => {
  return (
    <div>
      <div className="custom-skeleton-container flex justify-start gap-2">
        <Skeleton height={20} width={85} />
        <Skeleton height={14} width={40} className="mt-1.5" />
        <Skeleton height={14} width={40} className="mt-1.5" />
        <Skeleton height={14} width={40} className="mt-1.5" />
        <Skeleton height={14} width={40} className="mt-1.5" />
      </div>

      <div className="custom-skeleton-container mt-4 flex justify-between">
        <div className="flex flex-col gap-2">
          <Skeleton height={15} width={80} className="" />
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={30} width={30} className="" circle />
            <Skeleton height={15} width={40} className="mt-3" />
          </div>
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={30} width={30} className="" circle />
            <Skeleton height={15} width={40} className="mt-3" />
          </div>
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={30} width={30} className="" circle />
            <Skeleton height={15} width={40} className="mt-3" />
          </div>
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={30} width={30} className="" circle />
            <Skeleton height={15} width={40} className="mt-3" />
          </div>
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={30} width={30} className="" circle />
            <Skeleton height={15} width={40} className="mt-3" />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <Skeleton height={15} width={40} className="" />
          <Skeleton height={15} width={25} className="mt-3" />
          <Skeleton height={15} width={25} className="mt-3.5" />
          <Skeleton height={15} width={25} className="mt-3.5" />
          <Skeleton height={15} width={25} className="mt-3.5" />
          <Skeleton height={15} width={25} className="mt-3.5" />
        </div>

        <div className="flex flex-col gap-2">
          <Skeleton height={15} width={35} className="" />
          <Skeleton height={15} width={25} className="mt-3" />
          <Skeleton height={15} width={25} className="mt-3.5" />
          <Skeleton height={15} width={25} className="mt-3.5" />
          <Skeleton height={15} width={25} className="mt-3.5" />
          <Skeleton height={15} width={25} className="mt-3.5" />
        </div>

        <div className="flex flex-col gap-2">
          <Skeleton height={15} width={35} className="" />
          <Skeleton height={30} width={30} className="" circle />
          <Skeleton height={30} width={30} className="" circle />
          <Skeleton height={30} width={30} className="" circle />
          <Skeleton height={30} width={30} className="" circle />
          <Skeleton height={30} width={30} className="" circle />
        </div>

        <div className="flex flex-col gap-2">
          <Skeleton height={15} width={55} className="" />
          <Skeleton height={15} width={55} className="mt-3" />
          <Skeleton height={15} width={55} className="mt-3.5" />
          <Skeleton height={15} width={55} className="mt-3.5" />
          <Skeleton height={15} width={55} className="mt-3.5" />
          <Skeleton height={15} width={55} className="mt-3.5" />
        </div>
      </div>
    </div>
  );
};

export default AgentOverviewSkeleton;
