import { isSameDay } from "date-fns";

export const getChartLabelForDates = (dateString1: string, dateString2: string) => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  const isTheSameDay = isSameDay(date1, date2);

  if (isTheSameDay) {
    return date1.toLocaleDateString();
  }

  return `${date1.toLocaleDateString()} - ${date2.toLocaleDateString()}`;
};
