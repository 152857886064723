import { Skeleton } from "@/components/ui/skeleton";

const TopAgentsSkeletons = () => {
  return (
    <div>
      <Skeleton height={20} width={100} />

      <div className="mt-6 flex flex-col flex-wrap gap-6 md:flex-row">
        <div className="grow rounded-[4px] bg-neutral-50 px-4 py-3">
          <div>
            <Skeleton height={16} width={134} />
            <Skeleton height={40} width={220} />
            <Skeleton height={16} width={150} />
          </div>

          <div className="custom-skeleton-container mt-3 flex items-center gap-3">
            <Skeleton circle height={16} width={16} />
            <Skeleton height={16} width={30} />
            <Skeleton height={16} width={60} />
          </div>
        </div>

        <div className="grow">
          <div>
            <Skeleton height={17} width={134} />
            <Skeleton height={30} width={80} />
          </div>

          <div className="mt-6">
            <Skeleton height={17} width={134} />
            <Skeleton height={30} width={80} />
          </div>
        </div>

        <div className="grow">
          <div>
            <Skeleton height={40} width={134} />
            <Skeleton circle height={65} width={65} />
            <Skeleton height={20} width={125} />
            <Skeleton height={20} width={150} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopAgentsSkeletons;
