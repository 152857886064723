import { StatsAnimatedValueColumn } from "./StatsAnimatedValueColumn";

export const StatsAnimatedValue = ({ value, suffix }: { value: number; suffix?: string }) => {
  const numArray = Math.ceil(value).toString().split("").reverse();

  return (
    <p className="relative mt-1 flex h-full w-fit select-none overflow-hidden text-lg font-medium text-black opacity-75 md:text-4xl">
      <span className="flex flex-row-reverse">
        {numArray.map((number, index) => (
          <StatsAnimatedValueColumn key={index} digit={number} />
        ))}
      </span>
      <span>{suffix}</span>
    </p>
  );
};
