import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import type { StatsInterval } from "@/types/stats";
import { StatsIntervals } from "@/types/stats";
import _ from "lodash";

export const StatsIntervalPicker = ({
  statsInterval,
  onStatsIntervalChange,
}: {
  statsInterval: StatsInterval;
  onStatsIntervalChange: (statsInterval: StatsInterval) => void;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="flex items-center gap-1 rounded-lg px-3 py-1 text-sm   shadow-sm"
        onClick={e => e.stopPropagation()}
      >
        {_.capitalize(statsInterval)}
        <Icons.ChevronRight className="h-4 w-4 rotate-90" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuItem
          className="flex items-center gap-1.5"
          onClick={() => {
            onStatsIntervalChange(StatsIntervals.DAILY);
          }}
        >
          {_.capitalize(StatsIntervals.DAILY)}
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex items-center gap-1.5"
          onClick={() => {
            onStatsIntervalChange(StatsIntervals.WEEKLY);
          }}
        >
          {_.capitalize(StatsIntervals.WEEKLY)}
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex items-center gap-1.5"
          onClick={() => {
            onStatsIntervalChange(StatsIntervals.MONTHLY);
          }}
        >
          {_.capitalize(StatsIntervals.MONTHLY)}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
