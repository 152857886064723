import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import type { FullAgent } from "@/types/agent";
import { isValidUrl } from "@/utils/isValidUrl";
import { useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Icons } from "@/components/ui/icons";
import { Label } from "@/components/ui/label";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import type { EventFor } from "@/types/types";

type AuthorizedDomainsProps = { form: UseFormReturn<FullAgent["publish"]> };

export const AuthorizedDomains = ({ form }: AuthorizedDomainsProps) => {
  const [newDomain, setNewDomain] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [accordionValueOpen, setAccordionValueOpen] = useState("");
  const { control } = form;

  const {
    fields: domainsData,
    append: appendDomain,
    remove: removeDomain,
  } = useFieldArray({
    control,
    name: "allowedOrigins",
  });

  const addWeb = (e: EventFor<"form", "onSubmit">) => {
    e.preventDefault();
    const urlWithHttps =
      newDomain.includes("https://") || newDomain.includes("http://") ? newDomain : `https://${newDomain}`;
    const isValid = isValidUrl(urlWithHttps);
    if (!isValid) {
      return setErrorMessage("Invalid domain");
    }
    if (domainsData.some(url => url.domain === urlWithHttps)) {
      return setErrorMessage("This domain already exists");
    }
    appendDomain({ domain: urlWithHttps });
    setAccordionValueOpen("domains");
    setNewDomain("");
    setErrorMessage("");
  };

  return (
    <div className="mb-4">
      <form onSubmit={addWeb} className="relative flex flex-col items-end gap-2 pb-2">
        <div className="flex w-full flex-col gap-1.5">
          <div className="flex gap-1">
            <Label htmlFor="authorizedDomains" className="text-md">
              Authorized Domains
            </Label>
            <HybridTooltipPopover>
              <p>
                Please enter the authorised origins that your agent can communicate from, i.e mydomain.com (Leave it
                empty if you want to authorise all origins)
              </p>
            </HybridTooltipPopover>
          </div>
          <div className="flex flex-col items-end gap-2">
            <Input
              autoFocus
              id="authorizedDomains"
              value={newDomain}
              onChange={e => setNewDomain(e.target.value)}
              error={errorMessage}
              placeholder="mydomain.com"
            />
            <Button type="submit" disabled={!newDomain.trim().length} className="mt-[1px] w-full">
              Add domain
            </Button>
          </div>
        </div>
      </form>

      <Accordion
        type="single"
        collapsible
        className="rounded-md border border-slate-200"
        value={accordionValueOpen}
        onValueChange={setAccordionValueOpen}
      >
        <AccordionItem value="domains">
          <AccordionTrigger className="px-4 py-2 text-xs font-medium text-neutral-800">
            <div className="flex h-4 min-w-[16px] items-center justify-center rounded-full bg-primary-500 px-1 text-[10px] font-medium leading-none text-white">
              {domainsData.length}
            </div>
            <div className="ml-2 mr-auto">List of added domains</div>
          </AccordionTrigger>
          <AccordionContent className="flex flex-col gap-2 bg-zinc-100 px-5 py-3">
            {domainsData.map((domain, index) => (
              <div
                key={domain.domain}
                className="flex w-full items-center justify-between gap-2 rounded-md border border-slate-200 bg-white px-2 hover:bg-primary-50"
              >
                <p className="text-xs font-medium text-neutral-800">{domain.domain}</p>
                <Button variant="ghost" type="button" size="action-icon" onClick={() => removeDomain(index)}>
                  <Icons.Trash className="text-neutral-400" />
                </Button>
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
