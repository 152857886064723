import { cn } from "@/lib/utils";
import { AgentState, type Agent } from "@/types/agent";

const AgentStatusDot = ({ agentState, className }: { agentState: Agent["state"]; className?: string }) => {
  if (
    agentState === AgentState.AGENT_CREATING ||
    agentState === AgentState.AGENT_REMOVING ||
    agentState === AgentState.AGENT_UPDATING
  ) {
    return null;
  }

  const statusColorMap = {
    AGENT_READY: "bg-success",
    AGENT_ERROR: "bg-error",
    AGENT_DRAFT: "bg-gray-500",
  };

  return (
    <div
      className={cn(
        "absolute right-0 top-0 size-3 rounded-full border border-white",
        statusColorMap[agentState],
        className
      )}
    />
  );
};

export default AgentStatusDot;
