import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useState } from "react";
import { PublishAgentDialogChoose } from "./components/PublishAgentDialogChoose";
import { PublishAgentDialogDetails } from "./components/PublishAgentDialogDetails";
import { PublishAgentDialogSuccess } from "./components/PublishAgentDialogSuccess";
import type { Agent } from "@/types/agent";
import { cn } from "@/lib/utils";
import type { F1 } from "@/types/types";
import type { Step } from "./types/PublishAgentDialogSteps";

type PublishAgentDialogProps = {
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
  alreadySelectedAgentId?: Agent["_id"];
  getPublishedAgentData?: boolean;
};

export const PublishAgentDialog = ({
  isDialogOpen,
  setShowDialog,
  alreadySelectedAgentId,
  getPublishedAgentData = false,
}: PublishAgentDialogProps) => {
  const [selectedAgentId, setSelectedAgentId] = useState<string | null>(alreadySelectedAgentId || null);
  const [publishStep, setPublishStep] = useState<Step>(alreadySelectedAgentId ? "details" : "choose");

  const componentsMap = {
    choose: (
      <PublishAgentDialogChoose
        selectedAgentId={selectedAgentId}
        setSelectedAgentId={setSelectedAgentId}
        setPublishStep={setPublishStep}
      />
    ),
    details: (
      <PublishAgentDialogDetails
        setPublishStep={setPublishStep}
        selectedAgentId={selectedAgentId || ""}
        getPublishedAgentData={getPublishedAgentData}
      />
    ),
    success: <PublishAgentDialogSuccess agentId={selectedAgentId || ""} />,
  };

  const isSuccess = publishStep === "success";

  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <DialogContent className={cn("flex h-full flex-col", { "!h-[620px]": isSuccess })}>
        <DialogHeader>
          <DialogTitle>Publish community details</DialogTitle>
        </DialogHeader>
        <div className="relative size-full flex-1">{componentsMap[publishStep]}</div>
      </DialogContent>
    </Dialog>
  );
};
