import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { BillingPlan } from "@/types/subscriptions";

export const billingPlansKeys = {
  all: ["billingPlans"] as const,
};

const getBillingPlans = async () => {
  const { data } = await restClient.get<BillingPlan[]>(apiPaths.getBillingPlans);
  return data;
};

export const useGetBillingPlans = () => {
  const query = useQuery({
    queryKey: billingPlansKeys.all,
    queryFn: getBillingPlans,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return query;
};
