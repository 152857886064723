import { SubscriptionPeriod, SubscriptionTiers } from "@/types/subscriptions";
import { AccountUsageBenefitList } from "./AccountUsageBenefitList";
import { AccountUsagePricingCard } from "./AccountUsagePricingCard";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";
import { toast } from "react-toastify";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { LoadingSpinner } from "@/components/ui/loading-spinner";

type AccountUsageStartupCardProps = {
  period?: SubscriptionPeriod;
  showButton?: boolean;
};
export const AccountUsageStartupCard = ({ showButton = true }: AccountUsageStartupCardProps) => {
  const { openDialog } = useSubscriptionTierDialogContext();
  const { data: plans, isLoading } = useGetBillingPlans();

  if (isLoading) {
    return <LoadingSpinner className="flex size-full items-center justify-center" />;
  }

  if (!plans) {
    toast.error("Failed to retrieve billing plans information");
    return null;
  }
  const startupPlan = plans[SubscriptionTiers.STARTUP];
  const monthPriceOnAnnual = (startupPlan.price[SubscriptionPeriod.ANNUALLY].amount / 12).toString();
  const features = startupPlan.features_as_text.split(", ");

  const onSelectStartupPlan = () => {
    openDialog({
      selectedPlan: { plan: startupPlan, period: SubscriptionPeriod.ANNUALLY },
      step: "stripePaymentSummary",
    });
  };

  return (
    <AccountUsagePricingCard
      title="STARTUP"
      price={monthPriceOnAnnual}
      periodUnit="month"
      {...(showButton && { btn: { text: "Select plan", variant: "secondary", onClick: onSelectStartupPlan } })}
    >
      <AccountUsageBenefitList features={features} />
    </AccountUsagePricingCard>
  );
};
