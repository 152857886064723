import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { StatsQueryFilters } from "@/types/stats";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";
import { useQuery } from "@tanstack/react-query";

type UserActivity = {
  user: {
    _id: string;
    name: string;
    avatar: string;
  };
  messages: number;
  downvoted: number;
};

export const myAgentsUsersActivities = {
  all: ["myAgentsUsersActivities"] as const,
  params: ({ startDateParam, endDateParam }: { startDateParam: string; endDateParam: string }) =>
    [...myAgentsUsersActivities.all, startDateParam, endDateParam] as const,
};

const getMyAgentsUsersActivities = async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
  const { data } = await restClient.get<UserActivity[]>(apiPaths.myAgentsUsersActivities, {
    params: {
      startDate,
      endDate,
    },
  });
  return data;
};

type Props = Omit<StatsQueryFilters, "channels">;

export const useGetMyAgentsUsersActivities = ({ currentRange, startDate, endDate }: Props) => {
  const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
  const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

  const query = useQuery({
    queryKey: myAgentsUsersActivities.params({ startDateParam, endDateParam }),
    queryFn: () => getMyAgentsUsersActivities({ startDate: startDateParam, endDate: endDateParam }),
  });

  return query;
};
