import { useState } from "react";
import { useDebounceValue } from "usehooks-ts";

import noAgentImg from "@/assets/images/NoAgent.svg";
import { ChatHistoryDialog } from "@/components/ChatHistoryDialog/ChatHistoryDialog";
import { Skeleton } from "@/components/ui/skeleton";
import { MessagesNumRanges } from "@/components/ChatHistoryDialog/constants/chatHistory";
import { ChatHistoryConversationDialog } from "@/components/ChatHistoryDialog/ChatHistoryConversationDialog";
import { ChatHistoryDialogListItem } from "@/components/ChatHistoryDialog/components/ChatHistoryDialogListItem";

import { useGetAllAgentsConversationsWithUser } from "@/data/queries/useGetAllAgentsConversationsWithUser";

import { DateRangeTypes } from "@/types/datepicker";
import type { User } from "@/types/user";
import type { DateRangeType } from "@/types/datepicker";
import type { MessagesNumRange } from "@/components/ChatHistoryDialog/constants/chatHistory";
import type { ConversationWithSearchMatches } from "@/pages/Stats/components/AgentChatHistoryDialog/AgentChatHistoryDialog";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  userId: User["_id"];
  userName: User["name"];
  initialCurrentRange?: DateRangeType;
  isOnlyDownvotedMessages: boolean;
  onIsOnlyDownvotedMessagesChange: (isOnlyDownvotedMessages: boolean) => void;
};

export const UserChatHistoryDialog = ({
  userId,
  userName,
  isOpen,
  onClose,
  initialCurrentRange,
  isOnlyDownvotedMessages,
  onIsOnlyDownvotedMessagesChange,
}: Props) => {
  const [searchValue, setSearchValue] = useState("");

  const [currentRange, setCurrentRange] = useState<DateRangeType>(initialCurrentRange ?? DateRangeTypes.Last7Days);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [currentConversation, setCurrentConversation] = useState<ConversationWithSearchMatches | null>(null);

  const [selectedMessagesNumRange, setSelectedMessagesNumRange] = useState<MessagesNumRange>(MessagesNumRanges.All);
  const [customMinNumOfMessages, setCustomMinNumOfMessages] = useState<number | null>(null);
  const [customMaxNumOfMessages, setCustomMaxNumOfMessages] = useState<number | null>(null);

  const [debouncedSearchValue] = useDebounceValue(searchValue, 700);
  const [debouncedCustomMinNumOfMessages] = useDebounceValue(customMinNumOfMessages, 700);
  const [debouncedCustomMaxNumOfMessages] = useDebounceValue(customMaxNumOfMessages, 700);

  const { data, isLoading } = useGetAllAgentsConversationsWithUser({
    userId,
    enabled: !!userId,
    startDate,
    endDate,
    currentRange,
    minMessages: debouncedCustomMinNumOfMessages,
    maxMessages: debouncedCustomMaxNumOfMessages,
    searchValue: debouncedSearchValue,
    onlyWithDownvotes: isOnlyDownvotedMessages,
  });

  const loader = Array.from({ length: 5 })?.map((_, index) => (
    <div key={index} className="h-12 px-1">
      <div key={index} className="custom-skeleton-container flex justify-between lg:px-6">
        <Skeleton height={18} width={200} />
        <Skeleton height={18} width={100} />
      </div>
    </div>
  ));

  const showSearchMatches = data?.totalSearchMatches !== undefined && !!debouncedSearchValue;

  return currentConversation ? (
    <ChatHistoryConversationDialog
      isOpen={isOpen}
      currentConversation={currentConversation}
      onBackButtonPress={() => setCurrentConversation(null)}
      onClose={onClose}
      showSearchMatches={showSearchMatches}
      debouncedSearchValue={debouncedSearchValue}
    />
  ) : (
    <ChatHistoryDialog
      header={`${userName}'s conversations`}
      isOpen={isOpen}
      onClose={onClose}
      searchValue={searchValue}
      onSearchValueChange={setSearchValue}
      showSearchMatches={showSearchMatches}
      totalSearchMatches={data?.totalSearchMatches ?? 0}
      debouncedSearchValue={debouncedSearchValue}
      currentRange={currentRange}
      onChangeCurrentRange={setCurrentRange}
      startDate={startDate}
      onStartDateChange={setStartDate}
      endDate={endDate}
      onEndDateChange={setEndDate}
      selectedMessagesNumRange={selectedMessagesNumRange}
      onSelectedMessagesRangeChange={setSelectedMessagesNumRange}
      isOnlyDownvotedMessages={isOnlyDownvotedMessages}
      onIsOnlyDownvotedMessagesChange={onIsOnlyDownvotedMessagesChange}
      customMinNumOfMessages={customMinNumOfMessages}
      onCustomMinNumOfMessagesChange={setCustomMinNumOfMessages}
      customMaxNumOfMessages={customMaxNumOfMessages}
      onCustomMaxNumOfMessagesChange={setCustomMaxNumOfMessages}
    >
      {isLoading ? (
        loader
      ) : data?.conversations.length === 0 ? (
        <div className="flex h-full flex-col items-center justify-center">
          <img src={noAgentImg} alt="No Agent Found" className="w-60" />
          <p className="mt-5 max-w-[500px] text-center text-sm font-medium text-neutral-400">
            There is no data available right now. Create your agent, and you will start seeing conversations here.
          </p>
        </div>
      ) : (
        data?.conversations?.map(conversationItem => {
          return (
            <ChatHistoryDialogListItem
              key={conversationItem.conversation._id}
              conversationItem={conversationItem}
              conversationUsers={conversationItem.conversation.users}
              onOpen={() => setCurrentConversation(conversationItem)}
            />
          );
        })
      )}
    </ChatHistoryDialog>
  );
};
