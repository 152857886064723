import { Icons } from "@/components/ui/icons";

type Props = {
  currentUsage: number | undefined;
  limit: number | undefined;
};

export const KnowledgeLimitInfoBadge = ({ currentUsage, limit }: Props) => {
  const getLimitInfo = () => {
    if (limit === undefined) {
      return currentUsage;
    }

    if (limit <= 0) {
      return (
        <>
          {currentUsage}/
          <Icons.Infinity className="h-4" />
        </>
      );
    }

    return `${currentUsage}/${limit}`;
  };

  return (
    <div className="flex h-5 min-w-5 items-center justify-center rounded-full bg-primary-500 px-1.5">
      <p className="flex items-center justify-center text-xs font-medium text-white">{getLimitInfo()}</p>
    </div>
  );
};
