import { Button } from "@/components/ui/button";
import { DialogClose, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { useGetCustomerBillingInfo } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { SubscriptionTierNames, SubscriptionTiers } from "@/types/subscriptions";
import { useGetUser } from "@/data/queries/useGetUser";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";

const iconsMap = {
  [SubscriptionTiers.FREE]: Icons.TierUpgrade,
  [SubscriptionTiers.STARTUP]: Icons.TierStartup,
  [SubscriptionTiers.PRO]: Icons.TierPro,
  [SubscriptionTiers.SCALE]: Icons.TierScale,
  [SubscriptionTiers.PREMIUM]: Icons.TierPremium,
  [SubscriptionTiers.ENTERPRISE]: Icons.TierEnterprise,
};

export const StripePaymentSuccess = () => {
  const { selectedPlan } = useSubscriptionTierDialogContext();
  const { user } = useGetUser();
  const { data: billingPlanInfo, isLoading } = useGetCustomerBillingInfo();
  if (!selectedPlan) {
    return null;
  }

  const PlanIcon = iconsMap[selectedPlan.plan.tier];

  if (isLoading) {
    return <LoadingSpinner className="flex size-full items-center justify-center" />;
  }

  if (!billingPlanInfo) {
    return <h2 className="my-10 text-center">There was an error while retreiving your plan information.</h2>;
  }

  return (
    <>
      <DialogHeader className="min-h-12 text-base font-bold">Subscription Confirmed!</DialogHeader>
      <div className="grid place-items-center overflow-auto px-8">
        <PlanIcon className="size-[200px]" />
        <h2 className="mb-10 text-primary-400">
          Welcome to <span className="uppercase">{SubscriptionTierNames[user?.subscription.tier ?? 0]}</span>
        </h2>
        <div className="w-full max-w-[500px]">
          <h3 className="mb-4  text-base font-bold text-neutral-750">Payment Summary</h3>
          <div className="mb-20 grid grid-cols-2 gap-x-5 gap-y-1 rounded-md border border-neutral-300 p-4 text-xs font-medium text-neutral-600">
            <span>Price</span>{" "}
            <span className="text-right">${`${(billingPlanInfo.subscription.price / 100).toFixed(2)}`}</span>
            <span>Billing period</span>{" "}
            <span className="text-right">
              {billingPlanInfo.subscription.interval === "month" ? "monthly" : "annually"}
            </span>
            <span>Renewal date</span>{" "}
            <span className="text-right">
              {new Date(billingPlanInfo.subscription.nextBillingDate * 1000).toLocaleDateString()}
            </span>
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="tertiary">Done</Button>
          </DialogClose>
        </DialogFooter>
      </div>
    </>
  );
};
