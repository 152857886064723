export const checkIfKnowledgeLimitExceeded = ({
  currentUsage,
  limit,
}: {
  currentUsage: number | undefined;
  limit: number | undefined;
}) => {
  if (currentUsage === undefined || limit === undefined) {
    return true;
  }

  if (limit < 0) {
    return false;
  }

  return currentUsage >= limit;
};
