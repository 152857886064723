import { DropdownMenu, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import { ShareAgentDialog } from "@/components/dialogs/ShareAgentDialog/ShareAgentDialog";
import AgentTileOptionsButton from "./AgentTileOptionsButton";

type AgentTileLikeOptionsProps = {
  agent: {
    _id: string;
    name: string;
    likedByUser?: boolean;
  };
  onLikeAgentClick: () => void;
};

const AgentTileLikeOptions = ({ agent, onLikeAgentClick }: AgentTileLikeOptionsProps) => {
  return (
    <DropdownMenu modal={false}>
      <AgentTileOptionsButton />
      <DropdownMenuContent onClick={e => e.stopPropagation()} align="start">
        <DropdownMenuItem onSelect={e => e.preventDefault()}>
          <ShareAgentDialog
            agentId={agent._id}
            agentName={agent.name}
            renderButton={() => (
              <button className="flex w-full items-center gap-1.5 text-xs">
                <Icons.Share />
                Share
              </button>
            )}
          />
        </DropdownMenuItem>
        {agent.likedByUser ? (
          <DropdownMenuItem className="flex items-center gap-1.5 text-xs" onClick={onLikeAgentClick}>
            <Icons.Unlike className="text-red-600" />
            Unlike
          </DropdownMenuItem>
        ) : (
          <DropdownMenuItem className="flex items-center gap-1.5 text-xs" onClick={onLikeAgentClick}>
            <Icons.Heart className="text-primary-black" />
            Like
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AgentTileLikeOptions;
