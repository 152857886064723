import { cn } from "@/lib/utils";
import type { ReactElement } from "react";
import type React from "react";
import { cloneElement, isValidElement } from "react";

export const StatsSingleAgentRowChannelIcon = ({ icon }: { icon: React.ReactNode }) => {
  if (isValidElement(icon)) {
    const originalClassName = (icon.props as { className?: string }).className || "";

    const newClassName = cn(
      originalClassName,
      "absolute left-1/2 z-0 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[30px] w-[30px]"
    );

    const iconWithProps = cloneElement(icon as ReactElement, {
      className: newClassName,
    });

    return iconWithProps;
  }

  return null;
};
