import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { Icons } from "@/components/ui/icons";
import { Avatar } from "@/components/Avatar";
import { useGetUser } from "@/data/queries/useGetUser";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { MobileNav } from "@/components/navigation/MobileNav";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import { SubscriptionTierButton } from "./SubscriptionTierButton";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { NavigationProfileSections } from "../Profiles/NavigationProfileSection";

export const TopBarNav = () => {
  const navigate = useNavigate();
  const { user } = useGetUser();
  const { setNavigationProfileOpen } = useNavigationProfileContext();
  const { isMobile } = useMediaQueriesContext();
  const isNotPhone = useTailwindMediaQuery("sm");

  return (
    <nav className="fixed z-10 flex w-full items-center justify-between overflow-hidden bg-transparent px-6 py-5">
      <MobileNav />
      <Icons.AgentLogo
        className="max-h-8 w-[100px] cursor-pointer text-primary-black lg:max-h-10"
        onClick={() => navigate(ROUTES.workspace)}
      />
      <div className="flex items-center gap-6">
        {user?.subscription.tier !== undefined && !isMobile && <SubscriptionTierButton tier={user.subscription.tier} />}
        <Avatar
          src={user?.avatar}
          name={user?.name}
          onClick={() =>
            setNavigationProfileOpen({ open: true, section: isNotPhone ? NavigationProfileSections.MY_ACCOUNT : null })
          }
          className="size-8 cursor-pointer rounded-full lg:size-10"
          size="custom"
        />
      </div>
    </nav>
  );
};
