import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import type { Agent } from "@/types/agent";
import { useDuplicateAgent } from "@/data/mutations/useDuplicateAgent";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import DuplicateAgent from "@/assets/images/DuplicateAgent.svg";
import type { F1 } from "@/types/types";

type DuplicateAgentDialogProps = {
  agentId: Agent["_id"] | null;
  setAgentId: F1<Agent["_id"] | null>;
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
};

export const DuplicateAgentDialog = ({
  agentId,
  setAgentId,
  isDialogOpen,
  setShowDialog,
}: DuplicateAgentDialogProps) => {
  const { mutateAsync, isPending } = useDuplicateAgent();
  const navigate = useNavigate();

  const duplicateAgent = async () => {
    if (!agentId) {
      return;
    }
    const newDuplicatedAgent = await mutateAsync(agentId);
    setShowDialog(false);
    setAgentId(null);
    navigate(ROUTES.editAgent(newDuplicatedAgent._id));
  };

  const toggleDialog = (open: boolean) => {
    setShowDialog(open);
    setAgentId(null);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={toggleDialog}>
      <DialogContent variant="medium" className="h-[290px]">
        <DialogHeader></DialogHeader>
        <div className="flex flex-col items-center gap-2.5 px-8 pb-4">
          <img src={DuplicateAgent} alt="Duplicate Agent" />
          <div className="text-xl font-bold">Do you want to duplicate this agent?</div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="md" variant="secondary">
              Cancel
            </Button>
          </DialogClose>
          <Button size="md" variant="primary" onClick={duplicateAgent} loading={isPending}>
            Duplicate
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
