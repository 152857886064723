import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAgentFormNavigationTab } from "../hooks/useAgentFormNavigationTab";
import { AgentFormTabs, agentTabs } from "../types/AgentFormTypes";
import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";

type AgentTabsProps = {
  disabled: boolean;
  agentExist: boolean;
};

export const AgentTabs = ({ disabled = false, agentExist }: AgentTabsProps) => {
  const { activeTab, switchTab } = useAgentFormNavigationTab();
  return (
    <Tabs value={activeTab.name} className="mb-8 space-y-4" orientation="vertical">
      <TabsList className="h-auto w-full flex-col overflow-hidden rounded-b-none rounded-t-sm bg-transparent">
        {agentTabs.map((tab, index) => (
          <>
            <TabsTrigger
              key={tab.name}
              value={tab.name}
              disabled={
                disabled ||
                (!agentExist && tab == AgentFormTabs.DEPLOY) ||
                (!agentExist && tab == AgentFormTabs.ACTIONS)
              }
              onClick={() => {
                switchTab({ newTab: tab });
              }}
              className="w-full justify-start rounded-[4px] py-6 pl-8 text-neutral-500 data-[state=active]:border-r-4 data-[state=active]:border-r-primary-400 data-[state=active]:bg-primary-50 data-[state=active]:text-primary-black"
            >
              {index + 1}. {tab.name}
            </TabsTrigger>
            {tab.name === activeTab.name && !!tab.subTabs.length && (
              <div className="flex flex-col self-start duration-700 animate-in fade-in">
                {tab.subTabs.map(subTab => (
                  <Link
                    to={`#${subTab.domId}`}
                    state={{ tab }}
                    replace
                    key={subTab.name}
                    className={cn("py-2 pl-10 text-sm font-medium text-neutral-400 focus:text-primary-400")}
                  >
                    {subTab.name}
                  </Link>
                ))}
              </div>
            )}
          </>
        ))}
      </TabsList>
    </Tabs>
  );
};
