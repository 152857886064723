import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";

type Props = {
  text: string;
};

type Response = {
  detectedLanguage: string;
};

export const useDetectLanguage = () => {
  const sendHelpForm = async (payload: Props) => {
    const { data } = await restClient.post<Response>(apiPaths.detectLanguage, payload);
    return data;
  };

  const mutation = useMutation<Response, AxiosError<{ message: string }>, Props>({
    mutationFn: sendHelpForm,
  });

  return mutation;
};
