import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";

type Response = {
  languages: {
    language: string;
    name: string;
  }[];
};

export const allLanguages = {
  all: ["allLanguages"] as const,
};

const getAllLanguages = async () => {
  const { data } = await restClient.get<Response>(apiPaths.getAllLanguages);
  return data;
};

export const useGetAllLanguages = () => {
  const query = useQuery({
    queryKey: allLanguages.all,
    queryFn: getAllLanguages,
  });

  return query;
};
