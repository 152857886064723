import { WorkspaceWhatsNewCarousel } from "./WorkspaceWhatsNewCarousel";
import { Link } from "react-router-dom";
import { ROUTES } from "@/constants/routes";

const WorkspaceWhatsNew = () => {
  return (
    <div className="h-full border-l-[1.5px] border-neutral-200 p-6">
      <h2 className="mb-6 text-2xl text-neutral-750">
        What's new on <span className="text-primary-400">AgentX</span>?
      </h2>

      <WorkspaceWhatsNewCarousel />

      <h2 className="my-4 mt-10 text-2xl text-neutral-750">Features</h2>
      <div className="ml-4 flex flex-col gap-4">
        <p>
          How to deploy and publish an agent to your website?{" "}
          <Link
            className="text-primary-500 hover:underline"
            to="https://www.agentx.so/post/how-to-publish-an-ai-chat-agent-created-on-agentx-1-0"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read
          </Link>
        </p>
        <p>
          How to create a formless Lead Generation agent?{" "}
          <Link
            className="text-primary-500 hover:underline"
            to="https://www.agentx.so/post/how-to-create-a-lead-generation-ai-agent-chatbot-on-agentx---formless-and-straight-to-your-mailbox"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read
          </Link>
        </p>
        <p>
          How to create an AI group chat?{" "}
          <Link
            className="text-primary-500 hover:underline"
            to="https://www.agentx.so/post/how-to-create-a-group-chat-with-gpt-4o-and-claude-3-5-and-more"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read
          </Link>
        </p>
        <p>
          Join the waitlist for our upcoming e-commerce AI release.{" "}
          <Link
            className="text-primary-500 hover:underline"
            to="https://www.agentx.so/e-commerce"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join
          </Link>
        </p>
      </div>

      <h2 className="my-4 mt-10 text-2xl text-neutral-750">Need professional helps?</h2>

      <div className="flex flex-col gap-4">
        <p>
          Leave us a message{" "}
          <Link className="text-primary-500 hover:underline" to={ROUTES.help}>
            here
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default WorkspaceWhatsNew;
