import Lottie from "react-lottie";
import animationData from "@/assets/animations/line-chart-up.json";
import { cn } from "@/lib/utils";

type SizeProps =
  | {
      width?: undefined | null;
      parentWidth: number;
      maxWidth: number;
    }
  | {
      width: number;
      parentWidth?: undefined;
      maxWidth?: undefined;
    }
  | {
      width?: undefined | null;
      parentWidth?: undefined | null;
      maxWidth?: undefined | null;
    };

type Props = {
  trend: number;
  className?: string;
} & SizeProps;

const StatsDataTrendChart = ({ trend, className, width, parentWidth, maxWidth }: Props) => {
  const getTrendType = (value: number) => {
    return value < 0 ? "down" : "up";
  };

  const trendType = getTrendType(trend);

  const getAnimationWidth = () => {
    if (width) {
      return width;
    }

    if (parentWidth) {
      const widthBasedOnParent = parentWidth * 0.2;

      if (widthBasedOnParent >= maxWidth) {
        return maxWidth;
      }

      return widthBasedOnParent;
    }

    return 92.55;
  };

  const animationWidth = getAnimationWidth();
  const animationHeight = animationWidth * 0.5;

  return (
    <div className="relative">
      <div className="absolute left-1/2 top-1/2 h-8 w-14 -translate-x-1/2 -translate-y-1/2 rounded-full bg-trend-chart blur-[30px]" />
      <div
        className={cn({
          className,
          "rotate-180 -scale-x-100": trendType === "down",
        })}
      >
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={animationHeight}
          width={animationWidth}
        />
      </div>
    </div>
  );
};

export default StatsDataTrendChart;
