import { useQuery } from "@tanstack/react-query";
import type { Agent } from "@/types/agent";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { SingleCommunityAgent } from "@/types/community";

export const singleCommunityAgentKeys = {
  all: ["singleCommunityAgent"] as const,
  id: (id: SingleCommunityAgent["_id"]) => [...singleCommunityAgentKeys.all, id] as const,
};

type Props = {
  agentId: SingleCommunityAgent["_id"];
};

const getSingleCommunityAgent = async (agentId: Agent["_id"]) => {
  const { data } = await restClient.get<SingleCommunityAgent>(apiPaths.getSingleCommunityAgent(agentId));
  return data;
};

export const useGetSingleCommunityAgent = ({ agentId }: Props) => {
  const query = useQuery({
    queryKey: singleCommunityAgentKeys.id(agentId),
    queryFn: () => getSingleCommunityAgent(agentId),
    enabled: !!agentId,
  });

  return query;
};
