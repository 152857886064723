import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { Agent } from "@/types/agent";
import type { Tool } from "@/types/tools";

export const agentToolsKeys = {
  all: ["agentTools"],
  id: (id: Agent["_id"]) => [...agentToolsKeys.all, id],
};

type Props = {
  agentId: Agent["_id"];
};

const getAgentTools = async ({ agentId }: Props) => {
  const { data } = await restClient.get<Tool[]>(apiPaths.getAgentTools(agentId));
  return data;
};

export const useGetAgentTools = ({ agentId }: Props) => {
  return useQuery({
    queryKey: agentToolsKeys.id(agentId),
    queryFn: () => getAgentTools({ agentId }),
    enabled: !!agentId,
  });
};
