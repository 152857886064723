import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import { restClient } from "../initAxios";
import type { Agent } from "@/types/agent";

export const chatHistoryWithAgentsKeys = {
  all: ["chatHistoryWithAgents"] as const,
  fullHistory: () => [...chatHistoryWithAgentsKeys.all, "full"] as const,
  limited: (limit?: number) => [...chatHistoryWithAgentsKeys.all, limit] as const,
};

type Props = {
  limit?: number;
};

export type ChatHistoryWithAgents = {
  agents: {
    avatar: Agent["avatar"];
    name: Agent["name"];
    _id: Agent["_id"];
    publishedToCommunity: Agent["publishedToCommunity"];
    state?: Agent["state"];
  }[];
  conversationsCount: number;
};

const getChatHistoryWithAgents = async ({ limit }: Props = {}) => {
  const { data } = await restClient.get<ChatHistoryWithAgents[]>(apiPaths.getChatHistoryWithAgents({ limit }));
  return data;
};

export const useGetChatHistoryWithAgents = () => {
  return useQuery({
    queryKey: chatHistoryWithAgentsKeys.fullHistory(),
    queryFn: () => getChatHistoryWithAgents(),
  });
};

export const useGetChatHistoryWithAgentsLimited = ({ limit }: Props = {}) => {
  return useQuery({
    queryKey: chatHistoryWithAgentsKeys.limited(limit),
    queryFn: () => getChatHistoryWithAgents({ limit }),
  });
};
