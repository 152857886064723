import { useRef, useEffect } from "react";

export const VideoPlayer = ({ src }: { src: string }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const loopDuration = 1.9; // Loop duration in 1.9 seconds to prevent showing branding video at the end
  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const video = videoRef.current;

    const handleTimeUpdate = () => {
      if (video.currentTime >= loopDuration) {
        video.currentTime = 0;
      }
    };

    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  return (
    <video
      ref={videoRef}
      autoPlay
      loop
      controls
      muted
      playsInline
      crossOrigin="anonymous"
      className="my-4 w-full max-w-[450px]	rounded-md object-contain"
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};
