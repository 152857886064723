import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { BillingPlanCoupon } from "@/types/subscriptions";

export const totalUsingCouponKeys = {
  all: ["totalUsingCoupon"] as const,
  id: ({ planId, coupon }: Props) => [...totalUsingCouponKeys.all, { planId, coupon }] as const,
};

type Props = {
  planId: string;
  coupon: string;
};
const getTotalUsingCoupon = async (props: Props) => {
  const { data } = await restClient.get<BillingPlanCoupon>(apiPaths.getTotalUsingCoupon(props));
  return data;
};

export const useGetTotalUsingCoupon = (props: Props) => {
  const query = useQuery({
    queryKey: totalUsingCouponKeys.id(props),
    queryFn: () => getTotalUsingCoupon(props),
    enabled: !!props.planId && !!props.coupon,
    retry: false,
  });

  return query;
};
