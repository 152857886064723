export function parseConcatenatedJsonObjects(concatenatedString: string) {
  const jsonObjects = [];
  let startIndex = 0;

  while (startIndex < concatenatedString.length) {
    const openBraceIndex = concatenatedString.indexOf("{", startIndex);
    if (openBraceIndex === -1) {
      break;
    }

    const closeBraceIndex = findMatchingClosingBrace(concatenatedString, openBraceIndex);
    if (closeBraceIndex === -1) {
      break;
    }

    const jsonString = concatenatedString.slice(openBraceIndex, closeBraceIndex + 1);
    try {
      const jsonObject = JSON.parse(jsonString);
      jsonObjects.push(jsonObject);
      startIndex = closeBraceIndex + 1;
    } catch (e) {
      // JSON.parse failed, move start index to look for the next open brace
      startIndex = openBraceIndex + 1;
    }
  }

  return jsonObjects;
}

function findMatchingClosingBrace(str: string, openBraceIndex: number) {
  let openBraces = 0;

  for (let i = openBraceIndex; i < str.length; i++) {
    if (str[i] === "{") {
      openBraces++;
    } else if (str[i] === "}") {
      openBraces--;
      if (openBraces === 0) {
        return i;
      }
    }
  }

  return -1;
}
