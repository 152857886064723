import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import type { Agent } from "@/types/agent";
import { SocialMediaShare } from "@/components/SocialMediaShare";
import type { OnShareMutationCallback } from "@/components/ShareMultiEmails";
import { ShareMultiEmails } from "@/components/ShareMultiEmails";
import type { ReactElement } from "react";
import { useShareAgentByEmail } from "@/data/mutations/useShareAgentByEmail";

type ShareAgentDialogProps = {
  agentId: Agent["_id"];
  agentName?: Agent["name"];
  renderButton: () => ReactElement;
};

export const ShareAgentDialog = ({ agentId, agentName, renderButton }: ShareAgentDialogProps) => {
  const shareLink = `${import.meta.env.VITE_BASE_FRONTEND_URL || "localhost:3001"}/share/agent/${agentId}${agentName ? "/" + agentName.replaceAll(" ", "-") : ""}`;
  const { mutate, isPending } = useShareAgentByEmail();

  const handleShare: OnShareMutationCallback = ({ emails, onSuccessCallback }) => {
    mutate({ agentId, emails }, { onSuccess: onSuccessCallback });
  };
  return (
    <Dialog>
      <DialogTrigger asChild>{renderButton()}</DialogTrigger>
      <DialogContent className="!h-[400px]">
        <DialogHeader>
          <DialogTitle>Share Agent</DialogTitle>
        </DialogHeader>
        <DialogDescription className="flex h-full flex-1 flex-col items-center gap-6">
          <ShareMultiEmails onShare={handleShare} isMutationPending={isPending} />
          <SocialMediaShare shareLink={shareLink} />
        </DialogDescription>
        <DialogFooter className="p-4">
          <DialogClose asChild>
            <Button size="md" variant="tertiary">
              Cancel
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
