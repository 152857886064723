import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";

type Props = {
  title: string;
  content: string;
  image?: string[];
};

export const useSendHelpForm = () => {
  const sendHelpForm = async (payload: Props) => {
    const { data } = await restClient.post<string>(apiPaths.sendHelpForm, payload);
    return data;
  };

  const mutation = useMutation<string, AxiosError<{ message: string }>, Props>({
    mutationFn: sendHelpForm,
  });

  return mutation;
};
