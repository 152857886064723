import { FileIcon } from "@/components/AgentForm/components/Knowledge/components/FileIcon";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import type { DomainLinks, EmbedKnowledges } from "@/types/agent";
import { useState } from "react";

type AgentDetailsEmbedKnowledgeListProps = {
  embedKnowledges: {
    web: EmbedKnowledges[];
    doc: EmbedKnowledges[];
    faq: EmbedKnowledges[];
  };
  handleSimplePinChange: (checked: boolean, idOrIds: string | string[]) => void;
  sortedWebData: DomainLinks[];
  selectedChatKnowledge: string[];
};
export const AgentDetailsEmbedKnowledgeList = ({
  embedKnowledges,
  sortedWebData,
  handleSimplePinChange,
  selectedChatKnowledge,
}: AgentDetailsEmbedKnowledgeListProps) => {
  const [expandedWebNodePins, setExpandedWebNodePins] = useState<Set<string>>(new Set());

  const handleExpandWebNodePin = (webNodeDomain: string) => {
    setExpandedWebNodePins(prevPins => {
      const newPinsSet = new Set(prevPins);
      if (!newPinsSet.delete(webNodeDomain)) {
        newPinsSet.add(webNodeDomain);
      }
      return newPinsSet;
    });
  };

  return (
    <>
      {embedKnowledges.doc.map(file => {
        const isDocumentChecked = selectedChatKnowledge.includes(file._id);
        return (
          <div
            key={file._id}
            className={cn(
              "group grid grid-cols-[1fr_1rem] gap-2 px-3 py-2",
              isDocumentChecked ? "bg-primary-100" : " hover:bg-neutral-100"
            )}
          >
            <div className="custom-break-anywhere">
              <div className="flex items-center gap-1">
                <FileIcon className="size-4 min-w-4" fileName={file.filename} />
                {file.filename}
              </div>
              <div className="text-neutral-400">{file.description}</div>
            </div>
            <Checkbox
              className={cn("hidden duration-300 animate-in fade-in group-hover:block", isDocumentChecked && "block")}
              variant="pin"
              size="sm"
              checked={isDocumentChecked}
              onCheckedChange={checked => handleSimplePinChange(checked, file._id)}
            />
          </div>
        );
      })}
      {sortedWebData.map(websiteNode => {
        const isWebNodeChecked = websiteNode.links.some(link => selectedChatKnowledge.includes(link._id));
        return (
          <>
            <div
              key={websiteNode.domain}
              className={cn(
                "group grid grid-cols-[0.75rem_1fr_1rem] gap-2 px-3 py-2",
                isWebNodeChecked ? "bg-primary-100" : "hover:bg-neutral-100"
              )}
            >
              <Button variant="ghost" size="custom" onClick={() => handleExpandWebNodePin(websiteNode.domain)}>
                <Icons.Triangle
                  className={cn("size-3 transition-all", !expandedWebNodePins.has(websiteNode.domain) && "-rotate-90")}
                />
              </Button>
              <div className="flex items-center gap-1 custom-break-anywhere">
                <div className="flex h-4 min-w-[16px] items-center justify-center rounded-full bg-primary-500 px-1 text-xxs font-medium text-white">
                  {websiteNode.links.length}
                </div>
                <div
                  className="flex cursor-pointer items-center gap-1 text-left"
                  onClick={() => handleExpandWebNodePin(websiteNode.domain)}
                >
                  <Icons.Link2 className="size-4 min-w-4" /> {websiteNode.domain}
                </div>
              </div>
              <div>
                <Checkbox
                  className={cn(
                    "hidden duration-300 animate-in fade-in group-hover:block",
                    isWebNodeChecked && "block"
                  )}
                  variant="pin"
                  size="sm"
                  onCheckedChange={checked =>
                    handleSimplePinChange(
                      checked,
                      websiteNode.links.map(link => link._id)
                    )
                  }
                  checked={isWebNodeChecked}
                />
              </div>
            </div>
            {expandedWebNodePins.has(websiteNode.domain) && (
              <div className={cn("duration-300 animate-in fade-in", isWebNodeChecked && "bg-primary-100")}>
                {websiteNode.links.map(url => (
                  <div
                    key={url._id}
                    className={cn(
                      "group grid grid-cols-[1fr_1rem] gap-1 py-2 pl-8 pr-3 ",
                      !isWebNodeChecked && "hover:bg-neutral-100"
                    )}
                  >
                    <div className="flex items-center gap-1 break-all text-left">
                      <Icons.Link2 className="size-4 min-w-4" /> {url.source}
                    </div>
                    <Checkbox
                      className={cn(
                        "text-primary-200 duration-300 animate-in fade-in group-hover:text-primary-400 data-[state=checked]:text-primary-200"
                      )}
                      variant="pin"
                      size="sm"
                      checked={selectedChatKnowledge.includes(url._id)}
                      onCheckedChange={checked => handleSimplePinChange(checked, url._id)}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        );
      })}
      {embedKnowledges.faq.map(faqItem => {
        const isFaqChecked = selectedChatKnowledge.includes(faqItem._id);
        return (
          <div
            key={faqItem._id}
            className={cn(
              "group grid grid-cols-[1fr_1rem] gap-2 px-3 py-2",
              isFaqChecked ? "bg-primary-100" : "hover:bg-neutral-100"
            )}
          >
            <div className="custom-break-anywhere">
              <div className="flex items-center gap-1">
                <Icons.Message className="size-4 min-w-4" />
                {faqItem.description}
              </div>
              <div className="text-neutral-400">{faqItem.content}</div>
            </div>
            <Checkbox
              className={cn("hidden duration-300 animate-in fade-in group-hover:block", isFaqChecked && "block")}
              variant="pin"
              size="sm"
              checked={isFaqChecked}
              onCheckedChange={checked => handleSimplePinChange(checked, faqItem._id)}
            />
          </div>
        );
      })}
    </>
  );
};
