import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { RelationResponse } from "@/types/relation";
import type { CommunityAgent } from "@/types/community";

type Props = {
  agentId: CommunityAgent["_id"];
  emails: string[];
};

const shareAgentByEmail = async ({ agentId, emails }: Props) => {
  const { data } = await restClient.post<RelationResponse>(apiPaths.shareAgentByEmail(agentId), { emails });
  return data;
};

export const useShareAgentByEmail = () => {
  const mutation = useMutation<RelationResponse, AxiosError, Props>({
    mutationFn: shareAgentByEmail,
  });

  return mutation;
};
