import type { Conversation, Message } from "@/types/conversation";
import { restClient } from "./initAxios";
import { apiPaths } from "./apiPaths";

type Props = {
  conversationId: Conversation["_id"];
  messageId: Message["_id"];
};

export const getSingleMessage = async ({ conversationId, messageId }: Props) => {
  const { data } = await restClient.get<Message>(apiPaths.getConversationSingleMessage({ conversationId, messageId }));
  return data;
};
