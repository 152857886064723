import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { useGetUser } from "@/data/queries/useGetUser";
import { LoadingSpinner } from "./ui/loading-spinner";
import { useGetAppConfig } from "@/data/queries/useGetAppConfig";
import MainLayout from "./Layouts/MainLayout";

export const ProtectedRoutes = () => {
  const token = localStorage.getItem("token");
  const { user, isLoading } = useGetUser({ enabled: !!token, retry: 0 });

  useGetAppConfig();

  if (!user && isLoading) {
    return <LoadingSpinner className="flex h-screen w-screen items-center justify-center" />;
  }
  if (!user) {
    return <Navigate to={ROUTES.login} replace />;
  }

  window.isPanel = true;

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};
