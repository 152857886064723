import { useState, type PropsWithChildren } from "react";
import { SubscriptionTierContext } from "./SubscriptionTierDialogContext";
import { SubscriptionTierDialog } from "@/components/dialogs/SubscriptionTierDialog/SubscriptionTierDialog";
import type { BillingPlan, SubscriptionPeriod } from "@/types/subscriptions";
import type { F1 } from "@/types/types";

export type SubscriptionTierDialogContextProps = {
  isOpen: boolean;
  openDialog: (props?: OpenDialogProps) => void;
  closeDialog: () => void;
  activeStep: SubscriptionDialogSteps;
  setActiveStep: F1<SubscriptionDialogSteps>;
  selectedPlan: SelectedPlan | null;
  setSelectedPlan: F1<SelectedPlan>;
};

export type SelectedPlan = { plan: BillingPlan; period: SubscriptionPeriod };

const subscriptionDialogSteps = [
  "subscriptionTiers",
  "stripePaymentSummary",
  "stripePaymentSuccess",
  "changeSubscriptionTier",
] as const;

export type SubscriptionDialogSteps = (typeof subscriptionDialogSteps)[number];

export type OpenDialogProps = {
  step: SubscriptionDialogSteps;
  selectedPlan: SelectedPlan;
};

export const SubscriptionTierDialogProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState<SubscriptionDialogSteps>("subscriptionTiers");
  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan | null>(null);

  const openDialog = (props?: OpenDialogProps) => {
    if (props) {
      setActiveStep(props.step);
      setSelectedPlan(props.selectedPlan);
    }
    setIsOpen(true);
  };
  const closeDialog = () => {
    setIsOpen(false);
    setTimeout(() => {
      setSelectedPlan(null);
      setActiveStep("subscriptionTiers");
    }, 300);
  };
  const value = {
    isOpen,
    openDialog,
    closeDialog,
    activeStep,
    setActiveStep,
    selectedPlan,
    setSelectedPlan,
  };

  return (
    <SubscriptionTierContext.Provider value={value}>
      <SubscriptionTierDialog />
      {children}
    </SubscriptionTierContext.Provider>
  );
};
