import { Avatar } from "@/components/Avatar";
import type { FullAgent } from "@/types/agent";
import { useGetUser } from "@/data/queries/useGetUser";
import { useSendChatMessage } from "../hooks/useSendChatMessage";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useUpdateNavConversationHistory } from "@/data/queryUpdates/useUpdateNavConversationHistory";

type PredefinedQuestionsProps = {
  agent: FullAgent;
};

export const PredefinedQuestions = ({ agent }: PredefinedQuestionsProps) => {
  const { updateNavConversationHistory } = useUpdateNavConversationHistory();

  const { user } = useGetUser();
  const { sendMessage } = useSendChatMessage();
  const { isPreview } = useChatContext();

  const questions = agent.definedActions?.predefined;

  if (!questions?.length) {
    return null;
  }

  const handleSendMessage = (message: string) => {
    void sendMessage({
      messageText: message,
      onSendMessageCallback: () => {
        if (!isPreview) {
          updateNavConversationHistory({ agents: [agent] });
        }
      },
      agents: [agent],
    });
  };

  return (
    <div className="px-4 py-1 hover:bg-primary-50">
      <div className="flex items-center gap-3">
        <Avatar name={user?.name} src={user?.avatar} />
        <div className="text-sm font-bold text-primary-black">Me:</div>
      </div>

      <div className="mx-1 ml-[50px] mt-3 flex cursor-pointer flex-wrap text-xs">
        {questions.map(({ question }) => (
          <div
            key={question}
            onClick={() => handleSendMessage(question)}
            className="my-1 mr-2 rounded-light border border-solid border-cloud-dark bg-white p-2 transition duration-200 ease-in-out hover:bg-cloud-dark"
          >
            {question}
          </div>
        ))}
      </div>
    </div>
  );
};
