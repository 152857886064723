import { AgentName } from "./components/AgentName";
import { LLMModel } from "./components/LLMModel";
import { CreativityLevel } from "./components/CreativityLevel";
import { AgentBio } from "./components/AgentBio";
import { GreetingMessages } from "./components/GreetingMessage";
import { ResponseInstruction } from "./components/ResponseInstruction";
import { AgentAvatar } from "./components/AgentAvatar";

export const GeneralInfo = () => {
  return (
    <div className="flex flex-col gap-3 md:flex-row md:gap-8">
      <AgentAvatar />
      <div className="flex w-full flex-col gap-5">
        <AgentName />
        <div className="flex w-full flex-col gap-3 sm:flex-row">
          <LLMModel />
          <CreativityLevel />
        </div>
        <AgentBio />
        <GreetingMessages />
        <ResponseInstruction />
      </div>
    </div>
  );
};
