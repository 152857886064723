import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
export const WhatsNew = () => {
  return (
    <div className="flex h-full flex-col gap-3 px-6 py-4 lg:ml-auto lg:w-1/3 lg:border-l lg:border-neutral-200">
      <h4 className="pb-2 text-2xl font-bold">FAQs</h4>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="howItWorks" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">What is AI Agent and what does AgentX do?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            AI Agent is an autonomous system powered by large language model, complemented by several key components,
            such as goal driven task planning, short-term and long-term memory, and capable to use tools. AgentX is a
            platform that offers all the necessary tool sets for users to build their AI agents.
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="howItWorks" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">Do I need coding skills to build an AI Agent?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            Not required. AgentX offers a no-code solution, allowing users to build and tune their AI agents using
            natural language without any programming skill. However, we offer advanced tool for users who are
            comfortable with coding to achieve more customized results.
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="howItWorks" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">What model can I use to build an AI agent?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            AgentX provides multi-model mix match building experience. You can choose LLM from different vendor as you
            like, such as OpenAI GPT3.5, GPT4, Google Gemini pro, and Anthropic Claude. You can also empower your AI
            Agent with Stable Diffusion XL image generation engine. We work hard to integrate more powerful models for
            you to pick. Feel free to send us any suggestions at contact@agentx.so
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
