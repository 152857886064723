import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { Agent, ExternalAgent } from "@/types/agent";

export const externalAgentsKeys = {
  all: ["externalAgents"],
  id: (id: Agent["_id"]) => [...externalAgentsKeys.all, id],
};

type Props = {
  agentId: Agent["_id"];
};
type ExternalAgents = {
  externalRobots: ExternalAgent[];
};

const getExternalAgents = async ({ agentId }: Props) => {
  const { data } = await restClient.get<ExternalAgents>(apiPaths.getExternalAgents(agentId));
  return data.externalRobots;
};

export const useGetExternalAgents = ({ agentId }: Props) => {
  return useQuery({
    queryKey: externalAgentsKeys.id(agentId),
    queryFn: () => getExternalAgents({ agentId }),
    enabled: !!agentId,
    placeholderData: [],
  });
};
