import type { Agent } from "@/types/agent";

const getAgentsKey = (agentIds: Agent["_id"][]) => agentIds.sort().join(",");

export class LocalStorageHelpers {
  static getAgentsLocalStorageConversation = (agentIds: Agent["_id"][]) => {
    return localStorage.getItem(getAgentsKey(agentIds));
  };

  static saveAgentsLocalStorageConversation = (agentIds: Agent["_id"][], conversationId: string) => {
    localStorage.setItem(getAgentsKey(agentIds), conversationId);
  };

  static removeAgentsLocalStorageConversation = (agentIds: Agent["_id"][]) => {
    localStorage.removeItem(getAgentsKey(agentIds));
  };

  static updateLocalStorageConversation = ({
    prevAgentIds,
    updatedAgentIds,
    conversationId,
  }: {
    prevAgentIds: Agent["_id"][];
    updatedAgentIds: Agent["_id"][];
    conversationId: string;
  }) => {
    this.removeAgentsLocalStorageConversation(prevAgentIds);
    this.saveAgentsLocalStorageConversation(updatedAgentIds, conversationId);
  };
}
