import { CommunityPublishing } from "./components/CommunityPublishing";
import { Integrations } from "./components/Integrations/Integrations";

export const Deploy = () => {
  return (
    <>
      <CommunityPublishing />
      <Integrations />
    </>
  );
};
