import { useQuery } from "@tanstack/react-query";
import type { Agent, FullAgent } from "@/types/agent";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";

export const singlePrivateAgentWithConfigKeys = {
  all: ["singlePrivateAgentWithConfig"] as const,
  id: (id: FullAgent["_id"]) => [...singlePrivateAgentWithConfigKeys.all, id] as const,
  refetch: (id: FullAgent["_id"]) => ["refetch", ...singlePrivateAgentWithConfigKeys.all, id] as const,
};

type Props = {
  agentId: FullAgent["_id"];
  enabled?: boolean;
};

export const getSinglePrivateAgentWithConfig = async (agentId: Agent["_id"]) => {
  const { data } = await restClient.get<FullAgent>(apiPaths.getSingleAgentWithConfig(agentId));
  return data;
};

export const useGetSinglePrivateAgentWithConfig = ({ agentId, enabled = true }: Props) => {
  const query = useQuery({
    queryKey: singlePrivateAgentWithConfigKeys.id(agentId),
    queryFn: () => getSinglePrivateAgentWithConfig(agentId),
    enabled,
  });

  return query;
};
