import type { SingleAgentEngagementStats } from "@/types/stats";
import { SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP } from "../../constants/statsConstants";
import { BarChartBarItem, BarChartContainer, BarChartTooltip } from "@/components/ui/bar-chart";
import { getChartLabelForDates } from "../../utils/getChartLabelForDates";

type Props = {
  data: SingleAgentEngagementStats | undefined;
};

export const StatsSingleAgentEngagementChart = ({ data }: Props) => {
  const maxValue = Math.max(
    ...(data?.stats.flatMap(period => [period.messages, period.conversations, period.views, period.likes ?? 0]) ?? [])
  );

  return (
    <BarChartContainer itemsLength={data?.stats.length ?? 0}>
      {({ barWidth, labelInterval }) => {
        return data?.stats.map((period, index) => {
          const data = [
            {
              id: "1",
              label: "Views",
              value: period.views,
              color: SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP.views.color,
              colorTransparent: SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP.views.colorTransparent,
            },
            {
              id: "2",
              label: "Messages",
              value: period.messages,
              color: SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP.messages.color,
              colorTransparent: SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP.messages.colorTransparent,
            },
            {
              id: "3",
              label: "Conversations",
              value: period.conversations,
              color: SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP.conversations.color,
              colorTransparent: SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP.conversations.colorTransparent,
            },
            {
              id: "4",
              label: "Likes",
              value: period.likes ?? 0,
              color: SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP.likes.color,
              colorTransparent: SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP.likes.colorTransparent,
            },
          ];

          const showLabel = index % labelInterval === 0;

          return (
            <BarChartBarItem
              key={`${period.intervalStartDate}-${period.intervalEndDate}`}
              maxValue={maxValue}
              data={data}
              barStyle={{
                width: barWidth,
                maxWidth: "40px",
                minWidth: "16px",
                margin: "0 2px",
              }}
              renderLabel={() =>
                showLabel && (
                  <div className="absolute left-1/2 top-full mt-4 -translate-x-1/2">
                    <p className="text-center text-sm font-normal leading-4 text-neutral-400">
                      {getChartLabelForDates(period.intervalStartDate, period.intervalEndDate)}
                    </p>
                  </div>
                )
              }
              renderTooltip={() => (
                <BarChartTooltip
                  renderTitle={() => (
                    <p className="my-2 text-xl font-medium text-white">
                      {getChartLabelForDates(period.intervalStartDate, period.intervalEndDate)}
                    </p>
                  )}
                  data={data}
                />
              )}
            />
          );
        });
      }}
    </BarChartContainer>
  );
};
