import type { Dispatch, SetStateAction } from "react";
import { createContext } from "react";
import type { NavigationProfileStateType } from "./NavigationProfileProvider";

type NavigationProfileContextProps = {
  navigationProfileOpen: NavigationProfileStateType;
  setNavigationProfileOpen: Dispatch<SetStateAction<NavigationProfileStateType>>;
};

export const NavigationProfileContext = createContext<NavigationProfileContextProps | null>(null);
