import { useBlocker } from "react-router";

export const useAgentFormBlocker = (condition: boolean) => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const hasPathnameChanged = currentLocation.pathname !== nextLocation.pathname;

    return hasPathnameChanged && condition;
  });

  return { blocker };
};
