import { cn } from "@/lib/utils";
import { isRequiredError } from "@/utils/isRequiredError";
import type { FieldError } from "react-hook-form";

type Error = FieldError | string;
type ErrorMessageProps = {
  error: Error;
  className?: string;
  id?: string;
};
const getErrorMessage = (error: Error) => {
  if (typeof error === "string") {
    return error;
  }
  if (typeof error === "object" && error?.message) {
    return error.message;
  }
  if (isRequiredError(error)) {
    return "This field is required";
  }
};

export const ErrorMessage = ({ error, id, className }: ErrorMessageProps) => {
  const errorMessage = getErrorMessage(error);

  if (!errorMessage) {
    return null;
  }

  return (
    <div className={cn("flex items-center gap-1", className)} {...(id && { id: `input-error-${id}` })}>
      <p className="text-[10px] leading-3 text-red-500">{errorMessage}</p>
    </div>
  );
};
