import { Skeleton } from "@/components/ui/skeleton";

const mockChartsData = [
  {
    value: 100,
  },
  {
    value: 200,
  },
  {
    value: 170,
  },
  {
    value: 180,
  },
  {
    value: 260,
  },
  {
    value: 190,
  },
  {
    value: 120,
  },
];

export const StatsMainChartSkeleton = () => {
  return (
    <>
      <div className="custom-skeleton-container flex w-full items-center justify-between">
        <Skeleton height={30} width={140} />
        <Skeleton height={25} width={100} />
      </div>

      <div className="custom-skeleton-container my-6 flex gap-8">
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className="flex gap-2">
            <Skeleton circle height={16} width={16} />
            <Skeleton height={10} width={50} />
          </div>
        ))}
      </div>

      <div className="relative mb-14 flex w-full grow justify-around">
        <div className="absolute left-0 top-0 flex h-full w-full flex-col justify-between">
          <div className="z-0 h-[1px] w-full bg-neutral-200" />
          <div className="z-0 h-[1px] w-full bg-neutral-200" />
          <div className="z-0 h-[1px] w-full bg-neutral-200" />
          <div className="z-0 h-[1px] w-full bg-neutral-200" />
          <div className="z-0 h-[1px] w-full bg-neutral-200" />
          <div className="z-0 h-[1px] w-full bg-neutral-200" />
          <div className="z-0 h-[1px] w-full bg-neutral-200" />
        </div>

        {mockChartsData.map((dataItem, index) => {
          return (
            <div key={index} className="relative">
              <div className="absolute bottom-0 w-full rounded-t-full">
                <Skeleton height={dataItem.value} width={40} className="rounded-t-full" />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
