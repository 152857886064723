import { Icons } from "@/components/ui/icons";

export const MAX_FILE_SIZE_MB = 20; // 20MB
export const MAX_FILE_SIZE_INPUT = MAX_FILE_SIZE_MB * 1024 * 1024; // 20MB

export const allowedFileFormats = [
  { mimeType: "application/pdf", icon: Icons.Pdf, extension: ".pdf" },
  { mimeType: "application/vnd.ms-word", icon: Icons.FileText, extension: ".doc" },
  {
    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    icon: Icons.FileText,
    extension: ".docx",
  },
  { mimeType: "text/plain", icon: Icons.FileText, extension: ".txt" },
  { mimeType: "image/jpeg", icon: Icons.FileImage, extension: ".jpeg" },
  { mimeType: "image/png", icon: Icons.FileImage, extension: ".png" },
  { mimeType: "image/avif", icon: Icons.FileImage, extension: ".avif" },
  { mimeType: "image/jpg", icon: Icons.FileImage, extension: ".jpg" },
  { mimeType: "image/heic", icon: Icons.FileImage, extension: ".heic" },
  { mimeType: "image/bmp", icon: Icons.FileImage, extension: ".bmp" },
  { mimeType: "image/webp", icon: Icons.FileImage, extension: ".webp" },
  { mimeType: "text/csv", icon: Icons.Spreadsheet, extension: ".csv" },
  {
    mimeType: "application/vnd.ms-excel",
    icon: Icons.Spreadsheet,
    extension: ".xls",
  },
  {
    mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icon: Icons.Spreadsheet,
    extension: ".xlsx",
  },
];

export const isFileFormatAllowed = (file: File) => {
  return allowedFileFormats.map(format => format.mimeType).includes(file.type);
};

export const isImageFormatAllowed = (file: File) => {
  return allowedFileFormats.some(el => el.mimeType.startsWith("image") && el.mimeType === file.type);
};
