import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { Button } from "@/components/ui/button";
import { useGetAgentDownvotedMessages } from "@/data/queries/stats/useGetAgentDownvotedMessages";
import type { DateRangeType } from "@/types/datepicker";
import type { Agent } from "@/types/agent";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { Icons } from "@/components/ui/icons";
import { formatDate } from "date-fns";
import { calculateTimeDifference } from "@/utils/calculateTimeDifference";
import { Accordion, AccordionContent, AccordionItem } from "@/components/ui/accordion";
import { MessageCloudTranslated } from "@/components/Chat/components/MessageCloudTranslated";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  agentId: Agent["_id"];
  currentRange: DateRangeType;
  startDate: Date | null;
  endDate: Date | null;
};

const StatsDownvotedMessagesDialog = ({ isOpen, setIsOpen, agentId, currentRange, startDate, endDate }: Props) => {
  const { activeChannelsOrigins } = useStatsContext();

  const { data, isLoading } = useGetAgentDownvotedMessages({
    agentId,
    currentRange,
    startDate,
    endDate,
    channels: activeChannelsOrigins,
    enabled: isOpen && !!agentId,
  });

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent variant="large">
        <DialogHeader className="font-bold text-primary-black">Conversation</DialogHeader>
        <DialogDescription className="flex flex-col gap-2 overflow-y-scroll text-center">
          {isLoading ? (
            <div className="flex h-full items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              {data?.length === 0 && (
                <div className="flex h-full items-center justify-center">
                  <p>No downvoted messages</p>
                </div>
              )}

              {data?.map(message => {
                return (
                  <Accordion key={message._id} type="single" collapsible className="accordion">
                    <AccordionItem value={message._id} className="item border-300 rounded-lg border px-5 py-3">
                      <AccordionPrimitive.Trigger className="flex w-full items-center justify-between">
                        <div className="flex items-center justify-start gap-2">
                          <Icons.ChevronRight className="rotate-90" />
                          <p className="text-sm font-medium">{formatDate(message?.createdAt, "yyyy-MM-dd HH:mm:ss")}</p>
                          <p className="text-sm text-neutral-500">({message.conversationId})</p>
                        </div>
                        <p className="text-xs font-medium text-neutral-400">
                          {calculateTimeDifference(message.createdAt)}
                        </p>
                      </AccordionPrimitive.Trigger>

                      <AccordionContent className="mt-3 lg:px-8">
                        <MessageCloudTranslated
                          message={message.text}
                          isBotMessage={"bot" in message}
                          key={message._id + message.text}
                          messageId={message._id}
                          conversationId={message.conversationId}
                          trace={message.trace}
                          vote={message.vote}
                          messageIndex={0}
                          createdAt={message.createdAt}
                          isBookmark={message.isBookmark}
                          agent={message.bot}
                          setDocPreviewPage={undefined}
                          setDocumentInPreview={undefined}
                          botNames={[message.bot?.name ?? ""]}
                          isGeneratingBotResponse={message.isGenerating}
                          attachments={message.attachments}
                          messageUser={message.user}
                          disableActions
                        />
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                );
              })}
            </>
          )}
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="md" variant="secondary">
              Cancel
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default StatsDownvotedMessagesDialog;
