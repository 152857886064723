import { useContext } from "react";
import { SubscriptionTierContext } from "./SubscriptionTierDialogContext";

export const useSubscriptionTierDialogContext = () => {
  const context = useContext(SubscriptionTierContext);

  if (context) {
    return context;
  }

  throw new Error("Use this hook in SubscriptionTierContext scope");
};
