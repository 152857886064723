import type { AgentFormFields } from "@/types/agent";
import type { UseFormSetError } from "react-hook-form";
import type { TypeFromConstObject } from "@/types/types";
import { domElementIds } from "@/types/dom-element-ids";

export const AgentFormTabs = {
  GENERAL_INFO: {
    name: "General info*",
    subTabs: [],
  },
  KNOWLEDGE: {
    name: "Knowledge*",
    subTabs: [
      { name: "Onboarding info", domId: domElementIds.AGENT_FORM_ONBOARDING_INFO },
      { name: "Document info", domId: domElementIds.AGENT_FORM_DOCUMENT_INFO },
      { name: "Online info", domId: domElementIds.AGENT_FORM_ONLINE_INFO },
      { name: "FAQ", domId: domElementIds.AGENT_FORM_FAQ },
    ],
  },
  ACTIONS: { name: "Actions (optional)", subTabs: [] },
  DEPLOY: { name: "Deploy", subTabs: [] },
} as const;

export type AgentFormTab = TypeFromConstObject<typeof AgentFormTabs>;

export const agentTabs = Object.values(AgentFormTabs);

export type AgentFormOnSubmit = ({
  formValues,
  onSetFormError,
  onReset,
  isLeadGenToolDirty,
}: {
  formValues: AgentFormFields;
  onSetFormError: UseFormSetError<AgentFormFields>;
  onReset: () => void;
  isLeadGenToolDirty: boolean;
}) => void;
