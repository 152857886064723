import { SubscriptionPeriod, SubscriptionTiers } from "@/types/subscriptions";

export const SUBSCRIPTION_TIERS_PRICING = {
  [SubscriptionTiers.FREE]: { [SubscriptionPeriod.MONTHLY]: 0, [SubscriptionPeriod.ANNUALLY]: 0 },
  [SubscriptionTiers.STARTUP]: { [SubscriptionPeriod.MONTHLY]: 23, [SubscriptionPeriod.ANNUALLY]: 19 },
  [SubscriptionTiers.PRO]: { [SubscriptionPeriod.MONTHLY]: 71, [SubscriptionPeriod.ANNUALLY]: 59 },
  [SubscriptionTiers.SCALE]: { [SubscriptionPeriod.MONTHLY]: 239, [SubscriptionPeriod.ANNUALLY]: 199 },
  [SubscriptionTiers.PREMIUM]: { [SubscriptionPeriod.MONTHLY]: 0, [SubscriptionPeriod.ANNUALLY]: 0 },
  [SubscriptionTiers.ENTERPRISE]: { [SubscriptionPeriod.MONTHLY]: 0, [SubscriptionPeriod.ANNUALLY]: 0 },
};

export const TIER_BENEFITS_LIST = {
  [SubscriptionTiers.STARTUP]: [
    "5 Active Agent",
    "1000 message interactions",
    "200 website knowledge source",
    "200 document knowledge source",
    "High concurrency",
    "Email & text integration",
  ],
  [SubscriptionTiers.PRO]: [
    "20 Active Agent",
    "8000 message interactions",
    "500 website knowledge source",
    "500 document knowledge source",
    "Real-time lead analytics",
    "Dedicated customer support",
    "High concurrency",
    "Email & text integration",
    "Remove branding",
  ],
  [SubscriptionTiers.SCALE]: [
    "8000 message interactions",
    "20000 website knowledge source",
    "20000 document knowledge source",
    "Real-time lead analytics",
    "Dedicated customer support",
    "High concurrency",
    "Email & text integration",
    "Remove branding",
  ],
};
