import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useState } from "react";

interface RenameBookmarkDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onRenameSubmit: (bookmarkName: string) => Promise<unknown>;
  isSubmitLoading?: boolean;
}

const RenameBookmarkDialog = ({ isOpen, setIsOpen, onRenameSubmit, isSubmitLoading }: RenameBookmarkDialogProps) => {
  const [bookmarkName, setBookmarkName] = useState("");

  const handleRename = async () => {
    await onRenameSubmit(bookmarkName);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="!h-fit !w-fit min-w-80">
        <DialogHeader className=" pl-4">Rename bookmark</DialogHeader>
        <div className="w-full px-4 pb-12 pt-4">
          <Input value={bookmarkName} onChange={e => setBookmarkName(e.target.value)} inputClassName="w-full" />
        </div>

        <DialogFooter className="flex justify-end gap-2 px-4">
          <Button onClick={() => setIsOpen(false)} variant="tertiary" size="md">
            Cancel
          </Button>
          <Button onClick={handleRename} loading={isSubmitLoading} size="md">
            OK
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RenameBookmarkDialog;
