import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { Parameter, Tool } from "@/types/tools";
import type { Agent } from "@/types/agent";
import { agentLeadGenToolKeys } from "../queries/useGetAgentLeadGenTool";

type Props = {
  parameters: Parameter[];
  agentId: Agent["_id"];
  disabled: boolean;
  webhook?: Tool["webhook"];
};

const updateLeadGenTool = async (props: Props) => {
  const { data } = await restClient.put<Tool>(apiPaths.updateAgentLeadGenerationTool(props.agentId), props);
  return data;
};

export const useUpdateLeadGenerationTool = () => {
  const queryClient = useQueryClient();

  return useMutation<Tool, AxiosError<{ message: string }>, Props>({
    mutationFn: updateLeadGenTool,
    onSuccess: (data, variables) => {
      const { agentId } = variables;
      queryClient.setQueryData<Tool>(agentLeadGenToolKeys.id(agentId), data);
    },
  });
};
