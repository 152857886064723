import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { CommunityUser } from "@/types/community";

export const profileFollowersKeys = {
  all: ["profileFollowers"] as const,
  id: (id: CommunityUser["_id"]) => [...profileFollowersKeys.all, id],
};

type Props = {
  profileId: CommunityUser["_id"];
};

const getProfileFollowers = async ({ profileId }: Props) => {
  const { data } = await restClient.get<CommunityUser[]>(apiPaths.getProfileFollowers(profileId));
  return data;
};

export const useGetProfileFollowers = ({ profileId }: Props) => {
  const query = useQuery({
    queryKey: profileFollowersKeys.id(profileId),
    queryFn: () => getProfileFollowers({ profileId }),
  });

  return query;
};
