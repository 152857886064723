import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import type { Conversation } from "../../types/conversation";
import { restClient } from "../initAxios";
import type { RelationResponse } from "@/types/relation";
import { RelationType } from "@/types/relation-types";

export const useShareConversation = () => {
  const queryClient = useQueryClient();

  const shareConversation = async (props: { conversationId: Conversation["_id"] }) => {
    const cachedData = queryClient.getQueryData<RelationResponse>(["sharedConversation", props.conversationId]);

    if (cachedData) {
      return cachedData;
    }

    const payload = {
      from_id: props.conversationId,
      from_type: RelationType.CONVERSATION,
      to_type: RelationType.SHARE,
    };
    const { data } = await restClient.post<RelationResponse>(apiPaths.shareRelation, payload);
    return data;
  };

  const mutation = useMutation<RelationResponse, AxiosError, { conversationId: Conversation["_id"] }>({
    mutationFn: shareConversation,
    onSuccess: (data, variables) => {
      const { conversationId } = variables;
      queryClient.setQueryData(["sharedConversation", conversationId], data);
    },
  });

  return mutation;
};
