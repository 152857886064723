import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { User } from "@/types/user";

type Props = {
  password: string;
  newPassword: string;
  email: User["email"];
};

const changePassword = async (props: Props) => {
  const { data } = await restClient.put<User>(apiPaths.changeUserPassword, props);
  return data;
};

export const useChangePassword = () => {
  const mutation = useMutation<User, AxiosError<{ message: string }>, Props>({
    mutationFn: changePassword,
  });

  return mutation;
};
