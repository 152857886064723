import { useContext } from "react";
import { NavigationProfileContext } from "@/contexts/NavigationProfileContext/NavigationProfileContext";

export const useNavigationProfileContext = () => {
  const context = useContext(NavigationProfileContext);
  if (!context) {
    throw new Error("useNavigationProfileContext must be used within a NavigationProfileProvider");
  }
  return context;
};
