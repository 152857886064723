import type { BookmarkMessage, MessageBookmarkGroup } from "@/types/bookmarks";

export const groupBookmarksByPin = (bookmarkGroups: MessageBookmarkGroup[]) =>
  bookmarkGroups.reduce<{ pinned: MessageBookmarkGroup[]; unpinned: MessageBookmarkGroup[] }>(
    (acc, group) => {
      const pinnedMessages: BookmarkMessage[] = [];
      const unpinnedMessages: BookmarkMessage[] = [];

      // Directly split messages into pinned and unpinned arrays (avoid Array.reduce() for performance reasons)
      group.messages.forEach(message => {
        message.isPinned ? pinnedMessages.push(message) : unpinnedMessages.push(message);
      });

      // If there are pinned messages, create a new group and add it to the accumulator
      if (pinnedMessages.length) {
        acc.pinned.push({ ...group, messages: pinnedMessages });
      }

      // If there are unpinned messages, create a new group and add it to the accumulator
      if (unpinnedMessages.length) {
        acc.unpinned.push({ ...group, messages: unpinnedMessages });
      }

      return acc;
    },
    { pinned: [], unpinned: [] } // Initial accumulator value
  );
