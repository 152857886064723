import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { CommunityAgent, CommunityAgentFilters } from "@/types/community";
import type { RelationResponse } from "@/types/relation";
import { singleCommunityAgentKeys } from "../queries/useGetSingleCommunityAgent";
import { communityAgentsKeys } from "@/data/queries/useGetCommunityAgents";
import { likedCommunityAgentsKeys } from "@/data/queries/useGetLikedCommunityAgents";
import { useSearchParams } from "react-router-dom";
import { userPublishedAgentsKeys } from "../queries/useGetUserPublishedAgents";
import type { User } from "@/types/user";
import { communityFeaturedAgentsKeys } from "@/data/queries/useGetCommunityFeaturedAgents";
import { communityBasicAgentsKeys } from "@/data/queries/useGetCommunityBasicAgents";
import { singleConversationAgentKeys } from "../queries/useGetSingleConversationAgentWithConfig";
import { singlePrivateAgentWithConfigKeys } from "../queries/useGetSinglePrivateAgentWithConfig";

type Props = { agentId: CommunityAgent["_id"]; status: boolean; profileId: User["_id"] };

const likeAgent = async ({ agentId, status }: Props) => {
  const { data } = await restClient.post<RelationResponse>(apiPaths.likeAgent(agentId), { status });
  return data;
};

export const useLikeAgent = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const mutation = useMutation<
    RelationResponse,
    AxiosError<{ message: string }>,
    Props,
    {
      previousCommunityAgent: CommunityAgent | undefined;
      previousCommunityAgents: CommunityAgent[] | undefined;
      previousLikedAgents: CommunityAgent[] | undefined;
      previousUserPublishedAgents: CommunityAgent[] | undefined;
      previousFeaturedAgents: CommunityAgent[] | undefined;
      previousBasicAgents: CommunityAgent[] | undefined;
      communityAgentKeysFilters: ReturnType<typeof communityAgentsKeys.params>;
    }
  >({
    mutationFn: likeAgent,
    onSuccess: (_, { agentId }) => {
      void queryClient.invalidateQueries({ queryKey: singleCommunityAgentKeys.id(agentId) });
      void queryClient.invalidateQueries({ queryKey: communityAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: userPublishedAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: likedCommunityAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: communityFeaturedAgentsKeys.all });
    },
    onMutate: async ({ agentId, status, profileId }) => {
      // we have few endpoints that returns list of agents, so we have to do optimistic update for all of them
      await queryClient.cancelQueries({ queryKey: singleCommunityAgentKeys.id(agentId) });
      await queryClient.cancelQueries({ queryKey: communityAgentsKeys.all });
      await queryClient.cancelQueries({ queryKey: userPublishedAgentsKeys.all });
      await queryClient.cancelQueries({ queryKey: communityFeaturedAgentsKeys.all });

      // user published agents
      const previousUserPublishedAgents = queryClient.getQueryData<CommunityAgent[]>(
        userPublishedAgentsKeys.id(profileId)
      );
      queryClient.setQueryData<CommunityAgent[]>(userPublishedAgentsKeys.id(profileId), prev => {
        if (!prev) {
          return prev;
        }
        return prev.map(agent => {
          if (agent._id === agentId) {
            return { ...agent, likedByUser: status, likes: status ? agent.likes + 1 : agent.likes - 1 };
          }
          return agent;
        });
      });

      //single agent
      const previousCommunityAgent = queryClient.getQueryData<CommunityAgent>(singleCommunityAgentKeys.id(agentId));
      queryClient.setQueryData<CommunityAgent>(singleCommunityAgentKeys.id(agentId), prev => {
        if (!prev) {
          return prev;
        }
        return { ...prev, likedByUser: status, likes: status ? prev.likes + 1 : prev.likes - 1 };
      });
      queryClient.setQueryData<CommunityAgent>(singleConversationAgentKeys.id(agentId), prev => {
        if (!prev) {
          return prev;
        }

        return { ...prev, likedByUser: status, likes: status ? prev.likes + 1 : prev.likes - 1 };
      });
      queryClient.setQueryData<CommunityAgent>(singlePrivateAgentWithConfigKeys.id(agentId), prev => {
        if (!prev) {
          return prev;
        }

        return { ...prev, likedByUser: status, likes: status ? prev.likes + 1 : prev.likes - 1 };
      });

      // community agents list
      const communityAgentKeysFilters = communityAgentsKeys.params({
        query: searchParams.get("query") || "",
        //creator: searchParams.get("creator") as CommunityAgentFilters["creator"],
        //paid: searchParams.get("paid") as CommunityAgentFilters["paid"],
        //sort: searchParams.get("sort") as CommunityAgentFilters["sort"],
        category: searchParams.get("category") as CommunityAgentFilters["category"],
      });
      const previousCommunityAgents = queryClient.getQueryData<CommunityAgent[]>(communityAgentKeysFilters);
      queryClient.setQueryData<CommunityAgent[]>(communityAgentKeysFilters, prev => {
        if (!prev) {
          return prev;
        }
        return prev.map(agent => {
          if (agent._id === agentId) {
            return { ...agent, likedByUser: status, likes: status ? agent.likes + 1 : agent.likes - 1 };
          }
          return agent;
        });
      });

      // my community liked agents list
      const previousLikedAgents = queryClient.getQueryData<CommunityAgent[]>(likedCommunityAgentsKeys.all);
      queryClient.setQueryData<CommunityAgent[]>(likedCommunityAgentsKeys.all, prev => {
        if (!prev) {
          return prev;
        }
        return prev.map(agent => {
          if (agent._id === agentId) {
            return { ...agent, likedByUser: status, likes: status ? agent.likes + 1 : agent.likes - 1 };
          }
          return agent;
        });
      });

      // my community featured agents list
      const previousFeaturedAgents = queryClient.getQueryData<CommunityAgent[]>(communityFeaturedAgentsKeys.all);
      queryClient.setQueryData<CommunityAgent[]>(communityFeaturedAgentsKeys.all, prev => {
        if (!prev) {
          return prev;
        }
        return prev.map(agent => {
          if (agent._id === agentId) {
            return { ...agent, likedByUser: status, likes: status ? agent.likes + 1 : agent.likes - 1 };
          }
          return agent;
        });
      });

      // my community basic agents list
      const previousBasicAgents = queryClient.getQueryData<CommunityAgent[]>(communityBasicAgentsKeys.all);
      queryClient.setQueryData<CommunityAgent[]>(communityBasicAgentsKeys.all, prev => {
        if (!prev) {
          return prev;
        }
        return prev.map(agent => {
          if (agent._id === agentId) {
            return { ...agent, likedByUser: status, likes: status ? agent.likes + 1 : agent.likes - 1 };
          }
          return agent;
        });
      });

      return {
        previousCommunityAgent,
        previousCommunityAgents,
        previousLikedAgents,
        communityAgentKeysFilters,
        previousUserPublishedAgents,
        previousFeaturedAgents,
        previousBasicAgents,
      };
    },
    onError: (_, variables, context) => {
      if (context?.previousCommunityAgent) {
        queryClient.setQueryData(singleCommunityAgentKeys.id(variables.agentId), context.previousCommunityAgent);
      }
      if (context?.previousCommunityAgents) {
        queryClient.setQueryData(context.communityAgentKeysFilters, context.previousCommunityAgents);
      }
      if (context?.previousLikedAgents) {
        queryClient.setQueryData(likedCommunityAgentsKeys.all, context.previousLikedAgents);
      }
      if (context?.previousUserPublishedAgents) {
        queryClient.setQueryData(userPublishedAgentsKeys.id(variables.profileId), context.previousUserPublishedAgents);
      }
      if (context?.previousFeaturedAgents) {
        queryClient.setQueryData(communityFeaturedAgentsKeys.all, context.previousFeaturedAgents);
      }
      if (context?.previousBasicAgents) {
        queryClient.setQueryData(communityBasicAgentsKeys.all, context.previousBasicAgents);
      }
    },
  });

  return mutation;
};
