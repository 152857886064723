// import { customEvents } from "@/types/customEvents";
import { customEvents } from "@/types/custom-events";
import { searchParamsData } from "@/types/search-params";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

const useScrollToMessage = ({ enabled }: { enabled: boolean }) => {
  const [searchParams] = useSearchParams();
  const scrollToMessageId = searchParams.get(searchParamsData.MESSAGE);

  const hasScrolledRef = useRef(false);
  const lastMessageIdRef = useRef<string | null>(null);

  const scrollToMessage = (messageId: string) => {
    const scrollElement = document.getElementById(messageId);
    if (scrollElement) {
      scrollElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  useEffect(() => {
    const handleScrollToMessageEvent = (event: CustomEvent<{ messageId: string }>): void => {
      const { messageId } = event.detail;

      scrollToMessage(messageId);
    };

    window.addEventListener(customEvents.MESSAGE_SCROLL_REQUEST, handleScrollToMessageEvent as EventListener);

    return () => {
      window.removeEventListener(customEvents.MESSAGE_SCROLL_REQUEST, handleScrollToMessageEvent as EventListener);
    };
  }, []);

  useEffect(() => {
    if (scrollToMessageId !== lastMessageIdRef.current) {
      hasScrolledRef.current = false;
      lastMessageIdRef.current = scrollToMessageId;
    }

    const shouldRun = enabled && scrollToMessageId && !hasScrolledRef.current;

    if (!shouldRun) {
      return;
    }

    const scrollElement = document.getElementById(scrollToMessageId);

    const timeout = setTimeout(() => {
      if (scrollElement) {
        scrollElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
        hasScrolledRef.current = true;
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [enabled, scrollToMessageId]);
};

export default useScrollToMessage;
