import { Icons } from "./icons";

type ChipProps = {
  text: string;
  canDelete?: boolean;
  onDeleteClick: () => void;
};

const Chip = ({ text, onDeleteClick, canDelete = true }: ChipProps) => {
  return (
    <button
      disabled={!canDelete}
      onClick={onDeleteClick}
      className="group flex items-center gap-1 rounded-sm bg-violet-50 px-2 py-1"
    >
      <span className="select-none whitespace-nowrap text-sm font-medium leading-5 text-neutral-750">{text}</span>

      {canDelete && (
        <Icons.XCircle className="group-hover:fill-netrual-300 text-neutral-400 group-hover:text-neutral-750" />
      )}
    </button>
  );
};

export default Chip;
