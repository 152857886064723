import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { cn } from "@/lib/utils";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { Icons } from "./icons";
import { Label } from "./label";

const checkboxVariants = cva("peer shrink-0 ", {
  variants: {
    variant: {
      square:
        "rounded-sm border border-primary-400 hover:border-primary-600 disabled:border-primary-200 data-[state=checked]:bg-primary-400 disabled:bg-primary-200 disabled:data-[state=checked]:bg-primary-200  hover:data-[state=checked]:bg-primary-600 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed text-white data-[state=checked]:text-white",
      circle:
        "rounded-full border border-primary-400 hover:border-primary-600 disabled:border-primary-200 data-[state=checked]:bg-primary-400 disabled:bg-primary-200 disabled:data-[state=checked]:bg-primary-200  hover:data-[state=checked]:bg-primary-600 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed text-white data-[state=checked]:text-white",
      pin: "border-none text-primary-400 hover:text-primary-600 data-[state=checked]:text-primary-400 hover:data-[state=checked]:text-primary-600 disabled:text-primary-200 disabled:data-[state=checked]:text-primary-200 disabled:cursor-not-allowed",
    },
    error: {
      false: "",
      true: "border-error text-error data-[state=checked]:bg-error hover:border-error hover:data-[state=checked]:bg-error",
    },
    size: {
      sm: "h-4 w-4 stroke-1",
      md: "h-6 w-6 stroke-2",
    },
  },
  defaultVariants: {
    variant: "square",
    size: "md",
  },
});

const labelVariants = cva("font-medium text-primary-black", {
  variants: {
    size: {
      sm: "stroke-1 text-xxs ml-2",
      md: "stroke-2 text-sm ml-4",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

type CheckboxProps = Omit<
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
  "checked" | "onCheckedChange"
> &
  VariantProps<typeof checkboxVariants> & {
    checked: boolean;
    onCheckedChange?: (checked: boolean) => void;
    indeterminate?: boolean;
    label?: string;
    labelClassName?: string;
  };

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  (
    { className, variant, size, error, indeterminate, label, labelClassName, checked, onCheckedChange, ...props },
    ref
  ) => {
    if (indeterminate && variant === "pin") {
      throw new Error("Indeterminate state is not supported with pin variant");
    }

    const getErrorClassName = () => {
      if (!error) {
        return "";
      }

      return variant === "pin"
        ? "text-error hover:text-error data-[state=checked]:text-error-500 hover:data-[state=checked]:text-error-500 disabled:text-error-500 disabled:data-[state=checked]:text-error-500"
        : "border-error data-[state=checked]:bg-error hover:border-error hover:data-[state=checked]:bg-error";
    };

    const getIcon = () => {
      if (indeterminate) {
        return <Icons.CheckboxIndeterminate className="text-white" />;
      }

      if (variant === "pin") {
        return <Icons.PinFilled />;
      }

      return <Icons.CheckboxCheck />;
    };

    return (
      <div className="flex items-center">
        <CheckboxPrimitive.Root
          ref={ref}
          className={cn(checkboxVariants({ variant, size, className }), getErrorClassName())}
          {...props}
          checked={checked}
          onCheckedChange={onCheckedChange}
        >
          {!checked && variant === "pin" && (
            <span className="flex h-full w-full items-center justify-center">
              <Icons.PinOutline />
            </span>
          )}
          <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-current")}>
            {getIcon()}
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label && (
          <Label htmlFor={props.id} className={cn(labelVariants({ size }), labelClassName)}>
            {label}
          </Label>
        )}
      </div>
    );
  }
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
