import { useState } from "react";
import type { Onboarding } from "@/types/agent";
import { useFieldArray } from "react-hook-form";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";
import { arrayMove } from "@dnd-kit/sortable";
import { Checkbox } from "@/components/ui/checkbox";
import { OnboardingInfoTableRow } from "./OnboardingInfoTableRow";
import { v4 as uuidv4 } from "uuid";
import {
  DraggableTable,
  DraggableTableActionPopup,
  DraggableTableBody,
  DraggableTableHeader,
  DraggableTableHeaderCell,
  DraggableTableNewRowButton,
} from "@/components/ui/draggable-table";
import { KnowledgeLimitInfoBadge } from "./KnowledgeLimitInfoBadge";
import { domElementIds } from "@/types/dom-element-ids";

export const OnboardingInfo = () => {
  const form = useAgentFormContext();

  const {
    control,
    formState: { errors },
  } = form;

  const {
    fields: onboardingData,
    append: appendOnboarding,
    remove: removeOnboarding,
    update: updateOnboarding,
  } = useFieldArray({
    control,
    name: "inMemoryKnowledge.onboardingList",
  });

  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

  const showTableActionPopup = selectedRows.size > 0;

  const handleAddRow = () => {
    const newOnboarding: Onboarding = { _id: uuidv4(), description: "", content: "", isNew: true };
    appendOnboarding(newOnboarding);
  };

  const handleUpdateRow = ({ _id, description, content }: { _id: string; description?: string; content?: string }) => {
    const updatedOnboardingData = onboardingData.find(onboarding => onboarding._id === _id);

    if (!updatedOnboardingData) {
      return;
    }

    if (description !== undefined) {
      updatedOnboardingData.description = description;
    }

    if (content !== undefined) {
      updatedOnboardingData.content = content;
    }

    updateOnboarding(
      onboardingData.findIndex(onboarding => onboarding._id === _id),
      updatedOnboardingData
    );
  };

  const handleSelectRow = (id: string) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(id)) {
      newSelectedRows.delete(id);
    } else {
      newSelectedRows.add(id);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleResetSelectedRows = () => {
    setSelectedRows(new Set());
  };

  const handleDeleteRows = (ids: string[]) => {
    const indexesToDelete = ids.map(id => onboardingData.findIndex(onboarding => onboarding._id === id));

    removeOnboarding(indexesToDelete);
    handleResetSelectedRows();
  };

  const handleDuplicateRows = (ids: string[]) => {
    const newOnboardingData = ids.map(id => {
      const originalOnboarding = onboardingData.find(onboarding => onboarding._id === id);
      return {
        description: `${originalOnboarding?.description} copy` ?? "",
        content: originalOnboarding?.content ?? "",
        _id: uuidv4(),
        isNew: true,
      };
    });

    form.setValue("inMemoryKnowledge.onboardingList", [...onboardingData, ...newOnboardingData], { shouldDirty: true });
    handleResetSelectedRows();
  };

  const handleToggleSelectAllRows = () => {
    if (selectedRows.size === onboardingData.length) {
      setSelectedRows(new Set());
    } else {
      setSelectedRows(new Set(onboardingData.map(onboarding => onboarding._id)));
    }
  };

  const handleDragEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const updatedOnboardingData = arrayMove(onboardingData, oldIndex, newIndex);
    form.setValue("inMemoryKnowledge.onboardingList", updatedOnboardingData, { shouldDirty: true });
  };

  const getDescriptionError = (index: number) => {
    const error = errors?.inMemoryKnowledge?.onboardingList?.[index]?.description?.message ?? "";
    return error;
  };

  const getContentError = (index: number) => {
    const error = errors?.inMemoryKnowledge?.onboardingList?.[index]?.content?.message ?? "";
    return error;
  };

  const onboardingError = errors?.inMemoryKnowledge?.onboardingList?.message ?? "";

  return (
    <Accordion id={domElementIds.AGENT_FORM_ONBOARDING_INFO} type="single" collapsible defaultValue="onboarding">
      <AccordionItem value="onboarding">
        <AccordionTrigger>
          <div className="relative flex items-center gap-2">
            <h4>1. Add onboarding info </h4>
            <HybridTooltipPopover heading="Expand Your Agent's Knowledge:">
              <p>
                Add a title and content to your agent's knowledge baes. It can be any information that you want your
                agent to know. e.g. "Title: When is AgentX founded? Content: AgentX is founded in 2023."
              </p>
            </HybridTooltipPopover>

            <KnowledgeLimitInfoBadge currentUsage={onboardingData.length} limit={undefined} />

            {onboardingError && (
              <p className="absolute -bottom-3 left-4 text-xxs leading-3 text-red-500">{onboardingError}</p>
            )}
          </div>
        </AccordionTrigger>

        <AccordionContent className="pb-0">
          <div className="px-4 pt-4">
            {showTableActionPopup && (
              <DraggableTableActionPopup
                numOfSelectedRows={selectedRows.size}
                onDelete={() => handleDeleteRows(Array.from(selectedRows))}
                onDuplicate={() => handleDuplicateRows(Array.from(selectedRows))}
              />
            )}
            <DraggableTable onDragEndAction={handleDragEnd} itemsIds={onboardingData.map(item => item._id)}>
              <DraggableTableHeader>
                <tr className="grid grid-cols-[auto_minmax(100px,_1fr)_minmax(100px,_2fr)] border-t">
                  <DraggableTableHeaderCell>
                    <Checkbox
                      size="sm"
                      value="all"
                      checked={selectedRows.size === onboardingData.length && onboardingData.length > 0}
                      onClick={() => {
                        handleToggleSelectAllRows();
                      }}
                      className="border-primary-100 group-hover/row:border-primary-400"
                    />
                  </DraggableTableHeaderCell>
                  <DraggableTableHeaderCell className="py-2 pl-0 pr-4">Title</DraggableTableHeaderCell>
                  <DraggableTableHeaderCell className="border-l py-2">Content</DraggableTableHeaderCell>
                </tr>
              </DraggableTableHeader>
              <DraggableTableBody>
                {onboardingData.map(onboardingItem => (
                  <OnboardingInfoTableRow
                    key={onboardingItem._id}
                    row={onboardingItem}
                    onUpdateRow={handleUpdateRow}
                    onSelectRow={handleSelectRow}
                    onDeleteRow={id => handleDeleteRows([id])}
                    onDuplicateRow={id => handleDuplicateRows([id])}
                    isSelected={selectedRows.has(onboardingItem._id)}
                    descriptionError={getDescriptionError(onboardingData.indexOf(onboardingItem))}
                    contentError={getContentError(
                      onboardingData.findIndex(onboarding => onboarding._id === onboardingItem._id)
                    )}
                  />
                ))}

                <DraggableTableNewRowButton onNewRowClick={handleAddRow} />
              </DraggableTableBody>
            </DraggableTable>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
