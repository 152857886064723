import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";

type Payload = {
  name: string;
  resetToken: string;
  password: string;
};

type Response = {
  message: string;
};
const createWorkspaceUser = async (payload: Payload) => {
  const { data } = await restClient.post<Response>(apiPaths.createEnterpriseUser, payload);
  return data;
};

export const useCreateWorkspaceUser = () => {
  return useMutation<Response, AxiosError<{ message: string }>, Payload>({
    mutationFn: createWorkspaceUser,
  });
};
