type AvatarPathMap = Record<string, string>;

export const avatarPathMap = (src: string | undefined): string | undefined => {
  const map: AvatarPathMap = {
    "/assets/images/avatars/1.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/1.svg",
    "/assets/images/avatars/2.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/2.svg",
    "/assets/images/avatars/3.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/3.svg",
    "/assets/images/avatars/4.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/4.svg",
    "/assets/images/avatars/5.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/5.svg",
    "/assets/images/avatars/6.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/6.svg",
    "/assets/images/avatars/7.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/7.svg",
    "/assets/images/avatars/8.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/8.svg",
    "/assets/images/avatars/9.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/9.svg",
    "/assets/images/avatars/10.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/10.svg",
    "/assets/images/avatars/11.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/11.svg",
    "/assets/images/avatars/12.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/12.svg",
    "/assets/images/avatars/13.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/13.svg",
    "/assets/images/avatars/14.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/14.svg",
    "/assets/images/avatars/15.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/15.svg",
    "/assets/images/avatars/16.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/16.svg",
    "/assets/images/avatars/17.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/17.svg",
    "/assets/images/avatars/18.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/18.svg",
    "/assets/images/avatars/19.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/19.svg",
    "/assets/images/avatars/20.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/20.svg",
    "/assets/images/avatars/21.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/21.svg",
    "/assets/images/avatars/22.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/22.svg",
    "/assets/images/avatars/23.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/23.svg",
    "/assets/images/avatars/24.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/24.svg",
    "/assets/images/avatars/25.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/25.svg",
    "/assets/images/avatars/26.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/26.svg",
    "/assets/images/avatars/27.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/27.svg",
    "/assets/images/avatars/28.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/28.svg",
    "/assets/images/avatars/29.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/29.svg",
    "/assets/images/avatars/30.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/30.svg",
    "/assets/images/avatars/31.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/31.svg",
    "/assets/images/avatars/32.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/32.svg",
    "/assets/images/avatars/33.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/33.svg",
    "/assets/images/avatars/34.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/34.svg",
    "/assets/images/avatars/35.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/35.svg",
    "/assets/images/avatars/36.svg": "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/36.svg",
    "/assets/images/user-avatars/ua-1.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-1.svg",
    "/assets/images/user-avatars/ua-2.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-2.svg",
    "/assets/images/user-avatars/ua-3.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-3.svg",
    "/assets/images/user-avatars/ua-4.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-4.svg",
    "/assets/images/user-avatars/ua-5.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-5.svg",
    "/assets/images/user-avatars/ua-6.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-6.svg",
    "/assets/images/user-avatars/ua-7.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-7.svg",
    "/assets/images/user-avatars/ua-8.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-8.svg",
    "/assets/images/user-avatars/ua-9.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-9.svg",
    "/assets/images/user-avatars/ua-10.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-10.svg",
    "/assets/images/user-avatars/ua-11.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-11.svg",
    "/assets/images/user-avatars/ua-12.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-12.svg",
    "/assets/images/user-avatars/ua-13.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-13.svg",
    "/assets/images/user-avatars/ua-14.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-14.svg",
    "/assets/images/user-avatars/ua-15.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-15.svg",
    "/assets/images/user-avatars/ua-16.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-16.svg",
    "/assets/images/user-avatars/ua-17.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-17.svg",
    "/assets/images/user-avatars/ua-18.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-18.svg",
    "/assets/images/user-avatars/ua-19.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-19.svg",
    "/assets/images/user-avatars/ua-20.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-20.svg",
    "/assets/images/user-avatars/ua-21.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-21.svg",
    "/assets/images/user-avatars/ua-22.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-22.svg",
    "/assets/images/user-avatars/ua-23.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-23.svg",
    "/assets/images/user-avatars/ua-24.svg":
      "https://agentx-resources.s3.us-west-1.amazonaws.com/system_user_avatars/ua-24.svg",
  };
  if (!src) {
    return undefined;
  }
  return map[src] || src;
};

export const pickAgentAvatar = (): string => {
  const list = [
    "/assets/images/avatars/1.svg",
    "/assets/images/avatars/2.svg",
    "/assets/images/avatars/3.svg",
    "/assets/images/avatars/4.svg",
    // "/assets/images/avatars/5.svg", // this avatar is sad face
    // "/assets/images/avatars/6.svg", // this avatar is mad face
    "/assets/images/avatars/7.svg",
    "/assets/images/avatars/8.svg",
    "/assets/images/avatars/9.svg",
    "/assets/images/avatars/10.svg",
    // "/assets/images/avatars/11.svg", // this avatar is sad face
    // "/assets/images/avatars/12.svg", // this avatar is mad face
    "/assets/images/avatars/13.svg",
    "/assets/images/avatars/14.svg",
    "/assets/images/avatars/15.svg",
    "/assets/images/avatars/16.svg",
    // "/assets/images/avatars/17.svg", // this avatar is sad face
    // "/assets/images/avatars/18.svg", // this avatar is mad face
    "/assets/images/avatars/19.svg",
    "/assets/images/avatars/20.svg",
    "/assets/images/avatars/21.svg",
    "/assets/images/avatars/22.svg",
    // "/assets/images/avatars/23.svg", // this avatar is sad face
    // "/assets/images/avatars/24.svg", // this avatar is mad face
    "/assets/images/avatars/25.svg",
    "/assets/images/avatars/26.svg",
    "/assets/images/avatars/27.svg",
    "/assets/images/avatars/28.svg",
    "/assets/images/avatars/29.svg",
    "/assets/images/avatars/30.svg",
    "/assets/images/avatars/31.svg",
    "/assets/images/avatars/32.svg",
    "/assets/images/avatars/33.svg",
    "/assets/images/avatars/34.svg",
    "/assets/images/avatars/35.svg",
    "/assets/images/avatars/36.svg",
  ];
  const randomElement = list[Math.floor(Math.random() * list.length)];
  return randomElement;
};

export const pickUserAvatar = (): string => {
  const list = [
    "/assets/images/user-avatars/ua-1.svg",
    "/assets/images/user-avatars/ua-2.svg",
    "/assets/images/user-avatars/ua-3.svg",
    "/assets/images/user-avatars/ua-4.svg",
    "/assets/images/user-avatars/ua-5.svg",
    "/assets/images/user-avatars/ua-6.svg",
    "/assets/images/user-avatars/ua-7.svg",
    "/assets/images/user-avatars/ua-8.svg",
    "/assets/images/user-avatars/ua-9.svg",
    "/assets/images/user-avatars/ua-10.svg",
    "/assets/images/user-avatars/ua-11.svg",
    "/assets/images/user-avatars/ua-12.svg",
    "/assets/images/user-avatars/ua-13.svg",
    "/assets/images/user-avatars/ua-14.svg",
    "/assets/images/user-avatars/ua-15.svg",
    "/assets/images/user-avatars/ua-16.svg",
    "/assets/images/user-avatars/ua-17.svg",
    "/assets/images/user-avatars/ua-18.svg",
    "/assets/images/user-avatars/ua-19.svg",
    "/assets/images/user-avatars/ua-20.svg",
    "/assets/images/user-avatars/ua-21.svg",
    "/assets/images/user-avatars/ua-22.svg",
    "/assets/images/user-avatars/ua-23.svg",
    "/assets/images/user-avatars/ua-24.svg",
  ];
  const randomElement = list[Math.floor(Math.random() * list.length)];
  return randomElement;
};
