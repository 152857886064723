import { useQuery } from "@tanstack/react-query";
import { restClient } from "../initAxios";
import { apiPaths } from "../apiPaths";
import type { Tool } from "@/types/tools";

export const publicToolsKeys = {
  all: ["publicTools"],
};

const getPublicTools = async () => {
  const { data } = await restClient.get<Tool[]>(apiPaths.getPublicTools);
  return data;
};

export const useGetPublicTools = () => {
  return useQuery({
    queryKey: publicToolsKeys.all,
    queryFn: getPublicTools,
  });
};
