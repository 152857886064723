import type { InfiniteData } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "@/data/initAxios";
import { userMessageBookmarksKeys } from "../../queries/useGetUserMessageBookmarks";
import { conversationMessagesKeys } from "@/data/queries/useGetConversationMessages";
import type { ConversationMessagesResponse } from "@/types/conversation";
import { toast } from "react-toastify";
import useIsChatWithPagination from "@/components/Chat/hooks/useIsChatWithPagination";

type Props = {
  messageId: string;
  agentId: string;
  conversationId: string;
};

const createBookmark = async (props: Props) => {
  const { data } = await restClient.post<unknown>(apiPaths.createUserMessageBookmark, {
    messageId: props.messageId,
    agentId: props.agentId,
  });
  return data;
};

export const useCreateMessageBookmark = () => {
  const queryClient = useQueryClient();
  const withPagination = useIsChatWithPagination();

  const mutation = useMutation<unknown, AxiosError<{ message: string }>, Props>({
    mutationFn: createBookmark,
    onSuccess: () => {
      toast.success("Bookmark added!");
      void queryClient.invalidateQueries({ queryKey: userMessageBookmarksKeys.all });
    },
    onMutate: async ({ conversationId, messageId }) => {
      await queryClient.cancelQueries({
        queryKey: conversationMessagesKeys.id({ conversationId }, withPagination),
      });

      queryClient.setQueryData<InfiniteData<ConversationMessagesResponse>>(
        conversationMessagesKeys.id({ conversationId }, withPagination),
        prev => {
          if (!prev) {
            return undefined;
          }

          return {
            pageParams: [...prev.pageParams],
            pages: prev.pages.map(page => {
              return {
                ...page,
                messages: page.messages.map(message => {
                  if (message._id === messageId) {
                    return { ...message, isBookmark: true };
                  }
                  return message;
                }),
              };
            }),
          };
        }
      );
    },
  });

  return mutation;
};
