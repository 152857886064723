import type React from "react";
import type { FieldError, FieldValues, Path, RegisterOptions, UseFormReturn } from "react-hook-form";
import { Controller } from "react-hook-form";
import { Input } from "./input";

export type FormInputProps<T extends FieldValues> = {
  formObj: UseFormReturn<T>;
  name: Path<T>;
  error?: FieldError;
  rules?: RegisterOptions;
  label?: string;
  showRevealPasswordButton?: boolean;
  disableValidationMessage?: boolean;
  containerClassName?: string;
  inputClassName?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "className">;

export const FormInput = <T extends FieldValues>({
  required = false,
  formObj,
  children,
  ...props
}: FormInputProps<T>) => {
  const error = formObj.formState.errors[props.name];

  return (
    <Controller
      control={formObj.control}
      name={props.name}
      rules={{
        required,
        ...props.rules,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        validate: value => !!value.trim(),
      }}
      render={({ field: { onChange, value } }) => (
        <Input value={value || ""} onChange={onChange} required={required} error={error as FieldError} {...props}>
          {children}
        </Input>
      )}
    />
  );
};
