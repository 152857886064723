import { Button } from "@/components/ui/button";
import { ChangeUserAvatar } from "@/components/Profiles/ChangeUserAvatar";
import { MyAccount } from "@/components/Profiles/MyAccount";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { AgentsList } from "@/components/Profiles/AgentsList";
import { AccountActivities } from "@/components/Profiles/AccountActivities";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import { useBookmarkChatHistoryContext } from "@/contexts/BookmarkChatHistoryContext/useBookmarkChatHistoryContext";
import { useLogOut } from "@/hooks/useLogOut";
import { NavigationProfileSection, NavigationProfileSections } from "./NavigationProfileSection";
import { SubscriptionTierButton } from "../navigation/SubscriptionTierButton";
import { useGetUser } from "@/data/queries/useGetUser";
import { Billing } from "./Billing/Billing";
import { Team } from "./Team/Team";

const sectionsMap = {
  [NavigationProfileSections.MY_ACCOUNT]: MyAccount,
  [NavigationProfileSections.AGENTS]: AgentsList,
  [NavigationProfileSections.TEAM]: Team,
  [NavigationProfileSections.BILLING]: Billing,
  [NavigationProfileSections.ACCOUNT_USAGE]: AccountActivities,
};

export const ProfileDialog = () => {
  const { navigationProfileOpen, setNavigationProfileOpen } = useNavigationProfileContext();
  const { setIsOpen } = useBookmarkChatHistoryContext();
  const logOutUser = useLogOut();
  const { user } = useGetUser();

  const logOut = () => {
    logOutUser();
    setNavigationProfileOpen({ open: false, section: null });
    setIsOpen(false);
  };

  const onOpenHandler = (isOpen: boolean) => {
    setNavigationProfileOpen(prev => {
      return {
        open: isOpen,
        section: isOpen ? prev.section : null,
      };
    });
  };

  const ActiveSectionComponent = navigationProfileOpen.section
    ? sectionsMap[navigationProfileOpen.section]
    : () => null;

  return (
    <Dialog onOpenChange={onOpenHandler} open={navigationProfileOpen.open}>
      <DialogContent className="gap-0 p-0">
        <div className="flex h-full flex-col sm:flex-row">
          <div
            className={cn(
              "flex h-full flex-col items-center gap-y-5 border-neutral-200 px-5 py-10 sm:border-r-[1.5px] sm:px-3 md:px-5 lg:px-8",
              { "hidden sm:flex": navigationProfileOpen.section }
            )}
          >
            <ChangeUserAvatar />
            <NavigationProfileSection />

            <div className="mt-auto w-full">
              {user?.subscription.tier !== undefined && (
                <SubscriptionTierButton tier={user.subscription.tier} className="w-full" />
              )}
              <Button variant="ghost" className="mx-auto mt-2 w-full text-base font-medium" onClick={logOut}>
                Log out
              </Button>
            </div>
          </div>
          {navigationProfileOpen.section && (
            <div className="w-full pb-8 sm:pl-3 sm:pt-10 md:pl-5 lg:pl-8">
              <Button
                variant="ghost"
                className="px-4 py-6 sm:hidden"
                onClick={() => {
                  setNavigationProfileOpen(prev => ({ ...prev, section: null }));
                }}
              >
                <Icons.Arrow className="size-5" />
                <span className="sr-only">Go Back to Profile Menu</span>
              </Button>
              <div className="h-[calc(100dvh-54px)] overflow-y-auto p-5 sm:h-full sm:p-0 sm:pr-3 md:pr-5 lg:pr-12">
                <ActiveSectionComponent />
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
