import { useQuery } from "@tanstack/react-query";
import type { Agent } from "@/types/agent";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { ChatAgent } from "@/types/conversation";

export const singleConversationAgentKeys = {
  all: ["singleConversationAgentWithConfig"] as const,
  id: (id: ChatAgent["_id"]) => [...singleConversationAgentKeys.all, id] as const,
};

type Props = {
  agentId: ChatAgent["_id"];
};

export const getSingleConversationAgentWithConfig = async (agentId: Agent["_id"]) => {
  const { data } = await restClient.get<ChatAgent>(apiPaths.getSingleConversationAgentWithConfig(agentId));
  return data;
};

export const useGetSingleConvestationAgentWithConfig = ({ agentId }: Props) => {
  const query = useQuery({
    queryKey: singleConversationAgentKeys.id(agentId),
    queryFn: () => getSingleConversationAgentWithConfig(agentId),
  });

  return query;
};
