import { DialogClose, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { useGetUser } from "@/data/queries/useGetUser";
import { SubscriptionPeriod, SubscriptionTiers } from "@/types/subscriptions";
import { Button } from "@/components/ui/button";
import { useUpdateSubscriptionPlan } from "@/data/mutations/subscriptions/useUpdateSubscriptionPlan";
import { Icons } from "@/components/ui/icons";
import { useGetCustomerBillingInfo } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { AccountUsageEnterpriseCard } from "@/components/dialogs/AccountUsageDialog/components/AccountUsageEnterpriseCard";
import { AccountUsageStartupCard } from "@/components/dialogs/AccountUsageDialog/components/AccountUsageStartupCard";
import { AccountUsageProCard } from "@/components/dialogs/AccountUsageDialog/components/AccountUsageProCard";
import { AccountUsageFreeCard } from "@/components/dialogs/AccountUsageDialog/components/AccountUsageFreeCard";
import { useCancelSubscriptionPlan } from "@/data/mutations/subscriptions/useCancelSubscriptionPlan";
import { AccountUsageScaleCard } from "@/components/dialogs/AccountUsageDialog/components/AccountUsageScaleCard";

const cardComponentsMap = (period: SubscriptionPeriod) => {
  return {
    [SubscriptionTiers.FREE]: <AccountUsageFreeCard />,
    [SubscriptionTiers.STARTUP]: <AccountUsageStartupCard showButton={false} period={period} />,
    [SubscriptionTiers.PRO]: <AccountUsageProCard showButton={false} period={period} />,
    [SubscriptionTiers.SCALE]: <AccountUsageScaleCard showButton={false} period={period} />,
    [SubscriptionTiers.PREMIUM]: <AccountUsageProCard showButton={false} period={period} />,
    [SubscriptionTiers.ENTERPRISE]: <AccountUsageEnterpriseCard onCloseDialog={() => null} />,
  };
};

export const ChangeSubscriptionTier = () => {
  const { selectedPlan, setActiveStep, closeDialog } = useSubscriptionTierDialogContext();
  const { user } = useGetUser();
  const { data: billingInfo } = useGetCustomerBillingInfo();
  const { mutate: updateSubscriptionPlan, isPending: isSubscriptionPlanUpdating } = useUpdateSubscriptionPlan();
  const { mutate: cancelSubscriptionPlan, isPending: isSubscriptionPlanCancelling } = useCancelSubscriptionPlan();

  if (!user) {
    return <h2 className="px-8 py-6">Could not retrieve user data. Please try again later.</h2>;
  }
  if (!selectedPlan) {
    return <h2 className="px-8 py-6">Could not retrieve plan data. Please try again later.</h2>;
  }
  if (!billingInfo) {
    return <h2 className="px-8 py-6">Could not retrieve billing data. Please try again later.</h2>;
  }

  const currentSubscriptionTier = user.subscription.tier;

  const CurrentSubscriptionTierCard = cardComponentsMap(
    billingInfo.subscription.interval === "month" ? SubscriptionPeriod.MONTHLY : SubscriptionPeriod.ANNUALLY
  )[currentSubscriptionTier];
  const TargetSubscriptionTierCard = cardComponentsMap(selectedPlan.period)[selectedPlan.plan.tier];

  const handleChangeSubscription = () => {
    selectedPlan.plan.tier === SubscriptionTiers.FREE
      ? cancelSubscriptionPlan(undefined, {
          onSuccess: () => {
            closeDialog();
          },
        })
      : updateSubscriptionPlan(
          { targetPlanId: selectedPlan.plan.price[selectedPlan.period].planId },
          {
            onSuccess: () => {
              setActiveStep("stripePaymentSuccess");
            },
          }
        );
  };

  return (
    <>
      <DialogHeader className="min-h-12">
        <Button size="custom" variant="ghost" onClick={() => setActiveStep("subscriptionTiers")}>
          <Icons.Arrow className="size-6" />
          <span className="sr-only">go back to plans</span>
        </Button>
        <span className="ml-5 font-medium">Confirm plan change</span>
      </DialogHeader>
      <div className="flex flex-col gap-6 px-8 py-6">
        <p className="text-sm font-medium text-primary-black">
          Please review the detail of your new plan and click “Confirm” when ready.
        </p>
        <div className="mx-auto grid max-w-[700px] grid-cols-[1fr_100px_1fr] items-center gap-5">
          {CurrentSubscriptionTierCard}
          <div className="flex flex-col items-center justify-center text-center text-xs font-medium text-neutral-750">
            <Icons.Arrow className="size-8 rotate-180 text-primary-400" />
            <span className="rounded-full border border-neutral-300 bg-neutral-200 px-2 py-1">changing to</span>
          </div>
          {TargetSubscriptionTierCard}
        </div>
      </div>
      <DialogFooter>
        <DialogClose asChild>
          <Button variant="secondary">Cancel</Button>
        </DialogClose>
        <Button loading={isSubscriptionPlanCancelling || isSubscriptionPlanUpdating} onClick={handleChangeSubscription}>
          Confirm
        </Button>
      </DialogFooter>
    </>
  );
};
