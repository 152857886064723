import type { ReactNode } from "react";

type BottomBarProps = { children: ReactNode };

export const BottomBar = ({ children }: BottomBarProps) => {
  return (
    <div className="absolute inset-x-[-1px] bottom-0 flex h-[88px] items-center gap-2 border border-neutral-200 bg-white p-4">
      {children}
    </div>
  );
};
