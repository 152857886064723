import type { ChartData } from "chart.js";
import type React from "react";
import { Doughnut } from "react-chartjs-2";
import { StatsSingleAgentRowChannelIcon } from "./StatsSingleAgentRowChannelIcon";

type Props = {
  value: number;
  total: number;
  name: string;
  icon: React.ReactNode;
  color: string;
};

export const StatsSingleAgentRowDetailsChart = ({ value, total, name, icon, color }: Props) => {
  const getChartData = () => {
    if (total === 0) {
      return [value, 1];
    }

    return [value, total - value];
  };

  const chartData: ChartData<"doughnut", number[], unknown> = {
    datasets: [
      {
        data: getChartData(),
        backgroundColor: [color, "#E5E7EB"],
      },
    ],
  };

  const getPercentValue = () => {
    if (total === 0) {
      return 0;
    }
    return ((value / total) * 100).toFixed(0);
  };

  return (
    <div className="relative mb-4 flex h-fit flex-col">
      <StatsSingleAgentRowChannelIcon icon={icon} />
      <Doughnut
        data={chartData}
        height={80}
        width={80}
        options={{
          maintainAspectRatio: false,
          aspectRatio: 1,
          responsive: true,
          cutout: "68%",
          layout: {
            padding: 0,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
        }}
      />
      <p className="absolute left-1/2 top-20 -translate-x-1/2 whitespace-nowrap text-sm font-medium">
        {name}
        <span className="text-neutral-400">{` ${getPercentValue()}%`}</span>
      </p>
    </div>
  );
};
