import { billingPlansKeys } from "./../../queries/subscriptions/useGetBillingPlans";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError, AxiosResponse } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { toast } from "react-toastify";
import { userKeys } from "@/data/queries/useGetUser";

export type CreateSubscriptionPlanBody = {
  planId: string;
  customerDetails: {
    name: string;
    address: {
      line1: string;
      line2: string | null | undefined;
      city: string;
      postal_code: string;
      state: string;
      country: string;
    };
  };
  paymentMethodId: string;
  coupon?: string;
};
type Response = {
  success: boolean;
};
const createSubscriptionPlan = async (props: CreateSubscriptionPlanBody) => {
  const { data } = await restClient.post<Response, AxiosResponse<Response>, CreateSubscriptionPlanBody>(
    apiPaths.createSubscriptionPlan,
    props
  );
  return data;
};

export const useCreateSubscriptionPlan = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<{ message: string }>, CreateSubscriptionPlanBody>({
    mutationFn: createSubscriptionPlan,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: userKeys.all }),
        queryClient.invalidateQueries({ queryKey: billingPlansKeys.all }),
      ]);
      toast.success("Subscription created successfully");
    },
  });
};
