import type { TypeFromConstObject } from "./types";

export const DateRangeTypes = {
  Today: "Today",
  Yesterday: "Yesterday",
  Last7Days: "Last 7 days",
  Last14Days: "Last 14 days",
  Last30Days: "Last 30 days",
  Last60Days: "Last 60 days",
  Last90Days: "Last 90 days",
  Custom: "Custom range",
} as const;

export type DateRangeType = TypeFromConstObject<typeof DateRangeTypes>;

export const dateRangeTypes = Object.values(DateRangeTypes);
