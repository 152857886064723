import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { User } from "@/types/user";

type Props = {
  password: string;
  resetToken: string;
};

const updatePassword = async (props: Props) => {
  const { data } = await restClient.post<User>(apiPaths.updatePassword, props);
  return data;
};

export const useUpdatePassword = () => {
  const mutation = useMutation<User, AxiosError<{ message: string }>, Props>({
    mutationFn: updatePassword,
  });

  return mutation;
};
