import { AccountUsagePricingCard } from "./AccountUsagePricingCard";
import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";

export const AccountUsageEnterpriseCard = ({ onCloseDialog }: { onCloseDialog: () => void }) => {
  const navigate = useNavigate();

  return (
    <AccountUsagePricingCard
      title="Enterprise Plan"
      btn={{
        text: "Contact us",
        variant: "primary",
        onClick: () => {
          navigate(ROUTES.help);
          onCloseDialog();
        },
        className: "w-full",
      }}
      isHighlighted
      className="py-8"
    >
      <p className="mr-10 text-xs font-medium leading-5 text-primary-black">
        Our Enterprise pricing plan is tailored for business with specific needs. For customised solutions and dedicated
        support, please contact our team.
      </p>
    </AccountUsagePricingCard>
  );
};
