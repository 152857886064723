import { ROUTES } from "@/constants/routes";
import { useGetUser } from "@/data/queries/useGetUser";
import { useLocation, useNavigate } from "react-router-dom";

export const CommunityHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useGetUser();

  const isMyCommunityProfileActive = pathname.includes(ROUTES.communityProfile(user?._id || ""));

  return (
    <div className="flex w-full flex-col items-center justify-center gap-2.5 rounded-t-[20px] border-b border-neutral-300 px-2.5 pt-8 shadow lg:pt-14">
      <div className="flex gap-10">
        <div
          className={`flex cursor-pointer pb-1 text-base ${
            !isMyCommunityProfileActive && "border-b-2 border-violet-600 font-bold"
          }`}
          onClick={() => navigate(ROUTES.community)}
        >
          Agent Space
        </div>
        <div
          className={`flex cursor-pointer pb-1 text-base ${
            isMyCommunityProfileActive && "border-b-2 border-violet-600 font-bold"
          }`}
          onClick={() => navigate(ROUTES.communityProfile(user?._id || ""))}
        >
          My profile
        </div>
      </div>
    </div>
  );
};
