import { createContext } from "react";
import type { DateRangeType } from "@/types/datepicker";
import type { StatsPageChannelTab } from "./StatsProvider";

type StatsProps = {
  currentRange: DateRangeType;
  setCurrentRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedAgentId: string | null;
  setSelectedAgentId: (agentId: string | null) => void;
  conversationsListAgentId: string;
  setConversationsListAgentId: React.Dispatch<React.SetStateAction<string>>;
  isConversationsListOpen: boolean;
  activeChannelsOrigins: number[];
  activeChannelTab: StatsPageChannelTab;
  switchChannelTab: ({ newTab }: { newTab: StatsPageChannelTab }) => void;
};

export const StatsContext = createContext<StatsProps | null>(null);
