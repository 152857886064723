import type { EngagementOverviewStat } from "@/types/stats";
import { ENGAGEMENT_STATS_COLORS_MAP } from "../../constants/statsConstants";
import { BarChartBarItem, BarChartContainer, BarChartTooltip } from "@/components/ui/bar-chart";
import { getChartLabelForDates } from "../../utils/getChartLabelForDates";

export const StatsEngagementOverviewChart = ({ data }: { data: EngagementOverviewStat[] | undefined }) => {
  const maxValue = Math.max(...(data?.flatMap(period => [period.messages, period.conversations, period.agents]) ?? []));

  return (
    <BarChartContainer itemsLength={data?.length ?? 0}>
      {({ barWidth, labelInterval }) => {
        return data?.map((period, index) => {
          const data = [
            {
              id: "1",
              label: "Agents",
              value: period.agents,
              color: ENGAGEMENT_STATS_COLORS_MAP.agents.color,
              colorTransparent: ENGAGEMENT_STATS_COLORS_MAP.agents.colorTransparent,
            },
            {
              id: "2",
              label: "Messages",
              value: period.messages,
              color: ENGAGEMENT_STATS_COLORS_MAP.messages.color,
              colorTransparent: ENGAGEMENT_STATS_COLORS_MAP.messages.colorTransparent,
            },
            {
              id: "3",
              label: "Conversations",
              value: period.conversations,
              color: ENGAGEMENT_STATS_COLORS_MAP.conversations.color,
              colorTransparent: ENGAGEMENT_STATS_COLORS_MAP.conversations.colorTransparent,
            },
          ];

          const showLabel = index % labelInterval === 0;

          return (
            <BarChartBarItem
              key={`${period.intervalStartDate}-${period.intervalEndDate}`}
              maxValue={maxValue}
              data={data}
              barStyle={{
                width: barWidth,
                maxWidth: "40px",
                minWidth: "16px",
                margin: "0 2px",
              }}
              renderLabel={() =>
                showLabel && (
                  <div className="absolute left-1/2 top-full mt-4 -translate-x-1/2">
                    <p className="text-center text-sm font-normal leading-4 text-neutral-400">
                      {getChartLabelForDates(period.intervalStartDate, period.intervalEndDate)}
                    </p>
                  </div>
                )
              }
              renderTooltip={() => (
                <BarChartTooltip
                  renderTitle={() => (
                    <p className="my-2 text-xl font-medium text-white">
                      {getChartLabelForDates(period.intervalStartDate, period.intervalEndDate)}
                    </p>
                  )}
                  data={data}
                />
              )}
            />
          );
        });
      }}
    </BarChartContainer>
  );
};
