import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { FullAgent } from "@/types/agent";

export const workspaceBasicAgentsKeys = {
  all: ["workspaceBasicAgents"] as const,
};
const getWorkspaceBasicAgents = async () => {
  const { data } = await restClient.get<FullAgent[]>(apiPaths.getWorkspaceBasicAgents);

  return data;
};

export const useGetWorkspaceBasicAgents = ({ enabled }: { enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: workspaceBasicAgentsKeys.all,
    queryFn: getWorkspaceBasicAgents,
    enabled,
  });
};
