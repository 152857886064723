import { useQuery } from "@tanstack/react-query";
import { restClient } from "../initAxios";
import { apiPaths } from "../apiPaths";
import type { Agent } from "@/types/agent";
import type { Conversation } from "@/types/conversation";

export const agentConversationsKeys = {
  all: ["agentConversations"] as const,
  id: (agentId: Agent["_id"]) => [...agentConversationsKeys.all, agentId] as const,
};

type Props = {
  agentId: Agent["_id"];
};

type AgentConversation = {
  _id: Conversation["_id"];
  createdAt: Conversation["createdAt"];
  lastActivity: Conversation["lastActivity"];
  title: string;
};

const getAgentConversations = async ({ agentId }: Props) => {
  const { data } = await restClient.get<AgentConversation[]>(apiPaths.getAgentConversations(agentId));
  return data;
};

export const useGetAgentConversations = ({ agentId }: Props) => {
  return useQuery({
    queryKey: agentConversationsKeys.id(agentId),
    queryFn: () => getAgentConversations({ agentId }),
  });
};
