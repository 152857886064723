import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import * as Sentry from "@sentry/react";

Sentry.init({
  enabled: !!import.meta.env.VITE_ENV && import.meta.env.VITE_ENV !== "dev",
  dsn: import.meta.env.VITE_SENTRY_DSN as string,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/agentx\.so\/api\/v1/, import.meta.env.VITE_CHAT_BUBBLE_URL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.VITE_ENV as string,
});

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_PUBLIC_KEY as string}>
      <App />
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);
