import { MyProfile } from "@/components/Profiles/MyProfile";
import { AccountSecurity } from "@/components/Profiles/AccountSecurity";
import { GenerateNewAPIKey } from "@/components/Profiles/GenerateNewAPIKey";
import { AssociatedAccount } from "@/components/Profiles/AssociatedAccount";
import { DeleteAccount } from "./DeleteAccount";
import { useGetUser } from "@/data/queries/useGetUser";

export const MyAccount = () => {
  const { user } = useGetUser();
  const canDeleteAccount = user?.customer == null;
  return (
    <>
      <MyProfile />
      <AccountSecurity />
      <GenerateNewAPIKey />
      <AssociatedAccount />
      {canDeleteAccount && <DeleteAccount />}
    </>
  );
};
