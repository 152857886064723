import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { Agent } from "@/types/agent";

type Props = {
  agentIds: Agent["_id"][];
};

const createAgentInteractionStat = async ({ agentIds }: Props) => {
  const { data } = await restClient.post<string>(apiPaths.createAgentInteractionStat, {
    agentIds,
  });
  return data;
};

export const useCreateAgentInteractionStat = () => {
  const mutation = useMutation<string, AxiosError<{ message: string }>, Props>({
    mutationFn: createAgentInteractionStat,
  });

  return mutation;
};
