import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import type { BillingPlanCoupon } from "@/types/subscriptions";
import { SubscriptionPeriod } from "@/types/subscriptions";
import { useRef } from "react";
import { cn } from "@/lib/utils";
import type { F1 } from "@/types/types";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { toast } from "react-toastify";

type StripePaymentDetailsProps = {
  setCoupon: F1<string | undefined, void>;
  couponQuery: UseQueryResult<BillingPlanCoupon, Error>;
};
export const StripePaymentDetails = ({ setCoupon, couponQuery }: StripePaymentDetailsProps) => {
  const { selectedPlan } = useSubscriptionTierDialogContext();
  const couponInputRef = useRef<HTMLInputElement>(null);
  const { data: couponData, isLoading: isLoadingCoupon } = couponQuery;
  if (!selectedPlan) {
    toast.error("There was an error while retreiving your plan information.");
    return null;
  }
  const planPricingInfo = selectedPlan.plan.price[selectedPlan.period];
  const planPrice = planPricingInfo.amount.toFixed(2);

  const planPeriodFormatted = selectedPlan.period === SubscriptionPeriod.MONTHLY ? "month" : "year";

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    const inputValue = couponInputRef.current?.value;
    if (inputValue) {
      setCoupon(inputValue);
      couponInputRef.current.value = "";
    }
  };

  return (
    <div className="gap-6 rounded-md border border-neutral-300 p-4">
      <h2 className="mb-1 text-[1.75rem] font-bold text-neutral-750">{selectedPlan.plan.long_name}</h2>
      <div className="mt-3 space-y-6 divide-y pl-8">
        <div className="flex flex-col gap-4">
          <p className="text-xs font-medium leading-4 text-neutral-500">{selectedPlan.plan.description}</p>
          <span className="ml-auto text-xs font-medium text-neutral-500">
            ${planPrice} / {planPeriodFormatted}
          </span>
        </div>
        <div className="pt-6">
          <div className="flex items-center justify-between gap-2.5 text-xs font-bold text-neutral-750">
            Subtotal<span>${planPrice}</span>
          </div>
          {couponData && (
            <div className="mt-1 flex justify-between text-xs">
              {couponData.coupon}
              <span className="font-medium text-error">
                - ${(couponData.originalPrice - couponData.discountPrice).toFixed(2)}
              </span>
            </div>
          )}
          <form onSubmit={handleSubmit} className="mt-2 flex items-center justify-between gap-2.5">
            <Input type="text" ref={couponInputRef} placeholder="Add promotion code" />
            <Button type="submit" loading={isLoadingCoupon}>
              Apply
            </Button>
          </form>
        </div>
        <div className="pt-6">
          <div
            className={cn("font-meidum flex items-center justify-between gap-2.5 text-sm leading-5 text-neutral-750", {
              "text-success": couponData?.discountPrice,
            })}
          >
            Total due <span>${couponData?.discountPrice.toFixed(2) || planPrice}</span>
          </div>
          {/*<div className="flex items-center justify-between gap-2.5 text-sm font-bold leading-6 text-primary-black">
            Total due today <span>$0.00</span>
          </div>*/}
        </div>
      </div>
    </div>
  );
};
