/* eslint-disable import/no-cycle */
import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";
import { useLikeAgent } from "@/data/mutations/useLikeAgent";
import AgentTileWrapper from "./components/AgentTileWrapper";
import AgentTileCreatorInfo from "./components/AgentTileCreatorInfo";
import AgentTileEditOptions from "./components/AgentTileEditOptions";
import AgentTileLikeOptions from "./components/AgentTileLikeOptions";
import type { User } from "@/types/user";
import { memo } from "react";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { LocalStorageHelpers } from "@/utils/LocalStorageHelpers";

type AgentTileProps = {
  agent: {
    _id: string;
    name: string;
    description?: string;
    avatar: string;
    creatorUser?: {
      _id: User["_id"];
      name: User["name"];
      avatar: User["avatar"];
      email: User["email"];
    };
    likedByUser?: boolean;
    likes?: number;
    publishedToCommunity: boolean;
  };
  displayLikeOptions?: true;
  displayEditOptions?: boolean;
  hideLikesCount?: true;
};

const _AgentTile = ({ agent, displayLikeOptions, displayEditOptions, hideLikesCount }: AgentTileProps) => {
  const navigate = useNavigate();
  const { mutate } = useLikeAgent();

  const likeAgent = () => {
    mutate({ agentId: agent._id, status: !agent.likedByUser, profileId: agent.creatorUser?._id || "" });
  };

  const onTileClick = () => {
    const lastConversationId = LocalStorageHelpers.getAgentsLocalStorageConversation([agent._id]);

    const newRoute = lastConversationId
      ? ROUTES.conversation(lastConversationId)
      : getNewConversationRoute([agent._id]);

    navigate(newRoute);
  };

  const getBottomInfo = () => (
    <AgentTileCreatorInfo
      agent={{
        creatorUser: agent.creatorUser,
        likedByUser: agent.likedByUser,
        likes: agent.likes,
      }}
      onLikeAgentClick={likeAgent}
      hideLikesCount={hideLikesCount}
    />
  );

  return (
    <AgentTileWrapper
      onClick={onTileClick}
      agent={{
        name: agent.name,
        avatar: agent?.avatar,
        description: agent.description,
      }}
      renderBottomInfo={getBottomInfo}
    >
      {displayEditOptions && (
        <AgentTileEditOptions
          agent={{
            _id: agent._id,
            name: agent.name,
          }}
        />
      )}

      {displayLikeOptions && (
        <AgentTileLikeOptions
          agent={{
            _id: agent._id,
            name: agent.name,
            likedByUser: agent.likedByUser,
          }}
          onLikeAgentClick={likeAgent}
        />
      )}
    </AgentTileWrapper>
  );
};

export const AgentTile = memo(_AgentTile);
