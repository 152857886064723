import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { StrippedCommunityAgent } from "@/types/community";

export const communityFeaturedAgentsKeys = {
  all: ["featuredAgents"] as const,
};
const getCommunityFeaturedAgents = async () => {
  const { data } = await restClient.get<StrippedCommunityAgent[]>(apiPaths.getCommunityFeaturedAgents);
  return data;
};

export const useGetCommunityFeaturedAgents = () => {
  return useQuery({
    queryKey: communityFeaturedAgentsKeys.all,
    queryFn: getCommunityFeaturedAgents,
  });
};
