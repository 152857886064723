import { useState } from "react";
import type { EmbedKnowledges } from "@/types/agent";
import { useFieldArray } from "react-hook-form";
import { Icons } from "@/components/ui/icons";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { useGetUser } from "@/data/queries/useGetUser";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";
import { arrayMove } from "@dnd-kit/sortable";
import { v4 as uuidv4 } from "uuid";
import { Checkbox } from "@/components/ui/checkbox";
import FAQTableRow from "./FAQTableRow";
import { checkIfKnowledgeLimitExceeded } from "@/utils/checkIfKnowledgeLimitExceeded";
import {
  DraggableTable,
  DraggableTableActionPopup,
  DraggableTableBody,
  DraggableTableHeader,
  DraggableTableHeaderCell,
  DraggableTableNewRowButton,
} from "@/components/ui/draggable-table";
import { Button } from "@/components/ui/button";
import { KnowledgeLimitInfoBadge } from "./KnowledgeLimitInfoBadge";
import { domElementIds } from "@/types/dom-element-ids";

export const FAQ = () => {
  const form = useAgentFormContext();

  const { user } = useGetUser();
  const { openDialog } = useAccountUsageDialogContext();

  const {
    control,
    formState: { errors },
  } = form;

  const handleOpenAccountUsageDialog = () => openDialog("document");

  const userFaqLimit = user?.subscription.knowledge.faqs?.limit;

  const isFAQLimitExceeded = checkIfKnowledgeLimitExceeded({
    currentUsage: user?.subscription.knowledge.faqs?.currentUsage,
    limit: userFaqLimit,
  });

  const {
    fields: faqData,
    append: appendFaq,
    remove: removeFaq,
    update: updateFaq,
  } = useFieldArray({
    control,
    name: "embedKnowledges.faq",
  });

  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

  const showTableActionPopup = selectedRows.size > 0;

  const handleAddRow = () => {
    const newOnboarding = {
      _id: uuidv4(),
      description: "",
      content: "",
      predefined: false,
    } as EmbedKnowledges;

    appendFaq(newOnboarding);
  };

  const handleUpdateRow = ({
    _id,
    description,
    content,
    predefined,
  }: {
    _id: string;
    description?: string;
    content?: string;
    predefined?: boolean;
  }) => {
    const updatedFaqData = faqData.find(faqItem => faqItem._id === _id);

    if (!updatedFaqData) {
      return;
    }

    if (description !== undefined) {
      updatedFaqData.description = description;
    }

    if (content !== undefined) {
      updatedFaqData.content = content;
    }

    if (predefined !== undefined) {
      updatedFaqData.predefined = predefined;
    }

    updateFaq(
      faqData.findIndex(faqItem => faqItem._id === _id),
      updatedFaqData
    );
  };

  const handleSelectRow = (id: string) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(id)) {
      newSelectedRows.delete(id);
    } else {
      newSelectedRows.add(id);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleResetSelectedRows = () => {
    setSelectedRows(new Set());
  };

  const handleDeleteRows = (ids: string[]) => {
    const indexesToDelete = ids.map(id => faqData.findIndex(item => item._id === id));

    removeFaq(indexesToDelete);
    handleResetSelectedRows();
  };

  const handleDuplicateRows = (ids: string[]) => {
    const newFaqData = ids.map(id => {
      const originalFaq = faqData.find(faqItem => faqItem._id === id);

      return {
        description: `${originalFaq?.description} copy` ?? "",
        content: originalFaq?.content ?? "",
        _id: uuidv4(),
        predefined: originalFaq?.predefined ?? false,
      } as EmbedKnowledges;
    });

    form.setValue("embedKnowledges.faq", [...faqData, ...newFaqData], { shouldDirty: true });
    handleResetSelectedRows();
  };

  const handleToggleSelectAllRows = () => {
    if (selectedRows.size === faqData.length) {
      setSelectedRows(new Set());
    } else {
      setSelectedRows(new Set(faqData.map(faqItem => faqItem._id)));
    }
  };

  const handleDragEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const updatedFaqData = arrayMove(faqData, oldIndex, newIndex);
    form.setValue("embedKnowledges.faq", updatedFaqData, { shouldDirty: true });
  };

  const getDescriptionError = (index: number) => {
    const error = errors?.embedKnowledges?.faq?.[index]?.description?.message ?? "";
    return error;
  };

  const getContentError = (index: number) => {
    const error = errors?.embedKnowledges?.faq?.[index]?.content?.message ?? "";
    return error;
  };

  const faqError = errors?.embedKnowledges?.faq?.message ?? "";

  return (
    <Accordion id={domElementIds.AGENT_FORM_FAQ} type="single" collapsible defaultValue="faq">
      <AccordionItem value="faq">
        <AccordionTrigger>
          <div className="relative flex items-center gap-2 pr-1.5 text-left">
            <h4>4. Frequently asked questions</h4>
            <HybridTooltipPopover heading="FAQs and conversation starters:">
              <p>
                Expand agent's knowledge from frequently asked questions. Select up to five of them as conversation
                starters. These can be displayed at the beginning of each conversation.
              </p>
            </HybridTooltipPopover>

            <KnowledgeLimitInfoBadge currentUsage={faqData.length} limit={userFaqLimit} />
            {faqError && <p className="absolute -bottom-3 left-6 text-xxs leading-3 text-red-500">{faqError}</p>}
          </div>
        </AccordionTrigger>

        <AccordionContent className="overflow-x-auto pb-0">
          <div className="p-4">
            {showTableActionPopup && (
              <DraggableTableActionPopup
                numOfSelectedRows={selectedRows.size}
                onDelete={() => handleDeleteRows(Array.from(selectedRows))}
                onDuplicate={() => handleDuplicateRows(Array.from(selectedRows))}
              />
            )}
            <DraggableTable onDragEndAction={handleDragEnd} itemsIds={faqData.map(item => item._id)}>
              <DraggableTableHeader>
                <tr className="grid grid-cols-[auto_minmax(200px,_2fr)_minmax(200px,_3fr)_minmax(200px,_1fr)] border-t">
                  <DraggableTableHeaderCell>
                    <Checkbox
                      size="sm"
                      value="all"
                      checked={selectedRows.size === faqData.length && faqData.length > 0}
                      onClick={() => {
                        handleToggleSelectAllRows();
                      }}
                      className="border-primary-100 group-hover/row:border-primary-400"
                    />
                  </DraggableTableHeaderCell>
                  <DraggableTableHeaderCell className="py-2 pl-0 pr-4">Question</DraggableTableHeaderCell>
                  <DraggableTableHeaderCell className="border-l py-2">Answer</DraggableTableHeaderCell>
                  <DraggableTableHeaderCell className="flex items-center justify-start gap-1.5 border-l py-2 pr-0">
                    Conversation Starter
                    <HybridTooltipPopover heading="Display the question:">
                      <p>Toggle the switch to select which FAQs will be displayed at the start of each conversation</p>
                    </HybridTooltipPopover>
                  </DraggableTableHeaderCell>
                </tr>
              </DraggableTableHeader>

              <DraggableTableBody>
                {faqData.map(faqItem => (
                  <FAQTableRow
                    key={faqItem._id}
                    row={faqItem}
                    onUpdateRow={handleUpdateRow}
                    onSelectRow={handleSelectRow}
                    onDeleteRow={id => handleDeleteRows([id])}
                    onDuplicateRow={id => handleDuplicateRows([id])}
                    isSelected={selectedRows.has(faqItem._id)}
                    descriptionError={getDescriptionError(faqData.indexOf(faqItem))}
                    contentError={getContentError(faqData.findIndex(onboarding => onboarding._id === faqItem._id))}
                  />
                ))}

                <DraggableTableNewRowButton
                  onNewRowClick={handleAddRow}
                  isDisabled={isFAQLimitExceeded}
                  disabledNode={
                    <>
                      <div className="flex items-center gap-4 pl-1">
                        <Icons.BlockRound />
                        <Button
                          onClick={handleOpenAccountUsageDialog}
                          variant="ghost"
                          size="custom"
                          className="static mr-[3px] p-0 font-bold text-purple-400"
                        >
                          Upgrade<span className="ml-1 text-neutral-750">to add more knowledge from links</span>
                        </Button>
                      </div>
                    </>
                  }
                />
              </DraggableTableBody>
            </DraggableTable>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
