import { Button } from "@/components/ui/button";
import type { AgentFormFields, DomainLinks, EmbedKnowledges, FullAgent } from "@/types/agent";
import { KnowledgeStatus } from "@/types/agent";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Icons } from "@/components/ui/icons";
import type { FieldArrayWithId, UseFieldArrayRemove, UseFieldArrayUpdate } from "react-hook-form";

type LinksAccordionProps = {
  webData: FieldArrayWithId<FullAgent, "embedKnowledges.web", "id">[];
  removeWeb: UseFieldArrayRemove;
  sortedWebData: DomainLinks[];
  updateWeb: UseFieldArrayUpdate<AgentFormFields, "embedKnowledges.web">;
};

export const LinksAccordion = ({ webData, removeWeb, sortedWebData, updateWeb }: LinksAccordionProps) => {
  const removeSingleLink = (link: EmbedKnowledges) => {
    const index = webData.findIndex(web => web.source === link.source);
    removeWeb(index);
  };

  const removeMultipleLinks = (links: EmbedKnowledges[]) => {
    const linkIndices = links.map(link => webData.findIndex(web => web.source === link.source));
    removeWeb(linkIndices);
  };

  const refreshLink = (link: EmbedKnowledges) => {
    updateWeb(
      webData.findIndex(web => web.source === link.source),
      {
        ...link,
        status: KnowledgeStatus.REFRESH,
      }
    );
  };

  const refreshMultipleLinks = (links: EmbedKnowledges[]) => {
    links.forEach(link => {
      updateWeb(
        webData.findIndex(web => web.source === link.source),
        {
          ...link,
          status: KnowledgeStatus.REFRESH,
        }
      );
    });
  };

  const renderStatusBadge = (status: string) => {
    switch (status) {
      case KnowledgeStatus.FAILED:
        return (
          <span className="mr-1 inline-flex items-center rounded-full border border-red-500 bg-red-50 px-2 py-1 text-xs font-medium text-neutral-800 disabled:border-red-100 disabled:bg-red-100 disabled:text-red-200">
            <Icons.Error />
            <span className="pl-1">Failed</span>
          </span>
        );
      case KnowledgeStatus.REFRESH:
        return (
          <span className="mr-1 inline-flex items-center rounded-full border border-primary-500 bg-primary-50 px-2 py-1 text-xs font-medium text-neutral-800 disabled:border-primary-100 disabled:bg-primary-100 disabled:text-primary-200">
            <Icons.Refresh />
            <span className="pl-1">Refresh</span>
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {sortedWebData.map(data => (
        <Accordion
          type="single"
          collapsible
          className="my-2 rounded-md border border-slate-200 bg-white"
          key={data.domain}
        >
          <AccordionItem value={`online-info-${data.domain}`} className="relative">
            <AccordionTrigger className="h-10 border-slate-200 px-4 text-xs font-medium text-neutral-800 hover:no-underline data-[state=open]:border-b">
              <div className="flex w-full items-center justify-between gap-2 pr-2">
                <div className="flex gap-2">
                  <div className="flex h-4 min-w-[16px] items-center justify-center rounded-full border border-primary-500 bg-white px-1 text-[10px] font-medium text-primary-500">
                    {data.links.length}
                  </div>
                  <div className="break-all text-left">{data.domain}</div>
                </div>
                <div className="flex gap-2">
                  <Button
                    variant="ghost"
                    type="button"
                    size="action-icon"
                    onClick={() => refreshMultipleLinks(data.links)}
                  >
                    <Icons.Refresh className="text-neutral-400" />
                  </Button>
                  <Button
                    variant="ghost"
                    type="button"
                    size="action-icon"
                    onClick={() => removeMultipleLinks(data.links)}
                  >
                    <Icons.Trash className="text-neutral-400" />
                  </Button>
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="flex flex-col bg-neutral-100 p-2">
              <div key={data.domain}>
                {data.links.map(link => (
                  <div
                    key={link._id}
                    className="my-2 flex w-full cursor-pointer items-center justify-between gap-2 rounded-md border border-slate-200 bg-white px-2"
                  >
                    <div>
                      {renderStatusBadge(link.status)}
                      <a
                        href={link.source}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="break-all text-xs font-medium text-neutral-800 hover:underline"
                      >
                        {link.source}
                      </a>
                    </div>
                    <div className="flex gap-2">
                      <Button variant="ghost" type="button" size="action-icon" onClick={() => refreshLink(link)}>
                        <Icons.Refresh className="text-neutral-400" />
                      </Button>
                      <Button variant="ghost" type="button" size="action-icon" onClick={() => removeSingleLink(link)}>
                        <Icons.Trash className="text-neutral-400" />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  );
};
