import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { AdvancedCustomizationContent } from "./AdvancedCustomizationContent";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";

export const AdvancedCustomization = () => {
  return (
    <Accordion type="single" collapsible className="w-full border-b border-neutral-100">
      <AccordionItem value="outer">
        <AccordionTrigger>
          <div className="flex gap-1 pb-2">
            <h4>Advanced Tool</h4>
            <HybridTooltipPopover heading="Boost Your Agent's Abilities With Advanced Tool:">
              <p>
                Advanced Tool is a module designed to provide programmatical control over your agent's behavior. It
                allows you to add parameters and coding logic to your agent. With Advanced Tool, you can create
                customized functions, automate repetitive tasks, connect with third-party API, and streamline your
                agents' workflow.
              </p>
            </HybridTooltipPopover>
            <p className="text-primary-500">(UPGRADE)</p>
          </div>
        </AccordionTrigger>
        <AccordionContent className="flex flex-1 flex-col">
          <AdvancedCustomizationContent />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
