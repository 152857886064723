import { Avatar } from "@/components/Avatar";
import { useGetUser } from "@/data/queries/useGetUser";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useUpdateAvatarUser } from "@/data/mutations/useUpdateAvatarUser";
import { FILE_INPUT_USER_ID, MAX_IMAGE_SIZE } from "@/constants/images";
import { isImageFormatAllowed } from "@/utils/fileUploads";
import { toast } from "react-toastify";

export const ChangeUserAvatar = () => {
  const { user } = useGetUser();
  const { mutate } = useUpdateAvatarUser();
  const [loadingUploadAvatar, setLoadingUploadAvatar] = useState(false);

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];
    if (!image) {
      return;
    }
    if (!isImageFormatAllowed(image)) {
      return toast.error("Image format not allowed");
    }
    if (image.size > MAX_IMAGE_SIZE) {
      return toast.error("Image size too large");
    }
    setLoadingUploadAvatar(true);
    const formData = new FormData();
    formData.append("avatar", image);
    mutate(formData, {
      onSuccess: () => {
        setLoadingUploadAvatar(false);
        toast.success("Avatar updated!");
      },
    });
  };

  const openImagePicker = () => {
    const input = document.getElementById(FILE_INPUT_USER_ID);
    if (input) {
      input.click();
    }
  };

  return (
    <div className="relative mx-auto">
      <Avatar src={user?.avatar} name={user?.name} className="size-[120px] rounded-full lg:size-[145px]" size="full" />
      <input
        type="file"
        accept="image/*"
        hidden
        name="avatar"
        onChange={uploadImage}
        id={FILE_INPUT_USER_ID}
        className="hidden"
      />
      <Button
        className="absolute bottom-0 size-full rounded-full bg-primary-400 text-xs font-bold text-slate-950"
        style={{ clipPath: "inset(75% 0 0 0)" }}
        onClick={openImagePicker}
        loading={loadingUploadAvatar}
      >
        <span className="absolute inset-x-0 bottom-2.5 text-center text-white lg:bottom-4">edit</span>
      </Button>
    </div>
  );
};
