import { useState, type PropsWithChildren } from "react";
import { AccountUsageContext } from "@/contexts/AccountUsageDialogContext/AccountUsageDialogContext";
import type { UsageExceededType } from "@/types/subscriptions";
import { AccountUsageDialog } from "@/components/dialogs/AccountUsageDialog/AccountUsageDialog";

export const AccountUsageProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const [usageExceededType, setUsageExceededType] = useState<UsageExceededType | null>(null);

  const openDialog = (type: UsageExceededType) => {
    setIsOpen(true);
    setUsageExceededType(type);
  };

  const value = {
    isOpen,
    openDialog,
  };

  return (
    <AccountUsageContext.Provider value={value}>
      {usageExceededType && isOpen && (
        <AccountUsageDialog isDialogOpen={isOpen} setShowDialog={setIsOpen} type={usageExceededType} />
      )}
      {children}
    </AccountUsageContext.Provider>
  );
};
