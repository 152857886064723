import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { SingleAgentEngagementStats, StatsInterval, StatsQueryFilters } from "@/types/stats";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";
import type { Agent } from "@/types/agent";

export const singleAgentEngagementStats = {
  all: ["singleAgentEngagementStats"] as const,
  params: ({
    agentId,
    startDateParam,
    endDateParam,
    channels,
    interval,
  }: {
    agentId: Agent["_id"];
    startDateParam: string;
    endDateParam: string;
    channels: number[];
    interval: StatsInterval;
  }) => [...singleAgentEngagementStats.all, agentId, startDateParam, endDateParam, channels, interval] as const,
};

const getSingleAgentEngagementStats = async ({
  agentId,
  startDate,
  endDate,
  channels,
  interval,
}: {
  agentId: Agent["_id"];
  startDate: string;
  endDate: string;
  channels: number[];
  interval: StatsInterval;
}) => {
  const { data } = await restClient.get<SingleAgentEngagementStats>(apiPaths.getSingleAgentEngagementStats(agentId), {
    params: {
      startDate,
      endDate,
      channels: channels.join(","),
      interval,
      timezone: StatisticsHelpers.getTimezone(),
    },
  });
  return data;
};

type Props = StatsQueryFilters & {
  agentId: Agent["_id"];
  interval: StatsInterval;
  enabled?: boolean;
};

export const useGetSingleAgentEngagementStats = (props: Props) => {
  const { currentRange, startDate, endDate, channels, interval, enabled, agentId } = props;

  const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
  const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

  const query = useQuery({
    queryKey: singleAgentEngagementStats.params({ agentId, startDateParam, endDateParam, channels, interval }),
    queryFn: () =>
      getSingleAgentEngagementStats({ agentId, startDate: startDateParam, endDate: endDateParam, channels, interval }),
    enabled: !!startDateParam && !!endDateParam && enabled,
    placeholderData: keepPreviousData,
  });

  return query;
};
