export enum RelationType {
  USER = "USER",
  AGENT = "AGENT",
  CONVERSATION = "CONVERSATION",
  COMMUNITY = "COMMUNITY",
  CATEGORY = "CATEGORY",
  LIKE = "LIKE",
  SUBSCRIPTION = "SUBSCRIPTION",
  BOOKMARK = "BOOKMARK",
  EMAIL = "EMAIL",
  MESSAGE = "MESSAGE",
  SHARE = "SHARE",
}
