import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { useCopyNotify } from "@/hooks/useCopyNotify";

type EmbedScriptProps = {
  chatBubbleScript: string;
};
export const EmbedScript = ({ chatBubbleScript }: EmbedScriptProps) => {
  const [, copy] = useCopyNotify();
  return (
    <div>
      <Label className="text-md">Embed</Label>
      <p className="mt-2 text-xs font-medium text-neutral-300">
        {"Copy and paste the following script at the end of your HTML file within the <body> section"}
      </p>
      <Textarea value={chatBubbleScript} className="mt-4 h-40 resize-none overflow-hidden bg-primary-50" />
      <div className="mt-2 flex justify-end">
        <Button onClick={() => copy(chatBubbleScript)}>Copy</Button>
      </div>
    </div>
  );
};
