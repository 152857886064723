import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import type { ExternalAgent, SlackAgent } from "@/types/agent";
import { externalAgentsKeys } from "@/data/queries/useGetExternalAgents";

type Response = {
  message: string;
  externalAgent: SlackAgent;
};

type Payload = {
  agentId: string;
};

const createSlackTemporaryId = async (payload: Payload) => {
  const { data } = await restClient.post<Response, AxiosResponse<Response>, Payload>(
    apiPaths.createSlackTemporaryId,
    payload
  );
  return data;
};

export const useCreateSlackTemporaryId = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<Response, AxiosError<Response>, Payload>({
    mutationFn: createSlackTemporaryId,
    onSuccess: ({ externalAgent }, { agentId }) => {
      queryClient.setQueryData<ExternalAgent[]>(externalAgentsKeys.id(agentId), prev => {
        if (!prev) {
          return [externalAgent];
        }
        return [...prev, externalAgent];
      });
      toast.success("Slack register ID created successfully.");
    },
  });

  return mutation;
};
